/********************************************************************
 *
 * AuthProvider.jsx
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2024 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

import React, { useEffect, useState } from "react";
import queryString from "query-string";

import CCAPIs from "../utils/CCAPIs";

const AuthContext = React.createContext({
  user: null,
  getResourceAccessToken: () => null,
  logout: () => null,
});

//
//  List of resources used by Admin App
//
const appResources = [
  {
    code: process.env.REACT_APP_AUTH_RESOURCE_ADMIN_API,
    scope: "staff admin",
  },
  {
    code: process.env.REACT_APP_AUTH_RESOURCE_PUBLIC_API,
    scope: "staff admin",
  },
  {
    code: process.env.REACT_APP_AUTH_RESOURCE_ANALYTICS_API,
    scope: "staff admin",
  },
  {
    code: process.env.REACT_APP_AUTH_RESOURCE_OPENID_API,
    scope: "openid email address phone profile user_role",
  },
];

/**
 * AuthProvider
 *
 * Implements Authentication and Authorization.
 *
 * The Auth API authenticates a user. If successful sets an JWT in an
 * HTTP-Only cookie and returns the user. The application stores the
 * user information in the context for convienience, but it should not
 * be used for authentication or managing sessions.
 *
 * @param {*} param0
 */
const AuthProvider = (props) => {
  const [user, setUser] = useState(null);

  //
  //  Initialization
  //
  useEffect(() => {
    initUser();
  }, []);

  ///////////////////////////////////////////////////////////////////////
  //
  //  Utility Methods
  //
  ///////////////////////////////////////////////////////////////////////

  const initUser = () => {
    const openIdConnect = CCAPIs(
      process.env.REACT_APP_OPENIDCONNET_URI,
      getResourceAccessToken(process.env.REACT_APP_AUTH_RESOURCE_OPENID_API)
    );

    openIdConnect
      .fetch(`/userinfo/`)
      .then(({ sub, name, given_name, family_name, email, phone, picture }) => {
        setUser({
          id: sub,
          fname: given_name,
          lname: family_name,
          email: email,
          phone: phone,
          imageUrl: picture,
        });
      })
      .catch((error) => {
        //
        //  Was not able to fetch or refresh a valid access token
        //  with account details, so authenticate
        //
        authenticate();
      });
  };

  ///////////////////////////////////////////////////////////////////////
  //
  //  Functions provided by Provider
  //
  ///////////////////////////////////////////////////////////////////////

  /**
   * GetResourceAccessToken
   *
   * Fetches and verifies the requested access token. If the token
   * is not valid, request an updated access token.
   *
   * @param {*} resourceCode
   *
   * @returns  If the token is valid, then function returns the
   * token.
   */
  const getResourceAccessToken = (resourceCode) => {
    return new Promise((resolve, reject) => {
      //
      //  Get local access token
      //
      getAccessToken(resourceCode)
        .then((accessToken) => {
          if (!accessToken)
            throw new Error(
              `Failed to fetch Access Token for Resource: ${resourceCode}`
            );
          resolve(accessToken);
        })
        .catch((error) => {
          reject(error);
          // authenticate();
        });
    });
  };

  /**
   * Logout
   *
   * Terminates user's session
   */
  const logout = () => {
    const connect = CCAPIs(
      process.env.REACT_APP_OPENIDCONNET_URI,
      getResourceAccessToken(process.env.REACT_APP_AUTH_RESOURCE_OPENID_API)
    );

    connect
      .create(`/session/logout/`)
      .then(() => {
        setUser(null);
        Promise.all(
          appResources.map((resource) => {
            return clearAccessToken(resource.code);
          })
        );
        authenticate();
      })
      .catch((error) => {
        throw error;
      });
  };

  return user ? (
    <AuthContext.Provider value={{ user, getResourceAccessToken, logout }}>
      {props.children}
    </AuthContext.Provider>
  ) : null;
};

/**
 * AuthCallback
 *
 * Callback handler that recieves and processes the Admin site
 * authorization token after a user signs in using the Canadian
 * Coastal OAuth server.
 *
 * @param {*} props
 */
const AuthCallback = ({ location }) => {
  const { code, state } = queryString.parse(location.search);
  const { uri = "/" } = decodeState(state);
  const [error, setError] = useState(null);

  if (!code) throw new Error("Authorization was denied.");

  //
  //  Use the authCode to initialize the list of resource Access Tokens
  //
  Promise.all(
    appResources.map((resource) => {
      return fetchTokensWithAuthCode(code, resource.code, resource.scope);
    })
  )
    .then(() => {
      //
      //  Navigate to user's selected URI stored in the state parameter
      //
      window.location.href = uri;
    })
    .catch((error) => {
      setError(error.message);
    });

  return error ? (
    <div
      dangerouslySetInnerHTML={{
        __html: `${error}`,
      }}
    />
  ) : (
    ""
  );
};

/////////////////////////////////////////////////////////////////////
//
//  Helper Functions
//
/////////////////////////////////////////////////////////////////////

/**
 * FetchTokensWithAuthCode
 *
 * @param {*} authCode
 * @param {*} resourceCode
 * @param {*} scope
 */
const fetchTokensWithAuthCode = (authCode, resourceCode, scope) => {
  const auth = CCAPIs(process.env.REACT_APP_AUTH_API_URL);

  return new Promise((resolve, reject) => {
    auth
      .create(`/oauth/v2/token/`, {
        client_id: process.env.REACT_APP_AUTH_CLIENT_CODE,
        grant_type: "authorization_code",
        code: authCode,
        resource: resourceCode,
        scope,
      })
      .then(({ access_token, refresh_token, expires_in }) => {
        setAccessToken(resourceCode, access_token, refresh_token, expires_in);
        resolve(access_token);
      })
      .catch((error) => {
        //
        //  Not authorized. Return to sign in.
        //
        if (error.status && error.status === 401) authenticate();
        //
        // Other Error
        //
        else reject(error);
      });
  });
};

/**
 * RefreshAccessTokens
 *
 * @param {*} refreshToken
 * @param {*} resourceCode
 */
const refreshAccessTokens = (refreshToken, resourceCode) => {
  const auth = CCAPIs(process.env.REACT_APP_AUTH_API_URL);

  return new Promise((resolve, reject) => {
    auth
      .create(`/oauth/v2/token/`, {
        client_id: process.env.REACT_APP_AUTH_CLIENT_CODE,
        grant_type: "refresh_token",
        refresh_token: refreshToken,
        resourceCode,
      })
      .then(({ access_token, refresh_token, expires_in }) => {
        setAccessToken(resourceCode, access_token, refresh_token, expires_in);
        resolve(access_token);
      })
      .catch((error) => {
        //
        //  Not authorized. Return to sign in.
        //
        if (error.status && error.status === 401) authenticate();
        //
        // Other Error
        //
        else reject(error);
      });
  });
};

/**
 * Authenticate
 *
 * Starts the OAuth flow to authenticate the user.
 *
 */
const authenticate = () => {
  let oauth = `${
    process.env.REACT_APP_AUTH_API_URL
  }/signin/v2/challenge/?client_id=${
    process.env.REACT_APP_AUTH_CLIENT_CODE
  }&response_type=${encodeURIComponent(
    "code"
  )}&redirect_uri=${encodeURIComponent(
    process.env.REACT_APP_AUTH_REDIRECT_URI
  )}&state=${encodeState({
    uri: window.location.href,
    data: null,
  })}`;

  window.location.href = oauth;
};

/////////////////////////////////////////////////////////////////////
//
//  Token Storage Functions
//
//  Token data is stored in the site's local storage.
//
//  Storage Record:
//
//  {
//      a: AccessToken,
//      r: RefreshToken,
//      e: Expiry
//  }
//
/////////////////////////////////////////////////////////////////////

/**
 * SetAccessToken
 *
 * Sets the token data for a resource.
 *
 * @param {*} token
 *
 * @returns Returns nothing
 */
const setAccessToken = (key, accessToken, refreshToken, expiry) => {
  if (!key) throw new Error("Parameter 'key' is required.");
  if (!accessToken) throw new Error("Parameter 'accessToken' is required.");
  if (!refreshToken) throw new Error("Parameter 'refreshToken' is required.");
  if (!expiry) throw new Error("Parameter 'expiry' is required.");

  return localStorage.setItem(
    key,
    JSON.stringify({
      a: accessToken,
      r: refreshToken,
      e: Date.now() + expiry * 1000,
    })
  );
};

/**
 * GetAccessToken
 *
 * Fetches the access token for a resource. If the token has expired,
 * then refresh before returning it.
 *
 * @param {*} key
 *
 * @returns Returns a Promise that resolves to an Access Token
 */
const getAccessToken = (key) => {
  return new Promise((resolve, reject) => {
    Promise.resolve()
      .then(() => {
        if (!key) throw new Error("Parameter 'key' is required.");

        let data = localStorage.getItem(key);

        if (!data) throw new Error(`Request for invalid Access Token: ${key}`);

        //
        //  Parse string
        //
        data = JSON.parse(data);

        //
        //  If access token expired, refresh.
        //
        if (data.e < Date.now()) return refreshAccessTokens(data.r, key);

        return data.a;
      })
      .then((accessToken) => {
        resolve(accessToken);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

/**
 * ClearAccessToken
 *
 * Clears token data for a specific resource
 *
 * @param {*}
 *
 * @returns Returns nothing
 */
const clearAccessToken = (key) => {
  return new Promise((resolve, reject) => {
    Promise.resolve()
      .then(() => {
        if (!key) throw new Error("Parameter 'key' is required.");

        localStorage.removeItem(key);
        resolve();
      })
      .catch((error) => {
        reject(error);
      });
  });
};

/////////////////////////////////////////////////////////////////////
//
//  Utility Functions
//
/////////////////////////////////////////////////////////////////////

/**
 * EncodeState
 */
const encodeState = (state) => {
  return btoa(JSON.stringify(state));
};

/**
 * DecodeState
 *
 * @param {*} encoded
 */
const decodeState = (encoded) => {
  return JSON.parse(atob(encoded));
};

/////////////////////////////////////////////////////////////////////
//
//  Exported Hooks
//
/////////////////////////////////////////////////////////////////////

const useAuth = () => {
  return React.useContext(AuthContext);
};

const useUser = () => {
  const auth = useAuth();
  return auth && auth.user;
};

export default AuthProvider;
export { AuthCallback, useAuth, useUser };
