/********************************************************************
 *
 * SessionEvents/index.jsx
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2024 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

import React from "react";
import PropTypes from "prop-types";
import { AppBar, Tabs, Tab, Box } from "@material-ui/core";
import ProductSearchEvents from "../../../lib/Analytics/Events/ProductSearchEvents";
import OrderEvents from "../../../lib/Analytics/Events/OrderEvents";

/**
 * SessionEvents
 *
 * Renders the events of a session
 */
const SessionEvents = ({ session }) => {
  const [tab, setTab] = React.useState(0);

  const onChangeTab = (event, value) => {
    setTab(value);
  };

  if (!session) return "No session record";

  return (
    <Box style={{ padding: "20px" }}>
      <AppBar position="static">
        <Tabs value={tab} onChange={onChangeTab}>
          <Tab id="tab-0" label="Searches" />
          <Tab id="tab-1" label="Orders" />
        </Tabs>
      </AppBar>
      <TabPanel value={tab} index={0}>
        <ProductSearchEvents session={session} />
      </TabPanel>
      <TabPanel value={tab} index={1}>
        <OrderEvents session={session} />
      </TabPanel>
    </Box>
  );
};

const TabPanel = ({ value, index, children, ...other }) => {
  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Box>
  );
};

SessionEvents.propTypes = {
  sessions: PropTypes.array,
};

export default SessionEvents;
