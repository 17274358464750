/********************************************************************
 *
 * ProductType.jsx
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2020 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

import React, { useState, useEffect } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { Avatar } from "@material-ui/core";
import {
  Collections,
  AssignmentTwoTone,
  SettingsTwoTone,
} from "@material-ui/icons";
import { Main } from "../../lib";
import {
  SectionList,
  SectionItemGroup,
  SectionItem,
} from "../../lib/SideBar/Menu";
import Error404 from "../../Error/404";
import Identity from "./Identity/Identity";
import Partners from "./Partners/Partners";
import RateClasses from "./Rates/RateClasses";
import Products from "./Products/Products";
import FeatureTypes from "./Features/FeatureTypes";
import { useApp, useAPI } from "../../../providers/AppProvider";

/**
 * ProductType
 *
 * Container component for managing a product type and its feature
 * types and rates.
 *
 * The product type id is passed in the URI and the object initialized
 * though a call to the API.
 *
 */
const ProductType = ({ match, history }) => {
  const [productType, setProductType] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const { productTypeId } = match.params;
  const app = useApp();
  const api = useAPI();

  /**
   * ComponentDidMount
   *
   * Fired when the component initially mounts.
   *
   */
  useEffect(() => {
    //
    //  If product type id is a paramter, we are in edit mode, otherwise
    //  we are creating a new product type
    //
    fetchProductType(productTypeId);
  }, [productTypeId]);

  ///////////////////////////////////////////////////////////////////
  //
  //  Utility methods
  //
  ///////////////////////////////////////////////////////////////////

  /**
   * FetchProductType
   *
   * Calls the API to fetch the requested product type.
   *
   * If the product type id is null, create a shell product type.
   *
   * @param {*} id
   */
  const fetchProductType = (id) => {
    //
    //  Status message
    //
    const notifyHandle = app.notify("Loading");

    return new Promise((resolve, reject) => {
      if (!id || id === 0) {
        //
        //  New Product Type Mode - initialize empty object
        //
        resolve({ featureTypes: {} });
        return;
      }

      api
        .fetch(`/api/producttypes/producttype/${id}?verbose`)
        .then(({ payload: productType }) => {
          setProductType(productType);
          setLoaded(true);
          app.endNotify(notifyHandle);
        })
        .catch((error) => {
          app.error({ error });
          setProductType(null);
          setLoaded(true);
        });
    });
  };

  ///////////////////////////////////////////////////////////////////
  //
  //  Event Handlers
  //
  ///////////////////////////////////////////////////////////////////

  /**
   * OnUpdate
   *
   * Fired when a child updates the data object.
   *
   * @param {*} event
   */
  const onUpdate = () => {
    //
    //  If product type id is a paramter, we are in edit mode, otherwise
    //  we are creating a new product type
    //
    fetchProductType(productTypeId);
  };

  /**
   * onDelete
   *
   * Handles a request in display mode to delete a feature type.
   *
   * @param {*} event
   */
  const onDelete = (event) => {
    if (!window.confirm("Are you sure you want to delete this product type?"))
      return;

    api
      .delete(`/api/producttypes/producttype/${productType.id}`)
      .then(() => {
        history.push(`/app/catalog/producttypes/`);
      })
      .catch((error) => {
        app.error({ error });
      });
  };

  ///////////////////////////////////////////////////////////////////////
  //
  //  Lifecycle methods
  //
  ///////////////////////////////////////////////////////////////////////

  return (
    <Main
      menu={{
        icon: <Collections />,
        title: "Product Management",
        subtitle: "Edit content, rates and distribution",
        nav: <Menu productType={productType} />,
      }}
      header={{
        icon: !!productType && !!productType.imageURL && (
          <Avatar alt={productType.name} src={productType.imageURL} />
        ),
        title: productType ? productType.name : "New Product",
        subtitle: productType && productType.target,
        callout: productType &&
          productType.deleted && { label: "deleted", color: "error" },
      }}
      loaded={loaded}
    >
      <Router productType={productType} onUpdate={onUpdate} />
    </Main>
  );
};

/**
 * Menu
 *
 * Renders the navigation controls for the functional areas of the
 * product type management page.
 *
 * @param {*} props
 */
const Menu = ({ productType }) => {
  const { id = 0 } = productType || {};

  return (
    <SectionList>
      <SectionItemGroup
        icon={<AssignmentTwoTone />}
        name="Inventory"
        open={true}
      >
        <SectionItem to={`/app/catalog/producttype/${id}/products`}>
          Products
        </SectionItem>
      </SectionItemGroup>
      <SectionItemGroup
        icon={<SettingsTwoTone />}
        name="Configuration"
        open={true}
      >
        <SectionItem to={`/app/catalog/producttype/${id}/description`}>
          Description
        </SectionItem>
        <SectionItem to={`/app/catalog/producttype/${id}/features`}>
          Features
        </SectionItem>
        <SectionItem to={`/app/catalog/producttype/${id}/rateclasses`}>
          Rates
        </SectionItem>
        <SectionItem to={`/app/catalog/producttype/${id}/partners`}>
          Partners
        </SectionItem>
      </SectionItemGroup>
    </SectionList>
  );
};

/**
 * Router
 *
 * Renders the routing logic for the page
 *
 * @param {*} props
 */
const Router = ({ productType, onUpdate }) => {
  if (!productType) return "";

  return (
    <Switch>
      {/* Identity */}
      <Route
        path="/app/catalog/producttype/:productTypeId/description"
        render={(props) => (
          <Identity {...props} productType={productType} onUpdate={onUpdate} />
        )}
      />
      {/* Features */}
      <Route
        path="/app/catalog/producttype/:productTypeId/features"
        render={(props) => (
          <FeatureTypes
            {...props}
            productType={productType}
            featureTypes={productType.featureTypes}
            onUpdate={onUpdate}
          />
        )}
      />
      {/* Partners */}
      <Route
        path="/app/catalog/producttype/:productTypeId/partners"
        render={(props) => <Partners {...props} productType={productType} />}
      />
      {/* RateClasses */}
      <Route
        path="/app/catalog/producttype/:productTypeId/rateclasses"
        render={(props) => <RateClasses {...props} productType={productType} />}
      />
      {/* Products */}
      <Route
        path="/app/catalog/producttype/:productTypeId/products"
        render={(props) => <Products {...props} productType={productType} />}
      />
      {/* Redirect */}
      <Route
        path="/app/catalog/producttype/:productTypeId"
        render={() => (
          <Redirect
            to={`/app/catalog/producttype/${productType.id}/products`}
          />
        )}
      />
      <Route path="/app/catalog/" component={Error404} />
    </Switch>
  );
};

export default ProductType;
