/********************************************************************
 *
 * /Sales/Orders/Sales.jsx
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2020 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Box, Typography } from "@material-ui/core";
import { Edit } from "@material-ui/icons";
import Pagination from "../../lib/Pagination";
import LiveSearch from "../../lib/LiveSearch";
import format from "../../../utils/format";
import { useApp, useAPI } from "../../../providers/AppProvider";

const PAGE_SIZE = 15;

/**
 * Sales
 *
 * Renders a searchable paged list of Orders.
 */
const Sales = ({ setTitle }) => {
  const [orders, setOrders] = useState(null);
  const [orderCount, setOrderCount] = useState(0);
  const [currPage, setCurrPage] = useState(1);
  const [pageSize, setPageSize] = useState(PAGE_SIZE);
  const [match, setMatch] = useState("");
  const [loaded, setLoaded] = useState(false);
  const app = useApp();
  const api = useAPI();

  useEffect(() => {
    setTitle("Sales");
  }, []);

  useEffect(() => {
    fetchOrders(currPage, pageSize, match);
  }, [currPage, pageSize, match]);

  /////////////////////////////////////////////////////////////////////
  //
  //  Utility methods
  //
  /////////////////////////////////////////////////////////////////////

  /**
   * fetchOrders
   *
   * Calls the api to fetch orders.
   *
   * Orders can be queried by matching user names or text in order
   * notes. Results can be paged.
   *
   * @param {*} currPage
   * @param {*} pageSize
   * @param {*} match
   */
  const fetchOrders = (currPage, pageSize, match) => {
    //
    //  Status message
    //
    const notifyHandle = app.notify("Loading");

    api
      .fetch(
        `/api/orders/?match=${match}&page=${currPage}&limit=${pageSize}&verbose`
      )
      .then(({ payload: orders, max: count }) => {
        setOrders(orders);
        setOrderCount(count);
        setLoaded(true);
        app.endNotify(notifyHandle);
      })
      .catch((error) => {
        app.error({ error });
        setLoaded(true);
      });
  };

  ///////////////////////////////////////////////////////////////////////
  //
  //  Event Handlers
  //
  ///////////////////////////////////////////////////////////////////////

  /**
   * onSearch
   *
   * Fired when the user enters a search phrase.
   *
   * @param {*} e
   */
  const onSearch = ({ match }) => {
    setCurrPage(1);
    setMatch(match);
  };

  /**
   * Fired when the user changes the current page.
   *
   * @param {*} e
   */
  const onTurnPage = ({ currPage }) => {
    setCurrPage(currPage);
  };

  ///////////////////////////////////////////////////////////////////////
  //
  //  Lifecycle methods
  //
  ///////////////////////////////////////////////////////////////////////

  return (
    <Box>
      <>
        <Box
          display="flex"
          alignItems="baseLine"
          justifyContent="space-between"
          flexWrap="wrap"
          mb={2}
        >
          <LiveSearch match={match} onSearch={onSearch} />
          <Pagination
            currPage={currPage}
            totalRecords={orderCount}
            pageSize={pageSize}
            pageNeighbours={1}
            onPageChanged={onTurnPage}
          />
        </Box>
        {loaded ? (
          orders ? (
            <PaginatedTable orders={orders} />
          ) : (
            "No Orders Available"
          )
        ) : (
          ""
        )}
      </>
    </Box>
  );
};

///////////////////////////////////////////////////////////////////////
//
//  Functional Components
//
///////////////////////////////////////////////////////////////////////

/**
 * PaginatedTable
 *
 * @param {*} param0
 */
const PaginatedTable = ({ orders }) => {
  if (!orders) return <div className="h4">No Orders Available</div>;

  return (
    <div className="table-responsive small">
      <table className="table table-striped table-sm">
        <thead>
          <tr>
            <th>Booked</th>
            <th>Order</th>
            <th>Name</th>
            <th>Account</th>
            <th>Confirmation</th>
            <th>Comments</th>
            <th />
          </tr>
        </thead>
        <tbody>
          {orders.map((order) => (
            <tr
              key={order.id}
              style={order.deleted ? { textDecoration: "line-through" } : null}
              className={order.deleted ? "text-muted" : null}
            >
              <td>{format.date(order.committed)}</td>
              <td>
                <Link
                  className={order.deleted ? "text-muted" : null}
                  to={`/app/sales/order/${order.id}`}
                >
                  {order.id}
                </Link>
              </td>
              <td>
                <Link
                  className={order.deleted ? "text-muted" : null}
                  to={`/app/sales/order/${order.id}`}
                >
                  {order.name}
                </Link>
              </td>
              <td>
                {order.user ? (
                  <Link
                    className={order.deleted ? "text-muted" : null}
                    to={`/app/crm/customer/${order.customer.id}`}
                  >
                    {order.customer.fname} {order.customer.lname}
                  </Link>
                ) : (
                  "Unknown"
                )}
              </td>
              <td>{order.confirmation}</td>
              <td className="text">
                <span>{order.message}</span>
              </td>
              <td>
                <Link to={`/app/sales/order/${order.id}`}>
                  <Edit fontSize="small" />
                </Link>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Sales;
