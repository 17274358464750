/********************************************************************
 *
 * /Sales/Orders/Order.jsx
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2020 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

import React, { useState, useEffect } from "react";
import {
  Grid,
  Box,
  Typography,
  AppBar,
  Toolbar,
  Tooltip,
  IconButton,
  makeStyles,
} from "@material-ui/core";
import {
  AddPhotoAlternateOutlined,
  LowPriorityOutlined,
  ReceiptOutlined,
} from "@material-ui/icons";
import { Switch, Route, Redirect } from "react-router-dom";
import { Main } from "../../lib";
import {
  SectionList,
  SectionItemGroup,
  SectionItem,
} from "../../lib/SideBar/Menu";
import Error404 from "../../Error/404";
import Details from "./Details";
import Accounting from "./Accounting";
import ReceiptDetails from "./ReceiptDetails";
import Reviews from "./Reviews";
import TextFieldEditor from "../../lib/TextFieldEditor";
import format from "../../../utils/format";
import { useApp, useAPI } from "../../../providers/AppProvider";

/**
 * Order
 *
 * Container component for managing an order.
 *
 */
const Order = ({ match }) => {
  const classes = styles();
  const [order, setOrder] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const app = useApp();
  const api = useAPI();

  const { id } = match.params;

  useEffect(() => {
    fetchOrder(id);
  }, [id]);

  ///////////////////////////////////////////////////////////////////////
  //
  //  Utility Methods
  //
  ///////////////////////////////////////////////////////////////////////

  /**
   * fetchOrder
   *
   * Calls the API to fetch the specified order
   *
   * @param {*} orderId
   */
  const fetchOrder = (orderId) => {
    //
    //  Status message
    //
    const notifyHandle = app.notify("Loading Order");

    api
      .fetch(`/api/orders/order/${orderId}?verbose`)
      .then(({ payload: order }) => {
        if (!order) throw new Error("Order Not Found");

        setOrder(order);
        setLoaded(true);
        app.endNotify(notifyHandle);
      })
      .catch((error) => {
        app.error({ error });
        setLoaded(true);
      });
  };

  ///////////////////////////////////////////////////////////////////////
  //
  //  Event Handlers
  //
  ///////////////////////////////////////////////////////////////////////

  /**
   * onSaveOrder
   *
   * Calls the API to update the order
   */
  const onSaveOrder = ({ order }) => {
    //
    //  Status message
    //
    const notifyHandle = app.notify("Saving Order");

    if (!order) {
      app.error({ error: "Parameter 'order' cannot be null." });
      return;
    }

    api
      .update(`/api/orders/order/${order.id}?verbose`, order)
      .then(({ payload: order }) => {
        setOrder(order);
        app.endNotify(notifyHandle);
      })
      .catch((error) => {
        app.error({ error });
      });
  };

  /**
   * onSendOrder
   *
   * Calls the API to send the order details
   */
  const onSendOrder = (order) => {
    let notifyHandle = null;

    if (
      !window.confirm(
        `Would you like to send order information to ${order.customer.fname} ${order.customer.lname}`
      )
    )
      return;

    //
    //  Status message
    //
    notifyHandle = app.notify("Sending Order");

    if (!order) {
      app.error({ error: "Parameter 'order' cannot be null." });
      return;
    }

    api
      .create(
        `/api/management/orders/order/${order.id}/communications/order`,
        order
      )
      .then(() => {
        app.endNotify(notifyHandle);
      })
      .catch((error) => {
        app.error({ error });
      });
  };

  const onAddReceipt = ({ order, receipt }) => {
    if (!order.receipts.items) order.receipts.items = [];

    order.receipts.items.push(receipt);

    setOrder(order);
  };

  ///////////////////////////////////////////////////////////////////////
  //
  //  Lifecycle methods
  //
  ///////////////////////////////////////////////////////////////////////

  return (
    <Main
      menu={{
        title: "Order Management",
        subtitle: "Review and Edit Orders",
        nav: <Menu order={order} />,
      }}
      header={{
        title: !!order ? `Order ${order ? order.id : ""}` : "New Order",
        subtitle: order ? (
          <TextFieldEditor
            title="Order Name"
            className={classes.orderName}
            content={order.name}
            onSave={(value) => {
              order.name = value;
              onSaveOrder({ order });
            }}
          />
        ) : (
          "Create a new Order"
        ),

        bonus: order && (
          <Grid container direction="column" justify="flex-end">
            <Grid item>
              <TextFieldEditor
                title="Purchase Order"
                prefix="Purchase Order"
                content={order.PO}
                onSave={(value) => {
                  order.PO = value;
                  onSaveOrder({ order });
                }}
              />
              <Typography variant="caption" component="div">
                Created {format.dateTime(order.timestamp)} by {order.user.fname}{" "}
                {order.user.lname}
              </Typography>
            </Grid>
          </Grid>
        ),
      }}
      loaded={loaded}
      context={
        order && (
          <AppBar
            color="transparent"
            position="static"
            elevation={1}
            style={{ backgroundColor: "#efefef" }}
          >
            <Toolbar variant="dense">
              <Tooltip title="Add New Item">
                <IconButton
                  href={`/app/catalog/producttypes/?orderId=${order.id}`}
                >
                  <AddPhotoAlternateOutlined />
                </IconButton>
              </Tooltip>
              <Tooltip title="Resend Order Details">
                <IconButton
                  disabled={!order.confirmation}
                  onClick={() => {
                    order && onSendOrder(order);
                  }}
                >
                  <LowPriorityOutlined />
                </IconButton>
              </Tooltip>
            </Toolbar>
          </AppBar>
        )
      }
    >
      <>
        <Box style={{ padding: "24px" }}>
          {order && (
            <Router
              order={order}
              onUpdated={(order) => setOrder(order)}
              onAddReceipt={onAddReceipt}
              onSaveOrder={onSaveOrder}
            />
          )}
        </Box>
      </>
    </Main>
  );
};

/**
 * Renders the navigation controls for the functional areas of the
 * order management page.
 *
 * @param {*} props
 */
const Menu = ({ order }) => {
  const { id = 0 } = order || {};

  return (
    <SectionList id="sidebar-nav">
      <SectionItemGroup icon="" name="Order" open={true}>
        <SectionItem to={`/app/sales/order/${id}/details`}>Details</SectionItem>
        <SectionItem to={`/app/sales/order/${id}/accounting`}>
          Transactions
        </SectionItem>
        <SectionItem to={`/app/sales/order/${id}/receipts`}>
          Receipt Details
        </SectionItem>
        <SectionItem to={`/app/sales/order/${id}/reviews`}>Reviews</SectionItem>
      </SectionItemGroup>
    </SectionList>
  );
};

/**
 * Renders the routing logic for the page
 *
 * @param {*} props
 */
const Router = ({ order, onSaveOrder, onAddReceipt, onUpdated }) => {
  return (
    <Switch>
      {/* Details */}
      <Route
        path="/app/sales/order/:orderId/details"
        render={(props) => (
          <Details
            {...props}
            order={order}
            onSave={onSaveOrder}
            onUpdated={onUpdated}
          />
        )}
      />
      {/* Accounting */}
      <Route
        path="/app/sales/order/:orderId/accounting"
        render={(props) => <Accounting {...props} order={order} />}
      />
      {/* Receipt Details */}
      <Route
        path="/app/sales/order/:orderId/receipts"
        render={(props) => (
          <ReceiptDetails
            {...props}
            order={order}
            onAddReceipt={onAddReceipt}
          />
        )}
      />
      {/* Reviews */}
      <Route
        path="/app/sales/order/:orderId/reviews"
        render={(props) => <Reviews {...props} order={order} />}
      />
      {/* Redirect */}
      <Route
        path="/app/sales/order/:orderId"
        render={() => <Redirect to={`/app/sales/order/${order.id}/details`} />}
      />
      <Route path="/app/sales/" component={Error404} />
    </Switch>
  );
};

const styles = makeStyles((theme) => ({
  orderName: {
    height: "20px",
    fontSize: "20px",
    display: "inline-block",
  },
}));

export default Order;
