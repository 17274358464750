/********************************************************************
 *
 * Directory.jsx
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2020 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Button, TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import ModalDialog, { DialogContent, DialogActions } from "../ModalDialog";

import { useApp, useAPI } from "../../../providers/AppProvider";

/**
 * Directory
 *
 * Renders a selectable list of existing customers.
 */
const Directory = ({ show, onChangeCustomer, onHide }) => {
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [customers, setCustomers] = useState(null);
  const [match] = useState("");
  const app = useApp();
  const api = useAPI();

  useEffect(() => {
    fetchCustomers(match);
  }, [match]);

  /////////////////////////////////////////////////////////////////////
  //
  //  Utility methods
  //
  /////////////////////////////////////////////////////////////////////

  /**
   * FetchCustomers
   *
   * Calls the API to fetch a list of customers
   *
   * @param {*} match
   *
   */
  const fetchCustomers = (match) => {
    //
    //  Status message
    //
    const notifyHandle = app.notify("Loading Customers");

    api
      .fetch(`/api/customers/?match=${match}`)
      .then(({ payload: customers }) => {
        if (!customers) throw new Error("Customers Not Found");
        setCustomers(customers);
        setSelectedCustomer(null);
        app.endNotify(notifyHandle);
      })
      .catch((error) => {
        app.error({ error });
        setCustomers(null);
        setSelectedCustomer(null);
      });
  };

  ///////////////////////////////////////////////////////////////////////
  //
  //  Lifecycle Methods
  //
  ///////////////////////////////////////////////////////////////////////

  return (
    <ModalDialog
      show={show}
      size="sm"
      title="Customer Directory"
      onHide={onHide}
      onEnter={() => {
        if (!customers) fetchCustomers(match);
      }}
    >
      <>
        <DialogContent>
          <Autocomplete
            value={selectedCustomer}
            onChange={(event, customer) => {
              setSelectedCustomer(customer);
            }}
            options={customers}
            getOptionLabel={(customer) =>
              `${customer.lname}, ${customer.fname}`
            }
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="Select Customer"
                variant="outlined"
                autoFocus
              />
            )}
          />
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            color="primary"
            disabled={!selectedCustomer}
            onClick={() => onChangeCustomer(selectedCustomer)}
          >
            Select
          </Button>
          <Button variant="secondary" onClick={onHide}>
            Cancel
          </Button>
        </DialogActions>
      </>
    </ModalDialog>
  );
};

/*
 **  PropTypes
 */
Directory.propTypes = {
  show: PropTypes.bool.isRequired,
  onChangeCustomer: PropTypes.func.isRequired,
  onHide: PropTypes.func,
};

export default Directory;
