/********************************************************************
 *
 * OrderProvider.jsx
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2019 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

import React, { useState, useEffect } from "react";
import { Box, Typography, IconButton, makeStyles } from "@material-ui/core";
import { CancelOutlined } from "@material-ui/icons";
import { useApp, useAPI } from "../providers/AppProvider";

const OrderContext = React.createContext({
  order: null,
  setOrder: (order) => null,
  clearOrder: () => null,
});

/**
 * OrderProvider
 *
 * @param {*} params
 */
const OrderProvider = ({ orderId, children }) => {
  const [_order, _setOrder] = useState(null);
  const app = useApp();
  const api = useAPI();

  useEffect(() => {
    if (orderId) fetchOrder(orderId);
  }, [orderId]);

  /**
   * FetchAssets
   *
   */
  const fetchOrder = (orderId) => {
    api
      .fetch(`/api/orders/order/${orderId}`)
      .then(({ payload: order }) => {
        _setOrder(order);
      })
      .catch((error) => {
        _setOrder(null);
        app.error({ error });
      });
  };

  ///////////////////////////////////////////////////////////////////////
  //
  //  API Methods
  //
  ///////////////////////////////////////////////////////////////////////

  const setOrder = (order) => {
    _setOrder(order);
  };

  const clearOrder = () => {
    _setOrder(null);
  };

  ///////////////////////////////////////////////////////////////////////
  //
  //  Lifecycle methods
  //
  ///////////////////////////////////////////////////////////////////////

  return (
    <OrderContext.Provider value={{ order: _order, setOrder, clearOrder }}>
      {!!_order && (
        <OrderBar
          order={_order}
          onCancel={() => {
            _setOrder(null);
          }}
        />
      )}

      {children}
    </OrderContext.Provider>
  );
};

const OrderBar = ({ order, onCancel }) => {
  const classes = useStyles();

  return (
    <Box className={classes.container}>
      <Box className={classes.content}>
        <Typography>Updating Order # {order.id}</Typography>
        <IconButton onClick={onCancel}>
          <CancelOutlined className={classes.icon} />
        </IconButton>
      </Box>
    </Box>
  );
};

/*
 **  Hooks
 */
const useOrder = () => {
  return React.useContext(OrderContext);
};

/*
 **  Styles
 */
const useStyles = makeStyles((theme) => ({
  container: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    zIndex: 1200,
    height: "100px",
    // marginBottom: "-30px",
    display: "flex",
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
  content: {
    display: "flex",
    flex: "1",
    alignItems: "center",
    justifyContent: "center",
    gap: "10px",
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },
  icon: {
    color: theme.palette.primary.contrastText,
    fontSize: "20px",
  },
}));

export default OrderProvider;
export { useOrder };
