/********************************************************************
 *
 * SyncButton.jsx
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2020 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

import React from "react";
import PropTypes from "prop-types";
import { Button, CircularProgress, makeStyles } from "@material-ui/core";

/**
 * SyncButton
 *
 * Renders a button that can be diabled while performing async functions.
 */
const SyncButton = ({ disabled, children, ...other }) => {
  const classes = styles();

  return (
    <div className={classes.wrapper}>
      <Button disabled={disabled} {...other}>
        {children}
        {disabled && (
          <CircularProgress size={24} className={classes.progress} />
        )}
      </Button>
    </div>
  );
};

/*
 **  Styles
 */
const styles = makeStyles((theme) => ({
  wrapper: {
    position: "relative",
  },
  progress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
}));

/*
 **  PropTypes
 */
SyncButton.propTypes = {
  type: PropTypes.string,
  disabled: PropTypes.bool.isRequired,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
};

SyncButton.defaultProps = {
  type: "button",
};

export default SyncButton;
