import React from "react";
import ReactDOM from "react-dom";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import "bootstrap/dist/css/bootstrap.css";
import "./index.css";
import { SnackbarProvider } from "notistack";
import App from "./App";
import {
  PublicAppProvider,
  AppProvider,
  AuthProvider,
  AuthCallback,
} from "./providers";
import { Page as Public } from "./components/Public/Page";
import * as serviceWorker from "./serviceWorker";

ReactDOM.render(
  <Router>
    <Switch>
      <Route path="/auth/code" component={AuthCallback} />
      <Route
        path="/app"
        render={() => (
          <AuthProvider>
            <SnackbarProvider
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
            >
              <AppProvider>
                <App />
              </AppProvider>
            </SnackbarProvider>
          </AuthProvider>
        )}
      />
      <Route
        path="/public"
        render={() => (
          <PublicAppProvider>
            <Public />
          </PublicAppProvider>
        )}
      />
      <Route path="/" render={() => <Redirect to="/app/" />} />
    </Switch>
  </Router>,

  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
