/********************************************************************
 *
 * utils/CCAPIs.js
 *
 * Enclosure function to access Canadian Coastal APIs.
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2021 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

const HttpError = require("http-errors");

/**
 * Export a closure that contains methods to call methods on the
 * respective APIs.
 */
export default (url, getAccessToken) => {
  const _url = url;

  return {
    create: (path, body) => {
      return callAPI(getAccessToken, _url, path, "POST", JSON.stringify(body));
    },
    update: (path, body) => {
      return callAPI(getAccessToken, _url, path, "PUT", JSON.stringify(body));
    },
    fetch: (path) => {
      return callAPI(getAccessToken, _url, path, "GET");
    },
    delete: (path) => {
      return callAPI(getAccessToken, _url, path, "DELETE");
    },
  };
};

/**
 * callAPI
 *
 * Calls the Canadian Coastal REST API. The arguments have been
 * initialized with the route, HTTP verb and payload. This method
 * configures the HTTP request parameters.
 *
 * @param {string} getAccessToken
 * @param {string} url
 * @param {string} path
 * @param {string} method
 * @param {string} body
 */
function callAPI(getAccessToken, url, path, method = "GET", body = null) {
  return new Promise((resolve, reject) => {
    //
    //  Keep getAccessToken in the Promise.resolve method. In the case
    //  of non-secure (public) APIs, the function reference may be null.
    //
    Promise.resolve(getAccessToken && getAccessToken)
      .then((token) => {
        const request = new Request(`${url}/${path.replace(/^(\/)/, "")}`, {
          method: method,
          credentials: "include",
          mode: "cors",
          headers: new Headers({
            authorization: token ? `Bearer ${token}` : undefined,
            CCToken: token,
            "Content-Type": "application/json",
          }),
          body: body,
        });

        return fetch(request);
      })
      .then((res) => {
        if (!res.ok) {
          return new Promise((resolve, reject) => {
            res.text().then((message) => {
              reject(new HttpError(res.status, message || res.statusText));
            });
          });
        }

        switch (res.status) {
          case 204:
            resolve();
            break;
          default:
            resolve(res.json());
        }
      })
      .catch((error) => {
        reject(new HttpError(error.status || 500, error.message));
      });
  });
}
