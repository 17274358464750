/********************************************************************
 *
 * /components/crm/customers/identity/contact.jsx
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2020 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

import React, { useState } from "react";
import {
  Box,
  Grid,
  Avatar,
  IconButton,
  Typography,
  makeStyles,
} from "@material-ui/core";
import { Edit, MailOutline } from "@material-ui/icons";
import { WidgetFrame, StatCard } from "../../../lib";
import ContactEdit from "./ContactEdit";

/**
 * Contact
 *
 */
const Contact = ({ customer, onUpdate }) => {
  const [edit, setEdit] = useState(false);
  const classes = useStyles();

  ///////////////////////////////////////////////////////////////////
  //
  //  Event Handlers
  //
  ///////////////////////////////////////////////////////////////////

  /**
   * onSaved
   *
   * Fired once the edit state has saved the descriptive information.
   *
   * Returns to display mode.
   *
   * @param {*} event
   */
  const onSaved = (event) => {
    onUpdate && onUpdate(event);
    setEdit(false);
  };

  /**
   * onCancelled
   *
   * Fired once the edit state has aboorted changes to the descriptive
   * information.
   *
   * Returns to display mode.
   *
   */
  const onCancelled = () => {
    setEdit(false);
  };

  ///////////////////////////////////////////////////////////////////
  //
  //  Lifecycle methods
  //
  ///////////////////////////////////////////////////////////////////

  if (!customer) return null;

  return (
    <WidgetFrame
      action={
        !!onUpdate && (
          <IconButton onClick={() => setEdit(true)} color="secondary">
            <Edit />
          </IconButton>
        )
      }
      background="dark"
    >
      <>
        <Box display="flex" flexDirection="column" alignItems="center">
          <Avatar className={classes.avatar} src={customer.imageURL}>
            {customer.isMinor && customer.minorName
              ? customer.minorName[0]
              : customer.fname[0]}
          </Avatar>
          {customer.isMinor ? (
            <>
              <Typography variant="h3">
                {customer.minorName ? customer.minorName : "Minor"}
              </Typography>

              <Typography variant="h6">
                {customer.fname} {customer.lname}
              </Typography>
            </>
          ) : (
            <Typography variant="h3">
              {customer.fname} {customer.lname}
            </Typography>
          )}
          <Typography variant="h6">{customer.phone}</Typography>
          <IconButton href={`mailto:${customer.email}`} color="secondary">
            <MailOutline />
          </IconButton>
          <Grid container spacing={4} style={{ marginTop: "4px" }}>
            <Grid item xs={12} xl={6}>
              <StatCard label="Lifetime Spend" value={`$123.44`} />
            </Grid>
            <Grid item xs={12} xl={6}>
              <StatCard label="Last Visit" value={`Jun 13, 2020`} />
            </Grid>
          </Grid>
        </Box>
        {!customer.deleted && onUpdate && edit && (
          <ContactEdit
            customer={customer}
            show={edit}
            onSaved={onSaved}
            onCancelled={onCancelled}
          />
        )}
      </>
    </WidgetFrame>
  );
};

const useStyles = makeStyles((theme) => ({
  avatar: {
    height: theme.spacing(15),
    width: theme.spacing(15),
    marginBottom: theme.spacing(2),
    border: "solid 2px #ffffff",
  },
}));

export default Contact;
