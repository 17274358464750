import moment from "moment-timezone";

let format = new (class Format {
  timeFormat = "HH:mm";

  /**
   * getMomentLocalized
   *
   * Initializes a date. If a timezone argument is included, then the
   * time is timezone adjusted, otherwise the time will be localized
   * to the browser locale.
   *
   * @param {date} date
   * @param {timezone} tz
   */
  getMomentLocalized(date, tz) {
    if (tz) {
      return moment.tz(date, tz);
    }
    return moment(date);
  }

  duration(duration) {
    return `${(duration / 60).toFixed(2)} hrs`;
  }

  date(date, tz) {
    return this.getMomentLocalized(date, tz).format("MMM D, YYYY");
  }

  dayDate(date, tz) {
    return this.getMomentLocalized(date, tz).format("dddd, MMM D, YYYY");
  }

  shortDate(date, tz) {
    return this.getMomentLocalized(date, tz).format(`MMM D`);
  }

  shortDay(date, tz) {
    return this.getMomentLocalized(date, tz).format(`ddd`);
  }

  day(date, tz) {
    return this.getMomentLocalized(date, tz).format(`dddd`);
  }

  shortDayDate(date, tz) {
    return this.getMomentLocalized(date, tz).format(`ddd, MMM D`);
  }

  month(date, tz) {
    return this.getMomentLocalized(date, tz).format("MMMM YYYY");
  }

  mday(date, tz) {
    let mdate = this.getMomentLocalized(date, tz);

    if (1 === mdate.date()) {
      return mdate.format("D MMM");
    } else {
      return mdate.format("D");
    }
  }

  dayDateTime(date, tz) {
    return this.getMomentLocalized(date, tz).format(
      `ddd, MMM D YYYY ${this.timeFormat}`
    );
  }

  dateTime(date, tz) {
    return this.getMomentLocalized(date, tz).format(
      `MMM D YYYY ${this.timeFormat} ${tz ? "z" : ""}`
    );
  }

  shortDateTime(date, tz) {
    return this.getMomentLocalized(date, tz).format(
      `MMM D ${this.timeFormat} ${tz ? "z" : ""}`
    );
  }

  numDateTime(date, tz) {
    return this.getMomentLocalized(date, tz).format(
      `YYYY/MM/DD ${this.timeFormat} ${tz ? "z" : ""}`
    );
  }

  shortDayDateTime(date, tz) {
    return this.getMomentLocalized(date, tz).format(
      `ddd, MMM D ${this.timeFormat} ${tz ? "z" : ""}`
    );
  }

  time(date, tz) {
    return this.getMomentLocalized(date, tz).format(`${this.timeFormat}`);
  }

  timeTZ(date, tz) {
    return this.getMomentLocalized(date, tz).format(
      `${this.timeFormat} ${tz ? "z" : ""}`
    );
  }

  timezone(tz) {
    return this.getMomentLocalized(moment(), tz).format(`Z z`);
  }

  fromApiDate(date, tz) {
    return this.getMomentLocalized(date, tz).format("YYYY-MM-DD");
  }

  fromApiTime(date, tz) {
    return this.getMomentLocalized(date, tz).format("HH:mm");
  }

  fromApiDateTime(datetime, tz) {
    return this.getMomentLocalized(datetime, tz).format("YYYY-MM-DD HH:mm");
  }

  toApiDateTime(datetime) {
    return `${moment(datetime).utc().format("YYYY-MM-DDTHH:mm")}Z`;
  }

  toApiDate(datetime) {
    return moment(datetime).utc().format("YYYY-MM-DD");
  }

  percent(amount) {
    return `${amount * 100}%`;
  }

  currency(amount) {
    const formatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 2,
    });

    return formatter.format(amount / 100);
  }

  waterLevel(metricData) {
    if (!metricData) return "--";
    return `${parseFloat(metricData).toFixed(2)}m`;
  }
})();

export default format;

/*
{

    public static function dbDate(DateTime $date) {
        return $date->format('Y-m-d');
    }

    public static function dbDateTime(DateTime $date) {
        return $date->format('Y-m-d H:i:s');
    }

    public static function shortdate($date) {
        return date( 'M d', strtotime($date));
    }

    public static function datetime($date) {
        return date( 'M d, Y g:i A', strtotime($date));
    }


    public static function fulldatetime($date) {
        return date( 'l, M d, Y g:i A', strtotime($date));
    }


    public static function time24($date) {
        return date( 'G:i', strtotime($date));
    }

    public static function currency($amount) {
        return money_format('$%.2n', $amount);
    }    

    
    public static function simoText($simoText){
        // Converts Single Input, Multiple Output text to HTML format
        $htmlText = htmlspecialchars($simoText);
        $htmlText = str_replace(["\r\n", "\r", "\n"], '<br />', $htmlText);

        return $htmlText;
    }

    public static function htmlTextToSimo($htmlText){
        // Converts Single Input, Multiple Output text to HTML format
        $text = htmlspecialchars_decode($htmlText);
        $text = str_replace(["<br />","<br>"], "\n", $text);

        return $text;
    }

    public static function teaserText($str, $len){
        if (strlen($str) > $len)
            $str = substr($str, 0, $len-3)."...";
        return htmlspecialchars($str);
    }
}
*/
