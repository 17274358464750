/********************************************************************
 *
 * /Sales/Discounts/DiscountTypes.jsx
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2020 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Box, Button } from "@material-ui/core";
import { Edit, LocalOffer } from "@material-ui/icons";
import { Main } from "../../lib";
import Pagination from "../../lib/Pagination";
import { useApp, useAPI } from "../../../providers/AppProvider";

const PAGE_SIZE = 15;

/**
 * DiscountTypes
 *
 */
const DiscountTypes = () => {
  const [discountTypes, setDiscountTypes] = useState(null);
  const [recordCount, setRecordCount] = useState(null);
  const [currPage, setCurrPage] = useState(1);
  const [pageSize, setPageSize] = useState(PAGE_SIZE);
  const [loaded, setLoaded] = useState(false);
  const app = useApp();
  const api = useAPI();

  useEffect(() => {
    fetchDiscountTypes(currPage, pageSize);
  }, [currPage, pageSize]);

  /////////////////////////////////////////////////////////////////////
  //
  //  Utility methods
  //
  /////////////////////////////////////////////////////////////////////

  /**
   * FetchDiscountTypes
   *
   * @param {*} currPage
   * @param {*} pageSize
   */
  const fetchDiscountTypes = (currPage, pageSize) => {
    //
    //  Request discount types
    //
    api
      .fetch(`/api/discountTypes/?page=${currPage}&limit=${pageSize}`)
      .then(({ payload: discountTypes, max: count }) => {
        setDiscountTypes(discountTypes);
        setRecordCount(count);
        setLoaded(true);
      })
      .catch((error) => {
        app.error({ error });
        setLoaded(true);
      });
  };

  /**
   * PaginatedTable
   *
   * @param {*} props
   */
  const PaginatedTable = (props) => {
    let { discountTypes } = props;

    return (
      <div className="table-responsive small">
        <table className="table table-striped table-sm">
          <thead>
            <tr>
              <th>Name</th>
              <th>Description</th>
              <th>Account</th>
              <th />
            </tr>
          </thead>
          <tbody>
            {discountTypes.map((discountType) => (
              <tr key={discountType.id}>
                <td>
                  <Link to={`/app/sales/discount/${discountType.id}`}>
                    {discountType.name}
                  </Link>
                </td>
                <td>{discountType.description}</td>
                <td>{discountType.accountId}</td>
                <td>
                  <Link to={`/app/sales/discount/${discountType.id}`}>
                    <Edit />
                  </Link>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  };

  ///////////////////////////////////////////////////////////////////////
  //
  //  Event Handlers
  //
  ///////////////////////////////////////////////////////////////////////

  /**
   * HandleTurnPage
   *
   * @param {*} e
   */
  const handleTurnPage = (e) => {
    setCurrPage(e.currentPage);
    setPageSize(e.pageSize);
  };

  ///////////////////////////////////////////////////////////////////////
  //
  //  Lifecycle methods
  //
  ///////////////////////////////////////////////////////////////////////

  return (
    <Main
      header={{
        icon: <LocalOffer />,
        title: "Discounts",
        bonus: (
          <Button variant="outlined" color="primary" href="/app/sales/discount">
            Create a new discount
          </Button>
        ),
      }}
      loaded={discountTypes}
    >
      {loaded && !discountTypes
        ? "No Discounts Available"
        : loaded && (
            <>
              <Box
                display="flex"
                alignItems="baseLine"
                justifyContent="flex-end"
                flexWrap="wrap"
                mb={2}
              >
                <Pagination
                  className={"small"}
                  totalRecords={recordCount}
                  pageSize={pageSize}
                  pageNeighbours={1}
                  onPageChanged={handleTurnPage}
                />
              </Box>
              <PaginatedTable discountTypes={discountTypes} />
            </>
          )}
    </Main>
  );
};

export default DiscountTypes;
