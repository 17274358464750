/********************************************************************
 *
 * CouponDialog.jsx
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2020 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

import React from "react";
import PropTypes from "prop-types";
import { Box, TextField, Button } from "@material-ui/core";
import { Formik } from "formik";
import * as yup from "yup";
import { ModalDialog, DialogContent, HorizontalGroup } from "../../../lib";
import { useApp, useAPI } from "../../../../providers/AppProvider";

/**
 * CouponDialog
 *
 * Dialog component that processes a coupon for charges within
 * an order.
 *
 */
const CouponDialog = ({ order, charge, show, onSaved, onCancelled }) => {
  const app = useApp();
  const api = useAPI();

  ///////////////////////////////////////////////////////////////////
  //
  //  Utility Methods
  //
  ///////////////////////////////////////////////////////////////////

  ///////////////////////////////////////////////////////////////////
  //
  //  Event Handlers
  //
  ///////////////////////////////////////////////////////////////////

  /**
   * OnSave
   *
   * Fired when the user commits the change.
   *
   * @param {*} coupon
   */
  const onSave = (coupon) => {
    //
    //  Status message
    //
    const notifyHandle = app.notify("Adding Coupon");

    api
      .create(
        `/api/orders/order/${order.id}/charges/charge/${charge.id}/coupon/${coupon.code}`,
        coupon
      )
      .then(({ payload: order }) => {
        onSaved && onSaved(order);
        app.endNotify(notifyHandle);
      })
      .catch((error) => {
        app.error({ error });
      });
  };

  /**
   * OnCancel
   *
   * @param {*} dirty
   */
  const onCancel = (dirty) => {
    if (
      !dirty ||
      window.confirm(
        "You have unsaved changes. Are you sure you want to close this dialog and loose your changes?"
      )
    ) {
      onCancelled && onCancelled();
    }
  };

  ///////////////////////////////////////////////////////////////////
  //
  //  Lifecycle methods
  //
  ///////////////////////////////////////////////////////////////////

  /**
   * Render
   *
   * Renders the discount functionality
   *
   */
  return (
    <ModalDialog
      title={`Apply coupon for ${charge.description}`}
      subtitle="TBD"
      size="sm"
      show={!!show}
    >
      <DialogContent>
        <FormBody onSave={onSave} onCancel={onCancel} />
      </DialogContent>
    </ModalDialog>
  );
};

/**
 * FormBody
 *
 * @param {*} props
 */
const FormBody = ({ onSave, onCancel }) => {
  ///////////////////////////////////////////////////////////////////
  //
  //  Event Handlers
  //
  ///////////////////////////////////////////////////////////////////

  return (
    <Formik
      initialValues={{ code: "" }}
      enableReinitialize={true}
      onSubmit={(coupon) => {
        onSave(coupon);
      }}
      validationSchema={yup.object().shape({
        code: yup.string().nullable().required("Reason is required"),
      })}
    >
      {({
        values,
        touched,
        errors,
        dirty,
        isSubmitting,
        setFieldValue,
        handleChange,
        handleBlur,
        handleSubmit,
      }) => {
        return (
          <form onSubmit={handleSubmit}>
            <Box display="flex" justifyContent="flex-end">
              <HorizontalGroup margin="ml1">
                <Button
                  type="submit"
                  variant="outlined"
                  color="primary"
                  disabled={!dirty || isSubmitting}
                >
                  Save
                </Button>
                <Button onClick={() => onCancel(dirty)}>Cancel</Button>
              </HorizontalGroup>
            </Box>
            <CouponForm
              coupon={values}
              touched={touched}
              errors={errors}
              setFieldValue={setFieldValue}
              onBlur={handleBlur}
              onChange={handleChange}
            />
          </form>
        );
      }}
    </Formik>
  );
};

const CouponForm = ({
  coupon,
  touched,
  errors,
  setFieldValue,
  onBlur,
  onChange,
}) => {
  return (
    <Box>
      <TextField
        id="code"
        label="Coupon"
        value={coupon.code}
        onBlur={onBlur}
        onChange={onChange}
        fullWidth
        required
        error={errors.code && touched.code}
        helperText={errors.code || " "}
      />
    </Box>
  );
};

CouponDialog.propTypes = {
  show: PropTypes.object, // Can be null
  charge: PropTypes.object.isRequired,
  onSaved: PropTypes.func.isRequired,
  onCancelled: PropTypes.func.isRequired,
};

export default CouponDialog;
