/********************************************************************
 *
 * SupplierGood.jsx
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2019 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

import React, { useState, useEffect } from "react";
import { Prompt } from "react-router";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPassport,
  faHourglassStart,
  faHourglassEnd,
  faStopwatch,
} from "@fortawesome/free-solid-svg-icons";
import { Button } from "react-bootstrap";
import moment from "moment";
import { Main } from "../../lib";
import format from "../../../utils/format";
import DateTimePicker from "../../lib/Inputs/DateTimePicker";
import Tickets from "../../lib/Tickets";
import { useApp, useAPI } from "../../../providers/AppProvider";

/**
 * SupplierGood
 *
 * Adds or edits a supplier good.
 *
 */
const SupplierGood = ({ history, match, location }) => {
  const [good, setGood] = useState(null);
  const [tickets, setTickets] = useState(null);
  const [dirty, setDirty] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const { id } = match.params;
  const app = useApp();
  const api = useAPI();

  useEffect(() => {
    const { good } = location;

    //
    //  Status message
    //
    const notifyHandle = app.notify("Loading");

    if (good) {
      setGood(good);
      setLoaded(true);
      setDirty(!good || !good.id);
    } else if (id) {
      fetchGood(id)
        .then((good) => {
          setGood(good);
          setLoaded(true);

          if (!good) return null;

          return fetchTickets(good.id);
        })
        .then((tickets) => {
          setTickets(tickets);
          app.endNotify(notifyHandle);
        })
        .catch((error) => {
          app.error({ error });
          setLoaded(true);
        });
    }
  }, []);

  ///////////////////////////////////////////////////////////////////
  //
  //  Utility methods
  //
  ///////////////////////////////////////////////////////////////////

  /**
   * FetchGood
   *
   * Calls the API to fetch the specified supplierGood
   *
   * @param {*} goodId
   */
  const fetchGood = (goodId) => {
    if (!goodId) return;

    return new Promise((resolve, reject) => {
      api
        .fetch(`/api/suppliergoods/suppliergood/${goodId}`)
        .then((envelope) => {
          resolve(envelope.payload);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  /**
   * FetchTickets
   *
   * Initializes the list of tickets for the supplier good.
   *
   * @param {*} goodId
   */
  const fetchTickets = (goodId) => {
    if (!goodId) return;

    return new Promise((resolve, reject) => {
      api
        .fetch(`/api/suppliergoods/suppliergood/${goodId}/tickets`)
        .then((envelope) => {
          resolve(envelope.payload);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  ///////////////////////////////////////////////////////////////////
  //
  //  Event Handlers
  //
  ///////////////////////////////////////////////////////////////////

  /**
   * OnChangeStartTime
   *
   * Fired when the user changes start time of a good.
   *
   * @param {*} event
   */
  const onChangeStartTime = (event) => {
    let { date } = event;

    good.start = good.displayStart = format.toApiDateTime(date);

    //
    //  Verify that start time is before end time
    //
    if (date.isAfter(moment(good.end))) good.end = good.displayEnd = good.start;

    setGood(good);
    setDirty(true);
  };

  /**
   * OnChangeEndTime
   *
   * Fired when the user changes time time of a good.
   *
   * @param {*} event
   */
  const onChangeEndTime = (event) => {
    let { date } = event;

    good.end = good.displayEnd = format.toApiDateTime(date);

    //
    //  Verify that end time is after start time
    //
    if (date.isBefore(good.start)) good.start = good.displayStart = good.end;

    setGood(good);
    setDirty(true);
  };

  /**
   * OnChangeQuantity
   *
   * @param {*} event
   */
  const onChangeQuantity = (event) => {
    event.preventDefault();

    good.quantity = event.target.value;

    setGood(good);
    setDirty(true);
  };

  /**
   * OnSubmit
   *
   * Fired when the user commits the change.
   *
   * @param {*} event
   */
  const onSubmit = (event) => {
    event.preventDefault();

    //
    //  Status message
    //
    const notifyHandle = app.notify("Saving");

    api
      .update(`/api/suppliergoods/suppliergood/${good.id ? good.id : ""}`, good)
      .then(() => {
        setDirty(false);
        app.endNotify(notifyHandle);

        history.goBack();
      })
      .catch((error) => {
        app.error({ error });
      });
  };

  /**
   * OnDelete
   *
   * Fired when the user deletes the good
   *
   * @param {*} event
   */
  const onDelete = (event) => {
    event.preventDefault();

    if (window.confirm("Are you sure you want to delete this supplier good?")) {
      //
      //  Status message
      //
      const notifyHandle = app.notify("Saving");

      if (!good.id) {
        //
        //  Nothing to delete
        //
        history.push(`/app/goods/`);
      }
      api
        .delete(`/api/suppliergoods/suppliergood/${good.id}`)
        .then(() => {
          app.endNotify(notifyHandle);
          setGood(null);
          setDirty(false);

          history.push(`/app/goods/`);
        })
        .catch((error) => {
          app.error({ error });
        });
    }
  };

  /**
   * OnCancel
   *
   * @param {*} event
   */
  const onCancel = (event) => {
    event.preventDefault();

    history.goBack();
  };

  ///////////////////////////////////////////////////////////////////////
  //
  //  Lifecycle methods
  //
  ///////////////////////////////////////////////////////////////////////

  return (
    <Main>
      <Prompt
        when={dirty}
        message={(location) =>
          `You have unsaved data. Are you sure you want to leave this page?`
        }
      />
      {!good && "Supplier good information not available"}
      {good && (
        <>
          <div className="d-flex justify-content-between flex-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
            <div className="page-title">
              <FontAwesomeIcon className="mr-4" icon={faPassport} />
              {!good && "New"} Supplier Good {good && good.id}
            </div>
            <div className="btn-toolbar mb-2 mb-md-0">
              {!tickets && (
                <Button
                  variant="outline-cc"
                  onClick={onDelete}
                  disabled={!good || !good.id}
                >
                  Delete
                </Button>
              )}
            </div>
          </div>
          <form className="needs-validation" onSubmit={onSubmit}>
            <div className="d-lg-flex justify-content-start flex-wrap">
              <div
                className="form-group"
                style={{ flexBasis: "32%", minWidth: 300 }}
              >
                <div>
                  {/* Header */}
                  <div className="d-flex justify-content-between align-items-start flex-nowrap">
                    <div className="small badge badge-info">
                      {good && good.tz}
                    </div>
                    <div className="">
                      <FontAwesomeIcon className="mr-1" icon={faStopwatch} />
                      {`${moment
                        .utc(moment(good.end).diff(moment(good.start)))
                        .format("HH:mm")} Hrs`}
                    </div>
                  </div>
                  {/* Depart */}
                  <label htmlFor="depart">Depart</label>
                  <DateTimePicker
                    id="depart"
                    value={good && good.start}
                    showUTC={false}
                    tz={good && good.tz}
                    onChange={onChangeStartTime}
                  />
                  {/* Return */}
                  <label htmlFor="return">Return</label>
                  <DateTimePicker
                    id="return"
                    value={good && good.end}
                    showUTC={false}
                    tz={good && good.tz}
                    onChange={onChangeEndTime}
                  />
                  <div className="mt-3">
                    <div className="flexRowStretch">
                      <div className="small">
                        <FontAwesomeIcon
                          className="mr-1"
                          icon={faHourglassStart}
                        />
                        {good && format.shortDayDateTime(good.start, good.tz)}
                      </div>
                      <div className="small">
                        <FontAwesomeIcon
                          className="mr-1"
                          icon={faHourglassEnd}
                        />
                        {good && format.shortDayDateTime(good.end, good.tz)}
                      </div>
                    </div>
                  </div>
                  <div className="mt-3">
                    <Quantity
                      good={good}
                      style={{ flexBasis: "48%", minWidth: 300 }}
                      onChangeQuantity={onChangeQuantity}
                    />
                  </div>
                </div>
              </div>
            </div>
            <button
              type="submit"
              className="btn btn-primary mr-2"
              disabled={!dirty}
              value="Submit"
            >
              Save
            </button>
            <button className="btn btn-secondary" onClick={onCancel}>
              Cancel
            </button>
            <hr />
          </form>
          <Tickets tickets={tickets} tz={good && good.tz} />
        </>
      )}
    </Main>
  );
};

const Quantity = ({ good, style, onChangeQuantity }) => {
  return (
    <div className="form-group" style={style}>
      <label htmlFor="quantity">Quantity</label>
      <input
        id="quantity"
        type="number"
        className="form-control"
        defaultValue={good && good.quantity}
        onChange={onChangeQuantity}
        min="-1"
        required
      />
      <small id="assetHelpBlock" className="form-text text-muted">
        How times can the good be sold? (-1 is free sell)
      </small>
    </div>
  );
};
export default SupplierGood;
