/********************************************************************
 *
 * Tour.jsx
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2019 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

import React, { useState, useEffect } from "react";
import { Button, Grid } from "@material-ui/core";
import { DirectionsBoat } from "@material-ui/icons";
import { Main } from "../../lib";
import WaterLevels from "../../lib/WaterLevels";
import TourStatus from "./libs/TourStatus";
import { Summary, Products, Orders, Tickets, Shifts } from "./Tour/";
import { useApp, useAPI } from "../../../providers";

/**
 * Tour
 *
 * Adds or edits a tour. Even though we are editting a
 * tour, we send the product id as a parameter. There are
 * constraints on the assets and employees that are assigned to
 * a specific product type, so we therefore need to be aware of the
 * context of the tour.
 *
 */
const Tour = ({ match, history, location }) => {
  const [tour, setTour] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const app = useApp();
  const api = useAPI();

  const { id: tourId } = match.params;

  /**
   * Tour State Management
   *
   * If tour id is a paramter, we are in edit mode, otherwise
   * we are creating a new tour
   */
  useEffect(() => {
    const { tour: _tour } = location;

    //
    //  Initilize Assets available for the tour
    //
    if (_tour) {
      setTour(_tour);
      setLoaded(true);
    } else if (tourId) {
      fetchTour(tourId);
    }
  }, [location, tourId]);

  ///////////////////////////////////////////////////////////////////
  //
  //  Utility Methods
  //
  ///////////////////////////////////////////////////////////////////

  /**
   * FetchTour
   *
   * @param {*} tourId
   */
  const fetchTour = (tourId) => {
    //
    //  Status message
    //
    const notifyHandle = app.notify("Loading Tour");

    api
      .fetch(`/api/tours/tour/${tourId}?verbose`)
      .then(({ payload: tour }) => {
        setTour(tour);
        setLoaded(true);
        app.endNotify(notifyHandle);
      })
      .catch((error) => {
        app.error({ error });
      });
  };

  ///////////////////////////////////////////////////////////////////
  //
  //  Event Handlers
  //
  ///////////////////////////////////////////////////////////////////

  /**
   * OnUpdate
   *
   * Fired when a child updates the data object.
   *
   * @param {*} event
   */
  const onUpdate = () => {
    fetchTour(tourId);
  };

  /**
   * OnDelete
   *
   * Fired when the user deletes the tour
   *
   * @param {*} event
   */
  const onDelete = (event) => {
    event.preventDefault();

    if (!window.confirm("Are you sure you want to delete this tour?")) return;

    const notifyHandle = app.notify("Deleting");

    if (!tour.id) {
      //
      //  Nothing to delete
      //
      history.push(`/app/goods/tours/`);
    }
    api
      .delete(`/api/tours/tour/${tour.id}`)
      .then(() => {
        setTour(null);
        app.endNotify(notifyHandle);

        history.push(`/app/goods/tours/`);
      })
      .catch((error) => {
        app.error({ error });
      });
  };

  ///////////////////////////////////////////////////////////////////////
  //
  //  Lifecycle methods
  //
  ///////////////////////////////////////////////////////////////////////

  return (
    <Main
      header={{
        icon: <DirectionsBoat />,
        title: `${!tour ? "New" : ""} Tour ${tour && tour.id}`,
        bonus: (
          <>
            <TourStatus
              tour={tour}
              onTourUpdated={(e) => {
                setTour(e.tour);
              }}
            />
            {true && (
              <Button
                variant="outlined"
                color="primary"
                onClick={onDelete}
                disabled={!tour || !tour.id}
              >
                Delete
              </Button>
            )}
          </>
        ),
      }}
      loaded={loaded}
    >
      {!tour ? (
        "Tour information not available"
      ) : (
        <Grid container spacing={4}>
          <Grid
            item
            xs={12}
            lg={4}
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              gap: 32,
            }}
          >
            <Summary tour={tour} onUpdate={onUpdate} />
            <Products tour={tour} />
          </Grid>
          <Grid item xs={12} lg={8} style={{ display: "flex" }}>
            <WaterLevels
              date={tour ? tour.start : null}
              showDate={false}
              tz={tour && tour.tz}
              departureTime={tour ? tour.start : null}
              returnTime={tour ? tour.end : null}
            />
          </Grid>
          <Grid item xs={12} lg={7}>
            <Orders tour={tour} />
          </Grid>
          <Grid item xs={12} lg={5}>
            <Shifts tour={tour} />
          </Grid>
          <Grid item xs={12}>
            <Tickets tour={tour} />
          </Grid>
        </Grid>
      )}
    </Main>
  );
};

export default Tour;
