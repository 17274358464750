/********************************************************************
 *
 * Dashboard.jsx
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2020 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

import React from "react";
import { Grid } from "@material-ui/core";
import { BarChart } from "@material-ui/icons/";
import { Main } from "../lib";
import Leads from "./Leads";
import ActivityLog from "./ActivityLog";
import ProductTypeHits from "./Analytics/ProductTypeHits";
import ProductTypeHits2 from "./Analytics/ProductTypeHits2";
import TotalTours from "./Analytics/TotalTours";
import TotalPAX from "./Analytics/TotalPAX";
import UnearnedPAX from "./Analytics/UnearnedPAX";
import UnearnedTours from "./Analytics/UnearnedTours";
import ShowIdentity from "../lib/ShowIdentity";

/**
 * Dashboard
 *
 * Displays system information.
 */
const Dashboard = () => {
  return (
    <Main header={{ icon: <BarChart />, title: "Dashboard" }} loaded={true}>
      <Grid container justify="center" alignItems="stretch" spacing={4}>
        <Grid item xs={12} sm={6} md={3}>
          <TotalTours />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <TotalPAX />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <UnearnedTours />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <UnearnedPAX />
        </Grid>
        <Grid item xs={12} md={6}>
          <Leads />
        </Grid>
        <Grid item xs={12} md={6}>
          <ActivityLog />
        </Grid>
        <Grid item xs={12}>
          <ProductTypeHits />
        </Grid>
        {/* <Grid item xs={12}>
          <ProductTypeHits2 />
        </Grid> */}
      </Grid>
    </Main>
  );
};

export default Dashboard;
