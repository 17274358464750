/********************************************************************
 *
 * App.jsx
 *
 * Application page router.
 *
 * Routes requests related to application functionality.
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2019 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

import "./app.css";

import React from "react";
import { Switch, Route } from "react-router-dom";

import { _SectionRouter as Dashboard } from "./components/Dashboard/_SectionRouter";
import { _SectionRouter as Catalog } from "./components/Catalog/_SectionRouter";
import { _SectionRouter as Reports } from "./components/Reports/_SectionRouter";
import { _SectionRouter as Sales } from "./components/Sales/_SectionRouter";
import { _SectionRouter as Goods } from "./components/Goods/_SectionRouter";
import { _SectionRouter as Admin } from "./components/Admin/_SectionRouter";
import { _SectionRouter as Customers } from "./components/CRM/_SectionRouter";
import ProductCalendar from "./components/ProductCalendar/ProductCalendar";
import { Header, Footer } from "./components/lib";
import Error404 from "./components/Error/404";
import DefaultThemeProvider from "./themes/default";

const App = () => {
  return (
    <div className="app-wrapper">
      <DefaultThemeProvider>
        <Header />
        <Switch>
          <Route path="/app/calendar/" component={ProductCalendar} />
          <Route path="/app/catalog/" component={Catalog} />
          <Route path="/app/crm/" component={Customers} />
          <Route path="/app/sales/" component={Sales} />
          <Route path="/app/goods/" component={Goods} />
          <Route path="/app/reports/" component={Reports} />
          <Route path="/app/admin/" component={Admin} />
          <Route path="/app/" exact component={Dashboard} />
          <Route path="/app/" component={Error404} />
        </Switch>
        <Footer />
      </DefaultThemeProvider>
    </div>
  );
};

export default App;
