/********************************************************************
 *
 * /Catalog/Products/Identity/Edit.jsx
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2021 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

import React from "react";
import { Box, Button, TextField } from "@material-ui/core";
import moment from "moment-timezone";
import { Formik } from "formik";
import * as yup from "yup";
import { HorizontalGroup } from "../../../../lib";
import ModalDialog, { DialogContent } from "../../../../lib/ModalDialog";
import format from "../../../../../utils/format";
import { useApp, useAPI } from "../../../../../providers";

/**
 * IdentityEdit
 *
 * Edits product details.
 *
 * @param {*} props
 */
const IdentityEdit = ({ product, show, onSaved, onCancelled }) => {
  const app = useApp();
  const api = useAPI();

  ///////////////////////////////////////////////////////////////////
  //
  //  Utiltiy Methods
  //
  ///////////////////////////////////////////////////////////////////

  ///////////////////////////////////////////////////////////////////
  //
  //  Event Handlers
  //
  ///////////////////////////////////////////////////////////////////

  /**
   * OnSave
   *
   * Fired when the user commits the change.
   *
   * @param {*} values
   */
  const onSave = (values) => {
    //
    //  Save
    //
    const notifyHandle = app.notify("Saving Product");

    api
      .update(`/api/products/product/${values.id ? values.id : ""}`, values)
      .then(({ payload: product }) => {
        onSaved && onSaved({ product });
        app.endNotify(notifyHandle);
      })
      .catch((error) => {
        app.error({ error });
      });
  };

  /**
   * OnCancel
   *
   * @param {*} dirty
   */
  const onCancel = (dirty) => {
    if (
      !dirty ||
      window.confirm(
        "You have unsaved changes. Are you sure you want to close this dialog and loose your changes?"
      )
    ) {
      onCancelled && onCancelled();
    }
  };

  ///////////////////////////////////////////////////////////////////////
  //
  //  Lifecycle methods
  //
  ///////////////////////////////////////////////////////////////////////

  return (
    <ModalDialog
      title="Manage Product"
      subtitle="Product are an instance of product type and can be purchased to consume goods and services."
      show={!!show}
    >
      <DialogContent>
        <FormBody product={product} onSave={onSave} onCancel={onCancel} />
      </DialogContent>
    </ModalDialog>
  );
};

/**
 * FormBody
 *
 * @param {*} props
 */
const FormBody = ({ product, onSave, onCancel }) => {
  /**
   * ComputeDateTime
   *
   * Computes the utc date and time object from the UI elements.
   *
   * @param {*} date
   * @param {*} time
   * @param {*} tz
   */
  const computeDateTime = (date, time, tz) => {
    if (!date || !time || !tz) return null;

    return moment
      .tz(date, "YYYY-MM-DD", tz)
      .add(moment.duration(time, "HH:mm"));
  };

  ///////////////////////////////////////////////////////////////////
  //
  //  Event Handlers
  //
  ///////////////////////////////////////////////////////////////////

  return (
    <Formik
      initialValues={{
        date: format.toApiDate(product.start),
        startTime: format.time(product.start, product.timezone),
        endTime: format.time(product.end, product.timezone),
        ...product,
      }}
      enableReinitialize={true}
      onSubmit={(product) => {
        //
        //  Coerce the dates and times
        //
        product.start = format.toApiDateTime(
          computeDateTime(product.date, product.startTime, product.timezone)
        );
        product.end = format.toApiDateTime(
          computeDateTime(product.date, product.endTime, product.timezone)
        );

        onSave(product);
      }}
      validationSchema={yup.object().shape({})}
    >
      {({
        values,
        touched,
        errors,
        dirty,
        isSubmitting,
        setFieldValue,
        handleChange,
        handleBlur,
        handleSubmit,
      }) => {
        return (
          <form onSubmit={handleSubmit}>
            <Box display="flex" justifyContent="flex-end">
              <HorizontalGroup margin="ml1">
                <Button
                  type="submit"
                  variant="outlined"
                  color="primary"
                  disabled={!dirty || isSubmitting}
                >
                  Save
                </Button>
                <Button onClick={() => onCancel(dirty)}>Cancel</Button>
              </HorizontalGroup>
            </Box>
            <ProductForm
              product={values}
              touched={touched}
              errors={errors}
              setFieldValue={setFieldValue}
              onBlur={handleBlur}
              onChange={handleChange}
            />
          </form>
        );
      }}
    </Formik>
  );
};

const ProductForm = ({
  product,
  touched,
  errors,
  setFieldValue,
  onBlur,
  onChange,
}) => {
  return (
    <Box>
      <TextField
        id="date"
        type="date"
        label="Date"
        value={product.date}
        onBlur={onBlur}
        onChange={onChange}
        fullWidth
        required
        error={errors.date && touched.date}
        helperText={errors.date || " "}
      />
      <TextField
        id="startTime"
        type="time"
        label="Start"
        value={product.startTime}
        onBlur={onBlur}
        onChange={onChange}
        fullWidth
        required
        error={errors.startTime && touched.startTime}
        helperText={errors.start || " "}
      />
      <TextField
        id="endTime"
        type="time"
        label="End"
        value={product.endTime}
        onBlur={onBlur}
        onChange={onChange}
        fullWidth
        required
        error={errors.endTime && touched.endTime}
        helperText={errors.endTime || " "}
      />
    </Box>
  );
};

export default IdentityEdit;
