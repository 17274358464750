/********************************************************************
 *
 * /components.sales/leads/addNote.jsx
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2020 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

import React, { useState } from "react";
import { Grid, TextField, Button, makeStyles } from "@material-ui/core";
import { WidgetFrame } from "../../lib";

/**
 * AddNote
 *
 * @param {*} props
 */
const AddNote = ({ onAddNote }) => {
  const [note, setNote] = useState("");
  const classes = useStyles();

  return (
    <WidgetFrame title="Notes">
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <TextField
            label="Add Note"
            multiline
            rows={6}
            value={note}
            onChange={(e) => {
              setNote(e.target.value);
            }}
            fullWidth
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12}>
          <Button
            className={classes.button}
            variant="outlined"
            color="primary"
            onClick={() => {
              onAddNote(note);
              setNote("");
            }}
          >
            Add
          </Button>
        </Grid>
      </Grid>
    </WidgetFrame>
  );
};

/*
 **  Styles
 */
const useStyles = makeStyles((theme) => ({
  button: { marginRight: theme.spacing(1) },
}));

export default AddNote;
