/********************************************************************
 *
 * /components/goods/goods/tour/summary.jsx
 *
 * Renders a summary of tour information.
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2019 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

import React, { useState } from "react";
import moment from "moment-timezone";
import { Grid, Avatar, IconButton } from "@material-ui/core";
import {
  AccessTime,
  Update,
  DirectionsBoat,
  Edit as EditIcon,
} from "@material-ui/icons";
import { WidgetFrame, TipChip, HorizontalGroup } from "../../../lib";
import { StatCard, SuperStat } from "../../../lib";
import format from "../../../../utils/format";
import EditTour from "./Edit";

const Summary = ({ tour, onUpdate }) => {
  const [edit, setEdit] = useState(false);

  ///////////////////////////////////////////////////////////////////
  //
  //  Event Handlers
  //
  ///////////////////////////////////////////////////////////////////

  /**
   * OnSaved
   *
   * Fired once the edit state has saved the descriptive information.
   *
   * Returns to display mode.
   *
   * @param {*} event
   */
  const onSaved = (event) => {
    if (onUpdate) {
      onUpdate(event);
      setEdit(false);
    }
  };

  ///////////////////////////////////////////////////////////////////
  //
  //  Lifecycle Methods
  //
  ///////////////////////////////////////////////////////////////////

  return (
    <WidgetFrame
      title={timePeriodStringBuilder(tour.start, tour.end, tour.tz)}
      action={
        !!onUpdate && (
          <IconButton onClick={() => setEdit(true)}>
            <EditIcon />
          </IconButton>
        )
      }
    >
      <>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <HorizontalGroup>
              <TipChip label={tour.tz} icon={<AccessTime />} tip="Timezone" />
              <TipChip
                label={format.duration(
                  tour && moment(tour.end).diff(moment(tour.start), "minutes")
                )}
                icon={<Update />}
                tip="Return Time"
              />
            </HorizontalGroup>
          </Grid>
          <Grid item xs={6} sm={4} lg={6}>
            <StatCard label="Capacity" value={tour.capacity} />
          </Grid>
          <Grid item xs={6} sm={4} lg={6}>
            <StatCard label="Available" value={tour.available} />
          </Grid>
          {!!tour.asset && (
            <Grid item xs={12} sm={4} lg={12}>
              <SuperStat
                icon={
                  <Avatar>
                    <DirectionsBoat />
                  </Avatar>
                }
                label="Asset"
                value={tour.asset.name}
              />
            </Grid>
          )}
          {!!tour.notes && (
            <Grid item xs={12}>
              {tour.notes}
            </Grid>
          )}
        </Grid>
        {onUpdate && edit && (
          <EditTour
            tour={tour}
            show={!!edit}
            onSaved={onSaved}
            onCancelled={() => setEdit(false)}
          />
        )}
      </>
    </WidgetFrame>
  );
};

/**
 * TimePeriodStringBuilder
 *
 * Builds a string based upon the tour's start and end times
 * @param {*} start
 * @param {*} end
 * @param {*} tz
 */
const timePeriodStringBuilder = (start, end, tz) => {
  const _start = moment.tz(start, tz);
  const _end = moment.tz(end, tz);

  if (_start.isSame(end, "day"))
    return `${_start.format("dddd, MMM D, YYYY HHmm")} - ${_end.format(
      "HHmm"
    )}`;
  else
    return `${_start.format("dddd, MMM D, YYYY HHmm")} - ${_end.format(
      "dddd, MMM D, YYYY HHmm"
    )}`;
};

export default Summary;
