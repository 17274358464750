import React from "react";
import {
  ThemeProvider,
  createMuiTheme,
  responsiveFontSizes,
} from "@material-ui/core/styles";

var theme = createMuiTheme({
  palette: {
    primary: {
      light: "#66c1b9",
      main: "#01899f",
      dark: "#0c4964",
      contrastText: "#eeeeee",
    },
    secondary: {
      light: "#ffffff",
      main: "#66c1b9",
      dark: "#01899f",
      contrastText: "#666666",
    },
    text: {
      primary: "#484848",
      secondary: "#999999",
    },
    action: {
      active: "#484848",
      hover: "#eeeeee",
      selected: "#01899f",
      disabled: "rgba(0, 0, 0, 0.26)",
      disabledBackground: "rgba(0, 0, 0, 0.12)",
    },
    background: {
      paper: "#fff",
      default: "#fafafa",
    },
  },
  typography: {
    fontWeight: 400,
    fontSize: 14,
    color: "#666",
    body1: {},
    body2: {},
    h1: { fontSize: 35, fontWeight: 300 },
    h2: { fontSize: 30, fontWeight: 300 },
    h3: { fontSize: 25, fontWeight: 500 },
    h4: { fontSize: 20, fontWeight: 400 },
    h5: { fontSize: 15, fontWeight: 500 },
    h6: { fontSize: 14 },
  },
  props: {
    MuiButtonBase: {
      disableRipple: true,
    },
    MuiLink: {
      underline: "none",
    },
  },
  overrides: {
    MuiTypography: {
      gutterBottom: { marginBottom: "2rem" },
      h1: {
        lineHeight: "1.25em",
      },
      h2: {
        lineHeight: "1.25em",
      },
      h3: {
        lineHeight: "1.25em",
      },
    },
    MuiButton: {
      root: {
        borderRadius: "8px",
      },
    },
    MuiCard: {
      root: {
        borderRadius: "8px",
      },
    },
    MuiCardHeader: {
      title: { fontWeight: 600 },
      subheader: { fontSize: "0.95em" },
    },
    MuiTextField: {
      root: {
        margin: "6px",
      },
    },
    MuiInput: {
      root: {
        margin: "8px",
      },
    },
    MuiPaper: {
      rounded: {
        borderRadius: "8px",
      },
    },
  },
});

//theme = responsiveFontSizes(theme, { factor: 10 });

export default ({ children }) => {
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};
