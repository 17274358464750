/********************************************************************
 *
 * Account.jsx
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2019 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

import React from "react";
import PropTypes from "prop-types";
import format from "../../utils/format";

/**
 * Account
 *
 * Renders an account and entries.
 */
const Account = ({ account }) => {
  let total = 0;

  if (!account) return "No account records";

  total = parseFloat(account.balance);

  return (
    <table className="table table-striped table-sm small">
      <colgroup>
        <col />
        <col />
        <col />
        <col style={{ width: "150px" }} />
        <col style={{ width: "150px" }} />
      </colgroup>
      <thead>
        <tr>
          <th>Date</th>
          <th>Transaction</th>
          <th>Details</th>
          <th className="text-right">Amount</th>
          <th className="text-right">{format.currency(account.balance)}</th>
        </tr>
      </thead>
      <tbody>
        {account.entries &&
          account.entries.map((entry, index) => (
            <tr key={index}>
              <td>{format.date(entry.timestamp)}</td>
              <td>{entry.transaction.type.name}</td>
              <td>{entry.description}</td>
              <td className="text-right">{format.currency(entry.amount)}</td>
              <td className="text-right">
                {format.currency((total += entry.amount))}
              </td>
            </tr>
          ))}
      </tbody>
    </table>
  );
};

Account.propTypes = {
  account: PropTypes.object,
};

export default Account;
