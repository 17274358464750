/********************************************************************
 *
 * CalProduct.jsx
 *
 * Private class that renders a product on the calendar grid.
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2019 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

import React, { useState, useEffect } from "react";
import format from "../../utils/format";
import moment from "moment-timezone";
import { useApp, useAPI } from "../../providers/AppProvider";

const CalProduct = ({ product }) => {
  const [shifts, setShifts] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const app = useApp();
  const api = useAPI();

  let departureCSS = "";
  let numCrew = shifts
    ? shifts.filter((shift) => {
        return shift.status === 1;
      }).length
    : -1;

  useEffect(() => {
    if (product && product.departure) fetchShifts(product.departure);
  }, [product]);

  const fetchShifts = (departure) => {
    //
    //  Request shifts
    //
    api
      .fetch(`/api/tours/tour/${departure.id}/shifts`)
      .then(({ payload: shifts }) => {
        setShifts(shifts);
        setLoaded(true);
      })
      .catch((error) => {
        app.error({ error });
        setLoaded(true);
      });
  };

  //
  //  Do nothing if product has been deleted
  //
  if (product.deleted) return "";

  if (product.departure) {
    switch (product.departure.status.id) {
      case 0: // Booked
        departureCSS = "dsOpen";
        break;
      case 1: // Departed
        departureCSS = "dsDeparted";
        break;
      case 2: // Closed
        departureCSS = "dsClosed";
        break;
      case 3: // Cancelled
        departureCSS = "dsCancelled";
        break;
      default:
        departureCSS = "dsOpen";
    }
  } else {
    // Do not display past products that were unsold (no departure assigned)
    if (moment().isAfter(product.startTime.time)) return "";

    //
    //  Product scheduled in the future without an assigned departure
    //
    departureCSS = "dsOpen";
  }

  if (product.deleted) departureCSS += " deleted";

  return (
    <div className={`calevent ${departureCSS}`}>
      <div className="eventtime">
        {`${format.time(product.startTime.time, product.timezone)}`}
      </div>
      <div className="eventcapacity">
        <span
          className={`badge ${
            product.capacity === product.available && product.departure
              ? "badge-available"
              : product.capacity === product.available
              ? "badge-empty"
              : product.available > 0
              ? "badge-available"
              : "badge-soldout"
          }`}
        >
          {product.capacity - product.available}/{product.capacity}
        </span>
        {product.departure && numCrew > 0 ? (
          <div className="badge badge-circle badge-cc">{numCrew}</div>
        ) : numCrew < 1 && this.state.isLoaded ? (
          <div className="badge badge-circle badge-cc-alert">0</div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default CalProduct;
