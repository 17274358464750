import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { withRouter } from "react-router";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { Card } from "react-bootstrap";
import { Main } from "../../lib";
import { useApp, useAPI } from "../../../providers/AppProvider";

/////////////////////////////////////////////////////////////////////
//
//  RateClassPartners
//
//  Renders the partners for a rate type.
//
/////////////////////////////////////////////////////////////////////
const RateClassPartners = ({ match }) => {
  const [partners, setPartners] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const app = useApp();
  const api = useAPI();
  let { id } = match.params;

  useEffect(() => {
    api
      .fetch(`/api/rateclasses/rateclass/${id}/partners`)
      .then(({ payload: partners }) => {
        setPartners(partners);
        setLoaded(true);
      })
      .catch((error) => {
        app.error({ error });
        setLoaded(true);
      });
  }, []);

  ///////////////////////////////////////////////////////////////////////
  //
  //  Event Handlers
  //
  ///////////////////////////////////////////////////////////////////////

  ///////////////////////////////////////////////////////////////////////
  //
  //  Lifecycle methods
  //
  ///////////////////////////////////////////////////////////////////////

  if (!partners) return "Loading Partners...";

  return (
    <Main className="container" loaded={partners}>
      {partners && (
        <>
          <div className="d-flex justify-content-between flex-wrap align-items-center pt-3 pb-2 mb-3 border-bottom">
            <div className="page-title">
              <FontAwesomeIcon className="mr-3" icon={faEdit} />
              Partner
            </div>
            <div className="btn-toolbar mb-2 mb-md-0" />
          </div>
          <div>
            <div className="d-flex justify-content-between flex-wrap align-items-center">
              <CardList rateClassId={id} partners={partners} />
            </div>
          </div>
        </>
      )}
    </Main>
  );
};

/**
 * CardList
 *
 * Renders a list of rate class summaries in card format
 *
 * @param {*} props
 */
const CardList = ({ rateClassId, partners }) => {
  return partners.map((partner) => (
    <Card key={partner.data.id} style={{ width: 330 }} className="mb-4">
      <Link
        className="highlight"
        to={`/app/catalog/rateclass/${rateClassId}/partner/${partner.data.id}/edit`}
      >
        <Card.Body>
          <img
            className={`img-fluid float-right d-none ${
              partner.data.logoURL && "d-md-block"
            } mb-3`}
            width="75"
            src={partner.data.logoURL}
            alt={partner.data.name}
          />
          <span className="">{partner.data.name}</span>
        </Card.Body>
      </Link>
    </Card>
  ));
};

export default withRouter(RateClassPartners);
