/********************************************************************
 *
 * CardList.jsx
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2019 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import {
  Grid,
  Box,
  Card,
  CardHeader,
  CardContent,
  CardActionArea,
  CardActions,
  Badge,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  AccordionActions,
  Divider,
  makeStyles,
} from "@material-ui/core";
import { ExpandMore } from "@material-ui/icons";

/**
 * CardList
 *
 * Renders a list of employee summaries in card format
 *
 * @param {*} param0
 */
const CardList = ({
  xs,
  sm,
  md,
  lg,
  xl,
  items,
  spacing,
  deleted,
  onRenderCard,
}) => {
  const classes = useStyles();

  if (!items) return null;

  return (
    <Grid
      className={classes.cardList}
      container
      spacing={spacing}
      alignItems="stretch"
    >
      {items.map((item, index) =>
        deleted && deleted(item) ? (
          ""
        ) : (
          <Grid
            item
            xs={xs}
            sm={sm}
            md={md}
            lg={lg}
            xl={xl}
            key={index}
            style={{ display: "flex" }}
          >
            {onRenderCard && onRenderCard({ item })}
          </Grid>
        )
      )}
    </Grid>
  );
};

const ExpansionCardList = ({ items, onRenderCard }) => {
  if (!items) return "";

  return items.map((item) => onRenderCard && onRenderCard({ item }));
};

const ExpansionCard = ({ header, content, actions }) => {
  return (
    <Accordion data-test-id="EXPANSION_CARD">
      <AccordionSummary expandIcon={<ExpandMore />}>{header}</AccordionSummary>
      <AccordionDetails>{content}</AccordionDetails>
      {actions && (
        <>
          <Divider />
          <AccordionActions>{actions}</AccordionActions>
        </>
      )}
    </Accordion>
  );
};

const LinkCard2 = ({ header, link, onClick, content, actions, elevation }) => {
  const classes = useStyles();

  return (
    <Card className={classes.card} elevation={elevation}>
      {link || onClick ? (
        <Link className={classes.contentLink} to={link} onClick={onClick}>
          <CardContent>
            {header}
            {content}
          </CardContent>
        </Link>
      ) : (
        <CardContent>
          {header}
          {content}
        </CardContent>
      )}
      {actions && (
        <CardActionArea>
          <CardActions className={classes.actions}>{actions}</CardActions>
        </CardActionArea>
      )}
    </Card>
  );
};

const LinkCard = ({ header, subheader, link, onClick, content, actions }) => {
  const classes = useStyles();

  return (
    <Card className={classes.card}>
      {link ? (
        <Link className={classes.contentLink} to={link} onClick={onClick}>
          {header && <CardHeader title={header} subheader={subheader} />}
          <CardContent className={classes.content}>{content}</CardContent>
        </Link>
      ) : (
        <>
          {header && <CardHeader title={header} subheader={subheader} />}
          <CardContent className={classes.content}>{content}</CardContent>
        </>
      )}
      {actions && (
        <CardActionArea>
          <CardActions className={classes.actions}>{actions}</CardActions>
        </CardActionArea>
      )}
    </Card>
  );
};

const CardContentHeader = ({ avatar, callout, content }) => {
  const classes = useStyles();
  return (
    <Box
      className={classes.cardContentHeader}
      display="flex"
      alignItems="center"
      wrap="nowrap"
    >
      {!!avatar && (
        <Box className={classes.cardContentAvatarContainer}>{avatar}</Box>
      )}
      <Box flexGrow={1}>
        <Badge
          badgeContent={callout ? callout.label : undefined}
          color={callout ? callout.color : undefined}
          anchorOrigin={
            callout && callout.anchor
              ? callout.anchor
              : { horizontal: "right", vertical: "top" }
          }
          style={{ width: "100%" }}
        >
          {content}
        </Badge>
      </Box>
    </Box>
  );
};

const CardAction = ({ to = "#", label, highlight, onClick }) => {
  const classes = useStyles();

  return (
    <Link
      to={to}
      onClick={onClick}
      variant="h6"
      className={`${classes.link} ${highlight && classes.highlight}`}
    >
      {label}
    </Link>
  );
};

/*
 **  Styles
 */
const useStyles = makeStyles((theme) => ({
  card: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    flex: 1,
    padding: theme.spacing(1.5),
    border: "1px solid rgba(122,123,151,.3)",
  },
  cardContentHeader: { marginBottom: theme.spacing(2) },
  cardContentAvatarContainer: {
    marginRight: theme.spacing(2),
  },
  contentLink: {
    display: "block",
    "&:hover": {
      backgroundColor: theme.palette.background.default,
      color: "#666666",
      textDecoration: "none",
    },
  },
  content: { flex: "1 1 auto" },
  link: { padding: "0.25em 0.5em" },
  cardList: {
    display: "flex",
    flexWrap: "wrap",
    "& > *": {
      marginBottom: theme.spacing(2),
    },
  },
  highlight: {
    color: theme.palette.primary.main,
    textTransform: "uppercase",
    borderStyle: "solid",
    borderWidth: "1px",
    borderRadius: "8px",
    borderColor: theme.palette.primary.light,
  },
  actions: {
    fontSize: "0.85em",
    borderTop: "solid 1px #eeeeee",
    padding: "0.5em 0.5em 0em 0.5em",
  },
}));

/*
 **  PropTypes
 */
CardList.propTypes = {
  items: PropTypes.array,
  onRenderHeader: PropTypes.func,
  onRenderLink: PropTypes.func,
  onRenderContent: PropTypes.func,
  onRenderActions: PropTypes.func,
};

ExpansionCardList.propTypes = {
  items: PropTypes.array.isRequired,
  onRenderCard: PropTypes.func,
};

LinkCard.propTypes = {
  header: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  subheader: PropTypes.string,
  link: PropTypes.string,
  content: PropTypes.object,
  actions: PropTypes.object,
};

CardAction.propTypes = {
  to: PropTypes.string,
  onClick: PropTypes.func,
  label: PropTypes.string.isRequired,
};

export {
  CardList,
  ExpansionCardList,
  ExpansionCard,
  LinkCard,
  LinkCard2,
  CardContentHeader,
  CardAction,
};
export {
  Card,
  CardHeader,
  CardContent,
  CardActionArea,
  CardActions,
} from "@material-ui/core";
