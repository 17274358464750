/********************************************************************
 *
 * TourScheduler.jsx
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2019 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

import React, { useState } from "react";
import PropTypes from "prop-types";
import { Grid, Box, Badge, Tooltip, makeStyles } from "@material-ui/core";
import { PersonAddDisabled, NightsStay } from "@material-ui/icons";
import moment from "moment";
import Calendar from "../../../lib/Calendar/Calendar";
import {
  WaterLevels,
  Schedule,
  ModalDialog,
  DialogContent,
  WidgetFrame,
} from "../../../lib";
import Alerts from "../libs/Alerts";
import format from "../../../../utils/format";
import TourMiniEdit from "../libs/TourMiniEdit";
import "./scheduler.css";
import { useApp, useAPI } from "../../../../providers/AppProvider";

const DEFAULT_CAPACITY = 10;

/**
 * TourScheduler
 *
 * Renders the schedule management component for a tour type.
 *
 * The interface allows the user to navigate a schedule of tours.
 * Using the calendar, a user can select a date and the scheduled
 * tours appear in the agenda. Tours can be added or editted by
 * clicing in the agenda.
 *
 * When a tour is selected, or a new tour is created, a dialog
 * appears with the summary data. The user can manipulate the data
 * in the dialog, or dive into more detailed information in the
 * Tour page.
 *
 * All dates and times are relative to the tour's timezone.
 */
const TourScheduler = ({ match, tourType }) => {
  const [calendar, setCalendar] = useState(null);
  const [selectedDate, setSelectedDate] = useState(
    moment().format("YYYY-MM-DD")
  );
  const [tours, setTours] = useState(null);
  const [currentTour, setCurrentTour] = useState(null);
  const [tz] = useState("America/Vancouver");
  const { goodTypeId } = match.params;
  const classes = useStyles();
  const app = useApp();
  const api = useAPI();

  /////////////////////////////////////////////////////////////////////
  //
  //  Utility methods
  //
  /////////////////////////////////////////////////////////////////////

  /**
   * FetchTours
   *
   * Calls the api to fetch tours for the visible dates on the
   * calendar.
   *
   * @param {*} date
   */
  const fetchTours = (goodTypeId, start, end) => {
    let _start, _end;

    return new Promise((resolve, reject) => {
      //
      //  Validate parameters
      //
      if (!start) throw new Error("Start date not defined");
      if (!end) throw new Error("End date not defined");

      //
      //  Request data for the timezone-adjusted start of day
      //
      _start = format.getMomentLocalized(start, tz).startOf("day");
      _end = format.getMomentLocalized(end, tz).endOf("day");

      if (!_start.isValid()) throw new Error("Invalid start date format");
      if (!_end.isValid()) throw new Error("Invalid end date format");

      //
      //  Request product types
      //
      api
        .fetch(
          `/api/tourtypes/tourtype/${goodTypeId}/daterange/${format.toApiDateTime(
            _start
          )}/${format.toApiDateTime(moment(_end).add(1, "day"))}/?verbose`
        )
        .then((envelope) => {
          resolve(envelope.payload);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  /**
   * CreateTour
   *
   * Creates a new tour from a given start date/time.
   *
   * Called when the user clicks on a timeslot. A tour is created
   * based upon the start date/tim and default data.
   *
   * @param {*} startDateTime
   * @param {*} tz
   */
  const createTour = (startDateTime, tz) => {
    let start, end;

    start = moment.tz(startDateTime, tz);
    end = moment(start).add(180, "minutes");

    return {
      start: start.toISOString(),
      end: end.toISOString(),
      acquire: moment(start)
        .subtract(
          process.env.REACT_APP_DEFAULT_TOUR_ASSET_PRETOUR_INTERVAL,
          "minutes"
        )
        .toISOString(),
      release: moment(end)
        .add(
          process.env.REACT_APP_DEFAULT_TOUR_ASSET_POSTTOUR_INTERVAL,
          "minutes"
        )
        .toISOString(),
      tz: tz,
      shared: 1,
      capacity: DEFAULT_CAPACITY,
    };
  };

  ///////////////////////////////////////////////////////////////////
  //
  //  Event Handlers
  //
  ///////////////////////////////////////////////////////////////////
  //
  //  Calendar Component
  //
  ///////////////////////////////////////////////////////////////////

  /**
   * OnCalendarUpdate
   *
   * Fired when the dates on the calendar change.
   *
   * Fetches tours for the dates visible on the calendar.
   *
   * @param {*} event
   */
  const onCalendarUpdate = (event) => {
    setCalendar(event);

    if (event && event.startDate && event.endDate) {
      //
      //  Status message
      //
      const notifyHandle = app.notify("Updating Tours");

      fetchTours(goodTypeId, event.startDate, event.endDate)
        .then((tours) => {
          setTours(tours);
          app.endNotify(notifyHandle);
        })
        .catch((error) => {
          app.error({ error });
        });
    }
  };

  /**
   * OnDateRender
   *
   * Fired when a calendar date renders.
   *
   * Determines if there are tours on the specified date and
   * marks up the calendar accordingly.
   *
   * @param {*} event
   */
  const onDateRender = (event) => {
    let daysTours = [];

    if (tours) {
      tours.forEach((tour) => {
        if (
          moment
            .tz(tour.start, tour.tz)
            .isSame(moment.tz(event.date, tour.tz), "day")
        )
          daysTours.push(tour);
      });
    }

    return (
      <div
        className={`${classes.dateCell} ${
          daysTours.length > 0 ? classes.hasTours : ""
        }`}
      >
        <div>{format.mday(event.date)}</div>
        <div className={classes.dateTourContainer}>
          {daysTours.map((tour) => (
            <Box key={tour.id} display="flex" justifyContent="space-between">
              <Badge
                classes={{ badge: classes.employeeBadge }}
                color="primary"
                badgeContent={tour.shifts && tour.shifts.length}
              >
                {`${format.time(tour.start, tour.tz)}`}
                {format
                  .getMomentLocalized(tour.start, tour.tz)
                  .isBefore(
                    format.getMomentLocalized(tour.end, tour.tz),
                    "day"
                  ) && (
                  <>
                    <Tooltip title="Multi day trip" arrow>
                      <NightsStay
                        style={{ marginLeft: "2px", fontSize: "0.65rem" }}
                      />
                    </Tooltip>
                  </>
                )}
              </Badge>
              <div
                style={{
                  textDecoration:
                    (tour.shared == false || tour.pax == tour.capacity) &&
                    "line-through",
                }}
              >
                {` ${tour.pax}/${tour.capacity}`}
              </div>
            </Box>
          ))}
        </div>
      </div>
    );
  };

  /**
   * OnSelectedDateChanged
   *
   * Fired when the user clicks a date cell in the calendar.
   *
   * @param {event} event
   */
  const onSelectedDateChanged = ({ date }) => {
    setSelectedDate(date);
  };

  ///////////////////////////////////////////////////////////////////
  //
  //  Schedule Component
  //
  ///////////////////////////////////////////////////////////////////

  /**
   *  OnHeaderRender
   *
   *  Renders alerts for the day. Alerts are stored as all-day events
   *  in the Canadian Coastal Google calendar.
   *
   * @param {*} event
   */
  const onHeaderRender = () => {
    return <Alerts date={selectedDate} />;
  };

  /**
   * OnTimeSlotRender
   *
   * Fired by the Schedule component when a time slot is being
   * rendered.
   *
   * @param {*} event
   */
  const onTimeSlotRender = (event) => {
    return (
      <div className="timeSlotContainer" onClick={() => onClickTimeSlot(event)}>
        {tours &&
          tours.map((tour) =>
            moment.tz(tour.start, tour.tz).isSame(event.dateTime, "day") &&
            moment.tz(tour.start, tour.tz).hour() === event.dateTime.hour() ? (
              <div
                key={tour.id}
                className="timeSlotTour"
                onClick={(event) => {
                  onClickTour({ tour: tour });
                  event.stopPropagation();
                }}
              >
                <div className="time">
                  {`${format.time(tour.start, tour.tz)} - ${format.timeTZ(
                    tour.end,
                    tour.tz
                  )}`}
                </div>
                <div className="details">
                  <div>{`${tour.pax} / ${tour.capacity}`}</div>
                  <div>
                    {tour.shared == false && (
                      <PersonAddDisabled
                        style={{
                          marginTop: "-3px",
                          fontSize: "14px",
                        }}
                      />
                    )}
                  </div>
                </div>
              </div>
            ) : (
              ""
            )
          )}
      </div>
    );
  };

  /**
   * OnClickTimeSlot
   *
   * Fired when a user clicks a timeslot in the schedule. Creates the framework of a tour.
   *
   * @param {*} event
   */
  const onClickTimeSlot = (event) => {
    setCurrentTour(createTour(event.dateTime, event.tz));
  };

  /**
   * OnClickTour
   *
   * @param {*} event
   */
  const onClickTour = (event) => {
    setCurrentTour(event.tour);
  };

  ///////////////////////////////////////////////////////////////////
  //
  //  Tour Dialog Component
  //
  ///////////////////////////////////////////////////////////////////

  /**
   * OnDialogClose
   *
   * Called as a callback from the TourMiniEdit component.
   *
   * Nullifies the currentTour (which closes the dialog), and
   * refreshes the tours. The refresh will display any new tour added
   * by the dialog.
   *
   * @param {*} e
   */
  const onDialogClose = (e) => {
    setCurrentTour(null);

    if (calendar && calendar.startDate && calendar.endDate) {
      //
      //  Status message
      //
      const notifyHandle = app.notify("Updating Tours");

      fetchTours(goodTypeId, calendar.startDate, calendar.endDate)
        .then((tours) => {
          setTours(tours);
          app.endNotify(notifyHandle);
        })
        .catch((error) => {
          app.error({ error });
        });
    }
  };

  ///////////////////////////////////////////////////////////////////
  //
  //  Lifecycle methods
  //
  ///////////////////////////////////////////////////////////////////

  return (
    <>
      <Grid container spacing={4}>
        <Grid item xs={6} style={{ display: "flex" }}>
          <WidgetFrame>
            <Calendar
              date={undefined}
              onCalendarUpdating={onCalendarUpdate}
              onDateRender={onDateRender}
              onSelectedDateChanged={onSelectedDateChanged}
            />
          </WidgetFrame>
        </Grid>
        <Grid item xs={6}>
          <WidgetFrame
            title={`${format.day(selectedDate, tz)} ${format.shortDate(
              selectedDate,
              tz
            )} `}
          >
            <Schedule
              date={selectedDate}
              tz={tz}
              onHeaderRender={onHeaderRender}
              onTimeSlotRender={onTimeSlotRender}
            />
          </WidgetFrame>
        </Grid>
        <Grid item xs={12}>
          <WaterLevels
            date={selectedDate}
            showDate={false}
            tz={tz}
            departureTime={undefined}
            returnTime={undefined}
          />
        </Grid>
      </Grid>
      <ModalDialog show={!!currentTour} size="sm">
        <DialogContent>
          <TourMiniEdit
            tourType={tourType}
            tour={currentTour}
            onSaved={onDialogClose}
            onCancelled={onDialogClose}
          />
        </DialogContent>
      </ModalDialog>
    </>
  );
};

/*
 **  Styles
 */
const useStyles = makeStyles({
  dateCell: { padding: "0.2em" },
  hasTours: { height: "100%", backgroundColor: "rgba(39,159,159, 0.2)" },
  dateTourContainer: { fontSize: "0.8em" },
  employeeBadge: {
    fontSize: "0.5rem",
    height: "10px",
    padding: "0 2px",
    minWidth: "10px",
    right: -6,
    top: 2,
  },
});

TourScheduler.propTypes = {
  tourType: PropTypes.object.isRequired,
};

export default TourScheduler;
