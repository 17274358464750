/********************************************************************
 *
 * TourMiniEdit.jsx
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2019 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Link, Button } from "@material-ui/core";
import { Form } from "react-bootstrap";
import { HorizontalGroup } from "../../../lib";
import moment from "moment-timezone";
import { useApp, useAPI } from "../../../../providers/AppProvider";

const TOURTYPE_PRIVATE = 0;
const TOURTYPE_SHARED = 1;

/**
 * TourMiniEdit
 *
 * Widget for quickly viewing/editting/creating a Tour.
 *
 */
const TourMiniEdit = ({ tour: tourExt, tourType, onSaved, onCancelled }) => {
  const [tour, setTour] = useState(null);
  const [dirty, setDirty] = useState(false);
  const [validated, setValidated] = useState(null);
  const app = useApp();
  const api = useAPI();

  useEffect(() => {
    //
    //  New tour mode
    //
    if (tourExt) {
      setTour(tourExt);
      setValidated(null);
      setDirty(tourExt.id == null);
    }
  }, [tourExt]);

  ///////////////////////////////////////////////////////////////////
  //
  //  Event Handlers
  //
  ///////////////////////////////////////////////////////////////////

  /**
   * OnChangeStartTime
   *
   * Fired when the user updates the start time of the tour.
   *
   * Update the tour's start and acquire times, and, if the new start
   * is past the end time, update the end and release times.
   *
   * @param {*} event
   *
   */
  const onChangeStartTime = (event) => {
    let _date = moment.tz(tour.end, tour.tz).startOf("day");

    let _dateTime = moment(_date).add(
      moment.duration(event.target.value, "HH:mm")
    );

    tour.start = _dateTime.toISOString();

    tour.acquire = moment(_dateTime)
      .subtract(
        process.env.REACT_APP_DEFAULT_TOUR_ASSET_PRETOUR_INTERVAL,
        "minutes"
      )
      .toISOString();

    if (_dateTime.isAfter(moment(tour.end))) {
      tour.end = _dateTime.toISOString();
      tour.release = moment(_dateTime)
        .add(
          process.env.REACT_APP_DEFAULT_TOUR_ASSET_POSTTOUR_INTERVAL,
          "minutes"
        )
        .toISOString();
    }

    setTour({ ...tour });
    setDirty(true);
  };

  /**
   * OnChangeEndTime
   *
   * Fired when the user updates the end time of the tour.
   *
   * Update the tour's end and release times, and, if the new end
   * is before the start time, update the start and acquire times.
   *
   * @param {*} event
   *
   */
  const onChangeEndTime = (event) => {
    let _date = moment.tz(tour.end, tour.tz).startOf("day");

    let _dateTime = moment(_date).add(
      moment.duration(event.target.value, "HH:mm")
    );

    tour.end = _dateTime.toISOString();

    tour.release = moment(_dateTime)
      .add(
        process.env.REACT_APP_DEFAULT_TOUR_ASSET_POSTTOUR_INTERVAL,
        "minutes"
      )
      .toISOString();

    if (_dateTime.isBefore(moment(tour.start))) {
      tour.start = _dateTime.toISOString();
      tour.acquire = moment(_dateTime)
        .subtract(
          process.env.REACT_APP_DEFAULT_TOUR_ASSET_PRETOUR_INTERVAL,
          "minutes"
        )
        .toISOString();
    }

    setTour({ ...tour });
    setDirty(true);
  };

  /**
   * OnChangeTourType
   *
   * @param {*} e
   */
  const onChangeTourType = (e) => {
    tour.shared = e.target.checked ? TOURTYPE_PRIVATE : TOURTYPE_SHARED;

    setTour({ ...tour });
    setDirty(true);
  };

  /**
   * OnSave
   *
   * @param {*} event
   */
  const onSave = (event) => {
    const { currentTarget: form } = event;

    event.preventDefault();

    //
    //  Validate form
    //
    if (form.checkValidity() === false) {
      setValidated(false);
      event.stopPropagation();
      return;
    }

    //
    //  Save
    //
    const notifyHandle = app.notify("Saving");

    api
      .update(
        `/api/tourtypes/tourtype/${tourType.id}/tours/tour/${
          tour.id ? tour.id : ""
        }`,
        tour
      )
      .then((tour) => {
        onSaved && onSaved({ tour: tour });
        setTour(null);
        setValidated(null);
        app.endNotify(notifyHandle);
      })
      .catch((error) => {
        app.error({ error });
      });
  };

  /**
   * onCancel
   *
   * Fired when the user aborts changes.
   *
   */
  const onCancel = () => {
    if (
      !dirty ||
      window.confirm(
        "You have unsaved changes. Are you sure you want to close this dialog and loose your changes?"
      )
    ) {
      onCancelled && onCancelled();
      setTour(null);
      setValidated(null);
    }
  };

  ///////////////////////////////////////////////////////////////////
  //
  //  Utility Methods
  //
  ///////////////////////////////////////////////////////////////////

  ///////////////////////////////////////////////////////////////////
  //
  //  Lifecycle methods
  //
  ///////////////////////////////////////////////////////////////////

  return (
    <Form
      className="needs-validation"
      validated={validated}
      onSubmit={onSave}
      noValidate
    >
      <HorizontalGroup>
        <Button
          size="small"
          href={`/app/goods/tour/${tour && tour.id ? tour.id : ""}`}
          onClick={() => onCancelled()}
          target="_blank"
        >
          More Options
        </Button>
        <Button type="submit" size="small" variant="outlined" disabled={!dirty}>
          Save
        </Button>
        <Button
          size="small"
          variant="contained"
          color="primary"
          onClick={onCancel}
        >
          Cancel
        </Button>
      </HorizontalGroup>
      <Editor
        tourType={tourType}
        tour={tour}
        onChangeStartTime={onChangeStartTime}
        onChangeEndTime={onChangeEndTime}
        onChangeTourType={onChangeTourType}
      />
    </Form>
  );
};

const Editor = ({
  tourType,
  tour,
  onChangeStartTime,
  onChangeEndTime,
  onChangeTourType,
}) => {
  return (
    <>
      <h3>
        {tour && tour.id
          ? `${tourType.name} ${tour.id}`
          : `New ${tourType.name}`}
      </h3>
      <div className="form-group">
        <label htmlFor="startTime">Depart</label>
        <input
          id="startTime"
          className="form-control"
          type="time"
          format="HH:mm"
          onChange={onChangeStartTime}
          value={
            tour && tour.start
              ? moment.tz(tour.start, tour.tz).format("HH:mm")
              : ""
          }
          required
        />
        <div className="invalid-feedback">Please choose a tour time.</div>
        <small>{tour && moment.tz(tour.start, tour.tz).format()}</small>
      </div>
      <div className="form-group">
        <label htmlFor="endTime">Return</label>
        <input
          id="endTime"
          className="form-control"
          type="time"
          onChange={onChangeEndTime}
          value={
            tour && tour.end ? moment.tz(tour.end, tour.tz).format("HH:mm") : ""
          }
          required
        />
        <div className="invalid-feedback">Please choose a return time.</div>
        <small>{tour && moment.tz(tour.end, tour.tz).format()}</small>
      </div>
      <div className="form-group">
        <div className="custom-control custom-switch">
          <input
            id="tourType"
            type="checkbox"
            className="custom-control-input"
            onChange={onChangeTourType}
            value="1"
            defaultChecked={tour && !tour.shared}
          />
          <label className="custom-control-label" htmlFor="tourType">
            Private Tour
          </label>
        </div>
      </div>
      <div className="form-group">Capacity: {tour && tour.capacity}</div>
    </>
  );
};

TourMiniEdit.propTypes = {
  tourType: PropTypes.object.isRequired,
  tour: PropTypes.object,
  onSaved: PropTypes.func.isRequired,
  onCancelled: PropTypes.func.isRequired,
};

export default TourMiniEdit;
