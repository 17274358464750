/********************************************************************
 *
 * Analytics/UnearnedPAX.jsx
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2021 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

import React, { useState, useEffect } from "react";
import { Avatar } from "@material-ui/core";
import { PeopleAlt } from "@material-ui/icons";
import { SuperStat } from "../../lib";
import { useApp, useAnalyticsAPI } from "../../../providers/AppProvider";

const UnearnedPAX = () => {
  const [totalTickets, setTotalTickets] = useState(null);
  const app = useApp();
  const analyticsApi = useAnalyticsAPI();

  useEffect(() => {
    fetchTotalFutureTicketCount();
  }, []);

  const fetchTotalFutureTicketCount = () => {
    analyticsApi
      .fetch("/api/tickets/unearned/")
      .then(({ payload: count }) => {
        setTotalTickets(count);
      })
      .catch((error) => {
        app.error({ error });
      });
  };

  return (
    <SuperStat
      icon={
        <Avatar style={{ backgroundColor: "green" }}>
          <PeopleAlt />
        </Avatar>
      }
      label="Confirmed Future Guests"
      value={totalTickets}
    />
  );
};

export default UnearnedPAX;
