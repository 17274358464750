/********************************************************************
 *
 * Analytics/ProductTypeHits.jsx
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2019 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

import React, { useState, useEffect } from "react";
import {
  Card,
  CardHeader,
  CardContent,
  Avatar,
  Typography,
  makeStyles,
} from "@material-ui/core";
import { TouchApp } from "@material-ui/icons";
import { ResponsiveLine } from "@nivo/line";
import moment from "moment-timezone";
import WidgetFrame from "../../lib/WidgetFrame";
import {
  useApp,
  useAnalyticsAPI,
  useAPI,
} from "../../../providers/AppProvider";

const ProductTypeHits = () => {
  const [graphData, setGraphData] = useState(null);
  const app = useApp();
  const analyticsAPI = useAnalyticsAPI();
  const api = useAPI();

  const classes = useStyles();

  useEffect(() => {
    fetchProductTypeHits();
  }, []);

  ///////////////////////////////////////////////////////////////////
  //
  //  Utility Functions
  //
  ///////////////////////////////////////////////////////////////////

  /**
   * FetchProductTypeHits
   *
   */
  const fetchProductTypeHits = () => {
    const date = moment();

    return Promise.all([
      api.fetch(`/api/producttypes/`),
      analyticsAPI.fetch(
        `/api/hits/producttype/${date.startOf("year").format()}/${date
          .endOf("year")
          .format()}`
      ),
    ])
      .then(([{ payload: productTypes }, { payload: hits }]) => {
        let graphData = hits.map((productTypeHits) => {
          const productType = productTypes.find(
            (productType) => productType.id === productTypeHits.productTypeId
          );
          return {
            id: productType ? productType.name : "Undefined",
            data: productTypeHits.hits.map((hit) => ({
              x: hit.date,
              y: hit.count,
            })),
          };
        });

        setGraphData(graphData);
      })
      .catch((error) => {
        app.error({ error });
      });
  };

  if (!graphData) return null;

  return (
    <WidgetFrame title="Hits By Product Type">
      <div style={{ height: "300px" }}>
        <ResponsiveLine
          data={graphData}
          margin={{ top: 50, right: 160, bottom: 50, left: 60 }}
          xScale={{
            type: "time",
            format: "%Y-%m-%d",
            precision: "day",
            useUTC: false,
          }}
          xFormat="time:%Y-%m-%d"
          yScale={{
            type: "linear",
            min: 0,
            max: "auto",
            stacked: false,
            reverse: false,
          }}
          yFormat=">(.0d"
          axisTop={null}
          axisRight={null}
          axisBottom={{
            tickValues: "every week",
            format: "%b %d",
            legend: "Date",
            legendPosition: "middle",
            legendOffset: 40,
          }}
          axisLeft={{
            orient: "left",
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            tickValues: 1,
            legend: "Hits",
            legendOffset: -40,
            legendPosition: "middle",
          }}
          colors={{ scheme: "category10" }}
          curve="monotoneX"
          lineWidth={3}
          pointSize={8}
          pointColor={{ theme: "background" }}
          pointBorderWidth={1}
          pointBorderColor={{ from: "serieColor" }}
          pointLabelYOffset={-12}
          useMesh={true}
          enableSlices={false}
          enableGridX={false}
          enableGridY={true}
          enableArea={true}
          tooltip={(data) => (
            <Card className={classes.tooltip}>
              <CardHeader
                avatar={
                  <Avatar style={{ backgroundColor: data.point.serieColor }}>
                    <TouchApp />
                  </Avatar>
                }
                title={data.point.serieId}
                subheader={data.point.data.xFormatted}
              />
              <CardContent className={classes.content}>
                <Typography variant="h1">
                  {`${data.point.data.yFormatted} Hit${
                    parseInt(data.point.data.yFormatted) > 1 ? "s" : ""
                  } `}
                </Typography>
              </CardContent>
            </Card>
          )}
          legends={[
            {
              anchor: "top-right",
              direction: "column",
              justify: false,
              translateX: 10,
              translateY: 0,
              itemsSpacing: 0,
              itemDirection: "left-to-right",
              itemWidth: 0,
              itemHeight: 20,
              itemOpacity: 0.75,
              symbolSize: 12,
              symbolShape: "circle",
              symbolBorderColor: "rgba(0, 0, 0, .5)",
              effects: [
                {
                  on: "hover",
                  style: {
                    itemBackground: "rgba(0, 0, 0, .03)",
                    itemOpacity: 1,
                  },
                },
              ],
            },
          ]}
        />
      </div>
    </WidgetFrame>
  );
};

const useStyles = makeStyles((theme) => ({
  tooltip: {
    backgroundColor: "rgba(255,255,255,0.75)",
    padding: theme.spacing(0),
    zIndex: 9999,
  },
  content: { textAlign: "center" },
}));

export default ProductTypeHits;
