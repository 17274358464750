/********************************************************************
 *
 * PublicAppProvider.jsx
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2019 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

import React, { useReducer } from "react";
import { makeStyles } from "@material-ui/core";
import { Alert } from "@material-ui/lab";

import CCAPIs from "../utils/CCAPIs";

const AppContext = React.createContext();

/**
 * PublicAppProvider
 *
 * @param {*} param0
 */
const PublicAppProvider = ({ children }) => {
  const [alerts] = useReducer((state, action) => {
    return [
      ...state,
      {
        timestamp: Date.now(),
        message: action.payload.message,
        severity: action.type,
      },
    ];
  }, []);

  const classes = useStyles();

  ///////////////////////////////////////////////////////////////////////
  //
  //  API Methods
  //
  ///////////////////////////////////////////////////////////////////////

  const notify = (message) => {};

  const endNotify = (key) => {};

  const log = (message) => {};

  const debug = (message) => {};

  const warning = (message) => {};

  const error = ({ error, component }) => {};

  ///////////////////////////////////////////////////////////////////////
  //
  //  Lifecycle methods
  //
  ///////////////////////////////////////////////////////////////////////

  return (
    <AppContext.Provider
      value={{ notify, endNotify, log, debug, warning, error }}
    >
      {/* Message Handling */}
      {alerts.length > 0 &&
        alerts.map((alert) => (
          <Alert
            key={alert.timestamp}
            className={classes.alert}
            variant="standard"
            severity={alert.severity}
          >
            {alert.message}
          </Alert>
        ))}
      {children}
    </AppContext.Provider>
  );
};

/*
 **  Hooks
 */
const usePublicApp = () => {
  return React.useContext(AppContext);
};

const usePublicAPI = () => {
  return CCAPIs(process.env.REACT_APP_PUBLIC_API_URL);
};

/*
 **  Styles
 */
const useStyles = makeStyles((theme) => ({
  alert: { zIndex: 1, borderRadius: 0 },
}));

export default PublicAppProvider;
export { usePublicApp, usePublicAPI };
