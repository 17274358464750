/********************************************************************
 *
 * Users.jsx
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2019 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useApp, useAPI } from "../../../providers/AppProvider";

const Users = ({ partnerId }) => {
  const [users, setUsers] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const app = useApp();
  const api = useAPI();

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = () => {
    //
    //  Status message
    //
    const notifyHandle = app.notify("Loading Users");

    api
      .fetch(`/api/partners/partner/${partnerId}/users/`)
      .then(({ payload: users }) => {
        setUsers(users);
        setLoaded(true);
        app.endNotify(notifyHandle);
      })
      .catch((error) => {
        app.error({ error });
        setLoaded(true);
      });
  };

  if (!users) return "Loading...";

  return (
    <div className="table-responsive small">
      <table className="table table-striped table-sm">
        <thead>
          <tr>
            <th>Name</th>
          </tr>
        </thead>
        <tbody>
          {users.map((user) => (
            <tr key={user.id}>
              <td>
                <Link
                  to={`/app/partners/user/${user.id}`}
                  title={`User ${user.id}`}
                >
                  {user.fname} {user.lname}
                </Link>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Users;
