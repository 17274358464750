/********************************************************************
 *
 * Partner.jsx
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2020 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

import React, { useState, useEffect } from "react";
import Users from "./Users";
import { Main } from "../../lib";
import { useApp, useAPI } from "../../../providers/AppProvider";

const Partner = ({ match }) => {
  const [partner, setPartner] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const app = useApp();
  const api = useAPI();

  const { id } = match.params;

  useEffect(() => {
    api
      .fetch(`/api/partners/partner/${id}`)
      .then(({ payload: partner }) => {
        setPartner(partner);
        setLoaded(true);
      })
      .catch((error) => {
        app.error({ error });
        setLoaded(true);
      });
  }, []);

  return (
    <Main className="col-md-9 ml-sm-auto col-lg-10 px-4" loaded={partner}>
      {partner && (
        <>
          <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
            <div className="page-title d-flex align-items-center">
              <img
                src={partner.logoURL}
                className="mr-3"
                style={{ height: "50px" }}
                alt={partner.name}
              />
              {partner.name}
            </div>
            <div className="btn-toolbar mb-2 mb-md-0">
              <div className="btn-group mr-2" />
            </div>
          </div>
          <a href={partner.website} rel="noopener noreferrer" target="_blank">
            {partner.website}
          </a>
          <h5 className="mt-3">Users</h5>
          <Users partnerId={partner.id} />
        </>
      )}
    </Main>
  );
};

export default Partner;
