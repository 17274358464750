/********************************************************************
 *
 * ProductCalendar.jsx
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2019 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt } from "@fortawesome/free-solid-svg-icons";
import Agenda from "./Agenda";
import Calendar from "../lib/Calendar/Calendar";
import CalProduct from "./CalProduct";
import format from "../../utils/format";
import { useApp, useAPI } from "../../providers/AppProvider";

/**
 * ProductCalendar
 *
 * Renders a calendar and agenda to display upcoming products
 *
 */
const ProductCalendar = () => {
  const [selectedDate, setSelectedDate] = useState(null);
  const [products, setProducts] = useState(null);
  const [agendaProducts, setAgendaProducts] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const app = useApp();
  const api = useAPI();

  /////////////////////////////////////////////////////////////////////
  //
  //  Utility methods
  //
  /////////////////////////////////////////////////////////////////////

  /**
   * fetchProducts
   *
   * Fetches the products from the CC API for the calendar dates and
   * stores the result in the state data.
   *
   * @param {date} start
   * @param {date} end
   */
  const fetchProducts = (start, end) => {
    if (!start && !end) return;

    api
      .fetch(
        `/api/products/daterange/${format.toApiDateTime(
          start
        )}/${format.toApiDateTime(end)}`
      )
      .then(({ payload: products }) => {
        setProducts(products);
        setLoaded(true);
      })
      .catch((error) => {
        app.error({ error });
        setLoaded(true);
      });
  };

  /**
   * fetchProductsForDate
   *
   * @param {array} products
   * @param {date} date
   *
   * @returns Null if products argument is null, otherwise and array
   * of zero or more products that occur on the date argument.
   *
   */
  const fetchProductsForDate = (date) => {
    //
    // Not loaded - return null
    //
    if (!products) return null;

    if (
      products.years[date.year()] &&
      products.years[date.year()].months[date.month()] &&
      products.years[date.year()].months[date.month()].days[date.date()]
    )
      return products.years[date.year()].months[date.month()].days[date.date()]
        .nodes;

    return null;
  };

  /////////////////////////////////////////////////////////////////////
  //
  //  Event handler methods
  //
  /////////////////////////////////////////////////////////////////////

  const onCalendarUpdating = (e) => {
    setProducts(fetchProducts(e.startDate, e.endDate));
  };

  const onDateRender = (e) => {
    let products = fetchProductsForDate(e.date);

    return (
      <div>
        {(products && products.length) > 0 ? (
          /* Loaded, products scheduled */ products.map((product) => (
            <CalProduct product={product} key={product.id} />
          ))
        ) : /* Loaded, nothing scheduled */ products &&
          products.length === 0 ? (
          ""
        ) : (
          /* Not loaded */ <div className="calevent calunassigned" />
        )}
      </div>
    );
  };

  /**
   * onSelectedDateChanged
   *
   * Fired when the user clicks a date cell in the calendar.
   *
   * @param {event} e
   */
  const onSelectedDateChanged = (e) => {
    setSelectedDate(e.date);
  };

  /////////////////////////////////////////////////////////////////////
  //
  //  Lifecycle methods
  //
  /////////////////////////////////////////////////////////////////////

  return (
    <main role="main">
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center px-2 pt-3 pb-2 mb-3 border-bottom">
        <div className="page-title">
          <FontAwesomeIcon className="mr-4" icon={faCalendarAlt} />
          Product Calendar
        </div>
      </div>
      <div className="d-flex flex-nowrap">
        <div style={{ flexBasis: "66%" }}>
          <Calendar
            date={undefined}
            width={750}
            onCalendarUpdating={onCalendarUpdating}
            onDateRender={onDateRender}
            onSelectedDateChanged={onSelectedDateChanged}
          />
        </div>
        <div className="agenda" style={{ flexBasis: "34%" }}>
          <Agenda date={selectedDate} products={agendaProducts} />
        </div>
      </div>
    </main>
  );
};

export default ProductCalendar;
