/********************************************************************
 *
 * RateClassEdit.jsx
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2020 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

import React, { useState, useEffect } from "react";
import { Paper, makeStyles } from "@material-ui/core";
import { MonetizationOn } from "@material-ui/icons/";
import { Main } from "../../lib";
import { useApp, useAPI } from "../../../providers/AppProvider";

/**
 * RateClassEdit
 *
 * Adds or edits a rate class.
 *
 * @param {*} param0
 */
const RateClassEdit = ({ match, history }) => {
  const classes = styles();
  const [rateClass, setRateClass] = useState(null);
  const [policies, setPolicies] = useState(false);
  const [terms, setTerms] = useState(false);
  const [dirty, setDirty] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const app = useApp();
  const api = useAPI();

  //
  //  If product id is a paramter, we are in edit mode, otherwise
  //  we are creating a new product
  //
  const { id } = match.params;

  /**
   * Initialize
   *
   */
  useEffect(() => {
    //
    //  Initilize Policies
    //
    initPolicies();

    //
    //  Initilize Terms
    //
    initTerms();

    if (!id) {
      //
      //  New rate class mode
      //
      setLoaded(true);
      return;
    }

    //
    //  Edit mode
    //
    fetchRateClass(id);
  }, [id]);

  /////////////////////////////////////////////////////////////////////
  //
  //  Utility methods
  //
  /////////////////////////////////////////////////////////////////////

  /**
   * FetchRateClass
   *
   * Fetches the rate class
   *
   * @param {*} id
   */
  const fetchRateClass = (id) => {
    api
      .fetch(`/api/rateclasses/rateclass/${id}?verbose`)
      .then(({ payload: rateClass }) => {
        setRateClass(rateClass);
        setLoaded(true);
      })
      .catch((error) => {
        app.error({ error });
        setLoaded(true);
      });
  };

  /**
   * InitPolicies
   *
   * Initializes the list of policies
   *
   */
  const initPolicies = () => {
    api
      .fetch(`/api/policies/`)
      .then(({ payload: policies }) => {
        setPolicies(policies);
      })
      .catch((error) => {
        app.error({ error });
      });
  };

  /**
   * InitTerms
   *
   * Initializes the list of terms
   */
  const initTerms = () => {
    api
      .fetch(`/api/terms/`)
      .then(({ payload: terms }) => {
        setTerms(terms);
      })
      .catch((error) => {
        app.error({ error });
      });
  };

  ///////////////////////////////////////////////////////////////////
  //
  //  Event Handlers
  //
  ///////////////////////////////////////////////////////////////////

  /**
   * OnUpdate
   *
   * @param {*} key
   * @param {*} value
   */
  const onUpdate = (key, value) => {
    let path = key.split(".");

    let i = 0;
    let obj = rateClass || {};
    for (i = 0; i < path.length - 1; i++) obj = obj[path[i]];

    obj[path[i]] = value;

    setRateClass({ ...obj });
    setDirty(true);
  };

  /**
   * OnDelete
   *
   * Fired when the user deletes the rate class.
   *
   * @param {*} event
   */
  const onDelete = (event) => {
    if (!window.confirm("Are you sure you want to delete this rate?")) return;

    api
      .delete(`/api/rateclasses/rateclass/${rateClass.id}`)
      .then((envelope) => {
        history.push(`/app/catalog/rateClasses`);
      })
      .catch((error) => {
        app.error({ error });
      });
  };

  /**
   * OnSave
   *
   * Fired when the user commits the change.
   *
   * @param {*} event
   */
  const onSave = (event) => {
    event.preventDefault();

    api
      .update(
        `/api/rateclasses/rateclass/${rateClass.id ? rateClass.id : ""}`,
        rateClass
      )
      .then((envelope) => {
        history.push(`/app/catalog/rateClass/${rateClass.id}`);
      })
      .catch((error) => {
        app.error({ error });
      });
  };

  /**
   * OnCancel
   *
   * @param {*} event
   */
  const onCancel = (event) => {
    event.preventDefault();
    window.history.back();
  };

  /**
   * RateClassDetail
   *
   * @param {*} param0
   */
  const RateClassDetails = ({ rateClass }) => {
    return (
      <form className="needs-validation" onSubmit={onSave}>
        <div className="form-group row">
          <div className="col">
            <label htmlFor="name">Name</label>
            <input
              id="name"
              name="name"
              className="form-control"
              type="text"
              placeholder="Name"
              onChange={(e) => onUpdate(e.target.id, e.target.value)}
              defaultValue={rateClass ? rateClass.name : ""}
              required
            />
            <small className="form-text text-muted">
              The name of the rate.
            </small>
            <div className="invalid-feedback">Please enter a rate name.</div>
          </div>
        </div>
        <div className="form-group row">
          <div className="col">
            <label htmlFor="description">Description</label>
            <textarea
              id="description"
              name="description"
              className="form-control"
              rows="2"
              onChange={(e) => onUpdate(e.target.id, e.target.value)}
              value={
                rateClass && rateClass.description ? rateClass.description : ""
              }
              required
            />
            <small className="form-text text-muted">
              Description for rate.
            </small>
            <div className="invalid-feedback">
              Please enter a description for the rate.
            </div>
          </div>
        </div>
        <div className="form-group row my-4">
          <div className="col">
            <label htmlFor="commission">Commission</label>
            <div className="row">
              <div className="col">
                <input
                  id="commission.rate"
                  name="commission.rate"
                  className="form-control"
                  type="number"
                  onChange={(e) => onUpdate(e.target.id, e.target.value)}
                  value={
                    rateClass &&
                    rateClass.commission &&
                    rateClass.commission.rate
                  }
                  required
                />
                <small className="form-text text-muted">
                  Commission amount.
                </small>
                <div className="invalid-feedback">
                  Please enter an amount for the commission.
                </div>
              </div>
              <div className="col">
                <select
                  id="commission.id"
                  name="commission.id"
                  className="form-control"
                  onChange={(e) => onUpdate(e.target.id, e.target.value)}
                  value={
                    rateClass && rateClass.commission
                      ? rateClass.commission.type
                      : "-1"
                  }
                >
                  <option value="-1" disabled>
                    -- Select --
                  </option>
                  <option value="0">Percentage</option>
                  <option value="1">Fixed</option>
                </select>

                <small className="form-text text-muted">Commission type.</small>
                <div className="invalid-feedback">
                  Please select the commission type.
                </div>
              </div>
            </div>
          </div>
          <div className="col">
            <label htmlFor="termId">Term</label>
            <select
              id="termId"
              name="termId"
              className="form-control"
              onChange={(e) => onUpdate(e.target.id, e.target.value)}
              value={rateClass && rateClass.term ? rateClass.term.id : "-1"}
            >
              <option value="-1" disabled>
                -- Select --
              </option>
              {terms
                ? terms.map((term, index) => (
                    <option key={term.id} value={index}>
                      {term.name}
                    </option>
                  ))
                : ""}
            </select>

            <small className="form-text text-muted">
              The term of the rate.
            </small>
            <div className="invalid-feedback">Please enter a rate term.</div>
          </div>
        </div>
        <div className="form-group row my-4">
          <div className="col">
            <label htmlFor="bookingPolicyId">Booking Policy</label>
            <select
              id="bookingPolicyId"
              name="bookingPolicyId"
              className="form-control"
              onChange={(e) => onUpdate(e.target.id, e.target.value)}
              value={
                rateClass && rateClass.policies
                  ? rateClass.policies[0].id
                  : "-1"
              }
            >
              <option value="-1" disabled>
                -- Select --
              </option>
              {policies
                ? policies.map((policy, index) => (
                    <option key={policy.id} value={policy.id}>
                      {policy.name}
                    </option>
                  ))
                : ""}
            </select>
            <small className="form-text text-muted">Booking Policy.</small>
            <div className="invalid-feedback">
              Please select the booking policy.
            </div>
          </div>
          <div className="col">
            <label htmlFor="cancellationPolicyId">Cancellation Policy</label>
            <select
              id="cancellationPolicyId"
              name="cancellationPolicyId"
              className="form-control"
              onChange={(e) => onUpdate(e.target.id, e.target.value)}
              value={rateClass ? rateClass.policies[1].id : "-1"}
            >
              <option value="-1" disabled>
                -- Select --
              </option>
              {policies
                ? policies.map((policy, index) => (
                    <option key={policy.id} value={policy.id}>
                      {policy.name}
                    </option>
                  ))
                : ""}
            </select>

            <small className="form-text text-muted">Cancellation Policy.</small>
            <div className="invalid-feedback">
              Please select the cancellation policy.
            </div>
          </div>
        </div>
        <button
          type="submit"
          className="btn btn-primary"
          disabled={!dirty}
          value="Submit"
        >
          Save
        </button>
        <button className="btn btn-secondary mx-3" onClick={onCancel}>
          Cancel
        </button>
      </form>
    );
  };

  return (
    <Main
      icon={<MonetizationOn />}
      title={`${rateClass ? "Edit" : "New"} Rate Class`}
      loaded={loaded}
    >
      <Paper className={classes.controls}>
        <RateClassDetails rateClass={rateClass} />
      </Paper>
    </Main>
  );
};

const styles = makeStyles((theme) => ({
  controls: { padding: theme.spacing(2) },
}));

export default RateClassEdit;
