import React, { useState } from "react";
import { DayPickerRangeController } from "react-dates";
import "react-dates/lib/css/_datepicker.css";
import { START_DATE, END_DATE } from "react-dates/constants";
import { useApp, useAPI } from "../../../providers";

/**
 * RateCalendar
 *
 * Renders the a calendar of rates for a rate class.
 */
const RateCalendar = ({ autoFocusEndDate }) => {
  const [rates, setRates] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [focusedInput, setFocusedInput] = useState(
    autoFocusEndDate ? END_DATE : START_DATE
  );
  const [loaded, setLoaded] = useState(false);
  const app = useApp();
  const api = useAPI();

  ///////////////////////////////////////////////////////////////////////
  //
  //  Event Handlers
  //
  ///////////////////////////////////////////////////////////////////////

  const onDatesChange = ({ startDate, endDate }) => {
    setStartDate(startDate);
    setEndDate(endDate);
  };

  const onFocusChange = (focusedInput) => {
    setFocusedInput(!focusedInput ? START_DATE : focusedInput);
  };

  const onRenderDayContents = (day, modifiers) => {
    return (
      <div
        data-toggle="tooltip"
        title="Open: 1/5/2020&#10;Closed: 30/9/2020"
      >
        <b>{day.format("D")}</b>
        <div className="small">$123.00</div>
      </div>
    );
  };

  ///////////////////////////////////////////////////////////////////////
  //
  //  Lifecycle methods
  //
  ///////////////////////////////////////////////////////////////////////

  //    if (!rates) return "Loading Rates...";

  return (
    <DayPickerRangeController
      startDate={startDate}
      endDate={endDate}
      daySize={60}
      focusedInput={focusedInput}
      onDatesChange={onDatesChange}
      onFocusChange={onFocusChange}
      renderDayContents={onRenderDayContents}
    />
  );
};

export default RateCalendar;
