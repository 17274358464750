/********************************************************************
 *
 * Template.jsx
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2020 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

import React from "react";
import PropTypes from "prop-types";
import { Grid, Typography } from "@material-ui/core";
import moment from "moment-timezone";

import format from "../../../../utils/format";

/**
 * Template
 *
 * Component that displays summary information about a product
 * template. A template is a proposed configuration of goods that
 * satisifes the requirements of a product type.
 *
 */
const Template = ({ template, onPublish }) => {
  return (
    <Grid container>
      <Grid item container xs={12} alignItems="flex-start">
        <Grid item style={{ flex: "1 1 auto" }}>
          <Typography variant="h6">{format.dayDate(template.start)}</Typography>
          {!moment(template.start).isSame(moment(template.end), "day") && (
            <span className="text-danger small ml-2">
              * Product spans one or more days
            </span>
          )}
          <Typography variant="body2">
            {format.time(template.start)} - {format.time(template.end)}
          </Typography>
        </Grid>
        <Grid item style={{ flex: "0 0" }}>
          {!template.id && onPublish && (
            <button
              type="buton"
              className="btn btn-outline-cc btn-sm"
              onClick={() => onPublish({ template: template })}
            >
              Publish
            </button>
          )}
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <table className="table table-sm small mb-2">
          <tbody>
            {Object.keys(template.features).map((key, index) => (
              <tr key={index}>
                <td>
                  <div>
                    {format.shortDateTime(template.features[key].start)}
                  </div>
                  <div>{format.shortDateTime(template.features[key].end)}</div>
                </td>
                <td>{template.features[key].name}</td>
                <td>{template.features[key].goodId}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </Grid>
    </Grid>
  );
};

Template.propTypes = {
  template: PropTypes.object.isRequired,
  onPublish: PropTypes.func,
};

export default Template;
