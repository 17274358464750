/********************************************************************
 *
 * /src/components/sales/receiptDetails/Receipt.jsx
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2024 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

import React from "react";
import {
  Grid,
  Card,
  CardHeader,
  CardContent,
  Avatar,
  Tooltip,
  IconButton,
  Typography,
} from "@material-ui/core";
import { AttachMoney, SendOutlined } from "@material-ui/icons";
import format from "../../../../utils/format";
import { useApp, useAPI } from "../../../../providers/AppProvider";

const Receipt = ({ order, receipt }) => {
  const app = useApp();
  const api = useAPI();

  if (!order || !receipt) return "";

  /**
   * onSendReceipt
   *
   * Calls the API to send the receipt
   */
  const onSendReceipt = () => {
    let notifyHandle = null;

    if (
      !window.confirm(
        `Would you like to send a receipt to ${order.customer.fname} ${order.customer.lname}`
      )
    )
      return;

    //
    //  Status message
    //
    notifyHandle = app.notify("Sending Receipt");

    api
      .create(
        `/api/management/orders/order/${order.id}/communications/receipt/${receipt.id}`,
        order
      )
      .then(() => {
        app.endNotify(notifyHandle);
      })
      .catch((error) => {
        app.error({ error });
      });
  };

  console.log(receipt);
  return (
    <Card style={{ maxWidth: "400px" }}>
      <CardHeader
        avatar={
          <Avatar>
            <AttachMoney />
          </Avatar>
        }
        title="Receipt"
        subheader={format.dateTime(receipt.created)}
        action={
          <Tooltip title="Email Receipt to Customer">
            <IconButton
              onClick={() => {
                onSendReceipt(order);
              }}
            >
              <SendOutlined />
            </IconButton>
          </Tooltip>
        }
      />
      <CardContent>
        <Grid container>
          <Grid item xs={4}>
            <Typography variant="body2">Amount:</Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography variant="body2">
              {format.currency(receipt.amount)}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="body2">{receipt.receiptType.name}:</Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography variant="body2">{receipt.ref}</Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="body2">User:</Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography variant="body2">
              {receipt.user.fname} {receipt.user.lname}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="body2">Terminal:</Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography variant="body2">{receipt.terminal}</Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="body2">Entry #</Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography variant="body2">{receipt.entryId}</Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="body2">Receipt #</Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography variant="body2">{receipt.id}</Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default Receipt;
