import React, { Component } from "react";
import SectionRouterHelper from "../SectionRouterHelper";
import _SectionSubRouter from "./_SubSectionRouter";

import Customers from "./Customers/Customers";
import Customer from "./Customers/Customer";

import UserExperience from "./UserExperience/Container";

import Reviews from "./Reviews/Reviews";
import Review from "./Reviews/Review";

export let routes = {
  root: "/app/crm",
  tree: {
    Customers: {
      label: "Customers",
      path: "/app/crm/customers/",
      component: Customers,
      children: [
        {
          path: "/app/crm/customer/:id",
          component: Customer,
        },
      ],
    },
    UserExperience: {
      label: "User Experience",
      path: "/app/crm/userexperience/",
      component: UserExperience,
      children: [
        {
          path: "/app/crm/userexperience/:userId",
          component: UserExperience,
        },
      ],
    },
    Reviews: {
      label: "Reviews",
      path: "/app/crm/reviews/",
      component: Reviews,
      children: [
        {
          path: "/app/crm/review/:id",
          component: Review,
        },
        {
          path: "/app/crm/review",
          component: Review,
        },
      ],
    },
  },
};

export class _SectionRouter extends Component {
  render() {
    //
    //  Create the section router.
    //
    return (
      <SectionRouterHelper
        routes={routes}
        subSectionRouter={_SectionSubRouter}
      />
    );
  }
}
