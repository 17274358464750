/********************************************************************
 *
 * /components/goods/goods/tour/orders.jsx
 *
 * Renders the orders that have booked products containing the
 * tour.
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2019 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

import React, { useState, useEffect } from "react";
import { Box, Avatar, Chip, Typography, makeStyles } from "@material-ui/core";
import { ShoppingCartOutlined } from "@material-ui/icons";
import {
  CardList,
  LinkCard2,
  CardContentHeader,
} from "../../../lib/DataDisplay/Cards";
import { WidgetFrame, Property } from "../../../lib";
import format from "../../../../utils/format";
import { useApp, useAPI } from "../../../../providers/AppProvider";

const Orders = ({ tour }) => {
  const [orders, setOrders] = useState(null);
  const app = useApp();
  const api = useAPI();
  const classes = useStyles();

  /**
   *  Orders State Management
   */
  useEffect(() => {
    if (tour) fetchOrders(tour.id);
  }, [tour]);

  ///////////////////////////////////////////////////////////////////
  //
  //  Utility Methods
  //
  ///////////////////////////////////////////////////////////////////

  /**
   * FetchOrders
   *
   * @param {*} tourId
   */
  const fetchOrders = (tourId) => {
    api
      .fetch(`/api/tours/tour/${tourId}/orders/?verbose`)
      .then(({ payload: orders }) => {
        setOrders(orders);
      })
      .catch((error) => {
        app.error({ error });
      });
  };

  return (
    <WidgetFrame
      avatar={
        <Avatar classes={{ root: classes.avatarRoot }}>
          <ShoppingCartOutlined />
        </Avatar>
      }
      title={`${orders && orders.length ? orders.length : "No"} Orders`}
    >
      <CardList
        xs={12}
        items={orders}
        spacing={2}
        headerBG="#01899f"
        onRenderCard={({ item: order }) => (
          <LinkCard2
            link={`/app/sales/order/${order.id}`}
            elevation={0}
            header={
              <CardContentHeader
                avatar={<Chip label={order.confirmation} />}
                content={
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    style={{ width: "100%" }}
                  >
                    <Typography variant="h2">{order.name}</Typography>
                    <Box>
                      <Typography variant="body2">{`Booked by ${order.user.fname} ${order.user.lname}`}</Typography>
                      <Typography variant="body2">{`on ${format.dateTime(
                        order.committed
                      )}`}</Typography>
                    </Box>
                  </Box>
                }
              />
            }
            content={!!order.message && <Property>{order.message}</Property>}
          />
        )}
      />
    </WidgetFrame>
  );
};

const useStyles = makeStyles((theme) => ({
  avatarRoot: { backgroundColor: theme.palette.primary.main },
}));

export default Orders;
