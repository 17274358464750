/********************************************************************
 *
 * lib/Layout/Main.jsx
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2020 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import {
  Box,
  Paper,
  Drawer,
  IconButton,
  Hidden,
  Avatar,
  Badge,
  CircularProgress,
  Typography,
  makeStyles,
} from "@material-ui/core";
import { Menu as MenuIcon, MenuOpen } from "@material-ui/icons";

/**
 * Main
 *
 * @param {*} props
 */
const Main = ({ menu, header, loaded, context, children }) => {
  const [mobileMenuVisible, setMobileMenuVisible] = useState(false);
  const classes = useStyles();
  const location = useLocation();

  useEffect(() => {
    setMobileMenuVisible(false);
  }, [location]);

  return (
    <div className="app-main">
      <Hidden mdUp>
        <MobileMenu
          {...menu}
          show={mobileMenuVisible}
          handleCloseMenu={() => setMobileMenuVisible(false)}
        />
      </Hidden>
      <Hidden smDown>
        <BrowserMenu {...menu} />
      </Hidden>
      <div className={`app-content ${!!menu && "app-content-sidebar-fixed"}`}>
        <div className="app-content--inner">
          {!loaded ? (
            <Box className={classes.progress}>
              <CircularProgress />
            </Box>
          ) : (
            <Content
              {...header}
              context={context}
              children={children}
              handleShowMobileMenu={() => setMobileMenuVisible(true)}
            />
          )}
        </div>
      </div>
    </div>
  );
};

const Content = ({
  icon,
  title,
  subtitle,
  callout,
  bonus,
  context,
  children,
  handleShowMobileMenu,
}) => {
  return (
    <Box style={{ width: "100%" }}>
      <Paper className="app-page-title" elevation={2} square>
        <div style={{ width: "100%" }}>
          <Box className="app-page-title--first">
            <div className="app-page-title--mobilemenu">
              <MenuIcon onClick={handleShowMobileMenu} />
            </div>
            <div
              className={`app-page-title--iconbox ${
                !!subtitle ? "subtitled" : ""
              }`}
            >
              {icon}
            </div>
            <div className="app-page-title--heading">
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                style={{ width: "100%" }}
              >
                <Box flexGrow="1">
                  <Badge
                    badgeContent={callout ? callout.label : undefined}
                    color={callout ? callout.color : undefined}
                  >
                    <Typography variant="h1">{title}</Typography>
                  </Badge>
                  <div className="app-page-title--description">{subtitle}</div>
                </Box>
                <Box>{bonus}</Box>
              </Box>
            </div>
          </Box>
        </div>
      </Paper>
      {!!context && <Box>{context}</Box>}
      <Box data-test-id="PAGE_CONTENT" style={{ padding: "16px" }}>
        {children}
      </Box>
    </Box>
  );
};

const MobileMenu = ({
  icon,
  title,
  subtitle,
  nav,
  bonus,
  show,
  handleCloseMenu,
}) => {
  const classes = useStyles();

  if (!nav) return null;

  return (
    <Drawer anchor="left" open={show} onClose={handleCloseMenu}>
      <div className="app-sidebar-header">
        <Box display="flex" alignItems="center">
          <Avatar
            className={`app-sidebar-userbox-avatar ${classes.sidebarAvatar}`}
          >
            {icon}
          </Avatar>
          <Box className="header-logo-text">{title}</Box>
        </Box>
        <IconButton onClick={handleCloseMenu}>
          <MenuOpen />
        </IconButton>
      </div>
      {nav}
    </Drawer>
  );
};

const BrowserMenu = ({ icon, title, subtitle, nav, bonus }) => {
  const classes = useStyles();

  if (!nav) return null;

  return (
    <>
      <Paper
        className="app-sidebar-wrapper app-sidebar-wrapper-fixed"
        elevation={3}
        square
      >
        <div className="app-sidebar-header"></div>
        <div className="app-sidebar-menu">
          <div className="scrollbar-container ps">
            <div className="app-sidebar-userbox mb-3">
              <Avatar
                className={`app-sidebar-userbox-avatar ${classes.sidebarAvatar}`}
              >
                {icon}
              </Avatar>
              <div className="app-sidebar-userbox-name">
                {title}
                {!!subtitle && (
                  <div className="app-sidebar-userbox-description">
                    {subtitle}
                  </div>
                )}
              </div>
            </div>
            <div>{nav}</div>
          </div>
        </div>
      </Paper>
    </>
  );
};

/*
 **  Styles
 */
const useStyles = makeStyles((theme) => ({
  main: {
    padding: "0",
    margin: "0",
    backgroundColor: "rgb(250,250,250)",
    position: "relative",
    minHeight: "100vh",
  },
  avatar: {
    display: "none",
    backgroundColor: "RGBA(1, 137, 159, 0.025)",
    color: theme.palette.primary.main,
    boxShadow:
      "0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)",
    [theme.breakpoints.up("sm")]: {
      display: "flex",
      marginRight: theme.spacing(1),
    },
  },
  progress: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
    paddingTop: "24px",
  },
  sidebarAvatar: {
    backgroundColor: "RGBA(1, 137, 159, 0.025)",
    color: theme.palette.primary.main,
    boxShadow:
      "0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)",
  },
}));

export default Main;
