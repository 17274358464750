/********************************************************************
 *
 * ProductTypes.jsx
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2019 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

import React, { useState, useEffect } from "react";
import { Button } from "@material-ui/core";
import { Collections } from "@material-ui/icons";
import { Main, CardList, LinkCard2, CardAction } from "../../lib";
import ModalDialog from "../../lib/ModalDialog";
import { useApp, useAPI } from "../../../providers/AppProvider";
import { ProductTypeSummaryHeader } from "./Identity/Summary";
import IdentityEdit from "./Identity/Edit";

/**
 * ProductTypes
 *
 * Renders a list of Product Types
 *
 */
const ProductTypes = () => {
  const [productTypes, setProductTypes] = useState(null);
  const [showAddProductType, setShowAddProductType] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const app = useApp();
  const api = useAPI();

  useEffect(() => {
    fetchProductTypes();
  }, []);

  /////////////////////////////////////////////////////////////////////
  //
  //  Utility methods
  //
  /////////////////////////////////////////////////////////////////////

  /**
   * FetchProductTypes
   *
   * Calls the api to fetch product types.
   *
   */
  const fetchProductTypes = () => {
    //
    //  Request product types
    //
    return new Promise((resolve, reject) => {
      //
      //  Status message
      //
      const notifyHandle = app.notify("Loading");

      api
        .fetch(`/api/producttypes/`)
        .then(({ payload: productTypes }) => {
          setProductTypes(productTypes);
          setLoaded(true);
          app.endNotify(notifyHandle);
        })
        .catch((error) => {
          app.error({ error });
          setLoaded(true);
        });
    });
  };

  ///////////////////////////////////////////////////////////////////
  //
  //  Event Handlers
  //
  ///////////////////////////////////////////////////////////////////

  /**
   * OnSaved
   *
   * Fired once the IdentiyEdit dialog has created and saved a new
   * product type.
   *
   * Reload the product types and hide the dialog.
   *
   * @param {*} event
   */
  const onSaved = () => {
    //
    //  Status message
    //
    const notifyHandle = app.notify("Saving");

    fetchProductTypes()
      .then((productTypes) => {
        setProductTypes(productTypes);
        setShowAddProductType(false);
        app.endNotify(notifyHandle);
      })
      .catch((error) => {
        app.error({ error });
      });
  };

  /**
   * OnDelete
   *
   * Fired when a user clicks the delete button on a product type.
   *
   * Deletes the requested product type and reloads the updated set
   * of property types.
   *
   * @param {*} event
   */
  const onDelete = (productType) => {
    if (
      !productType ||
      !window.confirm("Are you sure you want to delete this product type?")
    )
      return;

    //
    //  Status message
    //
    const notifyHandle = app.notify(`Deleting ${productType.name}`);

    api
      .delete(`/api/producttypes/producttype/${productType.id}`)
      .then(() => {
        app.endNotify(notifyHandle);
        fetchProductTypes();
      })
      .catch((error) => {
        app.error({ error });
      });
  };

  ///////////////////////////////////////////////////////////////////////
  //
  //  Lifecycle methods
  //
  ///////////////////////////////////////////////////////////////////////

  return (
    <Main
      header={{
        icon: <Collections />,
        title: `Products for ${process.env.REACT_APP_ORG_NAME}`,
        bonus: (
          <Button
            variant="outlined"
            color="primary"
            onClick={() => setShowAddProductType(true)}
          >
            Create a new product
          </Button>
        ),
      }}
      loaded={loaded}
    >
      {loaded && !productTypes ? (
        "No Product Types Available"
      ) : (
        <>
          <ModalDialog show={!!showAddProductType}>
            <IdentityEdit
              onSaved={onSaved}
              onCancelled={() => setShowAddProductType(false)}
            />
          </ModalDialog>
          <ProductTypeList productTypes={productTypes} onDelete={onDelete} />
        </>
      )}
    </Main>
  );
};

/**
 * ProductTypeList
 *
 * Renders a list of product type summaries in card format
 *
 * @param {*} props
 */
const ProductTypeList = ({ productTypes, onDelete }) => {
  return (
    <CardList
      xs={12}
      md={6}
      lg={4}
      items={productTypes}
      spacing={2}
      onRenderCard={({ item: productType }) => (
        <LinkCard2
          link={`/app/catalog/producttype/${productType.id}`}
          header={<ProductTypeSummaryHeader productType={productType} />}
          content={productType.shortDescription}
          actions={
            <>
              <CardAction
                to={`/app/catalog/producttype/${productType.id}/products`}
                label="Book Now"
                highlight
              />
              <CardAction
                to={`/app/catalog/producttype/${productType.id}`}
                label="Edit"
              />
              <CardAction
                to={`/app/catalog/producttype/${productType.id}/products`}
                label="Products"
              />
              <CardAction
                to={`/app/catalog/producttype/${productType.id}/rateclasses`}
                label="Rates"
              />
              <CardAction
                onClick={() => {
                  onDelete(productType);
                }}
                label="Delete"
              />
            </>
          }
        />
      )}
    />
  );
};

export default ProductTypes;
