/********************************************************************
 *
 * ContactEdit.jsx
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2020 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

import React from "react";
import {
  Box,
  Button,
  FormControlLabel,
  Switch,
  TextField,
} from "@material-ui/core";
import { Formik } from "formik";
import * as yup from "yup";
import { HorizontalGroup } from "../../../lib";
import ModalDialog, { DialogContent } from "../../../lib/ModalDialog";
import { useApp, useAPI } from "../../../../providers/AppProvider";

/**
 * ContactEdit
 *
 * Component for adding a new, or editting the descriptive information
 * an exiting customer.
 *
 */
const ContactEdit = ({ customer, show, onSaved, onCancelled }) => {
  const app = useApp();
  const api = useAPI();

  ///////////////////////////////////////////////////////////////////
  //
  //  Utiltiy Methods
  //
  ///////////////////////////////////////////////////////////////////

  ///////////////////////////////////////////////////////////////////
  //
  //  Event Handlers
  //
  ///////////////////////////////////////////////////////////////////

  /**
   * OnSave
   *
   * Fired when the user saves the changes
   *
   * @param {*} values
   */
  const onSave = (values) => {
    //
    //  Save
    //
    const notifyHandle = app.notify("Saving Contact Information");

    api
      .update(`/api/customers/customer/${values.id ? values.id : ""}`, values)
      .then(({ payload: customer }) => {
        onSaved && onSaved({ customer });
        app.endNotify(notifyHandle);
      })
      .catch((error) => {
        app.error({ error });
      });
  };

  /**
   * onCancel
   *
   * Fired when the user aborts changes.
   *
   */
  const onCancel = () => {
    if (
      !window.confirm(
        "You have unsaved changes. Are you sure you want to close this dialog and loose your changes?"
      )
    )
      return;

    onCancelled && onCancelled();
  };

  ///////////////////////////////////////////////////////////////////
  //
  //  Lifecycle methods
  //
  ///////////////////////////////////////////////////////////////////

  return (
    <ModalDialog title="Manage Travel Details" subtitle="TBD" show={show}>
      <DialogContent>
        <FormBody customer={customer} onSave={onSave} onCancel={onCancel} />
      </DialogContent>
    </ModalDialog>
  );
};

/**
 * FormBody
 *
 * @param {*} props
 */
const FormBody = ({ customer, onSave, onCancel }) => (
  <Formik
    initialValues={customer}
    enableReinitialize={true}
    onSubmit={(customer) => {
      onSave(customer);
    }}
    validationSchema={yup.object().shape({})}
  >
    {({
      values,
      touched,
      errors,
      dirty,
      isSubmitting,
      setFieldValue,
      handleChange,
      handleBlur,
      handleSubmit,
    }) => {
      return (
        <form onSubmit={handleSubmit}>
          <Box display="flex" justifyContent="flex-end">
            <HorizontalGroup margin="ml1">
              <Button
                type="submit"
                variant="outlined"
                color="primary"
                disabled={!dirty || isSubmitting}
              >
                Save
              </Button>
              <Button onClick={() => onCancel(dirty)}>Cancel</Button>
            </HorizontalGroup>
          </Box>
          <CustomerForm
            customer={values}
            touched={touched}
            errors={errors}
            setFieldValue={setFieldValue}
            onBlur={handleBlur}
            onChange={handleChange}
          />
        </form>
      );
    }}
  </Formik>
);

const CustomerForm = ({
  customer,
  touched,
  errors,
  setFieldValue,
  onBlur,
  onChange,
}) => {
  return (
    <Box>
      <FormControlLabel
        label="Minor"
        control={
          <Switch
            id="isMinor"
            checked={customer.isMinor}
            onChange={(e) => {
              setFieldValue("isMinor", !customer.isMinor);
            }}
          />
        }
      />
      <TextField
        id="minorName"
        label="Minor Name"
        value={customer.minorName}
        disabled={!customer.isMinor}
        onBlur={onBlur}
        onChange={onChange}
        fullWidth
        error={errors.minorName && touched.minorName}
        helperText={errors.minorName || " "}
      />
      <TextField
        id="fname"
        label="First Name"
        value={customer.fname}
        onBlur={onBlur}
        onChange={onChange}
        fullWidth
        error={errors.fname && touched.fname}
        helperText={errors.fname || " "}
      />
      <TextField
        id="lname"
        label="Last Name"
        value={customer.lname}
        onBlur={onBlur}
        onChange={onChange}
        fullWidth
        error={errors.lname && touched.lname}
        helperText={errors.lname || " "}
      />
      <TextField
        id="email"
        tyle="email"
        label="Email"
        value={customer.email}
        onBlur={onBlur}
        onChange={onChange}
        fullWidth
        error={errors.email && touched.email}
        helperText={errors.email || " "}
      />
      <TextField
        id="phone"
        label="Phone"
        value={customer.phone}
        onBlur={onBlur}
        onChange={onChange}
        fullWidth
        error={errors.phone && touched.phone}
        helperText={errors.phone || " "}
      />
      <TextField
        id="imageURL"
        type="url"
        label="Image URL"
        value={customer.imageURL}
        onBlur={onBlur}
        onChange={onChange}
        fullWidth
        error={errors.imageURL && touched.imageURL}
        helperText={errors.imageURL || " "}
      />
    </Box>
  );
};

export default ContactEdit;
