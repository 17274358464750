/********************************************************************
 *
 * Tours.jsx
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2020 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAnchor,
  faUsers,
  faFileMedicalAlt,
} from "@fortawesome/free-solid-svg-icons";
import "react-dates/initialize";
import { DateRangePicker } from "react-dates";
import "react-dates/lib/css/_datepicker.css";
import { START_DATE, END_DATE } from "react-dates/constants";
import format from "../../../utils/format";
import moment from "moment-timezone";
import { useApp, useAPI } from "../../../providers/AppProvider";

/**
 * Tours
 *
 * Displays the Tour Departure Report
 */
const Tours = () => {
  const [departures, setDepartures] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [focusedInput, setFocusedInput] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const app = useApp();
  const api = useAPI();

  useEffect(() => {
    let today = moment().startOf("day");

    let startDate = moment(JSON.parse(localStorage.getItem("_drStartDate")));
    let endDate = moment(JSON.parse(localStorage.getItem("_drEndDate")));

    if (!startDate.isValid() || !endDate.isValid()) {
      startDate = today;
      endDate = moment(today).endOf("month");
    }

    setStartDate(startDate);
    setEndDate(endDate);
  }, []);

  useEffect(() => {
    if (startDate && endDate) fetchDepartures(startDate, endDate);

    localStorage.setItem("_drStartDate", JSON.stringify(startDate));
    localStorage.setItem("_drEndDate", JSON.stringify(endDate));
  }, [startDate, endDate]);

  /////////////////////////////////////////////////////////////////////
  //
  //  Utility methods
  //
  /////////////////////////////////////////////////////////////////////

  /**
   * GetStartDate
   *
   * Computes an appropriate date to start the calendar.
   *
   * @param {*} date
   */
  const getStartDate = (date) => {
    if (date) {
      return moment(date).startOf("month").startOf("week").startOf("day");
    }

    return null;
  };

  /**
   * FetchDepartures
   *
   * Calls the api to fetch departures
   */
  const fetchDepartures = (startDate, endDate) => {
    if (!moment(startDate).isValid() || !moment(endDate).isValid()) return;

    //
    //  Request searches
    //
    api
      .fetch(
        `/api/products/daterange/${format.toApiDateTime(
          startDate
        )}/${format.toApiDateTime(endDate)}`
      )
      .then(({ payload: departures }) => {
        setDepartures(departures);
        setLoaded(true);
      })
      .catch((error) => {
        app.error({ error });
        setLoaded(true);
      });
  };

  ///////////////////////////////////////////////////////////////////
  //
  //  Event Handlers
  //
  ///////////////////////////////////////////////////////////////////

  const onDatesChange = ({ startDate, endDate }) => {
    setStartDate(startDate);
    setEndDate(endDate);
    setLoaded(false);
    setDepartures(null);
  };

  const onFocusChange = (focusedInput) => {
    setFocusedInput(focusedInput);
  };

  ///////////////////////////////////////////////////////////////////////
  //
  //  Lifecycle methods
  //
  ///////////////////////////////////////////////////////////////////////

  return (
    <main role="main" className="col-md-9 ml-sm-auto col-lg-10 px-4">
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
        <div className="page-title">
          <FontAwesomeIcon className="mr-4" icon={faFileMedicalAlt} />
          Departures
        </div>
        <div className="btn-toolbar mb-2 mb-md-0">
          <div className="btn-group mr-2" />
        </div>
      </div>
      <div className="mb-2">
        <DateRangePicker
          startDate={startDate}
          startDateId={START_DATE}
          endDate={endDate}
          endDateId={END_DATE}
          focusedInput={focusedInput}
          onDatesChange={onDatesChange}
          onFocusChange={onFocusChange}
        />
      </div>
      {loaded ? (
        departures ? (
          <Departures departures={departures} />
        ) : (
          "No Departures Available"
        )
      ) : (
        "Loading..."
      )}
    </main>
  );
};

/**
 * Departures
 *
 * @param {*} param0
 */
const Departures = ({ departures }) => {
  return (
    <div className="table-responsive small">
      <table className="table table-striped table-sm">
        <thead>
          <tr>
            <th>Departure</th>
            <th>Date</th>
            <th>Time</th>
            <th>Duration</th>
            <th>Levels</th>
            <th>Capacity</th>
            <th>Avail</th>
            <th>PAX</th>
            <th>Asset</th>
          </tr>
        </thead>
        <tbody>
          {departures
            ? departures.map(
                (departure) =>
                  !departure.deleted && (
                    <tr
                      key={departure.id}
                      className={departure.deleted ? "text-muted" : ""}
                      style={
                        departure.deleted
                          ? { textDecoration: "line-through" }
                          : null
                      }
                    >
                      <td>
                        <Link to={`/app/catalog/product/${departure.id}`}>
                          {departure.name}
                        </Link>
                        {departure.shared && (
                          <FontAwesomeIcon
                            data-toggle="tooltip"
                            title="Shared Tour"
                            className="ml-1 text-primary"
                            icon={faUsers}
                          />
                        )}
                        {departure.departure && (
                          <FontAwesomeIcon
                            data-toggle="tooltip"
                            title="Confirmed Departure"
                            className="ml-1 text-primary"
                            icon={faAnchor}
                          />
                        )}
                      </td>
                      <td>
                        <Link to={`/app/catalog/product/${departure.id}`}>
                          {format.shortDayDate(departure.startTime.time)}
                        </Link>
                      </td>
                      <td>
                        {`${format.time(departure.startTime.time)} -
                      ${format.time(departure.endTime.time)}`}
                      </td>
                      <td>{format.duration(departure.duration)}</td>
                      <td />
                      <td>{departure.capacity}</td>
                      <td>{departure.available}</td>
                      <td>{departure.departure && departure.departure.pax}</td>
                      <td>
                        {departure.departure && departure.departure.asset.name}
                      </td>
                    </tr>
                  )
              )
            : null}
        </tbody>
      </table>
    </div>
  );
};

export default Tours;
