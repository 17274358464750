/**
 * Footer.jsx
 *
 * Standard footer that contains branding, nav and authentication
 * credentials for the application.
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2019 Canadian Coastal Inc. All rights reserved.
 */

import React from "react";
import { Container, Typography, makeStyles } from "@material-ui/core";

const Footer = () => {
  const classes = styles();
  return (
    <Container component="footer" maxWidth={false} className={classes.footer}>
      <Typography variant="body1" className={classes.copyright} gutterBottom>
        © {new Date().getFullYear()} Canadian Coastal Sailing Inc. All rights
        reserved.
      </Typography>
    </Container>
  );
};

/*
 **  Styles
 */
const styles = makeStyles((theme) => ({
  footer: {
    color: theme.palette.text.primary,
    padding: "4em 0 4em 0",
    margin: 0,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
  },
  copyright: {},
}));

export default Footer;
