/**
 * Header.jsx
 *
 * Standard header that contains branding, nav and authentication
 * credentials for the application.
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2019 Canadian Coastal Inc. All rights reserved.
 */

import React, { useState } from "react";
import {
  AppBar,
  Toolbar,
  Hidden,
  Link,
  Avatar,
  Menu,
  MenuItem,
  IconButton,
  makeStyles,
} from "@material-ui/core";
import { MenuRounded } from "@material-ui/icons";
import MainMenu from "./MainMenu";
import SideMenu from "./SideMenu";
import { useAuth, useUser } from "../../../providers/AuthProvider";

const items = [
  {
    label: "sample",
    hide: "true",
    path: "/path/",
    children: [{ label: "submenu sample", hide: "true", path: "/subpath/" }],
  },
  {
    label: "Calendar",
    path: "/app/calendar/",
  },
  {
    label: "Catalog",
    children: [
      { label: "Products", path: "/app/catalog" },
      { label: "Rate Classes", path: "/app/catalog/rateclasses/" },
      { label: "Goods", path: "/app/goods" },
      { label: "Inventory", path: "/app/goods/inventory/" },
      { label: "Reports", path: "/app/catalog/reports/products/" },
    ],
  },
  {
    label: "CRM",
    children: [
      {
        label: "Customers",
        path: "/app/crm/customers",
      },
      {
        label: "Reviews",
        path: "/app/crm/reviews",
      },
    ],
  },
  {
    label: "Sales",
    children: [
      { label: "Orders", path: "/app/sales/" },
      { label: "Partners", path: "/app/sales/partners/" },
      { label: "Discounts", path: "/app/sales/discounts/" },
      { label: "Campaigns", path: "/app/sales/campaigns/" },
    ],
  },
  {
    label: "Reports",
    path: "/app/reports/",
  },
  {
    label: "Admin",
    children: [
      { label: "Staff", path: "/app/admin/employees/" },
      { label: "Assets", path: "/app/admin/assets/" },
      { label: "Settings", path: "/app/admin/config/" },
      { label: "Tools", path: "/app/admin/tools/" },
    ],
  },
];

const Header = () => {
  const [menu, setMenu] = useState(false);
  const [anchorEl, setAnchourEl] = useState(null);
  const classes = useStyles();
  const auth = useAuth();
  const user = useUser();

  /**
   * onToggleMenu
   *
   * Toggles the visibility of the side menu
   *
   * @param event
   * @param open
   */
  const onToggleMenu = (event, state) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setMenu(state);
  };

  return (
    <AppBar
      className="app-header"
      color="default"
      elevation={4}
      position="sticky"
    >
      <Toolbar className={classes.toolbar}>
        <Link href="/app/">
          <img
            src={process.env["REACT_APP_BRAND_PROFILE_IMG"]}
            alt="Canadian Coastal"
            style={{ padding: "0.5em" }}
          />
        </Link>
        <Hidden smDown>
          <MainMenu items={items} />
        </Hidden>
        <SideMenu
          items={items}
          open={menu}
          onClose={(e) => onToggleMenu(e, false)}
        />
        <div>
          <IconButton
            onClick={(e) => {
              setAnchourEl(e.currentTarget);
            }}
          >
            <Avatar
              className={classes.avatar}
              src={user.imageUrl}
              style={{ width: "60px", height: "60px" }}
            >{`${user.fname[0]}${user.lname[0]}`}</Avatar>
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            keepMounted
            open={!!anchorEl}
            onClose={() => {
              setAnchourEl(null);
            }}
          >
            <MenuItem onClick={auth.logout}>Logout</MenuItem>
          </Menu>
          <Hidden mdUp>
            <IconButton
              onClick={(e) => onToggleMenu(e, true)}
              aria-label="Menu"
            >
              <MenuRounded />
            </IconButton>
          </Hidden>
        </div>
      </Toolbar>
    </AppBar>
  );
};

/*
 **  Styles
 */
const useStyles = makeStyles((theme) => ({
  alt: {
    height: 0,
    opacity: 0,
  },
  brand: {},
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    height: "100%",
    width: "100%",
  },
  link: {
    color: theme.palette.text.primary,
    "&:hover": {
      color: theme.palette.primary.main,
    },
  },
  avatar: { backgroundColor: theme.palette.primary.main },
}));

export default Header;
