/********************************************************************
 *
 * /CRM/Reviews/Identity/Summary.jsx
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2019 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

import React from "react";
import { VerticalGroup, Property } from "../../../lib";

/**
 * ReviewSummary
 *
 * Displays summary information for a Review.
 */
const ReviewSummary = ({ review, digest }) => {
  return (
    !digest && (
      <VerticalGroup>
        <Property title="Lead" data={review.lead} />
        <Property title="Content" data={review.content} />
      </VerticalGroup>
    )
  );
};

export default ReviewSummary;
