/********************************************************************
 *
 * NoteEditor.jsx
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2020 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

import React, { useState } from "react";
import PropTypes from "prop-types";
import { IconButton, TextField } from "@material-ui/core";
import { Edit as EditIcon, Save } from "@material-ui/icons";
import { WidgetFrame, Property } from "../lib";

/**
 * NoteEditor
 *
 * Component that displays and edits notes.
 *
 */
const NoteEditor = ({ message, onSave }) => {
  const [edit, setEdit] = useState(false);

  /**
   * Render
   *
   * Renders the message in either edit or view mode
   *
   */
  return edit ? (
    <Edit
      message={message}
      onSave={(e) => {
        onSave && onSave(e);
        setEdit(false);
      }}
    />
  ) : (
    <View message={message} onEdit={(e) => setEdit(e.edit)} />
  );
};

/**
 * View
 *
 * Renders the note in View Mode.
 *
 * @param {*} props
 */
const View = ({ message, onEdit }) => {
  return (
    <WidgetFrame
      title="Order Notes"
      action={
        <IconButton onClick={() => onEdit({ edit: true })}>
          <EditIcon />
        </IconButton>
      }
    >
      <Property>{message}</Property>
    </WidgetFrame>
  );
};

/**
 * Edit
 *
 * Renders the note in Edit Mode.
 *
 * @param {*} props
 */
const Edit = ({ message, onSave }) => {
  const [data, setData] = useState(message);

  return (
    <WidgetFrame
      title="Order Notes"
      action={
        <IconButton
          onClick={() => {
            onSave && onSave({ message: data });
            setData(undefined);
          }}
        >
          <Save />
        </IconButton>
      }
    >
      <TextField
        value={data}
        onChange={(e) => setData(e.target.value)}
        variant="outlined"
        fullWidth
        multiline
        rows={4}
      />
    </WidgetFrame>
  );
};

NoteEditor.propTypes = {
  message: PropTypes.string,
  onSave: PropTypes.func.isRequired,
};

export default NoteEditor;
