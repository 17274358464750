/********************************************************************
 *
 * Identity.jsx
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2020 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

import React, { useState } from "react";
import { IconButton } from "@material-ui/core";
import { Timer, Edit } from "@material-ui/icons";
import WidgetFrame from "../../../lib/WidgetFrame";
import IdentityEdit from "./Edit";
import { VerticalGroup, TipChip, Property } from "../../../lib/";

/**
 * Identity
 *
 * Component that displays, adds and edits the descriptive information
 * for a product type.
 *
 * Note that the presence of the onUpdate callback determines if the
 * component is readonly or can be modified.
 *
 */
const Identity = ({ productType, onUpdate }) => {
  const [edit, setEdit] = useState(false);

  ///////////////////////////////////////////////////////////////////
  //
  //  Event Handlers
  //
  ///////////////////////////////////////////////////////////////////

  /**
   * OnSaved
   *
   * Fired once the edit state has saved the descriptive information.
   *
   * Returns to display mode.
   *
   * @param {*} event
   */
  const onSaved = (event) => {
    if (onUpdate) {
      onUpdate(event);
      setEdit(false);
    }
  };

  ///////////////////////////////////////////////////////////////////
  //
  //  Lifecycle methods
  //
  ///////////////////////////////////////////////////////////////////

  return (
    <WidgetFrame
      avatar={
        <TipChip
          tip="Timezone"
          size="small"
          color="primary"
          variant="default"
          avatar={
            <Timer
              fontSize="small"
              style={{ backgroundColor: "transparent" }}
            />
          }
          label={productType.timezone}
        />
      }
      action={
        !!onUpdate && (
          <IconButton onClick={() => setEdit(true)}>
            <Edit />
          </IconButton>
        )
      }
    >
      <VerticalGroup>
        <Property title="Tag Line" data={productType.tagLine} />
        <Property title="Target" data={productType.target} />
        <Property
          title="Short Description"
          data={productType.shortDescription}
        />
        <Property title="Full Description" data={productType.description} />
      </VerticalGroup>
      {onUpdate && edit && (
        <IdentityEdit
          productType={productType}
          show={!!edit}
          onSaved={onSaved}
          onCancelled={() => setEdit(false)}
        />
      )}
    </WidgetFrame>
  );
};

export default Identity;
