/********************************************************************
 *
 * Identity.jsx
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2020 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

import React from "react";
import Identity from "./Identity";
import { Box, Avatar, Chip, Typography, makeStyles } from "@material-ui/core";
import {
  Timeline,
  TimelineItem,
  TimelineSeparator,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
} from "@material-ui/lab";
import { CardContentHeader } from "../../../lib";

const ProductTypeSummaryHeader = ({ productType }) => {
  const classes = useStyles();

  return (
    <CardContentHeader
      avatar={
        <Avatar
          variant="circle"
          className={classes.avatar}
          src={productType.imageURL}
          alt={productType.name}
        >
          ?
        </Avatar>
      }
      callout={
        !!productType.deleted && {
          label: "Deleted",
          color: "error",
        }
      }
      content={
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          flexWrap="wrap"
          style={{ height: "100%", width: "100%" }}
        >
          <Box flexGrow={1} className="mb-2">
            <Typography variant="h4">{productType.name}</Typography>
            {productType.featureTypes.length === 1 ? (
              <FeatureType featureType={productType.featureTypes[0]} />
            ) : (
              <FeatureTypeTimeline featureTypes={productType.featureTypes} />
            )}
          </Box>
        </Box>
      }
    />
  );
};

const ProductTypeSummary = ({ productType }) => {
  return <Identity productType={productType} />;
};

const FeatureType = ({ featureType }) => (
  <Typography variant="h5">{featureType.name}</Typography>
);

const FeatureTypeTimeline = ({ featureTypes }) => {
  const classes = useStyles();

  return (
    <Timeline classes={{ root: classes.timelineRoot }}>
      {featureTypes &&
        featureTypes.map((featureType, index) => (
          <TimelineItem
            key={featureType.id}
            classes={{
              root: classes.timelineItemRoot,
              missingOppositeContent: classes.missingOppositeTimelineContent,
            }}
          >
            <TimelineSeparator
              classes={{ root: classes.timelineSeperatorRoot }}
            >
              <TimelineDot
                classes={{ root: classes.timelineDotRoot }}
                variant="outlined"
                color="primary"
              />
              {index < featureTypes.length - 1 && <TimelineConnector />}
            </TimelineSeparator>
            <TimelineContent classes={{ root: classes.timelineContentRoot }}>
              <FeatureType featureType={featureType} />
            </TimelineContent>
          </TimelineItem>
        ))}
    </Timeline>
  );
};
/*
 **  Styles
 */
const useStyles = makeStyles((theme) => ({
  avatar: {
    width: "100%",
    height: "100%",
    maxWidth: "75px",
    minWidth: "75px",
    maxHeight: "75px",
    minHeight: "75px",
  },
  featureTypeName: {
    fontSize: "0.9em",
    fontWeight: 400,
    color: theme.palette.text.secondary,
  },
  timelineRoot: { marginBottom: 0 },
  timelineSeperatorRoot: { paddingRight: "0px" },
  timelineItemRoot: { minHeight: "20px" },
  timelineContentRoot: { paddingTop: "0px", paddingBottom: "4px" },
  missingOppositeTimelineContent: { "&:before": { display: "none" } },
  timelineDotRoot: {
    marginBottom: "4px",
    marginTop: "4px",
    padding: "2px",
    borderWidth: "1px",
  },
}));

export { ProductTypeSummaryHeader, ProductTypeSummary };
