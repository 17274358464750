/********************************************************************
 *
 * /src/components/sales/accounting/index.jsx
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2024 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Paper } from "@material-ui/core";
import { Box, Button } from "@material-ui/core";
import format from "../../../../utils/format";

import { useApp, useAPI } from "../../../../providers/AppProvider";

/**
 * Accounting
 *
 * Component that displays the accounting transactions for an order.
 *
 */
const Accounting = ({ order }) => {
  const [transactions, setTransactions] = useState(null);
  const app = useApp();
  const api = useAPI();

  useEffect(() => {
    fetchTransactions(order.id);
  }, [order]);

  ///////////////////////////////////////////////////////////////////
  //
  //  Utility Methods
  //
  ///////////////////////////////////////////////////////////////////

  /**
   * fetchTransactions
   *
   * Calls the API to fetch transactions for the current order
   *
   * @param {*} orderId
   */
  const fetchTransactions = (orderId) => {
    //
    //  Status message
    //
    const notifyHandle = app.notify("Loading Transactions");

    api
      .fetch(`/api/orders/order/${orderId}/transactions`)
      .then(({ payload: transactions }) => {
        setTransactions(transactions);
        app.endNotify(notifyHandle);
      })
      .catch((error) => {
        app.error({ error });
        setTransactions(null);
      });
  };

  if (!transactions) return null;

  return (
    <Box>
      {!transactions && "No transactions available"}
      {transactions &&
        transactions.map((transaction) => (
          <div key={transaction.id} className="mb-4">
            <div className="h6 text-uppercase flexRowStretch">
              <div>
                <span className="text-uppercase mr-1">
                  {transaction.type.name}
                </span>
                ({transaction.id})
              </div>
              <div>POSTED {format.shortDateTime(transaction.timestamp)}</div>
            </div>
            <div
              style={{
                borderTop: "1px solid #999999",
                borderBottom: "1px solid #999999",
              }}
            >
              <Entries entries={transaction.entries} />
            </div>
            <div className="flexRowStretch small">
              <div>{transaction.description}</div>
              <div>
                {`Posted By ${transaction.user.fname} ${transaction.user.lname}`}
              </div>
            </div>
          </div>
        ))}
      <Button variant="outlined" onClick={() => {}}>
        Add Entry
      </Button>
    </Box>
  );
};

const Entries = ({ entries }) => {
  return (
    <table className="table table-striped table-sm small">
      <colgroup>
        <col style={{ width: "150px" }} />
        <col />
        <col style={{ width: "150px" }} />
        <col style={{ width: "150px" }} />
      </colgroup>
      <thead>
        <tr>
          <th>ID</th>
          <th>Account</th>
          <th className="text-right">DR</th>
          <th className="text-right">CR</th>
        </tr>
      </thead>
      <tbody>
        {entries.debits &&
          entries.debits.map((entry) => (
            <tr>
              <td>{entry.id}</td>
              <td>{entry.account.name}</td>
              <td className="text-right">{format.currency(entry.amount)}</td>
              <td></td>
            </tr>
          ))}
        {entries.credits &&
          entries.credits.map((entry) => (
            <tr>
              <td>{entry.id}</td>
              <td>{entry.account.name}</td>
              <td></td>
              <td className="text-right">{format.currency(entry.amount)}</td>
            </tr>
          ))}
      </tbody>
    </table>
  );
};

/*
 **  PropTypes
 */
Accounting.propTypes = {
  order: PropTypes.object.isRequired,
};

export default Accounting;
