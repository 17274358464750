import React, { Component } from "react";
import SectionRouterHelper from "../SectionRouterHelper";
import _SectionSubRouter from "./_SubSectionRouter";

import Dashboard from ".";

export let routes = {
  root: "/app/",
  tree: {
    Dashboard: {
      label: "Dashboard",
      path: "/app/",
      component: Dashboard,
      children: [],
    },
  },
};

export class _SectionRouter extends Component {
  render() {
    //
    //  Create the section router.
    //
    return (
      <SectionRouterHelper
        routes={routes}
        subSectionRouter={_SectionSubRouter}
      />
    );
  }
}
