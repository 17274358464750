/********************************************************************
 *
 * Shifts.jsx
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2019 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

import React, { useState, useEffect } from "react";
import {
  Card,
  CardHeader,
  CardContent,
  CircularProgress,
  Accordion,
  AccordionSummary,
  Typography,
  makeStyles,
} from "@material-ui/core";
import { DateRangePicker } from "react-dates";
import { START_DATE, END_DATE } from "react-dates/constants";
import moment from "moment-timezone";
import format from "../../../utils/format";
import ShiftStatus from "../../ShiftStatus";
import { useApp, useAPI } from "../../../providers/AppProvider";

/**
 * Shifts
 *
 * Displays an employee's shifts.
 *
 * @param {*} props
 */
const Shifts = ({ employee }) => {
  const [startDate, setStartDate] = useState(moment().startOf("week"));
  const [endDate, setEndDate] = useState(moment().endOf("week"));
  const [shifts, setShifts] = useState(null);
  const [focusedInput, setFocusedInput] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const app = useApp();
  const api = useAPI();
  const classes = styles();

  useEffect(() => {
    fetchShifts(employee, startDate, endDate);
  }, [employee, startDate, endDate]);

  /////////////////////////////////////////////////////////////////////
  //
  //  Utility methods
  //
  /////////////////////////////////////////////////////////////////////

  /**
   * FetchShifts
   *
   * Request employee shifts for a timeframe
   *
   * @param {*} employee
   * @param {*} startDate
   * @param {*} endDate
   */
  const fetchShifts = (employee, startDate, endDate) => {
    //
    //  Status message
    //
    const notifyHandle = app.notify("Loading Shifts");

    api
      .fetch(
        `/api/employees/employee/${employee.id}/shifts/${format.toApiDateTime(
          startDate
        )}/${format.toApiDateTime(endDate)}`
      )
      .then(({ payload: shifts }) => {
        setShifts(shifts);
        setLoaded(true);
        app.endNotify(notifyHandle);
      })
      .catch((error) => {
        app.error({ error });
        setLoaded(true);
      });
  };

  ///////////////////////////////////////////////////////////////////
  //
  //  Event Handlers
  //
  ///////////////////////////////////////////////////////////////////

  const onDatesChange = ({ startDate, endDate }) => {
    setStartDate(startDate);
    setEndDate(endDate);
  };

  const onFocusChange = (focusedInput) => {
    setFocusedInput(focusedInput);
  };

  ///////////////////////////////////////////////////////////////////////
  //
  //  Lifecycle methods
  //
  ///////////////////////////////////////////////////////////////////////

  return (
    <Card>
      <CardHeader title="Shifts" />

      <CardContent>
        <div className={classes.picker}>
          <DateRangePicker
            startDate={startDate}
            startDateId={START_DATE}
            endDate={endDate}
            endDateId={END_DATE}
            focusedInput={focusedInput}
            onDatesChange={onDatesChange}
            onFocusChange={onFocusChange}
            isOutsideRange={() => false}
            withPortal={true}
          />
        </div>
        {!loaded ? (
          <div className="d-flex justify-content-center mt-2">
            <CircularProgress />
          </div>
        ) : shifts ? (
          shifts.map((shift) => (
            <Accordion key={shift.id}>
              <AccordionSummary>
                <div className={classes.column}>
                  <Typography className={classes.heading}>
                    {format.dayDate(shift.tour.acquire)}
                  </Typography>
                  <Typography className={classes.secondaryHeading}>
                    {`Tour ${shift.tour.id}
                    ${shift.tour.asset ? shift.tour.asset.name : ""}`}
                  </Typography>
                </div>
                <div className={classes.column}>
                  <Typography className={classes.secondaryHeading}>
                    {`${format.time(shift.tour.acquire)} -
                      ${format.time(shift.tour.release)}`}
                  </Typography>
                </div>
                <div className={classes.column}>
                  <ShiftStatus status={shift.status} />
                </div>
              </AccordionSummary>
            </Accordion>
          ))
        ) : (
          <Typography>No Shifts</Typography>
        )}
      </CardContent>
    </Card>
  );
};

/*
 **  Styles
 */
const styles = makeStyles((theme) => ({
  picker: { marginBottom: theme.spacing(4) },
  column: {
    flexBasis: "33.33%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
}));

export default Shifts;
