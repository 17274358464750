/********************************************************************
 *
 * /Sales/Campaigns/Identity/Identity.jsx
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2020 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

import React, { useState } from "react";
import { IconButton } from "@material-ui/core";
import { Timer, Edit } from "@material-ui/icons";
import IdentityEdit from "./Edit";
import {
  WidgetFrame,
  VerticalGroup,
  HorizontalGroup,
  TipChip,
  Property,
} from "../../../lib";
import format from "../../../../utils/format";

/**
 * Identity
 *
 * Component that displays, adds and edits the descriptive information
 * for a campaign.
 *
 * Note that the presence of the onUpdate callback determines if the
 * component is readonly or can be modified.
 *
 */
const Identity = ({ campaign, onUpdate }) => {
  const [edit, setEdit] = useState(false);

  ///////////////////////////////////////////////////////////////////
  //
  //  Event Handlers
  //
  ///////////////////////////////////////////////////////////////////

  /**
   * OnSaved
   *
   * Fired once the edit state has saved the descriptive information.
   *
   * Returns to display mode.
   *
   * @param {*} campaign
   */
  const onSaved = (campaign) => {
    if (onUpdate) {
      onUpdate(campaign);
      setEdit(false);
    }
  };

  ///////////////////////////////////////////////////////////////////
  //
  //  Lifecycle methods
  //
  ///////////////////////////////////////////////////////////////////

  return (
    <WidgetFrame
      avatar={
        <HorizontalGroup margin="mr1">
          <TipChip
            tip="Start"
            size="small"
            color="primary"
            variant="default"
            avatar={
              <Timer
                fontSize="small"
                style={{ backgroundColor: "transparent" }}
              />
            }
            label={format.dayDate(campaign.start)}
          />
          <TipChip
            tip="End"
            size="small"
            color="primary"
            variant="default"
            avatar={
              <Timer
                fontSize="small"
                style={{ backgroundColor: "transparent" }}
              />
            }
            label={format.dayDate(campaign.end)}
          />
        </HorizontalGroup>
      }
      action={
        !!onUpdate && (
          <IconButton onClick={() => setEdit(true)}>
            <Edit />
          </IconButton>
        )
      }
    >
      <>
        <VerticalGroup>
          <Property title="Description" data={campaign.description} />
        </VerticalGroup>
        {!campaign.deleted && onUpdate && (
          <IdentityEdit
            campaign={campaign}
            show={!!edit}
            onSaved={onSaved}
            onCancelled={() => setEdit(false)}
          />
        )}
      </>
    </WidgetFrame>
  );
};

export default Identity;
