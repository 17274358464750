/********************************************************************
 *
 * Register.jsx
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2020 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

import React from "react";
import PropTypes from "prop-types";
import { Box, Typography } from "@material-ui/core";
import format from "../../../../utils/format";
import { WidgetFrame } from "../../../lib";
import Book from "./Book";
import Receipts from "./Receipts";

/**
 * Register
 *
 * Summary of charges, discounts, taxes and receipts
 */
const Register = ({ order, onUpdated }) => {
  ///////////////////////////////////////////////////////////////////////
  //
  //  Lifecycle methods
  //
  ///////////////////////////////////////////////////////////////////////

  /**
   * Render
   *
   */
  return (
    <WidgetFrame
      title={
        order.confirmation
          ? `CONFIRMED on ${format.dateTime(order.committed)} (#${
              order.confirmation
            })`
          : "PENDING"
      }
      action={
        !order.confirmation && (
          <a
            href={`https://canadiancoastal.com/order/review/${order.code}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            View Online
          </a>
        )
      }
    >
      <Box>
        <Typography variant="h3" paragraph>
          Order Summary
        </Typography>
        <Totals order={order} />
        {!order.confirmation ? (
          <>
            <hr />
            <Book order={order} onUpdated={onUpdated} />
          </>
        ) : (
          !!parseFloat(order.totals.due) && (
            <>
              <hr />
              <Typography variant="h5" paragraph>
                Payments
              </Typography>
              <Receipts order={order} onUpdated={onUpdated} />
            </>
          )
        )}
      </Box>
    </WidgetFrame>
  );
};

const Totals = ({ order }) => {
  return (
    <div className="card-text small">
      {order.charges.items &&
        order.charges.items.map((charge, index) => (
          <React.Fragment key={index}>
            <div className="row">
              <div className="col-8 pr-0">{charge.description}:</div>
              <div className="col-4 pl-0">{format.currency(charge.total)}</div>
            </div>
            {charge.discounts && charge.discounts.total > 0 && (
              <div className="row">
                <div className="col-8 pr-0">Discount:</div>
                <div className="col-4 pl-0">
                  -{format.currency(charge.discounts.total)}
                </div>
              </div>
            )}
          </React.Fragment>
        ))}
      {order.charges.items && (
        <div className="row font-weight-bold pt-2 pb-2">
          <div className="col-8 pr-0" style={{ paddingTop: "0.75em" }}>
            CHARGES:
          </div>
          <div
            className="col-4 pl-0"
            style={{ borderTop: "1px solid #cccccc", paddingTop: "0.75em" }}
          >
            {format.currency(order.charges.amount)}
          </div>
        </div>
      )}
      {order.taxes.items &&
        order.taxes.items.map((tax, index) => (
          <div key={index} className="row">
            <div className="col-8 pr-0">{tax.taxType.name}:</div>
            <div className="col-4 pl-0">{format.currency(tax.amount)}</div>
          </div>
        ))}
      {order.taxes && (
        <div className="row font-weight-bold pt-2 pb-2">
          <div className="col-8 pr-0" style={{ paddingTop: "0.75em" }}>
            TAXES:
          </div>
          <div
            className="col-4 pl-0"
            style={{ borderTop: "1px solid #cccccc", paddingTop: "0.75em" }}
          >
            {format.currency(order.taxes.total)}
          </div>
        </div>
      )}

      <div className="row font-weight-bold mt-1 mb-1">
        <div className="col-8 pr-0" style={{ paddingTop: "0.75em" }}>
          TOTAL:
        </div>
        <div
          className="col-4 pl-0"
          style={{ borderTop: "1px double #cccccc", paddingTop: "0.75em" }}
        >
          {format.currency(order.totals.amount)}
        </div>
      </div>

      {order.receipts && order.receipts.items && (
        <>
          {order.receipts.items.map((receipt, index) => (
            <div key={index} className="row">
              <div className="col-8 pr-0">{receipt.receiptType.name}:</div>
              <div className="col-4 pl-0">
                {format.currency(receipt.amount)}
              </div>
            </div>
          ))}
          <div className="row font-weight-bold mt-1 mb-1">
            <div className="col-8 pr-0" style={{ paddingTop: "0.75em" }}>
              TOTAL PAYMENTS:
            </div>
            <div
              className="col-4 pl-0"
              style={{
                borderTop: "1px double #cccccc",
                paddingTop: "0.75em",
              }}
            >
              {format.currency(order.receipts.total)}
            </div>
          </div>
        </>
      )}
      <div className="row font-weight-bold mt-4">
        <div className="col-8 pr-0">AMOUNT DUE:</div>
        <div className="col-4 pl-0">{format.currency(order.totals.due)}</div>
      </div>
    </div>
  );
};

/*
 **  PropTypes
 */
Register.propTypes = {
  order: PropTypes.object.isRequired,
  onUpdated: PropTypes.func.isRequired,
};

export default Register;
