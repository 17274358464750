/**
 * Header.jsx
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2019 Canadian Coastal Inc. All rights reserved.
 */

import React from "react";
import { AppBar, Toolbar, makeStyles } from "@material-ui/core";

/**
 * Header
 *
 * Standard header for publicly visible pages
 *
 * @param {*} param0
 */
const Header = ({ history }) => {
  const classes = styles();

  return (
    <AppBar className={classes.header} position="sticky">
      <Toolbar className={classes.toolbar}>
        <img
          src={process.env["REACT_APP_BRAND_PROFILE_IMG"]}
          alt="Canadian Coastal"
          style={{ padding: "0.5em" }}
        />
      </Toolbar>
    </AppBar>
  );
};

/*
 **  Styles
 */
const styles = makeStyles((theme) => ({
  header: {
    backgroundColor: "rgba(255,255,255,0.95)",
    display: "flex",
    opacity: 1,
    color: "rgb(103,103,103)",
    margin: "0 auto",
    height: "100px",
    width: "100%",
    transition: "height 0.5s, opacity 0.5s linear",
    top: "0px",
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    height: "100%",
    width: "100%",
  },
}));

export default Header;
