/********************************************************************
 *
 * TourType.jsx
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2020 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

import React, { useState, useEffect } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { Extension } from "@material-ui/icons";
import { Main } from "../../lib";
import {
  SectionList,
  SectionItemGroup,
  SectionItem,
} from "../../lib/SideBar/Menu";
import Error404 from "../../Error/404";
import TourScheduler from "./Schedulers/TourScheduler";
import Supplier from "./Notifications/Notification";
import { useApp, useAPI } from "../../../providers/AppProvider";

/**
 * TourType
 *
 * Container component for managing a tour type.
 *
 * The tour type id is passed in the URI and the object initialized
 * though a call to the API.
 *
 */
const TourType = ({ match, history }) => {
  const [tourType, setTourType] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const { goodTypeId } = match.params;
  const app = useApp();
  const api = useAPI();

  /**
   * ComponentDidMount
   *
   * Fired when the component initially mounts.
   *
   */
  useEffect(() => {
    if (!goodTypeId || goodTypeId === 0) {
      //
      //  New Tour Type Mode - initialize empty object
      //
      return;
    }

    //
    //  If tour type id is a paramter, we are in edit mode, otherwise
    //  we are creating a new tour type
    //
    fetchTourType(goodTypeId);
  }, [goodTypeId]);

  ///////////////////////////////////////////////////////////////////
  //
  //  Utility methods
  //
  ///////////////////////////////////////////////////////////////////

  /**
   * FetchTourType
   *
   * Calls the API to fetch the requested tour type.
   *
   * If the tour id is null, create a shell tour type.
   *
   * @param {*} tourTypeId
   */
  const fetchTourType = (tourTypeId) => {
    //
    //  Status message
    //
    const notifyHandle = app.notify("Loading");

    api
      .fetch(`/api/tourtypes/tourtype/${parseInt(tourTypeId)}/?verbose`)
      .then(({ payload: tourType }) => {
        setTourType(tourType);
        setLoaded(true);
        app.endNotify(notifyHandle);
      })
      .catch((error) => {
        app.error({ error });
        setTourType(null);
        setLoaded(true);
      });
  };

  ///////////////////////////////////////////////////////////////////
  //
  //  Event Handlers
  //
  ///////////////////////////////////////////////////////////////////

  /**
   * OnUpdate
   *
   * Fired when a child updates the data object.
   *
   * @param {*} event
   */
  const onUpdate = () => {
    //
    //  If tour type id is a paramter, we are in edit mode, otherwise
    //  we are creating a new tour type
    //
    fetchTourType(goodTypeId);
  };

  /**
   * onDelete
   *
   * Handles a request in display mode to delete a feature type.
   *
   * @param {*} event
   */
  const onDelete = (event) => {
    if (!window.confirm("Are you sure you want to delete this tour type?"))
      return;

    //
    //  Status message
    //
    const notifyHandle = app.notify("Deleting");

    api
      .delete(`/api/goodtypes/tour/${tourType.id}`)
      .then(() => {
        app.endNotify(notifyHandle);
        history.push(`/app/goods/`);
      })
      .catch((error) => {
        app.error({ error });
      });
  };

  ///////////////////////////////////////////////////////////////////////
  //
  //  Lifecycle methods
  //
  ///////////////////////////////////////////////////////////////////////

  /**
   * Render
   *
   * Renders the container for the tour type.
   *
   * Most functionality is delegated to subcomponents
   * servered by routing to different URIs.
   */

  if (!tourType) return null;

  return (
    <Main
      header={{
        icon: tourType && tourType.supplier && tourType.supplier.logoURL && (
          <img alt={tourType.supplier.name} src={tourType.supplier.logoURL} />
        ),
        title: tourType && tourType.name,
      }}
      menu={{
        icon: <Extension />,
        title: "Manage Goods",
        subtitle: "TBD",
        nav: <Menu tourType={tourType} />,
      }}
      loaded={loaded}
    >
      <Router tourType={tourType} onUpdate={onUpdate} />
    </Main>
  );
};

/**
 * Menu
 *
 * Renders the navigation controls for the functional areas of the
 * tour type management page.
 *
 * @param {*} props
 */
const Menu = ({ tourType }) => {
  const { id = 0 } = tourType || {};

  return (
    <SectionList>
      <SectionItemGroup icon={<Extension />} name="Setup" open={true}>
        <SectionItem to={`/app/goods/tourtype/${id}/schedule`}>
          Schedule
        </SectionItem>
        <SectionItem to={`/app/goods/tourtype/${id}/supplier`}>
          Notifications
        </SectionItem>
      </SectionItemGroup>
    </SectionList>
  );
};

/**
 * Router
 *
 * Renders the routing logic for the page
 *
 * @param {*} props
 */
const Router = ({ tourType, onUpdate }) => {
  return (
    <Switch>
      {/* Schedule */}
      <Route
        path="/app/goods/tourtype/:goodTypeId/schedule"
        render={(props) => <TourScheduler {...props} tourType={tourType} />}
      />
      {/* Supplier */}
      <Route
        path="/app/goods/tourtype/:goodTypeId/supplier"
        render={(props) => (
          <Supplier {...props} goodType={tourType} onUpdate={onUpdate} />
        )}
      />
      {/* Redirect */}
      <Route
        path="/app/goods/tourType/:goodTypeId"
        render={() => (
          <Redirect to={`/app/goods/tourtype/${tourType.id}/schedule`} />
        )}
      />
      <Route path="/app/goods/" component={Error404} />
    </Switch>
  );
};

export default TourType;
