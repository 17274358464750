/********************************************************************
 *
 * Inventory.jsx
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2020 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

import React, { Component } from "react";
import { Main } from "../../lib";

/**
 * Inventory
 *
 */
export default class Inventory extends Component {
  /////////////////////////////////////////////////////////////////////
  //
  //  Utility methods
  //
  /////////////////////////////////////////////////////////////////////

  ///////////////////////////////////////////////////////////////////
  //
  //  Event Handlers
  //
  ///////////////////////////////////////////////////////////////////

  ///////////////////////////////////////////////////////////////////////
  //
  //  Lifecycle methods
  //
  ///////////////////////////////////////////////////////////////////////

  render() {
    return (
      <Main className="container-fluid" loaded={true}>
        TBD
      </Main>
    );
  }
}
