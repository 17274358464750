/********************************************************************
 *
 * LiveSearch.jsx
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2019 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Box, TextField, InputAdornment, IconButton } from "@material-ui/core";
import { Search, ClearOutlined } from "@material-ui/icons";

const TIMEOUT = 1000;

/**
 * LiveSearch
 *
 * Search component that triggers an event wuth a search query when
 * user input has stopped for 500ms.
 *
 * Component requires an onSearch property function with a parameter
 * that will contain the query string.
 */
const LiveSearch = ({ match, placeholder, focus = false, onSearch }) => {
  const [query, setQuery] = useState("");
  const [timer, setTimer] = useState(null);

  useEffect(() => {
    setQuery(match ? match : "");
  }, [match]);

  /**
   * OnInputChanged
   *
   * Fired when the input value changes.
   *
   * Sets a timer that, upon expiry, fires the parent's onSearch
   * callback.
   *
   * @param {*} event
   */
  const onInputChange = ({ target: input }) => {
    setQuery(input.value);

    //
    // Clears the previously set timer.
    //
    clearTimeout(timer);

    //
    //  Reset the timer, to make the http call after 500MS
    //
    setTimer(
      setTimeout(() => {
        onSearch({ match: input.value });
      }, TIMEOUT)
    );
  };

  ///////////////////////////////////////////////////////////////////
  //
  //  Lifecycle methods
  //
  ///////////////////////////////////////////////////////////////////

  /**
   * Render
   *
   */
  return (
    <Box display="flex">
      <TextField
        id="search-input"
        type="text"
        value={query}
        autoComplete="off"
        autoFocus={focus}
        placeholder={placeholder ? placeholder : "Search..."}
        onChange={onInputChange}
        variant="outlined"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Search />
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                onClick={() => {
                  onInputChange({ target: { value: "" } });
                }}
              >
                <ClearOutlined style={{ color: "#999999" }} />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    </Box>
  );
};

/*
 **  PropTypes
 */
LiveSearch.propTypes = {
  match: PropTypes.string,
  placeholder: PropTypes.string,
  focus: PropTypes.bool,
  onSearch: PropTypes.func.isRequired,
};

export default LiveSearch;
