/********************************************************************
 *
 * /components/crm/customers/identity/identity.jsx
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2020 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

import React, { useState } from "react";
import { Grid, IconButton } from "@material-ui/core";
import TravelDetails from "./TravelDetails";
import Contact from "./Contact";

/**
 * Identity
 *
 * Component that displays, adds and edits the descriptive information
 * for a customer.
 *
 */
const Identity = ({ customer, onUpdate }) => {
  ///////////////////////////////////////////////////////////////////
  //
  //  Event Handlers
  //
  ///////////////////////////////////////////////////////////////////

  ///////////////////////////////////////////////////////////////////
  //
  //  Lifecycle methods
  //
  ///////////////////////////////////////////////////////////////////

  if (!customer) return null;

  return (
    <>
      <Grid container spacing={4}>
        <Grid item xs={12} md={4}>
          <Contact customer={customer} onUpdate={onUpdate} />
        </Grid>
        <Grid item xs={12} md={8}>
          <TravelDetails customer={customer} onUpdate={onUpdate} />
        </Grid>
      </Grid>
    </>
  );
};

export default Identity;
