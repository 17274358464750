/********************************************************************
 *
 * Identity.jsx
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2020 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

import React, { useState } from "react";
import { Typography, IconButton } from "@material-ui/core";
import { Edit } from "@material-ui/icons";
import { AddShoppingCart, AccessTime } from "@material-ui/icons";
import { WidgetFrame, HorizontalGroup, TipChip } from "../../../../lib/";
import format from "../../../../../utils/format";
import IdentityEdit from "./Edit";

/**
 * Identity
 *
 * Displays summary information for a Product
 */
const Identity = ({ product, digest, onUpdate }) => {
  const [edit, setEdit] = useState(false);

  ///////////////////////////////////////////////////////////////////
  //
  //  Event Handlers
  //
  ///////////////////////////////////////////////////////////////////

  /**
   * OnSaved
   *
   * Fired once the edit state has saved the descriptive information.
   *
   * Returns to display mode.
   *
   * @param {*} event
   */
  const onSaved = (event) => {
    onUpdate && onUpdate(event);
    setEdit(false);
  };

  ///////////////////////////////////////////////////////////////////
  //
  //  Lifecycle methods
  //
  ///////////////////////////////////////////////////////////////////

  return (
    <WidgetFrame
      title={`${format.dayDate(product.start, product.timezone)} ${format.time(
        product.start,
        product.timezone
      )} - ${
        format.date(product.start, product.timezone) !==
        format.date(product.end, product.timezone)
          ? format.dayDate(product.end, product.timezone)
          : ""
      } ${format.time(product.end, product.timezone)}`}
      action={
        !!onUpdate && (
          <IconButton onClick={() => setEdit(true)}>
            <Edit />
          </IconButton>
        )
      }
    >
      <>
        <HorizontalGroup margin="mr1">
          <TipChip
            label={product.available}
            icon={<AddShoppingCart />}
            tip="Available"
          />
          <TipChip
            label={product.timezone}
            icon={<AccessTime />}
            tip="Timezone"
          />
        </HorizontalGroup>
        {!digest && (
          <div className="pt-4">
            <Typography paragraph>{product.target}</Typography>
            <Typography variant="body2" paragraph>
              {product.shortDescription}
            </Typography>
          </div>
        )}
        {onUpdate && (
          <IdentityEdit
            product={product}
            show={!!edit}
            onSaved={onSaved}
            onCancelled={() => setEdit(false)}
          />
        )}
      </>
    </WidgetFrame>
  );
};

export default Identity;
