/********************************************************************
 *
 * /components.sales/leads/leadManager.jsx
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2020 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

import React, { useState, useEffect } from "react";
import {
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Button,
} from "@material-ui/core";
import { Formik } from "formik";
import * as yup from "yup";
import { WidgetFrame } from "../../lib";
import { useApp, useAPI } from "../../../providers/AppProvider";

const statusTypes = [
  { id: -1, name: "Closed" },
  { id: 0, name: "Open" },
  { id: 1, name: "Qualified" },
  { id: 9, name: "Sale" },
];

/**
 * LeadManager
 *
 * Manages state information for a lead
 *
 * @param {*} param0
 */
const LeadManager = ({ lead, onSave }) => {
  const app = useApp();
  const api = useAPI();

  ///////////////////////////////////////////////////////////////////
  //
  //  Utility Methods
  //
  ///////////////////////////////////////////////////////////////////

  ///////////////////////////////////////////////////////////////////
  //
  //  Event Handlers
  //
  ///////////////////////////////////////////////////////////////////

  ///////////////////////////////////////////////////////////////////
  //
  //  Lifecycle methods
  //
  ///////////////////////////////////////////////////////////////////

  if (!lead) return null;

  return (
    <WidgetFrame>
      <FormBody lead={lead} onSave={onSave} />
    </WidgetFrame>
  );
};

/**
 * FormBody
 *
 * @param {*} props
 */
const FormBody = ({ lead, onSave }) => (
  <Formik
    initialValues={lead || { source: null, assigned: null, status: null }}
    enableReinitialize={true}
    onSubmit={(values) => {
      onSave(values);
    }}
    validationSchema={yup.object().shape({
      shortDesc: yup
        .string()
        .nullable()
        .required("Short Description is required"),
    })}
  >
    {({
      values,
      touched,
      errors,
      dirty,
      isSubmitting,
      setFieldValue,
      handleChange,
      handleBlur,
      handleSubmit,
    }) => {
      return (
        <form onSubmit={handleSubmit}>
          <LeadForm
            lead={values}
            touched={touched}
            errors={errors}
            setFieldValue={setFieldValue}
            onBlur={handleBlur}
            onChange={handleChange}
          />
          <br />
          <Button
            type="submit"
            variant="outlined"
            color="primary"
            disabled={!dirty || isSubmitting}
          >
            Save
          </Button>
        </form>
      );
    }}
  </Formik>
);

/**
 * LeadForm
 *
 * Functional component to render the decriptive information of the
 * lead.
 *
 * @param {*} props
 */
const LeadForm = ({
  lead,
  touched,
  errors,
  setFieldValue,
  onBlur,
  onChange,
}) => {
  const [leadSources, setLeadSources] = useState(null);
  const [employees, setEmployees] = useState(null);
  const app = useApp();
  const api = useAPI();

  useEffect(() => {
    fetchLeadSources();
  }, []);

  useEffect(() => {
    fetchEmployees();
  }, []);

  ///////////////////////////////////////////////////////////////////
  //
  //  Utility Methods
  //
  ///////////////////////////////////////////////////////////////////

  const fetchLeadSources = () => {
    api
      .fetch(`/api/leadsources/`)
      .then(({ payload: leadSources }) => {
        setLeadSources(leadSources);
      })
      .catch((error) => {
        app.error({ error });
      });
  };

  const fetchEmployees = () => {
    api
      .fetch(`/api/employees/`)
      .then(({ payload: employees }) => {
        setEmployees(employees);
      })
      .catch((error) => {
        app.error({ error });
      });
  };

  ///////////////////////////////////////////////////////////////////
  //
  //  Event Handlers
  //
  ///////////////////////////////////////////////////////////////////

  return (
    <Grid container alignItems="flex-end" spacing={0}>
      <Grid item xs={12}>
        <TextField
          id="shortDesc"
          label="Description"
          value={lead.shortDesc}
          onChange={onChange}
          onBlur={onBlur}
          onChange={onChange}
          fullWidth
          required
          error={errors.shortDesc && touched.shortDesc}
          helperText={errors.shortDesc || " "}
        />
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={4}>
          <Grid item xs={12} md={4}>
            <FormControl fullWidth>
              <InputLabel id="source-label" shrink>
                Source
              </InputLabel>
              <Select
                inputProps={{
                  name: "source",
                  id: "source",
                }}
                labelId="source-label"
                value={
                  lead.source && lead.source.id != null ? lead.source.id : -1
                }
                onChange={(e) => {
                  setFieldValue(
                    "source",
                    leadSources.find(
                      (leadSource) => leadSource.id == e.target.value
                    )
                  );
                }}
              >
                <MenuItem value="-1" disabled>
                  -- Select One --
                </MenuItem>
                {leadSources &&
                  leadSources.map((leadSource) => (
                    <MenuItem key={leadSource.id} value={leadSource.id}>
                      {leadSource.name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={4}>
            <FormControl fullWidth>
              <InputLabel shrink id="assigned-label">
                Assigned To
              </InputLabel>
              <Select
                inputProps={{
                  name: "assigned",
                  id: "assigned",
                }}
                labelId="assigned-label"
                value={
                  lead.assigned && lead.assigned.id ? lead.assigned.id : -1
                }
                onChange={(e) => {
                  setFieldValue(
                    "assigned",
                    employees.find((employee) => employee.id == e.target.value)
                  );
                }}
              >
                <MenuItem value="-1" disabled>
                  -- Select One --
                </MenuItem>
                {employees &&
                  employees.map((employee) => (
                    <MenuItem key={employee.id} value={employee.id}>
                      {`${employee.fname} ${employee.lname}`}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={4}>
            <FormControl fullWidth>
              <InputLabel shrink id="status-label">
                Status
              </InputLabel>
              <Select
                inputProps={{
                  name: "status",
                  id: "status",
                }}
                labelId="status-label"
                value={
                  lead.status && lead.status.id != null ? lead.status.id : "0"
                }
                onChange={(e) => {
                  setFieldValue(
                    "status",
                    statusTypes.find(
                      (statusType) => statusType.id == e.target.value
                    )
                  );
                }}
              >
                <MenuItem value="-1" disabled>
                  -- Select One --
                </MenuItem>
                {statusTypes.map((statusType) => (
                  <MenuItem key={statusType.id} value={statusType.id}>
                    {statusType.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default LeadManager;
