/********************************************************************
 *
 * Configuration.jsx
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2020 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

import React, { useState, useEffect } from "react";
import { Settings } from "@material-ui/icons";
import { Switch, Route, Redirect, NavLink } from "react-router-dom";
import { Main } from "../../lib";
import {
  SectionList,
  SectionItemGroup,
  SectionItem,
} from "../../lib/SideBar/Menu";
import Error404 from "../../Error/404";
import Orders from "./Sales";
import Tours from "./Tours";
import { useApp, useAPI } from "../../../providers/AppProvider";

/**
 * Configuration
 *
 * Container component for configuration management.
 *
 */
const Configuration = () => {
  const [config, setConfig] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const app = useApp();
  const api = useAPI();

  useEffect(() => {
    fetchConfig();
  }, []);

  ///////////////////////////////////////////////////////////////////////
  //
  //  Utility Methods
  //
  ///////////////////////////////////////////////////////////////////////

  /**
   * FetchConfig
   *
   * Calls the API to fetch the configuration information
   *
   */
  const fetchConfig = () => {
    //
    //  Status message
    //
    const notifyHandle = app.notify("Loading");

    //
    //  Initialize configuration data
    //
    api
      .fetch(`/api/config/`)
      .then(({ payload: config }) => {
        setConfig(config);
        setLoaded(true);
        app.endNotify(notifyHandle);
      })
      .catch((error) => {
        app.error({ error });
        setConfig(null);
        setLoaded(true);
      });
  };

  /**
   * UpdateConfig
   *
   * Updates the config values.
   *
   * @param {*} key
   * @param {*} value
   */
  const updateConfig = (key, value) => {
    //
    //  Status message
    //
    const notifyHandle = app.notify("Saving change");

    return new Promise((resolve, reject) => {
      //
      //  Update system configuration values
      //
      api
        .update(`/api/config/`, { key, value })
        .then(() => {
          resolve(true);
          app.endNotify(notifyHandle);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  ///////////////////////////////////////////////////////////////////////
  //
  //  Event Handlers
  //
  ///////////////////////////////////////////////////////////////////////

  /**
   * OnChangeConfig
   *
   * Fired when a configuration value is changed.
   *
   * @param {*} key
   * @param {*} value
   */
  const onChangeConfig = ({ key, value }) => {
    updateConfig(key, value)
      .then(() => {
        config[key] = value;
        setConfig({ ...config });
      })
      .catch((error) => {
        app.error({ error });
      });
  };

  ///////////////////////////////////////////////////////////////////////
  //
  //  Lifecycle methods
  //
  ///////////////////////////////////////////////////////////////////////

  return (
    <Main
      menu={{
        icon: <Settings />,
        title: "System Settings",
        subtitle: "Update configuration settings",
        nav: <Menu config={config} />,
      }}
      header={{
        icon: <Settings />,
        title: "Configuration",
      }}
      loaded={loaded}
    >
      {config && <Router config={config} onChangeConfig={onChangeConfig} />}
    </Main>
  );
};

///////////////////////////////////////////////////////////////////////
//
//  Functional Components
//
///////////////////////////////////////////////////////////////////////

/**
 * Renders the navigation controls for the functional areas of the
 * order management page.
 *
 * @param {*} props
 */
const Menu = ({ config }) => {
  return (
    <SectionList>
      <SectionItemGroup icon={<Settings />} name="Settings" open={true}>
        <SectionItem to={`/app/admin/config/orders`}>Sales</SectionItem>
        <SectionItem to={`/app/admin/config/tours`}>Tours</SectionItem>
      </SectionItemGroup>
    </SectionList>
  );
};

/**
 * Renders the routing logic for the page
 *
 * @param {*} props
 */
const Router = ({ config, onChangeConfig }) => {
  return (
    <Switch>
      {/* Orders */}
      <Route
        path="/app/admin/config/orders"
        render={(props) => (
          <Orders {...props} config={config} onChangeConfig={onChangeConfig} />
        )}
      />
      {/* Tours */}
      <Route
        path="/app/admin/config/tours"
        render={(props) => (
          <Tours {...props} config={config} onChangeConfig={onChangeConfig} />
        )}
      />
      {/* Redirect */}
      <Route
        path="/app/admin/config"
        render={() => <Redirect to={`/app/admin/config/orders/`} />}
      />
      <Route path="/app/admin/config" component={Error404} />
    </Switch>
  );
};

export default Configuration;
