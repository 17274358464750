/********************************************************************
 *
 * Asset.jsx
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2019 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

import React, { useState, useEffect } from "react";
import { Avatar } from "@material-ui/core";
import { Main } from "../../lib";
import { useApp, useAPI } from "../../../providers/AppProvider";

/**
 * Asset
 *
 * Renders an asset
 */
const Asset = ({ match }) => {
  const [asset, setAsset] = useState(null);
  const [assetProductTypes, setAssetProductTypes] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const app = useApp();
  const api = useAPI();

  const { id } = match.params;

  useEffect(() => {
    fetchAsset(id);
  }, [id]);

  /////////////////////////////////////////////////////////////////////
  //
  //  Utility Methods
  //
  /////////////////////////////////////////////////////////////////////

  /**
   * FetchAsset
   *
   * Fetches the asset object
   *
   * @param {*} id
   */
  const fetchAsset = (id) => {
    api
      .fetch(`/api/assets/asset/${id}`)
      .then(({ payload: asset }) => {
        setAsset(asset);
        setLoaded(true);
      })
      .catch((error) => {
        app.error({ error });
        setLoaded(true);
      });
  };

  ///////////////////////////////////////////////////////////////////////
  //
  //  Event Handlers
  //
  ///////////////////////////////////////////////////////////////////////

  ///////////////////////////////////////////////////////////////////////
  //
  //  Lifecycle methods
  //
  ///////////////////////////////////////////////////////////////////////

  return (
    <Main
      header={{
        icon: !!asset && <Avatar>{asset.name[0]}</Avatar>,
        title: asset ? asset.name : "New Asset",
      }}
      loaded={loaded}
    >
      {asset && (
        <div className="row">
          <div className="col-12 col-lg-8">
            <AssetSummary asset={asset} />
          </div>
          <div className="col-12 col-lg-4">
            <AssetProductTypes assetProductTypes={assetProductTypes} />
          </div>
        </div>
      )}
    </Main>
  );
};

const AssetSummary = ({ asset }) => {
  return (
    <div className="card mb-3">
      <div className="card-body">
        <div className="card-title h4 text-uppercase mb-4">{asset.name}</div>
      </div>
    </div>
  );
};

const AssetProductTypes = ({ assetProductTypes }) => {
  return (
    <div className="card mb-3">
      <div className="card-body">
        <div className="card-title h6 text-uppercase">
          Product Types Assigned
        </div>
        <div className="card-text small">
          {assetProductTypes &&
            assetProductTypes.map((assetProductType) => (
              <div key={assetProductType.id} className="row">
                <div className="col-12 pr-0">{assetProductType.name}</div>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default Asset;
