/********************************************************************
 *
 * /src/components/sales/rReceiptDetails/index.jsx
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2024 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

import React from "react";
import { CardList } from "../../../lib";
import Receipt from "./Receipt";

/**
 * ReceiptDetails
 *
 * Component that displays the receipt transactions for an order.
 *
 */
const ReceiptDetails = ({ order }) => {
  ///////////////////////////////////////////////////////////////////
  //
  //  Lifecycle methods
  //
  ///////////////////////////////////////////////////////////////////

  if (!order) return "";

  return order.receipts
    ? order.receipts.items && (
        <CardList
          items={order.receipts.items}
          spacing={2}
          onRenderCard={({ item: receipt }) => (
            <Receipt order={order} receipt={receipt} />
          )}
        />
      )
    : "No receipts have been processed.";
};

export default ReceiptDetails;
