import React from "react";

/**
 * PartnerSummary
 *
 * Renders partner information.
 */
const PartnerSummary = ({ partner }) => {
  return (
    <div className="d-flex justify-content-between">
      <div>
        <div className="mb-2">
          <span
            className={`badge badge-pill badge-danger mr-2 ${
              partner.deleted ? "" : "d-none"
            }`}
          >
            Deleted
          </span>
        </div>
        <div className={`h4 ${partner.deleted ? "text-muted deleted" : ""}`}>
          {partner.name}
        </div>
        <p className={`lead ${partner.deleted ? "text-muted deleted" : ""}`}>
          {partner.website}
        </p>
      </div>
      <div>
        {partner.logoURL && (
          <img
            className="img-fluid d-none d-md-block"
            src={partner.logoURL}
            alt={partner.name}
          />
        )}
      </div>
    </div>
  );
};

export default PartnerSummary;
