/********************************************************************
 *
 * Product.jsx
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2020 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

import React, { useState, useEffect } from "react";
import moment from "moment-timezone";
import { Grid, Avatar, makeStyles } from "@material-ui/core";
import { Main } from "../../../lib";
import ProductSKUs from "./ProductSKUs";
import Orders from "./Orders";
import Features from "../Features/Features";
import Identity from "./Identity";
import { useApp, useAPI } from "../../../../providers/AppProvider";

/**
 * Product
 *
 * Renders a product.
 */
const Product = ({ match }) => {
  const classes = styles();
  const [product, setProduct] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const app = useApp();
  const api = useAPI();
  const canBook =
    true ||
    (product &&
      !product.deleted &&
      product.available > 0 &&
      moment.utc(product.start).isAfter());
  const { id: productId } = match.params;

  useEffect(() => {
    fetchProduct(productId);
  }, [productId]);

  ///////////////////////////////////////////////////////////////////////
  //
  //  Helper methods
  //
  ///////////////////////////////////////////////////////////////////////

  const fetchProduct = (productId) => {
    if (!productId) throw new Error("Parameter 'id' is required.");

    api
      .fetch(`/api/products/product/${productId}`)
      .then(({ payload: product }) => {
        setProduct(product);
        setLoaded(true);
      })
      .catch((error) => {
        app.error({ error });
        setLoaded(true);
      });
  };

  ///////////////////////////////////////////////////////////////////
  //
  //  Event Handlers
  //
  ///////////////////////////////////////////////////////////////////

  /**
   * OnUpdate
   *
   * Fired when a child updates the data object.
   *
   * @param {*} event
   */
  const onUpdate = () => {
    //
    //  If product type id is a paramter, we are in edit mode, otherwise
    //  we are creating a new product type
    //
    fetchProduct(productId);
  };

  ///////////////////////////////////////////////////////////////////////
  //
  //  Lifecycle Methods
  //
  ///////////////////////////////////////////////////////////////////////

  return (
    <Main
      header={{
        icon: product && product.imageURL && (
          <Avatar alt={product.name} src={product.imageURL} />
        ),
        title: product && `${product.name} (${product.id})`,
        callout: product &&
          product.deleted && { label: "deleted", color: "error" },
      }}
      loaded={loaded}
    >
      {loaded && !product && "Product Not Available"}
      {product && (
        <>
          <Grid container spacing={4}>
            <Grid item xs={12} md={8}>
              <Identity product={product} onUpdate={onUpdate} />
            </Grid>
            <Grid item xs={12} md={4}>
              <Features features={product.features} />
            </Grid>
            <Grid item xs={12}>
              <Orders product={product} />
            </Grid>
            <Grid item xs={12}>
              <ProductSKUs product={product} canBook={canBook} />
            </Grid>
          </Grid>
        </>
      )}
    </Main>
  );
};

/*
 **  Styles
 */
const styles = makeStyles((theme) => ({
  buttonList: {
    marginBottom: theme.spacing(2),
    "& > *": {
      marginLeft: theme.spacing(3),
    },
  },
}));

export default Product;
