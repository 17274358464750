/********************************************************************
 *
 * GoodTypes.jsx
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2019 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

import React, { useState, useEffect } from "react";
import { Box, Button, Typography } from "@material-ui/core";
import { Extension } from "@material-ui/icons";
import ModalDialog from "../../lib/ModalDialog";
import {
  Main,
  CardList,
  LinkCard2,
  CardContentHeader,
  CardAction,
} from "../../lib";
import { useApp, useAPI } from "../../../providers/AppProvider";

const links = ["tourtype", "inventorytype", "suppliertype"];

/**
 * GoodTypes
 *
 * Renders a list of Good Types
 *
 */
const GoodTypes = () => {
  const [goodTypes, setGoodTypes] = useState(null);
  const [showAddGoodType, setShowAddGoodType] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const app = useApp();
  const api = useAPI();

  useEffect(() => {
    fetchGoodTypes();
  }, []);

  /////////////////////////////////////////////////////////////////////
  //
  //  Utility methods
  //
  /////////////////////////////////////////////////////////////////////

  /**
   * FetchGoodTypes
   *
   * Calls the api to fetch good types.
   *
   */
  const fetchGoodTypes = () => {
    //
    //  Status message
    //
    const notifyHandle = app.notify("Loading");

    api
      .fetch(`/api/goodtypes/?verbose`)
      .then(({ payload: goodTypes }) => {
        setGoodTypes(goodTypes);
        setLoaded(true);
        app.endNotify(notifyHandle);
      })
      .catch((error) => {
        app.error({ error });
        setLoaded(true);
      });
  };

  ///////////////////////////////////////////////////////////////////
  //
  //  Event Handlers
  //
  ///////////////////////////////////////////////////////////////////
  /**
   * OnDelete
   *
   * Fired when a user clicks the delete button on a good type.
   *
   * Deletes the requested good type and reloads the updated set
   * of property types.
   *
   * @param {*} event
   */
  const onDelete = ({ goodType }) => {
    if (
      !goodType ||
      !window.confirm("Are you sure you want to delete this good?")
    )
      return;

    //
    //  Status message
    //
    const notifyHandle = app.notify("Deleting");

    api
      .delete(`/api/goodtypes/goodtype/${goodType.id}`)
      .then(() => {
        app.endNotify(notifyHandle);
        throw new Error("Update list");
      })
      .catch((error) => {
        app.error({ error });
      });
  };

  ///////////////////////////////////////////////////////////////////////
  //
  //  Lifecycle methods
  //
  ///////////////////////////////////////////////////////////////////////

  return (
    <Main
      header={{
        icon: <Extension />,
        title: `Goods available to ${process.env.REACT_APP_ORG_NAME}`,
        bonus: (
          <>
            <Button
              variant="outlined"
              color="primary"
              onClick={() => setShowAddGoodType(true)}
            >
              Create a new good
            </Button>
            <ModalDialog show={!!showAddGoodType}>Create/edit good</ModalDialog>
          </>
        ),
      }}
      loaded={loaded}
    >
      {!goodTypes && "No Good Types Available"}
      {goodTypes && (
        <CardList
          xs={12}
          md={6}
          lg={4}
          xl={3}
          spacing={4}
          items={goodTypes}
          onRenderCard={({ item: goodType }) => (
            <LinkCard2
              link={`/app/goods/${links[goodType.source.id]}/${goodType.id}`}
              content={
                <CardContentHeader
                  avatar={
                    <img
                      src={goodType.supplier.logoURL}
                      alt={goodType.supplier.name}
                    />
                  }
                  content={
                    <Box display="flex" flexDirection="column">
                      <Typography variant="h4">{goodType.name}</Typography>
                      <Typography variant="body2">
                        {goodType.supplier.name}
                      </Typography>
                    </Box>
                  }
                />
              }
              actions={
                <>
                  <CardAction
                    href={`/app/goods/${links[goodType.source.id]}/${
                      goodType.id
                    }`}
                    label="Edit"
                  />
                  <CardAction
                    type="button"
                    onClick={() => {
                      onDelete({ goodType: goodType });
                    }}
                    label="Delete"
                  />
                </>
              }
            />
          )}
        />
      )}
    </Main>
  );
};

export default GoodTypes;
