import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPassport } from "@fortawesome/free-solid-svg-icons";
import { Main } from "../../lib";
import { useApp, useAPI } from "../../../providers/AppProvider";

/**
 * RateClassPartnersEdit
 *
 * Adds or edits a rate class.
 */
const RateClassPartnersEdit = ({ match, history }) => {
  const [rateClass, setRateClass] = useState(null);
  const [companies, setCompanies] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const app = useApp();
  const api = useAPI();

  //
  //  If product id is a paramter, we are in edit mode, otherwise
  //  we are creating a new product
  //
  const { id } = match.params;

  useEffect(() => {
    //
    //  Status message
    //
    const notifyHandle = app.notify("Loading");

    Promise.all([fetchCompanies(id), fetchRateClass(id)])
      .then(([companies, rateClass]) => {
        setCompanies(companies);
        setRateClass(rateClass);
        setLoaded(true);
        app.endNotify(notifyHandle);
      })
      .catch((error) => {
        app.error({ error });
        setCompanies(null);
        setRateClass(null);
        setLoaded(true);
      });
  }, []);

  /////////////////////////////////////////////////////////////////////
  //
  //  Utility methods
  //
  /////////////////////////////////////////////////////////////////////

  /**
   * FetchCompanies
   *
   * Initializes the rate
   *
   * @param {*} id
   */
  const fetchCompanies = (id) => {
    return new Promise((resolve, reject) => {
      api
        .fetch(`/api/rateclasses/rateclass/${id}/partners?all=true`)
        .then((envelope) => {
          resolve(envelope.payload);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  /**
   * FetchRateClass
   *
   * Initializes the rate class
   *
   * @param {*} id
   */
  const fetchRateClass = (id) => {
    return new Promise((resolve, reject) => {
      api
        .fetch(`/api/rateclasses/rateclass/${id}`)
        .then((envelope) => {
          resolve(envelope.payload);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  ///////////////////////////////////////////////////////////////////
  //
  //  Event Handlers
  //
  ///////////////////////////////////////////////////////////////////

  /**
   * OnChangeStatus
   *
   * Fired when the user changes the status of a company on the rate
   * class.
   *
   * @param {*} event
   * @param {*} id
   * @param {*} state
   */
  const onChangeStatus = (event, id, state) => {
    event.preventDefault();

    //
    //  Status message
    //
    const notifyHandle = app.notify("Saving changes");

    api
      .update(`/api/rateclasses/rateclass/${rateClass.id}/partner`, {
        partnerId: id,
        status: state ? state : false,
      })
      .then(({ payload: companies }) => {
        setCompanies(companies);
      })
      .catch((error) => {
        app.error({ error });
      });
  };

  /**
   * OnSave
   *
   * Fired when the user commits the change.
   *
   * @param {*} event
   */
  const onDone = (event) => {
    event.preventDefault();

    history.goBack();
  };

  ///////////////////////////////////////////////////////////////////////
  //
  //  Lifecycle methods
  //
  ///////////////////////////////////////////////////////////////////////

  return (
    <Main className="container" loaded={loaded}>
      {companies && (
        <>
          <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
            <div className="page-title">
              <FontAwesomeIcon className="mr-4" icon={faPassport} />
              {rateClass && rateClass.name} Rate Class Parters
            </div>
            <div className="btn-toolbar mb-2 mb-md-0" />
          </div>
          <div className="row">
            <div className="col col-lg-8">
              <RateClassCompanies
                companies={companies}
                onChangeStatus={onChangeStatus}
              />
            </div>
          </div>
          <button className="btn btn-primary mt-lg-4" onClick={onDone}>
            Done
          </button>
        </>
      )}
    </Main>
  );
};

/**
 * RateClassCompanies
 *
 * @param {*} props
 */
const RateClassCompanies = ({ companies, onChangeStatus }) => {
  if (!companies) return "Loading...";

  return (
    <form className="ml-3 ml-md-5">
      {companies.map((company) => (
        <div key={company.data.id} className="custom-control custom-switch">
          <input
            id={company.data.id}
            className="custom-control-input"
            type="checkbox"
            onChange={(e) => onChangeStatus(e, e.target.id, e.target.checked)}
            value="1"
            checked={company.status === 1}
          />
          <label className="custom-control-label" htmlFor={company.data.id}>
            {company.data.name}
          </label>
        </div>
      ))}
    </form>
  );
};
export default RateClassPartnersEdit;
