/********************************************************************
 *
 * FeatureTypes.jsx
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2020 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

import React, { useState } from "react";
import ModalDialog, { DialogContent } from "../../../lib/ModalDialog";
import { CardList, LinkCard2, CardAction } from "../../../lib/";
import {
  FeatureTypeHeader,
  FeatureTypeSummary,
  FeatureTypeEdit,
} from "./Identity/";
import { useApp, useAPI } from "../../../../providers/AppProvider";

/**
 * FeatureTypes
 *
 * Functional component for the product type's feature types
 *
 * Renders a list of feature types and a modal (hidden) for
 * edit mode.
 *
 * @param {*} props
 */
const FeatureTypes = ({ productType, featureTypes, onUpdate, ...props }) => {
  const [showAddFeature, setShowAddFeature] = useState(false);
  const [editFeatureType, setEditFeatureType] = useState(null);
  const app = useApp();
  const api = useAPI();

  /**
   * OnSaved
   *
   * Fired once the edit state has saved a feature type.
   *
   * Returns to display mode.
   *
   * @param {*} event
   */
  const onSaved = (event) => {
    onUpdate && onUpdate(event);

    setEditFeatureType(null);
  };

  /**
   * OnSavedFeatureType
   *
   * Used by the Add Feature Dialog
   *
   * @param {*} event
   */
  const onSavedFeatureType = (event) => {
    onUpdate(event);
    setShowAddFeature(false);
  };

  /**
   * OnCancelled
   *
   * Fired once the edit state has aboorted changes to a feature type.
   *
   * Returns to display mode.
   *
   */
  const onCancelled = () => {
    setEditFeatureType(null);
  };

  /**
   * OnDelete
   *
   * Handles a request in display mode to delete a feature type.
   *
   * @param {*} event
   */
  const onDelete = (event) => {
    const { featureType } = event;

    if (!window.confirm("Are you sure you want to delete this feature type?"))
      return;

    api
      .delete(
        `/api/producttypes/producttype/${productType.id}/featuretypes/featuretype/${featureType.id}`
      )
      .then(() => {
        onUpdate && onUpdate();
      })
      .catch((error) => {
        app.error({ error });
      });
  };

  return (
    <>
      {!featureTypes ? (
        "No sales features have been created."
      ) : (
        <CardList
          xs={12}
          items={featureTypes}
          onRenderCard={({ item: featureType }) => (
            <LinkCard2
              onClick={() => {
                setEditFeatureType(featureType);
              }}
              header={<FeatureTypeHeader featureType={featureType} />}
              content={<FeatureTypeSummary featureType={featureType} />}
              actions={
                <>
                  <CardAction
                    label="Edit"
                    onClick={() => setEditFeatureType(featureType)}
                  />
                  <CardAction
                    label="Delete"
                    onClick={() => onDelete({ featureType })}
                  />
                </>
              }
            />
          )}
        />
      )}

      <ModalDialog
        title="Manage Feature Type"
        subtitle="Feature types are the building blocks of products"
        show={!!editFeatureType}
      >
        <DialogContent>
          <FeatureTypeEdit
            productType={productType}
            featureType={editFeatureType}
            onSaved={onSaved}
            onCancelled={onCancelled}
          />
        </DialogContent>
      </ModalDialog>

      <div>
        <button
          type="button"
          className="btn btn-outline-cc mt-3"
          onClick={() => setShowAddFeature(true)}
        >
          Add Feature
        </button>
        <ModalDialog show={!!showAddFeature}>
          <DialogContent>
            <FeatureTypeEdit
              productType={productType}
              onSaved={onSavedFeatureType}
              onCancelled={() => setShowAddFeature(false)}
            />
          </DialogContent>
        </ModalDialog>
      </div>
    </>
  );
};

export default FeatureTypes;
