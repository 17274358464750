/********************************************************************
 *
 * components/goods/products/index.jsx
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2020 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

import React, { useState, useEffect } from "react";
import moment from "moment-timezone";
import PropTypes from "prop-types";
import {
  Box,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Avatar,
  makeStyles,
} from "@material-ui/core";
import { Error } from "@material-ui/icons";

import { WidgetFrame } from "../../../lib";
import format from "../../../../utils/format";
import { useApp, useAPI } from "../../../../providers/AppProvider";

const TEMPLATES = {
  "bookingMobileTour.hbs": "Tour - Mobile",
  "bookingEmailXfer.hbs": "Transfer - Email",
};

/**
 * ProductsForGoods
 *
 * List of Products that contain this good
 *
 */
const ProductsForTour = ({ tour }) => {
  const [products, setProducts] = useState(null);
  const app = useApp();
  const api = useAPI();
  const classes = useStyles();

  /**
   *  Products State Management
   */
  useEffect(() => {
    if (tour) fetchProducts(tour.id);
  }, [tour]);

  ///////////////////////////////////////////////////////////////////
  //
  //  Utility Methods
  //
  ///////////////////////////////////////////////////////////////////

  /**
   * FetchProducts
   *
   * @param {*} tourId
   */
  const fetchProducts = (tourId) => {
    api
      .fetch(`/api/suppliergoods/suppliergood/${tourId}/products/?verbose`)
      .then(({ payload: products }) => {
        setProducts(products);
      })
      .catch((error) => {
        app.error({ error });
      });
  };

  ///////////////////////////////////////////////////////////////////
  //
  //  Lifecycle methods
  //
  ///////////////////////////////////////////////////////////////////

  /**
   * Render
   *
   * Renders a list of products that contain the tour
   *
   */

  if (!tour) return null;

  return (
    <WidgetFrame title={`Products for Goods ${tour.id}`}>
      <List>
        {!!products &&
          products.map((product) => (
            <ListItem
              key={product.id}
              className={classes.listItem}
              href={`/app/catalog/product/${product.id}`}
              component="a"
              target="__blank"
            >
              <ListItemAvatar>
                <Avatar alt={product.name} src={product.imageURL}>
                  {product.name[0]}
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={product.name}
                secondary={<ProductTime tour={tour} product={product} />}
              />
            </ListItem>
          ))}
      </List>
    </WidgetFrame>
  );
};

const ProductTime = ({ tour, product }) => {
  const productStart = moment.tz(product.start, product.timezone);
  const productEnd = moment.tz(product.end, product.timezone);
  const alert =
    !productStart.isSame(tour.start, "minutes") ||
    !productEnd.isSame(tour.end, "minutes");
  const classes = useStyles();

  return (
    <Box className={!!alert ? classes.error : null}>
      {!!alert && (
        <Box style={{ paddingRight: "8px" }} component="span">
          <Error className={classes.error} />
        </Box>
      )}
      {`${format.shortDateTime(productStart)} - ${format.duration(
        moment.duration(productEnd.diff(moment(productStart))).asMinutes()
      )}`}
    </Box>
  );
};

ProductsForTour.propTypes = {
  tour: PropTypes.object.isRequired,
};

/*
 **  Styles
 */
const useStyles = makeStyles((theme) => ({
  listItem: {
    "&:hover": {
      backgroundColor: theme.palette.background.default,
      color: "#666666",
      textDecoration: "none",
    },
  },
  error: {
    color: theme.palette.error.main,
  },
}));

export default ProductsForTour;
