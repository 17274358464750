/********************************************************************
 *
 * DiscountDialog.jsx
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2020 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Box,
  FormControl,
  FormHelperText,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Button,
} from "@material-ui/core";
import { Formik } from "formik";
import * as yup from "yup";
import { ModalDialog, DialogContent, HorizontalGroup } from "../../../lib";
import { useApp, useAPI } from "../../../../providers/AppProvider";

/**
 * DiscountDialog
 *
 * Dialog component that creates a new discount for charges within
 * an order.
 *
 */
const DiscountDialog = ({ order, charge, show, onSaved, onCancelled }) => {
  const app = useApp();
  const api = useAPI();

  ///////////////////////////////////////////////////////////////////
  //
  //  Event Handlers
  //
  ///////////////////////////////////////////////////////////////////

  /**
   * OnSave
   *
   * Fired when the user commits the change.
   *
   * @param {*} discount
   */
  const onSave = (discount) => {
    //
    //  Status message
    //
    const notifyHandle = app.notify("Adding Discount");

    api
      .create(
        `/api/orders/order/${order.id}/charges/charge/${charge.id}/discount?verbose`,
        discount
      )
      .then(({ payload: order }) => {
        onSaved && onSaved(order);
        app.endNotify(notifyHandle);
      })
      .catch((error) => {
        app.error({ error });
      });
  };

  /**
   * OnCancel
   *
   * @param {*} dirty
   */
  const onCancel = (dirty) => {
    if (
      !dirty ||
      window.confirm(
        "You have unsaved changes. Are you sure you want to close this dialog and loose your changes?"
      )
    ) {
      onCancelled && onCancelled();
    }
  };

  ///////////////////////////////////////////////////////////////////
  //
  //  Lifecycle methods
  //
  ///////////////////////////////////////////////////////////////////

  return (
    <ModalDialog
      title={`${charge.description} Discount`}
      subtitle="Discount will be applied on the gross amount"
      size="sm"
      show={!!show}
    >
      <DialogContent>
        <FormBody onSave={onSave} onCancel={onCancel} />
      </DialogContent>
    </ModalDialog>
  );
};

/**
 * FormBody
 *
 * @param {*} props
 */
const FormBody = ({ onSave, onCancel }) => {
  ///////////////////////////////////////////////////////////////////
  //
  //  Event Handlers
  //
  ///////////////////////////////////////////////////////////////////

  return (
    <Formik
      initialValues={{ discountType: { id: -1 }, amount: 0, description: "" }}
      enableReinitialize={true}
      onSubmit={(discount) => {
        discount.amount = discount.amount * 100;
        onSave(discount);
      }}
      validationSchema={yup.object().shape({
        amount: yup.number().positive().required("Amount required"),
        description: yup.string().nullable().required("Reason is required"),
        discountType: yup.object().shape({
          id: yup.number().positive("Please select a discount type"),
        }),
      })}
    >
      {({
        values,
        touched,
        errors,
        dirty,
        isSubmitting,
        setFieldValue,
        handleChange,
        handleBlur,
        handleSubmit,
      }) => {
        return (
          <form onSubmit={handleSubmit}>
            <Box display="flex" justifyContent="flex-end">
              <HorizontalGroup margin="ml1">
                <Button
                  type="submit"
                  variant="outlined"
                  color="primary"
                  disabled={!dirty || isSubmitting}
                >
                  Save
                </Button>
                <Button onClick={() => onCancel(dirty)}>Cancel</Button>
              </HorizontalGroup>
            </Box>
            <DiscountForm
              discount={values}
              touched={touched}
              errors={errors}
              setFieldValue={setFieldValue}
              onBlur={handleBlur}
              onChange={handleChange}
            />
          </form>
        );
      }}
    </Formik>
  );
};

const DiscountForm = ({
  discount,
  touched,
  errors,
  setFieldValue,
  onBlur,
  onChange,
}) => {
  const [discountTypes, setDiscountTypes] = useState(null);
  const app = useApp();
  const api = useAPI();

  useEffect(() => {
    fetchDiscountTypes();
  }, []);

  ///////////////////////////////////////////////////////////////////
  //
  //  Utiltiy Methods
  //
  ///////////////////////////////////////////////////////////////////

  const fetchDiscountTypes = () => {
    //
    //  Status message
    //
    const notifyHandle = app.notify("Loading Discounts");

    api
      .fetch(`/api/discountTypes?verbose`)
      .then(({ payload: discountTypes }) => {
        setDiscountTypes(discountTypes);
        app.endNotify(notifyHandle);
      })
      .catch((error) => {
        app.error({ error });
        setDiscountTypes(null);
      });
  };

  return (
    <Box>
      <FormControl
        fullWidth
        error={!!(errors.discountType && touched.discountType)}
      >
        <InputLabel id="discountType-label" shrink>
          Discount Type
        </InputLabel>
        <Select
          inputProps={{
            name: "discountType",
            id: "discountType",
          }}
          label="discountType-label"
          value={discount.discountType.id}
          onChange={(e) => {
            setFieldValue(
              "discountType",
              discountTypes.find(
                (discountType) => discountType.id == e.target.value
              )
            );
          }}
          value={discount.discountType ? discount.discountType.id : null}
          required
        >
          <MenuItem value="-1" disabled>
            -- Select --
          </MenuItem>
          {discountTypes &&
            discountTypes.map((discountType) => (
              <MenuItem key={discountType.id} value={discountType.id}>
                {discountType.name}
              </MenuItem>
            ))}
        </Select>
        <FormHelperText>
          {(errors.discountType && errors.discountType.id) || " "}
        </FormHelperText>
      </FormControl>
      <TextField
        id="amount"
        type="number"
        label="Amount"
        InputProps={{
          startAdornment: "$",
        }}
        value={discount.amount}
        min="0"
        step="0.01"
        placeholder="0.00"
        onBlur={onBlur}
        onChange={onChange}
        fullWidth
        required
        error={errors.amount && touched.amount}
        helperText={errors.amount || " "}
      />
      <TextField
        id="description"
        label="Reason"
        placeholder="Reason"
        value={discount.reason}
        multiline={true}
        rows="3"
        variant="outlined"
        onBlur={onBlur}
        onChange={onChange}
        fullWidth
        required
        error={errors.description && touched.description}
        helperText={errors.description || " "}
      />
    </Box>
  );
};

DiscountDialog.propTypes = {
  show: PropTypes.bool, // Can be null
  charge: PropTypes.object.isRequired,
  onSaved: PropTypes.func.isRequired,
  onCancelled: PropTypes.func.isRequired,
};

export default DiscountDialog;
