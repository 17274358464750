/********************************************************************
 *
 * Charge.jsx
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2020 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

import React, { useState } from "react";
import PropTypes from "prop-types";
import { Button, IconButton } from "@material-ui/core";
import { Delete } from "@material-ui/icons";
import moment from "moment";
import { LinkCard, CardAction } from "../../../lib";
import format from "../../../../utils/format";
import DiscountDialog from "./DiscountDialog";
import CouponDialog from "./CouponDialog";

import { useApp, useAPI } from "../../../../providers/AppProvider";

/**
 * Charge
 *
 * Component that renders information for a charge.
 *
 */
const Charge = ({ order, charge, onUpdated }) => {
  const [showAddDiscount, setShowAddDiscount] = useState(null);
  const [showAddCoupon, setShowAddCoupon] = useState(null);
  const app = useApp();
  const api = useAPI();

  ///////////////////////////////////////////////////////////////////
  //
  //  Utility Methods
  //
  ///////////////////////////////////////////////////////////////////

  const removeDiscount = (order, charge, discount) => {
    return new Promise((resolve, reject) => {
      api
        .delete(
          `/api/orders/order/${order.id}/charges/charge/${charge.id}/discount/${discount.id}`
        )
        .then(({ payload: order }) => {
          resolve(order);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  const removeCharge = (order, charge) => {
    return new Promise((resolve, reject) => {
      api
        .delete(`/api/orders/order/${order.id}/charges/charge/${charge.id}`)
        .then(({ payload: order }) => {
          resolve(order);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  ///////////////////////////////////////////////////////////////////
  //
  //  Event Handlers
  //
  ///////////////////////////////////////////////////////////////////

  const onDiscountAdded = (order) => {
    onUpdated && onUpdated(order);
    setShowAddDiscount(null);
  };

  const onRemoveDiscount = ({ discount }) => {
    //
    //  Status message
    //
    const notifyHandle = app.notify("Removing Discount");

    removeDiscount(order, charge, discount)
      .then((order) => {
        onUpdated && onUpdated(order);
        app.endNotify(notifyHandle);
      })
      .catch((error) => {
        app.error({ error });
      });
  };

  const onCouponAdded = (order) => {
    onUpdated && onUpdated(order);
    setShowAddCoupon(null);
  };

  const onRemoveCharge = (charge) => {
    if (!window.confirm("Are you sure you want to delete this charge?")) return;

    const notifyHandle = app.notify("Removing Charge");

    removeCharge(order, charge)
      .then((order) => {
        onUpdated && onUpdated(order);
        app.endNotify(notifyHandle);
      })
      .catch((error) => {
        app.error({ error });
      });
  };

  ///////////////////////////////////////////////////////////////////
  //
  //  Lifecycle methods
  //
  ///////////////////////////////////////////////////////////////////

  return (
    <>
      <LinkCard
        link={`/app/catalog/product/${charge.product.id}`}
        header={charge.description}
        content={
          <ChargeSummary charge={charge} onRemoveDiscount={onRemoveDiscount} />
        }
        actions={
          <>
            <CardAction
              label="Add Discount"
              onClick={() => {
                setShowAddDiscount(true);
              }}
            />
            <CardAction
              label="Add Coupon"
              onClick={() => {
                setShowAddCoupon(true);
              }}
            />
            <CardAction
              label="Remove"
              onClick={() => {
                onRemoveCharge(charge);
              }}
            />
          </>
        }
      />
      <DiscountDialog
        show={showAddDiscount}
        order={order}
        charge={charge}
        onSaved={onDiscountAdded}
        onCancelled={() => {
          setShowAddDiscount(null);
        }}
      />
      <CouponDialog
        show={showAddCoupon}
        order={order}
        charge={charge}
        onSaved={onCouponAdded}
        onCancelled={() => {
          setShowAddCoupon(null);
        }}
      />
    </>
  );
};

const ChargeSummary = ({ charge, onRemoveDiscount }) => {
  return (
    <div className="card-text">
      <div className="flexRowStretch mb-4">
        <div style={{ display: "flex" }}>
          <div className="lgChar">{charge.qty}</div>
          <div className="smChar ml-2">@ {format.currency(charge.price)}</div>
        </div>
        <div>
          {charge.product && (
            <ProductTime
              start={charge.product.start}
              end={charge.product.end}
            />
          )}
        </div>
      </div>
      {charge.rateClass.policies.map((policy) => (
        <div key={policy.id} className="row mb-2">
          <div className="col-12">
            <b>{policy.name}</b>
            <div className="small">{policy.description}</div>
          </div>
        </div>
      ))}
      {charge.discounts && charge.discounts.items && (
        <>
          <hr />
          <div className="h5 mb-4">Discounts</div>
          {charge.discounts.items.map((discount, index) => (
            <React.Fragment key={discount.id}>
              <div
                className={`flexRowStretch ${index > 0 ? "mt-3" : ""}`}
                style={{ alignItems: "baseline" }}
              >
                <div className="h6 m-0">{discount.discountType.name}</div>
                <div className="h6 m-0">
                  {format.currency(discount.amount)}{" "}
                  <IconButton onClick={(e) => onRemoveDiscount({ discount })}>
                    <Delete fontSize="small" />
                  </IconButton>
                </div>
              </div>
              <div
                className="flexRowStretch"
                style={{ alignItems: "baseline" }}
              >
                <div className="small">{discount.description}</div>
              </div>
            </React.Fragment>
          ))}
        </>
      )}
    </div>
  );
};
const ProductTime = ({ start, end }) => {
  return (
    <div style={{ display: "flex" }}>
      <div className="lgChar">{moment(start).format("D")}</div>
      <div className="ml-2">
        <div className="smChar font-weight-bold">
          {moment(start).format("MMM, YYYY")}
        </div>
        <div className="smChar">{moment(start).format("dddd")}</div>
      </div>
      <div className="ml-2">
        <div className="smChar font-weight-bold">
          {moment(start).format("HHmm")}
        </div>
        <div className="smChar font-weight-bold">
          {moment(end).format("HHmm")}
        </div>
      </div>
    </div>
  );
};

Charge.propTypes = {
  order: PropTypes.object.isRequired,
  charge: PropTypes.object.isRequired,
  onUpdated: PropTypes.func.isRequired,
};

export default Charge;
