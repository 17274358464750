/********************************************************************
 *
 * /components/lib/badge.jsx
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2021 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core";

const Badge = ({ label, color = "error" }) => {
  const [colourClass, setColourClass] = useState(null);
  const classes = useStyles();

  useEffect(() => {
    switch (color) {
      case "error":
        setColourClass(classes.error);
        break;
      case "warning":
        setColourClass(classes.warning);
        break;
      default:
        setColourClass(classes.default);
    }
  }, [color]);

  return <div className={`${classes.badge} ${colourClass}`}>{label}</div>;
};

const useStyles = makeStyles((theme) => ({
  badge: {
    display: "inline-block",
    height: "20px",
    textTransform: "uppercase",
    padding: "2px 6px",
    fontSize: "10px",
    fontWeight: 700,
    textAlign: "center",
    whiteSpace: "nowrap",
    verticalAlign: "baseline",
    borderRadius: ".2rem",
    transition:
      "color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out",
  },
  error: { color: "#ffffff", backgroundColor: "#f83245" },
  warning: { color: "#ffffff", backgroundColor: "#f4772e" },
  default: { color: "#ffffff", backgroundColor: theme.palette.primary.main },
}));

export default Badge;
