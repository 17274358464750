/********************************************************************
 *
 * Analytics/TotalTours.jsx
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2021 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

import React, { useState, useEffect } from "react";
import { Avatar } from "@material-ui/core";
import { DirectionsBoatOutlined } from "@material-ui/icons";
import { SuperStat } from "../../lib";
import { useApp, useAnalyticsAPI } from "../../../providers/AppProvider";

const TotalTours = () => {
  const [totalTours, setTotalTours] = useState(null);
  const app = useApp();
  const analyticsApi = useAnalyticsAPI();

  useEffect(() => {
    fetchTotalTourCount();
  }, []);

  const fetchTotalTourCount = () => {
    analyticsApi
      .fetch("/api/tours/earned/")
      .then(({ payload: count }) => {
        setTotalTours(count);
      })
      .catch((error) => {
        app.error({ error });
      });
  };

  return (
    <SuperStat
      icon={
        <Avatar style={{ backgroundColor: "red" }}>
          <DirectionsBoatOutlined />
        </Avatar>
      }
      label="Total Tours"
      value={totalTours}
    />
  );
};

export default TotalTours;
