/********************************************************************
 *
 * /lib/SideBar/Menu.jsx
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2020 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { List, ListItem, Button, Collapse } from "@material-ui/core";
import { ExpandMore } from "@material-ui/icons";

const SectionList = ({ children }) => {
  return <List className="p-0">{children}</List>;
};

const SectionItemGroup = ({ icon, name, children, open: openExt = true }) => {
  const [open, setOpen] = useState(openExt);

  return (
    <ListItem className="app-sidebar-item" disableGutters={true}>
      <Button
        className={`app-sidebar-button ${!!open && "active"}`}
        variant="text"
        color="primary"
        style={{ paddingLeft: "14px" }}
        onClick={() => setOpen(!open)}
      >
        {!!icon && <span className="app-sidebar-icon">{icon}</span>}
        <span>{name}</span>
        <ExpandMore
          className={`sidebar-expand-icon ${
            !!open && "sidebar-expand-icon-rotate"
          }`}
        />
      </Button>
      <Collapse in={open} unmountOnExit timeout={300}>
        <div className="sidebar-menu-children">
          <List>{children}</List>
        </div>
      </Collapse>
    </ListItem>
  );
};

const SectionItem = ({ to, children }) => (
  <ListItem className="app-sidebar-item" disableGutters={true}>
    <div style={{ flexGrow: 1 }}>
      <NavLink
        className="app-sidebar-button-wrapper"
        activeClassName="active-item"
        to={to}
        style={{ paddingLeft: "52px" }}
      >
        <span style={{ height: "24px" }}>{children}</span>
      </NavLink>
    </div>
  </ListItem>
);

export { SectionList, SectionItemGroup, SectionItem };
