/**
 * 404.jsx
 *
 * Error component
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2019 Canadian Coastal Inc. All rights reserved.
 *
 */

import React, { Component } from "react";

/**
 * Error404
 *
 * Error component
 *
 */
export default class Error404 extends Component {
  /**
   * Public Constructor
   *
   * @param {array} props
   *
   */
  constructor(props) {
    super(props);
    this.state = {};
  }

  ///////////////////////////////////////////////////////////////////
  //
  //  Lifecycle methods
  //
  ///////////////////////////////////////////////////////////////////

  UNSAFE_componentWillMount() {
    let imageNum = Math.floor(Math.random() * 6);

    document.body.style.backgroundImage = `url('/assets/images/bg${imageNum}.jpg')`;
    document.body.style.backgroundSize = "cover";
    document.body.style.backgroundRepeat = "no-repeat";
    document.body.style.backgroundPosition = imageNum < 1 ? "bottom" : "top";
    document.body.style.height = "100%";
  }

  componentWillUnmount() {
    document.body.style.backgroundImage = null;
    document.body.style.backgroundSize = null;
    document.body.style.backgroundRepeat = null;
    document.body.style.backgroundPosition = null;
    document.body.style.height = null;
  }

  /**
   * render
   *
   * Returns the HTML string for the component
   *
   * @returns string
   */
  render() {
    return (
      <div style={{ maxWidth: "none" }} className="container m-0 p-0">
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center"
          }}
          className="justify-content-center h-100"
        >
          <div
            style={{ backgroundColor: "rgba(245, 245, 245, 0.9)" }}
            className="jumbotron jumbotron-fluid"
          >
            <div className="container">
              <h1 className="display-4">Error 404</h1>

              <p className="lead">
                Need some help navigating? Then join us for a sail because we
                clearly know nothing about websites.
              </p>
              <div style={{ fontSize: "0.5em" }}>
                <p>{window.location.href}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
