import React, { Component } from "react";
import SectionRouterHelper from "../SectionRouterHelper";
import _SectionSubRouter from "./_SubSectionRouter";

import GoodTypes from "./Goods/GoodTypes";
import TourType from "./Goods/TourType";
import SupplierGoodType from "./Goods/SupplierGoodType";

import Tour from "./Goods/Tour";
import SupplierGood from "./Goods/SupplierGood";

import Ticket from "./Tickets/Ticket";

import Inventory from "./Inventory/Inventory";

export let routes = {
  root: "/app/goods",
  tree: {
    Goods: {
      label: "Goods",
      path: "/app/goods",
      component: GoodTypes,
      children: [
        {
          path: "/app/goods/tourtype/:goodTypeId",
          component: TourType,
        },
        {
          path: "/app/goods/suppliertype/:goodTypeId",
          component: SupplierGoodType,
        },
        {
          path: "/app/goods/tour/:id",
          component: Tour,
        },
        {
          path: "/app/goods/tour/",
          component: Tour,
        },
        {
          path: "/app/goods/ticket/:id",
          component: Ticket,
        },
        {
          path: "/app/goods/supplier/:id",
          component: SupplierGood,
        },
        {
          path: "/app/goods/supplier/",
          component: SupplierGood,
        },
      ],
    },
    Inventory: {
      label: "Inventory",
      path: "/app/goods/inventory/",
      component: Inventory,
      children: [],
    },
  },
};

export class _SectionRouter extends Component {
  render() {
    //
    //  Create the section router.
    //
    return (
      <SectionRouterHelper
        routes={routes}
        subSectionRouter={_SectionSubRouter}
      />
    );
  }
}
