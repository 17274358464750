import React from "react";
import {
  ThemeProvider,
  createMuiTheme,
  responsiveFontSizes,
} from "@material-ui/core/styles";

var theme = createMuiTheme({
  palette: {
    primary: {
      light: "#66c1b9",
      main: "#01899f",
      dark: "#0c4964",
      contrastText: "#eeeeee",
    },
    secondary: {
      light: "#ffffff",
      main: "#66c1b9",
      dark: "#01899f",
      contrastText: "#666666",
    },
    text: {
      primary: "#484848",
      secondary: "#999999",
    },
    action: {
      active: "#484848",
      hover: "#eeeeee",
      selected: "#01899f",
      disabled: "rgba(0, 0, 0, 0.26)",
      disabledBackground: "rgba(0, 0, 0, 0.12)",
    },
    background: {
      paper: "#fff",
      default: "#fafafa",
    },
  },
  typography: {
    fontWeight: 400,
    color: "#666",
    body1: {},
    body2: {},
    h1: {
      fontFamily: "'Work Sans', sans-serif, Helvetica, Arial, Verdana",
      fontWeight: 200,
      fontSize: "3.0rem",
      lineHeight: "1.167",
      textTransform: "uppercase",
    },
    h2: {
      fontFamily: "'Work Sans', sans-serif, Helvetica, Arial, Verdana",
      fontWeight: 200,
      fontSize: "2.5rem",
      lineHeight: "1.167",
      textTransform: "uppercase",
    },
    h3: {
      fontFamily: "'Work Sans', sans-serif, Helvetica, Arial, Verdana",
      fontWeight: 200,
      fontSize: "2.0rem",
      lineHeight: "1.167",
      textTransform: "uppercase",
    },
    h4: { fontSize: "1.2rem" },
    h5: { fontSize: "1.1rem" },
    h6: { fontSize: "1.0rem" },
  },
  props: {
    MuiButtonBase: {
      disableRipple: true,
    },
    MuiLink: {
      underline: "none",
    },
  },
  overrides: {
    MuiTypography: {
      gutterBottom: { marginBottom: "2rem" },
      h1: {},
      h2: {
        lineHeight: "1.25em",
      },
      h3: {
        lineHeight: "1.25em",
      },
    },
    MuiCard: {
      root: { border: "1px solid rgba(122,123,151,.3)", borderRadius: "8px" },
    },
  },
});

theme = responsiveFontSizes(theme, { factor: 10 });

export default ({ children }) => {
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};
