/********************************************************************
 *
 * Finance.jsx
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2020 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPassport } from "@fortawesome/free-solid-svg-icons";
import { Switch, Route, Redirect, NavLink } from "react-router-dom";
import { Main } from "../../lib";
import Error404 from "../../Error/404";
import Accounts from "./Accounts";

/**
 * Finance
 *
 * Container component for managing financial reports.
 *
 */
const Finance = () => {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    setLoaded(true);
  }, []);

  ///////////////////////////////////////////////////////////////////////
  //
  //  Utility Methods
  //
  ///////////////////////////////////////////////////////////////////////

  ///////////////////////////////////////////////////////////////////////
  //
  //  Event Handlers
  //
  ///////////////////////////////////////////////////////////////////////

  ///////////////////////////////////////////////////////////////////////
  //
  //  Lifecycle methods
  //
  ///////////////////////////////////////////////////////////////////////

  return (
    <Main className="tabbed" loaded={loaded}>
      {/* Menu */}
      <div className="navColumn">
        <span className="title">
          <FontAwesomeIcon className="mr-2" icon={faPassport} />
          Financal Reports
        </span>
        <div className="tabs">
          <Menu />
        </div>
      </div>
      {/* Content */}
      <div className="content flexColumn">
        <Router />
      </div>
    </Main>
  );
};

/**
 * Renders the navigation controls for the functional areas of the
 * page.
 *
 * @param {*} props
 */
const Menu = (props) => {
  return (
    <ul id="sidebar-nav">
      <li className="header">Lists</li>
      <li>
        <ul>
          <li>
            <NavLink
              className="tab"
              activeClassName="current"
              to={`/app/reports/finance/accounts`}
            >
              Accounts
            </NavLink>
          </li>
        </ul>
      </li>
      <li className="header">Sales</li>
      <li>
        <ul>
          <li>
            <NavLink
              className="tab"
              activeClassName="current"
              to={`/app/reports/finance/sales`}
            >
              Sales
            </NavLink>
          </li>
        </ul>
      </li>
    </ul>
  );
};

/**
 * Renders the routing logic for the page
 *
 * @param {*} props
 */
const Router = (props) => {
  return (
    <Switch>
      {/* Accounts */}
      <Route
        path="/app/reports/finance/accounts"
        render={(props) => <Accounts {...props} />}
      />
      {/* Redirect */}
      <Route
        path="/app/reports/finance"
        render={() => <Redirect to={`/app/reports/finance/accounts`} />}
      />
      <Route path="/app/reports/" component={Error404} />
    </Switch>
  );
};
export default Finance;
