/********************************************************************
 *
 * /components/public/schedule.jsx
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2020 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

import React, { useState, useEffect } from "react";
import {
  Container,
  Box,
  Avatar,
  Typography,
  CircularProgress,
  Button,
  Grid,
  Chip,
  Paper,
  makeStyles,
} from "@material-ui/core";
import {
  CardList,
  Card,
  CardHeader,
  CardContent,
  HorizontalGroup,
} from "../lib";
import format from "../../utils/format";
import { usePublicAPI } from "../../providers";

/**
 * Schedule
 *
 * Displays an employees upcoming shifts and shift management features.
 *
 * @param {*} props
 */
const Schedule = ({ match }) => {
  const { encodedId } = match.params;
  const classes = styles();
  const [shifts, setShifts] = useState(null);
  const [error, setError] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const publicApi = usePublicAPI();

  useEffect(() => {
    fetchShifts();
  }, [encodedId]);

  ///////////////////////////////////////////////////////////////////
  //
  //  Utility Functions
  //
  ///////////////////////////////////////////////////////////////////

  const fetchShifts = () => {
    //
    //  Request searches
    //
    publicApi
      .fetch(
        `/api/employees/employee/${encodedId}/shifts/${format.toApiDateTime(
          Date.now()
        )}`
      )
      .then(({ payload: shifts }) => {
        setShifts(shifts);
        setLoaded(true);
      })
      .catch((error) => {
        setError(error);
        setLoaded(true);
      });
  };

  ///////////////////////////////////////////////////////////////////
  //
  //  Event Handlers
  //
  ///////////////////////////////////////////////////////////////////

  const onUpdateShiftStatus = (shiftId, status) => {
    publicApi
      .update(
        `/api/employees/employee/${encodedId}/shift/${shiftId}/response`,
        {
          status: status,
        }
      )
      .then(({ payload: updatedShift }) => {
        let index = shifts.findIndex((shift) => shift.id === updatedShift.id);

        shifts[index] = updatedShift;

        setShifts([...shifts]);
      })
      .catch((error) => {
        setError(error);
      });
  };

  ///////////////////////////////////////////////////////////////////////
  //
  //  Lifecycle methods
  //
  ///////////////////////////////////////////////////////////////////////

  return (
    <Container className={classes.content} component="main" maxWidth="md">
      <Typography variant="h1">Sailing Schedule</Typography>
      <Typography color="error">{error && error.message}</Typography>
      <hr />

      {!loaded ? (
        <Box display="flex" justifyContent="center" mt={5}>
          <CircularProgress />
        </Box>
      ) : shifts ? (
        <CardList
          items={shifts}
          xs={12}
          spacing={2}
          onRenderCard={({ item: shift }) => (
            <ShiftCard
              shift={shift}
              onUpdateShiftStatus={onUpdateShiftStatus}
            />
          )}
        />
      ) : (
        <Typography variant="h3">No Scheduled Shifts</Typography>
      )}
    </Container>
  );
};

/**
 * ShiftCard
 *
 * @param {*} param0
 */
const ShiftCard = ({ shift, onUpdateShiftStatus }) => {
  const classes = styles();

  return (
    <Card>
      <CardHeader
        avatar={<Avatar className={classes.avatar}>{shift.tour.id}</Avatar>}
        title={format.dayDate(shift.tour.acquire)}
        subheader={`${format.time(shift.tour.acquire)} -
          ${format.time(shift.tour.release)}`}
      />
      <CardContent>
        <Grid container spacing={4}>
          {!!shift.tour.notes && (
            <Grid item xs={12}>
              <Paper elevation={1} className={classes.notes}>
                <Typography variant="body1">{`${shift.tour.notes}`}</Typography>
              </Paper>
            </Grid>
          )}
          <Grid item xs={12} md={6}>
            <Typography variant="body2">{`Depart: ${format.time(
              shift.tour.start
            )}`}</Typography>
            <Typography variant="body2">{`Return: ${format.time(
              shift.tour.end
            )}`}</Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="body2">{`Vessel: ${
              shift.tour.asset ? shift.tour.asset.name : "n/a"
            }`}</Typography>
          </Grid>
          {shift.status === 0 && (
            <Grid item xs={12}>
              <HorizontalGroup>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={(e) => onUpdateShiftStatus(shift.id, 1)}
                >
                  I'm Good!
                </Button>
                <Button
                  color="default"
                  variant="outlined"
                  onClick={(e) => onUpdateShiftStatus(shift.id, 2)}
                >
                  Sorry, Can't Make It
                </Button>
              </HorizontalGroup>
            </Grid>
          )}
          <Grid item xs={12}>
            <Typography variant="caption">
              {shift.status === 0
                ? `Sent On: ${format.date(shift.created)}`
                : shift.status === 1
                ? `Confirmed by ${shift.employee.fname} on
                              ${format.shortDate(shift.responded)}`
                : shift.status === 2
                ? `Declined by ${shift.employee.fname} on
                              ${format.shortDate(shift.responded)}`
                : ""}
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

/*
 **  Styles
 */
const styles = makeStyles((theme) => ({
  content: { padding: theme.spacing(4) },
  avatar: { backgroundColor: theme.palette.primary.main, fontSize: 12 },
  color: theme.palette.primary.contrastText,
  notes: { padding: 15, backgroundColor: "#eeeeee", color: "#666666" },
}));

export default Schedule;
