/********************************************************************
 *
 * /Catalog/Products/Features/Identity/Edit.jsx
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2020 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Typography,
  TextField,
  Select,
  Switch,
  FormControl,
  InputLabel,
  FormControlLabel,
  makeStyles,
} from "@material-ui/core";
import { Formik } from "formik";
import * as yup from "yup";
import { HorizontalGroup } from "../../../../lib/Groups";
import { useApp, useAPI } from "../../../../../providers/AppProvider";

/**
 * Edit
 *
 * Widget for quickly viewing/editting/creating a Feature Type.
 */
const Edit = ({
  productType,
  featureType: featureTypeExt,
  onSaved,
  onCancelled,
}) => {
  const [featureType, setFeatureType] = useState({
    name: "",
    shortDescription: "",
    description: "",
    shared: 1,
    good: { id: 0 },
    quantity: 0,
    fulfillment: "",
  });
  const [goodTypes, setGoodTypes] = useState(null);
  const app = useApp();
  const api = useAPI();

  if (!productType) throw new Error("'ProductType' property cannot be null.");

  useEffect(() => {
    initGoodTypes();
  }, []);

  useEffect(() => {
    setFeatureType(featureTypeExt ? { ...featureTypeExt } : {});
  }, [featureTypeExt]);

  ///////////////////////////////////////////////////////////////////
  //
  //  Utility Methods
  //
  ///////////////////////////////////////////////////////////////////

  /**
   * InitGoodTypes
   *
   * Fetches the list of available goodTypes.
   *
   */
  const initGoodTypes = () => {
    //
    //  Status message
    //
    const notifyHandle = app.notify("Loading");

    api
      .fetch(`/api/goodtypes/`)
      .then(({ payload: goodTypes }) => {
        setGoodTypes(goodTypes);
        app.endNotify(notifyHandle);
      })
      .catch((error) => {
        app.error({ error });
        setGoodTypes(null);
      });
  };

  ///////////////////////////////////////////////////////////////////
  //
  //  Event Handlers
  //
  ///////////////////////////////////////////////////////////////////

  /**
   * OnSave
   *
   * Fired when the user saves the changes
   *
   * Validates the form and calls the API to save the data.
   *
   * @param {*} featureType
   */
  const onSave = (featureType) => {
    //
    //  Save
    //
    const notifyHandle = app.notify("Saving");

    api
      .update(
        `/api/producttypes/producttype/${productType.id}/featuretype/${
          featureType.id ? featureType.id : ""
        }`,
        featureType
      )
      .then(() => {
        onSaved && onSaved({ featureType: { ...featureType } });
        setFeatureType(null);
        app.endNotify(notifyHandle);
      })
      .catch((error) => {
        app.error({ error });
      });
  };

  /**
   * onCancel
   *
   * Fired when the user aborts changes.
   *
   */
  const onCancel = (dirty) => {
    if (
      !dirty ||
      window.confirm(
        "You have unsaved changes. Are you sure you want to close this dialog and loose your changes?"
      )
    ) {
      onCancelled && onCancelled();
      setFeatureType(null);
    }
  };

  ///////////////////////////////////////////////////////////////////
  //
  //  Lifecycle methods
  //
  ///////////////////////////////////////////////////////////////////

  return (
    <Formik
      initialValues={featureType}
      enableReinitialize={true}
      onSubmit={(values) => {
        onSave(values);
      }}
      validationSchema={yup.object().shape({
        name: yup.string().nullable().required("Name is required"),
        shortDescription: yup
          .string()
          .nullable()
          .required("Short description is required"),
        description: yup.string().required("Description is required"),
        quantity: yup
          .number()
          .required("One or more goods are required")
          .positive("The quantity must be a positive number")
          .integer("Partial goods are not allowed"),
        fulfillment: yup
          .string()
          .nullable()
          .required("Fulfillment is required"),
      })}
    >
      {({
        values,
        touched,
        errors,
        dirty,
        isSubmitting,
        setFieldValue,
        handleChange,
        handleBlur,
        handleSubmit,
      }) => {
        return (
          <form onSubmit={handleSubmit}>
            <Box display="flex" justifyContent="flex-end">
              <HorizontalGroup margin="ml1">
                <Button
                  type="submit"
                  variant="outlined"
                  color="primary"
                  disabled={!dirty || isSubmitting}
                >
                  Save
                </Button>
                <Button onClick={() => onCancel(dirty)}>Cancel</Button>
              </HorizontalGroup>
            </Box>
            <Description
              featureType={values}
              touched={touched}
              errors={errors}
              setFieldValue={setFieldValue}
              onBlur={handleBlur}
              onChange={handleChange}
            />
            <GoodTypes
              featureType={values}
              goodTypes={goodTypes}
              touched={touched}
              errors={errors}
              setFieldValue={setFieldValue}
              onBlur={handleBlur}
              onChange={handleChange}
            />
            <Fulfillment
              featureType={values}
              touched={touched}
              errors={errors}
              onBlur={handleBlur}
              onChange={handleChange}
            />
          </form>
        );
      }}
    </Formik>
  );
};

/**
 * Description
 *
 * Functional component for the description input fields.
 *
 * @param {*} props
 */
const Description = ({
  featureType,
  touched,
  errors,
  setFieldValue,
  onBlur,
  onChange,
}) => {
  //  const { name, shortDescription, description } = featureType || {};
  const classes = useStyles();
  if (!featureType) return null;
  return (
    <Box>
      <Typography variant="h4" paragraph>
        Describe the Feature
      </Typography>
      <Box className={classes.inputs}>
        <TextField
          id="name"
          type="text"
          label="What is the name of the feature?"
          placeholder="Lift Pass"
          value={featureType.name}
          onBlur={onBlur}
          onChange={onChange}
          fullWidth
          required
          error={errors.name && touched.name}
          helperText={errors.name || " "}
        />
        <TextField
          id="shortDescription"
          type="text"
          label="Describe the feature"
          value={featureType.shortDescription}
          onBlur={onBlur}
          onChange={onChange}
          multiline
          rows="2"
          variant="outlined"
          fullWidth
          required
          error={errors.shortDescription && touched.shortDescription}
          helperText={errors.shortDescription || " "}
        />
        <TextField
          id="description"
          type="text"
          label="Describe the feature a little more."
          value={featureType.description}
          onBlur={onBlur}
          onChange={onChange}
          multiline
          rows="5"
          variant="outlined"
          fullWidth
          required
          error={errors.description && touched.description}
          helperText={errors.description}
        />
        <FormControlLabel
          label="Private"
          control={
            <Switch
              id="shared"
              checked={!featureType.shared}
              onChange={(e) => {
                setFieldValue("shared", !featureType.shared);
              }}
            />
          }
        />
      </Box>
    </Box>
  );
};

/**
 * GoodTypes
 *
 * Functional component for selection of good type assigned to the
 * feature type.
 *
 * @param {*} props
 */
const GoodTypes = ({
  featureType,
  goodTypes,
  touched,
  errors,
  setFieldValue,
  onBlur,
  onChange,
}) => {
  const classes = useStyles();

  if (!featureType) return null;

  return (
    <Box>
      <Typography variant="h4" paragraph>
        Product or Service
      </Typography>
      <Box className={classes.inputs}>
        <FormControl fullWidth>
          <InputLabel id="goodTypeLabel">
            What is the product or service being offered?
          </InputLabel>
          <Select
            inputProps={{
              name: "good.id",
              id: "good.id",
            }}
            labelId="goodTypeLabel"
            value={
              featureType.goodType && null != featureType.goodType.id
                ? featureType.goodType.id
                : -1
            }
            onChange={(e) => {
              setFieldValue(
                "goodType",
                goodTypes.find((goodType) => (goodType.id = e.target.value))
              );
            }}
            fullWidth
            required
          >
            <option value="-1" disabled>
              -- Select --
            </option>
            {goodTypes &&
              goodTypes.map((goodType) => (
                <option key={`gt${goodType.id}`} value={goodType.id}>
                  {goodType.name}
                </option>
              ))}
          </Select>
        </FormControl>
        <TextField
          id="quantity"
          inputProps={{ min: 0 }}
          type="number"
          label="How many are included?"
          value={featureType.quantity}
          min="0"
          onBlur={onBlur}
          onChange={onChange}
          fullWidth
          required
          error={errors.quantity && touched.quantity}
          helperText={errors.quantity || " "}
        />
      </Box>
    </Box>
  );
};

/**
 * Fulfillment
 *
 * Functional component for the fulfillment input fields.
 *
 * @param {*} props
 */
const Fulfillment = ({ featureType, touched, errors, onBlur, onChange }) => {
  const classes = useStyles();

  if (!featureType) return null;

  return (
    <Box>
      <Typography varaint="h4" paragraph>
        Fulfillment Process
      </Typography>
      <Box className={classes.inputs}>
        <TextField
          id="fulfillment"
          type="text"
          label="Describe how customers access the product or service."
          value={featureType.fulfillment}
          onBlur={onBlur}
          onChange={onChange}
          multiline
          rows="7"
          variant="outlined"
          fullWidth
          error={errors.fulfillment && touched.fulfillment}
          helperText={errors.fulfillment || " "}
        />
      </Box>
    </Box>
  );
};

/*
 **  Styles
 */
const useStyles = makeStyles((theme) => ({
  inputs: { paddingLeft: theme.spacing(2), marginBottom: theme.spacing(2) },
}));

export default Edit;
