import React, { Component } from "react";
import SectionRouterHelper from "../SectionRouterHelper";
import _SectionSubRouter from "./_SubSectionRouter";

import Finance from "./Finance/Finance";

import Tours from "./Goods/Tours";

export let routes = {
  root: "/app/reports",
  tree: {
    Finance: {
      label: "Finance",
      path: "/app/reports/finance/",
      component: Finance,
      children: [
        {
          path: "/app/reports/finance/:etc",
          component: Finance,
        },
      ],
    },
    Reports: {
      label: "Tours",
      path: "/app/reports/tours/",
      component: Tours,
      children: [],
    },
  },
};

export class _SectionRouter extends Component {
  render() {
    //
    //  Create the section router.
    //
    return (
      <SectionRouterHelper
        routes={routes}
        subSectionRouter={_SectionSubRouter}
      />
    );
  }
}
