/********************************************************************
 *
 * Summary.jsx
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2020 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

import React from "react";
import PropTypes from "prop-types";
import { Typography, Box, Link, makeStyles } from "@material-ui/core";
import { Phone, Email, Notes } from "@material-ui/icons";

/**
 * Summary
 *
 * Displays information about a customer.
 *
 * @param {*} props
 */
const Summary = ({ customer }) => {
  const classes = styles();

  return !customer ? (
    "No customer selected"
  ) : (
    <>
      <Typography variant="h6" paragraph>
        Contact Details
      </Typography>
      <Box className={classes.content}>
        {customer.email && (
          <Box display="flex" className={classes.subContent}>
            <Email color="disabled" className={classes.icon} fontSize="small" />
            <Link href={`mailto:${customer.email}`} variant="body2">
              {customer.email}
            </Link>
          </Box>
        )}
        {customer.phone && (
          <Box display="flex" className={classes.subContent}>
            <Phone color="disabled" className={classes.icon} fontSize="small" />
            <Typography variant="body2">{customer.phone}</Typography>
          </Box>
        )}
        {customer.comments && (
          <Box display="flex" className={classes.subContent}>
            <Notes color="disabled" className={classes.icon} fontSize="small" />
            <Typography variant="body2">{customer.comments}</Typography>
          </Box>
        )}
      </Box>
    </>
  );
};

/*
 **  Styles
 */
const styles = makeStyles((theme) => ({
  icon: { width: "60px", textAlign: "center", marginRight: theme.spacing(1) },
  content: { paddingLeft: theme.spacing(1), paddingBottom: theme.spacing(1) },
  subContent: { paddingBottom: theme.spacing(2) },
}));

/*
 **  PropTypes
 */
Summary.propTypes = {
  customer: PropTypes.object,
};

export default Summary;
