/********************************************************************
 *
 * Receipts.jsx
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2020 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  InputAdornment,
  Select,
  TextField,
  MenuItem,
  makeStyles,
} from "@material-ui/core";
import { AttachMoney } from "@material-ui/icons";
import { Formik } from "formik";
import * as yup from "yup";
import {
  Elements,
  ElementsConsumer,
  CardElement,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { useApp, useAPI } from "../../../../providers/AppProvider";

const PMTTYPE_CREDIT = 0;
const PMTTYPE_CASH = 1;
const PMTTYPE_CHEQ = 2;
const PMTTYPE_ETRANS = 3;
//const PMTTYPE_REFUND_CC = 4;

const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      color: "#484848",
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
      fontSmoothing: "antialiased",
      fontSize: "16px",
      "::placeholder": {
        color: "#484848",
      },
    },
    invalid: {
      color: "#484848",
      iconColor: "#999999",
    },
  },
};

/**
 * ReceiptsWrapper
 *
 * Wrapper component to initialize Stripe context
 *
 * @param {*} param0
 */
const ReceiptsWrapper = ({ order, onUpdated }) => {
  return (
    <Elements stripe={loadStripe(process.env.REACT_APP_STRIPE_PK)}>
      <ElementsConsumer>
        {({ stripe, elements }) => (
          <Receipts
            order={order}
            stripe={stripe}
            elements={elements}
            onUpdated={onUpdated}
          />
        )}
      </ElementsConsumer>
    </Elements>
  );
};

/**
 * Receipts
 *
 * Component that displays and processes receipts for orders. Stripe
 * requires that this component be wrapped in their continer to inject
 * their magic.
 */
const Receipts = ({ stripe, elements, order, onUpdated }) => {
  const [receiptTypes, setReceiptTypes] = useState(null);
  const [receiptTypeIdx, setReceiptTypeIdx] = useState(0);
  const [ref, setRef] = useState(null);
  const [amount, setAmount] = useState(null);
  const app = useApp();
  const api = useAPI();

  useEffect(() => {
    fetchReceiptTypes();
  }, []);

  ///////////////////////////////////////////////////////////////////
  //
  //  Utility Methods
  //
  ///////////////////////////////////////////////////////////////////

  /**
   * FetchReceiptTypes
   *
   * Calls the API to initialize the types of receipts
   */
  const fetchReceiptTypes = () => {
    //
    //  Status message
    //
    const notifyHandle = app.notify("Loading Receipts");

    api
      .fetch(`/api/receiptTypes`)
      .then(({ payload: receiptTypes }) => {
        setReceiptTypes(receiptTypes);
        setAmount(parseFloat(order.totals.due).toFixed(2));
        app.endNotify(notifyHandle);
      })
      .catch((error) => {
        app.error({ error });
        setReceiptTypes(null);
        setAmount(null);
      });
  };

  /**
   * ProcessReceipt
   *
   * Processes the payment receipt
   *
   * @param {*} order
   * @param {*} receiptType
   * @param {*} amount
   * @param {*} token
   */
  const processReceipt = (order, receiptType, amount, token = null) => {
    return new Promise((resolve, reject) => {
      api
        .create(`/api/orders/order/${order.id}/receipt`, {
          receiptType,
          amount,
          token,
          application: "admin",
        })
        .then(({ payload: order }) => {
          resolve(order);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  /**
   * GetToken
   *
   * Gets a receipt token based upon the receipt type
   */
  const getToken = (receipt) => {
    return new Promise((resolve, reject) => {
      switch (receipt.type.id) {
        case PMTTYPE_CREDIT:
          //
          //  Defer to getStripeToken.
          //
          getStripeToken()
            .then((token) => {
              resolve(token);
            })
            .catch((error) => {
              reject(error);
            });
          break;
        case PMTTYPE_CHEQ:
        case PMTTYPE_ETRANS:
          resolve(receipt.reference);
          break;
        default:
          resolve(null);
      }
    });
  };

  /**
   * GetStripeToken
   *
   * Requests an auth token from Stripe and returns the id.
   */
  const getStripeToken = () => {
    let card = null;

    return new Promise((resolve, reject) => {
      //
      // Ensure Stripe.js has loaded
      //
      if (!stripe || !elements)
        reject(new Error("Stripe has not initialized."));

      card = elements.getElement(CardElement);

      stripe
        .createToken(card)
        .then((result) => {
          if (result.error) throw result.error;

          resolve(result.token.id);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  ///////////////////////////////////////////////////////////////////
  //
  //  Event Handlers
  //
  ///////////////////////////////////////////////////////////////////

  const handleStripeCardElementChange = (event) => {
    if (event.error) app.error({ error: event.error });
  };

  const onSubmit = (event) => {
    event.preventDefault();

    getToken()
      .then((token) => {
        return processReceipt(
          order,
          receiptTypes[parseInt(receiptTypeIdx)],
          amount,
          token
        );
      })
      .then((order) => {
        onUpdated(order);
      })
      .catch((error) => {
        app.error({ error });
      });
  };

  const onSave = (receipt) => {
    getToken(receipt)
      .then((token) => {
        return processReceipt(order, receipt.type, receipt.amount, token);
      })
      .then((order) => {
        onUpdated(order);
      })
      .catch((error) => {
        app.error({ error });
      });
  };

  ///////////////////////////////////////////////////////////////////
  //
  //  Lifecycle methods
  //
  ///////////////////////////////////////////////////////////////////

  return (
    <FormBody
      amount={parseFloat(order.totals.due).toFixed(2)}
      onSave={onSave}
    />
  );
  return (
    <form
      id="receiptForm"
      className={`needs-validation text-left collapse ${
        order.totals.due > 0 && "show"
      }`}
      onSubmit={onSubmit}
    >
      <div className="form-group">
        <label htmlFor="receiptType">Payment Type</label>
        {!receiptTypes ? (
          "Waiting..."
        ) : (
          <select
            id="receiptType"
            className="form-control"
            defaultValue={receiptTypeIdx}
            onChange={(e) => {
              setReceiptTypeIdx(e.target.value);
            }}
          >
            {receiptTypes.map((receiptType, index) => (
              <option key={receiptType.id} value={index}>
                {receiptType.name}
              </option>
            ))}
          </select>
        )}
      </div>
      <div
        className={`form-group ${
          [PMTTYPE_CREDIT, PMTTYPE_CASH].includes(parseInt(receiptTypeIdx))
            ? "d-none"
            : ""
        }`}
      >
        <label htmlFor="ref">
          {parseInt(receiptTypeIdx) === PMTTYPE_CHEQ
            ? "Cheque Number"
            : "Reference Number"}
        </label>
        <input
          className={`form-control`}
          id="ref"
          type="text"
          onChange={(e) => {
            setRef(e.target.value);
          }}
          placeholder={
            parseInt(receiptTypeIdx) === PMTTYPE_CHEQ
              ? "Cheque #"
              : "Reference #"
          }
        />
      </div>
      <div
        className={`form-group ${
          [PMTTYPE_CREDIT].includes(parseInt(receiptTypeIdx)) ? "" : "d-none"
        }`}
      >
        <label htmlFor="card-element">Credit Card Details</label>
        <CardElement
          id="card-element"
          options={CARD_ELEMENT_OPTIONS}
          className="stripe_card"
          onChange={handleStripeCardElementChange}
        />
        <p id="card-errors" className="error" role="alert"></p>
      </div>
      <div className="form-group">
        <label htmlFor="subject">Amount</label>

        <div className="input-group mb-3">
          <div className="input-group-prepend">
            <span className="input-group-text">$</span>
          </div>
          <input
            className="form-control text-right"
            id="receipt"
            type="text"
            onBlur={(e) => {
              setAmount(parseFloat(e.target.value) * 100);
            }}
            defaultValue={amount && (parseFloat(amount) / 100).toFixed(2)}
          />
        </div>
      </div>
      <div>
        <button
          type="submit"
          className="btn btn-primary"
          style={{ width: "100px" }}
          disabled={
            !order.customer ||
            !order.customer.id ||
            (false && parseInt(receiptTypeIdx) === PMTTYPE_CREDIT && !stripe)
          }
        ></button>
      </div>
    </form>
  );
};

/**
 * FormBody
 *
 * @param {*} props
 */
const FormBody = ({ amount, onSave }) => {
  const [receiptTypes, setReceiptTypes] = useState(null);
  const app = useApp();
  const api = useAPI();

  useEffect(() => {
    fetchReceiptTypes();
  }, []);

  ///////////////////////////////////////////////////////////////////
  //
  //  Utility Methods
  //
  ///////////////////////////////////////////////////////////////////

  /**
   * FetchReceiptTypes
   *
   * Calls the API to initialize the types of receipts
   */
  const fetchReceiptTypes = () => {
    api
      .fetch(`/api/receiptTypes`)
      .then(({ payload: receiptTypes }) => {
        setReceiptTypes(receiptTypes);
      })
      .catch((error) => {
        app.error({ error });
        setReceiptTypes(null);
      });
  };

  ///////////////////////////////////////////////////////////////////
  //
  //  Event Handlers
  //
  ///////////////////////////////////////////////////////////////////

  if (!receiptTypes) return null;

  return (
    <Formik
      initialValues={{
        type: receiptTypes.find(
          (receiptType) => receiptType.id == PMTTYPE_CREDIT
        ),
        reference: "",
        cardElement: null,
        ccComplete: false,
        amount: parseFloat(amount / 100).toFixed(2),
      }}
      enableReinitialize={true}
      onSubmit={(receipt) => {
        receipt.amount = receipt.amount * 100;
        onSave(receipt);
      }}
      validationSchema={yup.object().shape({
        ccComplete: yup.boolean().when("type", (type, schema) => {
          return type.id == PMTTYPE_CREDIT
            ? schema.oneOf([true], "The credit card details are not complete.")
            : schema;
        }),
        amount: yup.number().positive().required("Amount required"),
      })}
    >
      {({
        values,
        touched,
        errors,
        dirty,
        isSubmitting,
        isValid,
        setFieldValue,
        handleChange,
        handleBlur,
        handleSubmit,
      }) => {
        return (
          <form onSubmit={handleSubmit}>
            <ReceiptForm
              receipt={values}
              receiptTypes={receiptTypes}
              touched={touched}
              errors={errors}
              setFieldValue={setFieldValue}
              onBlur={handleBlur}
              onChange={handleChange}
            />
            <Button
              type="submit"
              variant="outlined"
              color="primary"
              disabled={!isValid || isSubmitting}
            >
              Apply
            </Button>
          </form>
        );
      }}
    </Formik>
  );
};

const ReceiptForm = ({
  receipt,
  receiptTypes,
  touched,
  errors,
  setFieldValue,
  onBlur,
  onChange,
}) => {
  const [stripeError, setStripeError] = useState(null);
  const classes = useStyles();
  const app = useApp();

  ///////////////////////////////////////////////////////////////////
  //
  //  Event Handlers
  //
  ///////////////////////////////////////////////////////////////////

  const handleStripeCardElementChange = (event) => {
    const { complete, error } = event;

    if (error) setStripeError(error.message);

    return complete;
  };

  return (
    <Box>
      <FormControl fullWidth>
        <InputLabel id="type-label" shrink>
          Payment Type
        </InputLabel>
        <Select
          inputProps={{
            name: "type",
            id: "type",
          }}
          labelId="type-label"
          onChange={(e) => {
            if (e.target.value == PMTTYPE_CREDIT)
              setFieldValue("reference", null);

            setFieldValue(
              "type",
              receiptTypes.find(
                (receiptType) => receiptType.id == e.target.value
              )
            );
          }}
          value={receipt.type.id}
        >
          {receiptTypes &&
            receiptTypes.map((receiptType) => (
              <MenuItem key={receiptType.id} value={receiptType.id}>
                {receiptType.name}
              </MenuItem>
            ))}
        </Select>
        <p></p>
      </FormControl>
      {receipt.type.id == PMTTYPE_CREDIT ? (
        <>
          <CardElement
            id="card-element"
            options={CARD_ELEMENT_OPTIONS}
            className={classes.stripeCard}
            onChange={(e) =>
              setFieldValue("ccComplete", handleStripeCardElementChange(e))
            }
          />
          <p
            className="MuiFormHelperText-root MuiFormHelperText-filled Mui-required"
            role="alert"
          >
            <span>
              &#8203;
              {errors.ccComplete} {stripeError}
            </span>
          </p>
        </>
      ) : (
        receipt.type.id != PMTTYPE_CASH && (
          <TextField
            id="reference"
            label={
              receipt.type && receipt.type.id === PMTTYPE_CHEQ
                ? "Cheque Number"
                : "Reference Number"
            }
            placeholder={
              receipt.type && receipt.type.id === PMTTYPE_CHEQ
                ? "Cheque #"
                : "Reference #"
            }
            value={receipt.reference}
            onBlur={onBlur}
            onChange={onChange}
            fullWidth
            error={errors.reference && touched.reference}
            helperText={errors.reference || " "}
          />
        )
      )}
      <TextField
        id="amount"
        type="number"
        label="Amount"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <AttachMoney />
            </InputAdornment>
          ),
        }}
        value={receipt.amount}
        onBlur={onBlur}
        onChange={onChange}
        fullWidth
        required
        error={errors.amount && touched.amount}
        helperText={errors.amount || " "}
      />
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  stripeCard: {
    alignItems: "center",
    border: "1px solid #ced4da",
    borderRadius: "0.25rem",
    height: "calc(1.5em + 0.75rem + 2px)",
    padding: "7px",
  },
}));

export default ReceiptsWrapper;

/*
 **  PropTypes
 */
ReceiptsWrapper.propTypes = {
  order: PropTypes.object.isRequired,
  onUpdated: PropTypes.func.isRequired,
};
