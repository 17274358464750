/********************************************************************
 *
 * /Catalog/Products/Features/Identity/Summary.jsx
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2020 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

import React from "react";
import { Box, Typography, makeStyles } from "@material-ui/core";
import { VerticalGroup, Property } from "../../../../lib";

/**
 * Summary
 *
 * Renders a feature type.
 *
 */
const Summary = ({ featureType }) => {
  const { shortDescription, description, fulfillment } = featureType || {};
  const classes = useStyles();

  return (
    <>
      <VerticalGroup>
        <Property title="Short Description" data={shortDescription} />
        <Property title="Full Description" data={description} />
      </VerticalGroup>
      <Box>
        <Typography className={classes.paperHeader}>Fulfillment</Typography>
        {fulfillment ? (
          <Typography
            className={classes.paperContent}
            dangerouslySetInnerHTML={{ __html: fulfillment }}
          ></Typography>
        ) : (
          "No fulfillment information available."
        )}
      </Box>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  paperHeader: { fontSize: "0.85em", fontWeight: 600 },
  paperContent: { fontSize: "0.85em", paddingLeft: theme.spacing(2) },
}));

export default Summary;
