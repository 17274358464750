/********************************************************************
 *
 * SysClientName.jsx
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2019 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

const clients = [
  "Invalid",
  "Admin App",
  "Admin API",
  "Website",
  "Public API",
  "Analytics API",
  "CDN Server",
  "Auth Server",
  "SYC Racing Website",
  "Crew App",
  "OpenID Connect API",
];

const SysClientName = ({ id }) => {
  return clients[id];
};

export default SysClientName;
