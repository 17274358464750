import React from "react";
import SectionRouterHelper from "../SectionRouterHelper";
import _SectionSubRouter from "./_SubSectionRouter";

import Employees from "./Employees";
import Employee from "./Employees/Employee";

import Assets from "./Assets/Assets";
import Asset from "./Assets/Asset";

import Configuration from "./Configuration";

import Sandbox from "./Sandbox/Sandbox";

import Tools from "./Tools/Tools";

export let routes = {
  root: "/app/admin",
  tree: {
    Staff: {
      label: "Staff",
      path: "/app/admin/employees/",
      component: Employees,
      children: [
        {
          path: "/app/admin/employee/:id",
          component: Employee,
        },
        {
          path: "/app/admin/employee/",
          component: Employee,
        },
      ],
    },
    Assets: {
      label: "Assets",
      path: "/app/admin/assets/",
      component: Assets,
      children: [
        {
          path: "/app/admin/asset/:id",
          component: Asset,
        },
        {
          path: "/app/admin/asset/",
          component: Asset,
        },
      ],
    },
    Configuration: {
      label: "Settings",
      path: "/app/admin/config/",
      component: Configuration,
      children: [
        {
          path: "/app/admin/config/orders",
          component: Configuration,
        },
        {
          path: "/app/admin/config/tours",
          component: Configuration,
        },
      ],
    },
    Tools: {
      label: "Tools",
      path: "/app/admin/tools/",
      component: Tools,
      children: [],
    },
    Sandbox: {
      label: "Sandbox",
      path: "/app/admin/sandbox/",
      component: Sandbox,
      children: [],
    },
  },
};

const _SectionRouter = () => (
  <SectionRouterHelper routes={routes} subSectionRouter={_SectionSubRouter} />
);

export { _SectionRouter };
