/********************************************************************
 *
 * Tools.jsx
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2019 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

import React, { useState } from "react";
import { Main, WidgetFrame } from "../../lib";
import { useApp, useAPI } from "../../../providers/AppProvider";

/**
 * Tools
 *
 * Page that contains tools used for diagnistics
 */
const Tools = () => {
  const [sku, setSku] = useState(null);
  const [skuData, setSkuData] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const app = useApp();
  const api = useAPI();

  /////////////////////////////////////////////////////////////////////
  //
  //  Utility methods
  //
  /////////////////////////////////////////////////////////////////////

  ///////////////////////////////////////////////////////////////////////
  //
  //  Event Handlers
  //
  ///////////////////////////////////////////////////////////////////////

  /**
   * onDecodeSKU
   *
   * Fired when a configuration value is changed.
   *
   * @param {*} e
   */
  const onDecodeSKU = (e) => {
    e.preventDefault();

    api
      .fetch(`/api/skus/sku/${sku}`)
      .then(({ payload: skuData }) => {
        setSkuData(skuData);
        setLoaded(true);
      })
      .catch((error) => {
        app.error({ error });
        setLoaded(true);
      });
  };

  ///////////////////////////////////////////////////////////////////////
  //
  //  Lifecycle methods
  //
  ///////////////////////////////////////////////////////////////////////
  return (
    <Main
      header={{
        title: "Tools",
      }}
      loaded={true}
    >
      {true && (
        <WidgetFrame>
          <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
            <div className="page-title">Diagnostic Tools</div>
            <div className="btn-toolbar mb-2 mb-md-0" />
          </div>
          <form className="needs-validation row" onSubmit={onDecodeSKU}>
            <label className="" htmlFor="sku">
              SKU
            </label>
            <div className="col">
              <input
                id="sku"
                className="form-control"
                type="number"
                onChange={(e) => {
                  setSku(e.target.value);
                }}
              />
              <button className="btn btn-primary mt-2" type="submit">
                Decode
              </button>
            </div>
            <div className="col">
              <dl>
                <dt>SKU</dt>
                <dd>{skuData && sku}</dd>
                <dt>Rate Class</dt>
                <dd>{skuData && skuData.rateClass.id}</dd>
                <dt>Product</dt>
                <dd>{skuData && skuData.product.id}</dd>
                <dt>Departure</dt>
                <dd>
                  {skuData && skuData.departure ? skuData.departure.id : "-"}
                </dd>
                <dt>User</dt>
                <dd>{skuData && skuData.user.id}</dd>
              </dl>
            </div>
          </form>
          <hr />
        </WidgetFrame>
      )}
    </Main>
  );
};

export default Tools;
