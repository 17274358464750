/********************************************************************
 *
 * TourStatus.jsx
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2019 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

import React from "react";
import PropTypes from "prop-types";
import { useApp, useAPI } from "../../../../providers/AppProvider";

/**
 * TourStatus
 *
 * Renders the status of a tour
 *
 */
const TourStatus = ({ tour, onTourUpdated }) => {
  const app = useApp();
  const api = useAPI();

  /////////////////////////////////////////////////////////////////////
  //
  //  Utility Methods
  //
  /////////////////////////////////////////////////////////////////////

  /////////////////////////////////////////////////////////////////////
  //
  //  Event Handlers
  //
  /////////////////////////////////////////////////////////////////////

  /**
   * OnChangeStatus
   *
   * @param {*} event
   */
  const onChangeStatus = (event) => {
    let status = event.target.value;

    event.preventDefault();

    const notifyHandle = app.notify("Updating Tour Status");

    api
      .update(`/api/tours/tour/${tour.id}/status/`, {
        status: status,
      })
      .then((envelope) => {
        onTourUpdated && onTourUpdated({ tour: envelope.payload });
        app.endNotify(notifyHandle);
      })
      .catch((error) => {
        app.error({ error });
      });
  };

  ///////////////////////////////////////////////////////////////////////
  //
  //  Lifecycle Methods
  //
  ///////////////////////////////////////////////////////////////////////

  let btnClass;

  if (!tour || !tour.id) return "";

  switch (tour.status.id) {
    case 0:
      btnClass = "success";
      break;
    case 1:
      btnClass = "primary";
      break;
    case 2:
      btnClass = "secondary";
      break;
    default:
      btnClass = "danger";
  }

  return (
    <div className="dropdown" style={{ display: "inline-block" }}>
      <button
        className={`btn dropdown-toggle ${btnClass}`}
        type="button"
        id="dropdownMenuButton"
        data-toggle="dropdown"
      >
        {tour.status.name.toUpperCase()}
      </button>
      <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
        <button className="dropdown-item" value="0" onClick={onChangeStatus}>
          Open
        </button>
        <button className="dropdown-item" value="1" onClick={onChangeStatus}>
          Departed
        </button>
        <button className="dropdown-item" value="2" onClick={onChangeStatus}>
          Closed
        </button>
        <button className="dropdown-item" value="3" onClick={onChangeStatus}>
          Cancelled
        </button>
      </div>
    </div>
  );
};

TourStatus.propTypes = {
  tour: PropTypes.object,
  onTourUpdated: PropTypes.func.isRequired,
};

export default TourStatus;
