/********************************************************************
 *
 * Section.jsx
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2020 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

import React from "react";
import { Box, Typography, Divider, makeStyles } from "@material-ui/core";

const Section = ({ icon, title, children }) => {
  const classes = styles();

  return (
    <section>
      <Box className={classes.header} display="flex">
        <span className={classes.icon}>{icon}</span>
        <Typography className={classes.title} variant="h2">
          {title}
        </Typography>
      </Box>
      <Divider />
      <div className={classes.content}>{children}</div>
    </section>
  );
};

/*
 **  Styles
 */
const styles = makeStyles((theme) => ({
  header: { paddingBottom: theme.spacing(1) },
  title: {
    height: "20px",
    fontSize: "20px",
  },
  icon: {
    display: "none",
    lineHeight: "20px",
    [theme.breakpoints.up("sm")]: {
      display: "block",
      paddingRight: theme.spacing(1),
    },
    "& svg": { width: "20px", height: "auto" },
  },
  content: { paddingTop: theme.spacing(3), paddingBottom: theme.spacing(3) },
}));

export default Section;
