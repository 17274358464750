/********************************************************************
 *
 * /Catalog/Products/Features/Identity/Header.jsx
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2020 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

import React from "react";
import { Box, Avatar, Chip, Typography, makeStyles } from "@material-ui/core";
import { PersonAddDisabled, ErrorOutline } from "@material-ui/icons";
import { TipChip, CardContentHeader, HorizontalGroup } from "../../../../lib";

/**
 * Header
 *
 * Renders a feature type.
 *
 */
const Header = ({ featureType }) => {
  const classes = useStyles();

  const goodTypeClassPicker = [classes.tour, classes.inventory, classes.other];

  return (
    <CardContentHeader
      avatar={
        !featureType.goodType ? (
          <Avatar className={`${classes.avatar} ${classes.alert}`}>
            <ErrorOutline />
          </Avatar>
        ) : (
          <Avatar
            className={`${classes.avatar} ${
              goodTypeClassPicker[featureType.goodType.source.id]
            }`}
          >
            {featureType.goodType.source.name}
          </Avatar>
        )
      }
      content={
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          style={{ height: "100%" }}
        >
          <Box flexGrow={1}>
            <Typography variant="h4">{featureType.name}</Typography>
            <Typography variant="h5">
              by {featureType.goodType.supplier.name}
            </Typography>
          </Box>
          <HorizontalGroup margin={"ml1"}>
            {!!featureType.deleted && <Chip label="Deleted" />}
            {!featureType.shared && (
              <TipChip
                className={classes.private}
                tip={"Private use"}
                label={<PersonAddDisabled fontSize="small" />}
              />
            )}
            {!featureType.goodType ? (
              <Chip
                className={classes.alert}
                size="small"
                variant="default"
                label={"Feature is empty"}
              />
            ) : (
              <TipChip
                tip="Number of goods included per product"
                size="small"
                color="primary"
                variant="default"
                label={`Includes ${featureType.quantity} ${featureType.goodType.name}`}
              />
            )}
          </HorizontalGroup>
        </Box>
      }
    />
  );
};

const useStyles = makeStyles((theme) => ({
  avatar: { width: theme.spacing(8), height: theme.spacing(8) },
  private: {
    color: "darkRed",
    borderColor: "transparent",
  },
  alert: { backgroundColor: "red", color: "white" },
  tour: { backgroundColor: "green", fontSize: "0.7em" },
  inventory: { backgroundColor: "blue", fontSize: "0.7em" },
  other: { backgroundColor: "purple", fontSize: "0.7em" },
}));

export default Header;
