/********************************************************************
 *
 * ConfigSwitchSelect.jsx
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2019 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

import React from "react";
import PropTypes from "prop-types";
import {
  Box,
  Typography,
  Switch,
  Select,
  MenuItem,
  makeStyles,
} from "@material-ui/core";

const ConfigSwitchSelect = ({
  title,
  switchId,
  switchValue,
  valueId,
  value,
  options,
  metric,
  onChangeConfig,
}) => {
  const classes = styles();

  ///////////////////////////////////////////////////////////////////////
  //
  //  Lifecycle methods
  //
  ///////////////////////////////////////////////////////////////////////

  return (
    <div className={classes.container}>
      <Typography>{title}</Typography>
      <Box display="flex" alignItems="baseline">
        <Switch
          name={switchId}
          className={classes.switch}
          checked={switchValue}
          value="1"
          onChange={({ target }) => {
            onChangeConfig({
              key: target.name,
              value: target.checked ? 1 : 0,
            });
          }}
        />
        <Select
          name={valueId}
          labelId={`${valueId}-label`}
          className={classes.input}
          value={value}
          onChange={({ target }) => {
            onChangeConfig({ key: target.name, value: target.value });
          }}
        >
          {options.map((item, index) => (
            <MenuItem key={index} value={item.value}>
              {item.label}
            </MenuItem>
          ))}
        </Select>
        <Typography component="span">{metric}</Typography>
      </Box>
    </div>
  );
};

/*
 **  Styles
 */
const styles = makeStyles((theme) => ({
  container: { paddingBottom: theme.spacing(2) },
  switch: {
    marginRight: theme.spacing(2),
  },
  input: {
    marginRight: theme.spacing(2),
  },
}));

/*
 **  PropTypes
 */
ConfigSwitchSelect.propTypes = {
  title: PropTypes.string,
  switchId: PropTypes.string.isRequired,
  switchValue: PropTypes.any.isRequired,
  valueId: PropTypes.string.isRequired,
  value: PropTypes.any.isRequired,
  onChangeConfig: PropTypes.func.isRequired,
};

export default ConfigSwitchSelect;
