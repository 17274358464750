/********************************************************************
 *
 * AssetSummary.jsx
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2019 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

import React from "react";
import { Box, Avatar, Badge, Typography, makeStyles } from "@material-ui/core";
import { DirectionsBoat } from "@material-ui/icons";
import { CardContentHeader } from "../../lib";

/**
 * AssetSummaryHeader
 *
 * Renders a summary of the asset
 *
 * @param {*} param0
 */
const AssetSummaryHeader = ({ asset }) => {
  if (!asset) return "";

  return (
    <CardContentHeader
      avatar={
        <Avatar>
          <DirectionsBoat />
        </Avatar>
      }
      content={
        <Box>
          <Badge color="primary" badgeContent={asset.deleted ? "Deleted" : 0}>
            <Typography variant="h4">{asset.name}</Typography>
          </Badge>
        </Box>
      }
    />
  );
};

/**
 * AssetSummary
 *
 * Renders a summary of the asset
 *
 * @param {*} param0
 */
const AssetSummary = ({ asset }) => {
  if (!asset) return "";

  return null;
};

/*
 **  Styles
 */
const styles = makeStyles((theme) => ({
  avatar: { flex: "0 0 60px" },
  content: { flex: "1 1 auto" },
}));

export { AssetSummaryHeader, AssetSummary };
