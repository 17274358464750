/********************************************************************
 *
 * Customer.jsx
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2020 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

import React, { useState, useEffect } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { Avatar, Badge } from "@material-ui/core";
import { PersonOutlined } from "@material-ui/icons";
import { Main } from "../../lib";
import {
  SectionList,
  SectionItemGroup,
  SectionItem,
} from "../../lib/SideBar/Menu";
import Error404 from "../../Error/404";
import Identity from "./Identity/Identity";
import History from "./History/History";
import Communications from "./Communications/Communications";
import Communication from "./Communications/Communication";
import Account from "./Account/Account";

import { useApp, useAPI } from "../../../providers/AppProvider";

/**
 * Customer
 *
 * Renders Customer information
 */
const Customer = ({ match }) => {
  const [customer, setCustomer] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const app = useApp();
  const api = useAPI();

  const { id: customerId } = match.params;

  useEffect(() => {
    fetchCustomer(customerId);
  }, [customerId]);

  ///////////////////////////////////////////////////////////////////
  //
  //  Utility methods
  //
  ///////////////////////////////////////////////////////////////////

  const fetchCustomer = (id) => {
    //
    //  Status message
    //
    const notifyHandle = app.notify("Loading Customer");

    api
      .fetch(`/api/customers/customer/${id}?verbose`)
      .then(({ payload: customer }) => {
        setCustomer(customer);
        setLoaded(true);
        app.endNotify(notifyHandle);
      })
      .catch((error) => {
        app.error({ error });
        setCustomer(null);
        setLoaded(true);
      });
  };

  ///////////////////////////////////////////////////////////////////
  //
  //  Event Handlers
  //
  ///////////////////////////////////////////////////////////////////

  const onUpdate = () => {
    fetchCustomer(customerId);
  };

  return (
    <Main
      menu={{
        icon: <PersonOutlined />,
        title: "Customer Management",
        subtitle: "Information and Relationship",
        nav: <Menu customer={customer} />,
      }}
      header={{
        icon: !!customer && (
          <Avatar>
            {customer.fname && customer.fname[0]}{" "}
            {customer.lname && customer.lname[0]}
          </Avatar>
        ),
        title: customer ? (
          customer.isMinor ? (
            <Badge badgeContent="Minor" color="error">
              {`${customer.minorName ? customer.minorName : ""} Age: ${
                customer.age ? customer.age : "?"
              }`}
            </Badge>
          ) : (
            `${customer.fname} ${customer.lname}`
          )
        ) : (
          "New Customer"
        ),
      }}
      loaded={loaded}
    >
      <Router customer={customer} onUpdate={onUpdate} />
    </Main>
  );
};

/**
 * Renders the navigation controls for the functional areas of the
 * product type maagement page.
 *
 * @param {*} props
 */
const Menu = ({ customer }) => {
  const { id = 0 } = customer || {};

  return (
    <SectionList>
      <SectionItemGroup
        icon={<PersonOutlined />}
        name="Information"
        open={true}
      >
        <SectionItem to={`/app/crm/customer/${id}/details`}>
          Details
        </SectionItem>
        <SectionItem to={`/app/crm/customer/${id}/history`}>
          History
        </SectionItem>
      </SectionItemGroup>
      <SectionItemGroup icon={<PersonOutlined />} name="Sales" open={true}>
        <SectionItem to={`/app/crm/customer/${id}/communication`}>
          Communication
        </SectionItem>
        <SectionItem to={`/app/crm/customer/${id}/account`}>
          Account
        </SectionItem>
      </SectionItemGroup>
    </SectionList>
  );
};

/**
 * Renders the routing logic for the page
 *
 * @param {*} props
 */
const Router = ({ customer, onUpdate }) => {
  return (
    <Switch>
      {/* Identity */}
      <Route
        path="/app/crm/customer/:customerId/details"
        render={(props) => (
          <Identity {...props} customer={customer} onUpdate={onUpdate} />
        )}
        exact
      />
      {/* History */}
      <Route
        path="/app/crm/customer/:customerId/history"
        render={(props) => <History {...props} customer={customer} />}
      />
      {/* Communication */}
      <Route
        path="/app/crm/customer/:customerId/communication/:communicationId"
        render={(props) => <Communication {...props} />}
      />
      <Route
        path="/app/crm/customer/:customerId/communication"
        render={(props) => (
          <Communications {...props} customerId={customer.id} />
        )}
      />
      {/* Account */}
      <Route
        path="/app/crm/customer/:customerId/account"
        render={(props) => <Account {...props} customer={customer} />}
      />
      {/* Redirect */}
      <Route
        path="/app/crm/customer/:customerId"
        render={() => (
          <Redirect to={`/app/crm/customer/${customer.id}/details`} />
        )}
      />
      <Route path="/app/crm/" component={Error404} />
    </Switch>
  );
};

export default Customer;
