/********************************************************************
 *
 * RateClasses.jsx
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2020 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

import React, { useState, useEffect } from "react";
import { Button } from "@material-ui/core";
import { MonetizationOn } from "@material-ui/icons";
import { Main, CardList, LinkCard2, CardAction } from "../../lib";
import { RateClassSummaryHeader, RateClassSummary } from "./RateClassSummary";
import { useApp, useAPI } from "../../../providers/AppProvider";

const RateClasses = () => {
  const [rateClasses, setRateClasses] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const app = useApp();
  const api = useAPI();

  useEffect(() => {
    fetchRateClasses();
  }, []);

  /////////////////////////////////////////////////////////////////////
  //
  //  Utility methods
  //
  /////////////////////////////////////////////////////////////////////

  /**
   * FetchRateClasses
   *
   * Calls the api to fetch rate types
   */
  const fetchRateClasses = () => {
    const notifyHandle = app.notify("Loading Rate Classes");

    //
    //  Request rate types
    //
    api
      .fetch(`/api/rateclasses/?verbose`)
      .then(({ payload: rateClasses }) => {
        setRateClasses(rateClasses);
        setLoaded(true);
        app.endNotify(notifyHandle);
      })
      .catch((error) => {
        app.error({ error });
        setLoaded(true);
      });
  };

  ///////////////////////////////////////////////////////////////////////
  //
  //  Lifecycle Methods
  //
  ///////////////////////////////////////////////////////////////////////

  return (
    <Main
      header={{
        icon: <MonetizationOn />,
        title: `Rates for ${process.env.REACT_APP_ORG_NAME}`,
        bonus: (
          <Button
            variant="outlined"
            color="primary"
            href="/app/catalog/rateclass/"
          >
            Create a new rate
          </Button>
        ),
      }}
      loaded={loaded}
    >
      {rateClasses && (
        <CardList
          xs={12}
          sm={6}
          md={4}
          spacing={2}
          items={rateClasses}
          onRenderCard={({ item: rateClass }) => (
            <LinkCard2
              link={`/app/catalog/rateclass/${rateClass.id}`}
              header={<RateClassSummaryHeader rateClass={rateClass} />}
              content={<RateClassSummary rateClass={rateClass} />}
              actions={
                <>
                  <CardAction
                    to={`/app/catalog/rateclass/${rateClass.id}/partners/`}
                    label="Partners"
                  />
                  <CardAction
                    to={`/app/catalog/rateclass/${rateClass.id}`}
                    label="Rates"
                  />
                  <CardAction to="#" label="Delete" />
                </>
              }
            />
          )}
        />
      )}
    </Main>
  );
};

export default RateClasses;
