/********************************************************************
 *
 * RateClassSummary.jsx
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2020 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

import React from "react";
import { Avatar, Box, Chip, Typography, makeStyles } from "@material-ui/core";
import { AttachMoney } from "@material-ui/icons";
import { CardContentHeader } from "../../lib";

/**
 * RateClassSummaryHeader
 *
 * Renders a summary of the rate class
 *
 * @param {*} param0
 */
const RateClassSummaryHeader = ({ rateClass }) => {
  const classes = styles();

  if (!rateClass) return "";

  return (
    <CardContentHeader
      avatar={
        <Avatar className={classes.avatar}>
          <AttachMoney />
        </Avatar>
      }
      content={
        <Box display="flex" justifyContent="space-between">
          <Box>
            <Typography variant="h4">{rateClass.name}</Typography>
            <Typography variant="h6">{rateClass.description}</Typography>
          </Box>
          <Box>
            {rateClass.deleted && (
              <Chip className={classes.chip} color="primary" size="small">
                Deleted
              </Chip>
            )}

            <Chip
              className={classes.chip}
              color="primary"
              size="small"
              variant="outlined"
              label={
                rateClass.term &&
                rateClass.term.id === 0 &&
                rateClass.commission.rate === 0
                  ? "No Fee"
                  : rateClass.term.name
              }
            />
            <Chip
              className={classes.chip}
              color="primary"
              size="small"
              variant="outlined"
              label={
                rateClass.commission &&
                rateClass.commission.rate !== 0 &&
                rateClass.commission.display
              }
            />
          </Box>
        </Box>
      }
    />
  );
};

/**
 * RateClassSummary
 *
 * Renders a summary of the rate class
 *
 * @param {*} param0
 */
const RateClassSummary = ({ rateClass }) => {
  const classes = styles();

  if (!rateClass) return "";

  return (
    <Typography className={classes.description}>
      {rateClass.description}
    </Typography>
  );
};

const styles = makeStyles((theme) => ({
  description: { fontSize: "0.80em" },
  chip: { marginRight: theme.spacing(1) },
}));

export { RateClassSummaryHeader, RateClassSummary };
