/********************************************************************
 *
 * CRM/Customers/Communication.jsx
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2020 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

import React, { useState, useEffect } from "react";
import { Main } from "../../../lib";
import format from "../../../../utils/format";

import { useApp, useAPI } from "../../../../providers/AppProvider";

/**
 * Communication
 *
 */
const Communication = ({ match }) => {
  const [communication, setCommunication] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const app = useApp();
  const api = useAPI();

  useEffect(() => {
    const { communicationId } = match.params;

    fetchCommunication(communicationId);
  }, []);

  /**
   * FetchCommunication
   *
   * @param {*} communicationId
   */
  const fetchCommunication = (communicationId) => {
    api
      .fetch(`/api/communications/communication/${communicationId}`)
      .then(({ payload: communication }) => {
        setCommunication(communication);
        setLoaded(true);
      })
      .catch((error) => {
        app.error({ error });
        setCommunication(null);
        setLoaded(true);
      });
  };

  ///////////////////////////////////////////////////////////////////
  //
  //  Lifecycle methods
  //
  ///////////////////////////////////////////////////////////////////

  return (
    <Main className="container" loaded={loaded}>
      {communication && (
        <>
          <div className="h4">{communication.description}</div>
          <div>
            <b>From:</b>&nbsp;
            {communication.from}
          </div>
          <div>
            <b>To:</b>&nbsp;
            {communication.to}
          </div>
          <div>
            <b>Subject:</b>&nbsp;
            {communication.subject}
          </div>
          {communication.error && (
            <div className="text-danger">
              <b>Error:</b>&nbsp;
              {communication.error}
            </div>
          )}
          <div>
            <b>Sent:</b>&nbsp;
            {format.dayDateTime(communication.timestamp)}
          </div>
          <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3">
            <div>
              <div
                dangerouslySetInnerHTML={{
                  __html: `${communication.body}`,
                }}
              />
            </div>
          </div>
        </>
      )}
    </Main>
  );
};

export default Communication;
