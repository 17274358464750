/********************************************************************
 *
 * MainMenu.jsx
 *
 * Copyright 2020 Canadian Coastal Sailing, Inc. All rights reserved.
 *
 ********************************************************************/

import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { List, ListItem, Link, Menu, MenuItem } from "@material-ui/core";

/**
 * MainMenu
 *
 * Renders the side menu for smaller displays
 *
 * @param {*} props
 */
const MenuMenu = ({ items }) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <List className={classes.navigation} component="nav">
      {items.map(({ label, hide, path, children }, index) => (
        <React.Fragment key={index}>
          {!hide && (
            <ListItem key={index} component="div">
              <Link
                id={`menuItem${index}`}
                className={classes.link}
                variant="button"
                href={path}
                onClick={handleClick}
              >
                <div>{label}</div> {children && <ExpandMoreIcon />}
              </Link>
              {children && (
                <Menu
                  id={`menu${index}`}
                  anchorEl={anchorEl}
                  keepMounted
                  open={!!anchorEl && anchorEl.id == `menuItem${index}`}
                  anchorReference="anchorEl"
                  getContentAnchorEl={null}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                  }}
                  onClose={handleClose}
                >
                  {children.map(({ label, hide, path }, subindex) => {
                    if (hide) return null;
                    return (
                      <MenuItem
                        key={subindex}
                        className={`${classes.link} ${classes.submenu}`}
                      >
                        <Link color="textPrimary" href={path}>
                          {label}
                        </Link>
                      </MenuItem>
                    );
                  })}
                </Menu>
              )}
            </ListItem>
          )}
        </React.Fragment>
      ))}
    </List>
  );
};

/*
 **  Styles
 */
const useStyles = makeStyles((theme) => ({
  navigation: {
    display: "flex",
    border: "0",
    padding: "0 1.2em",
  },
  link: {
    display: "flex",
    alignItems: "center",
    whiteSpace: "nowrap",
    cursor: "pointer",
    color: theme.palette.text.primary,
    "&:hover": {
      color: theme.palette.primary.main,
    },
  },
  submenu: {
    "&:hover": {
      backgroundColor: theme.palette.background.default,
    },
  },
}));

/*
 **  PropTypes
 */
MenuMenu.propTypes = {
  items: PropTypes.array.isRequired,
};

MenuMenu.defaultProps = {};

export default MenuMenu;
