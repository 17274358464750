/********************************************************************
 *
 * /Catalog/Products/Identity/Edit.jsx
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2019 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

import React, { useState, useEffect } from "react";
import {
  Box,
  FormControl,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  Button,
  Typography,
  makeStyles,
} from "@material-ui/core";
import { Formik } from "formik";
import * as yup from "yup";
import { HorizontalGroup } from "../../../lib/Groups";
import ModalDialog, { DialogContent } from "../../../lib/ModalDialog";
import { useApp, useAPI } from "../../../../providers/AppProvider";

/**
 * IdentityEdit
 *
 * Component for adding a new, or editting the descriptive information
 * an exiting Product Type.
 *
 * Intended to be contained in either a modal or page component.
 *
 */
const IdentityEdit = ({ productType, show, onSaved, onCancelled }) => {
  const app = useApp();
  const api = useAPI();

  ///////////////////////////////////////////////////////////////////
  //
  //  Utility Methods
  //
  ///////////////////////////////////////////////////////////////////

  ///////////////////////////////////////////////////////////////////
  //
  //  Event Handlers
  //
  ///////////////////////////////////////////////////////////////////

  /**
   * OnSave
   *
   * Fired when the user saves the changes.
   *
   * Updates the product type and notifies the parent.
   *
   * @param {*} values
   */
  const onSave = (values) => {
    //
    //  Save
    //
    const notifyHandle = app.notify("Saving");

    api
      .update(
        `/api/producttypes/producttype/${values.id ? values.id : ""}`,
        values
      )
      .then(({ payload: productType }) => {
        onSaved && onSaved({ productType });
        app.endNotify(notifyHandle);
      })
      .catch((error) => {
        app.error({ error });
      });
  };

  /**
   * OnCancel
   *
   * Fired when the user aborts changes.
   *
   */
  const onCancel = (dirty) => {
    if (
      !dirty ||
      window.confirm(
        "You have unsaved changes. Are you sure you want to close this dialog and loose your changes?"
      )
    ) {
      onCancelled && onCancelled();
    }
  };

  ///////////////////////////////////////////////////////////////////
  //
  //  Lifecycle methods
  //
  ///////////////////////////////////////////////////////////////////

  return (
    <ModalDialog
      title="Manage Product Type"
      subtitle="Product Types form the descriptive template for all instances of the product"
      show={show}
    >
      <DialogContent>
        <FormBody
          productType={productType}
          onSave={onSave}
          onCancel={onCancel}
        />
      </DialogContent>
    </ModalDialog>
  );
};

/**
 * FormBody
 *
 * @param {*} props
 */
const FormBody = ({ productType, onCancel, onSave }) => (
  <Formik
    initialValues={productType}
    enableReinitialize={true}
    onSubmit={(values) => {
      onSave(values);
    }}
    validationSchema={yup.object().shape({
      name: yup.string().nullable().required("Name is required"),
      tagLine: yup.string().nullable().required("Tag line is required"),
      target: yup.string().required("Target is required"),
      shortDescription: yup
        .string()
        .nullable()
        .required("Short description is required"),
      description: yup.string().nullable().required("Description is required"),
      accountId: yup.number().required("Revenue accouont is required"),
    })}
  >
    {({
      values,
      touched,
      errors,
      dirty,
      isSubmitting,
      setFieldValue,
      handleChange,
      handleBlur,
      handleSubmit,
    }) => {
      return (
        <form onSubmit={handleSubmit}>
          <Box display="flex" justifyContent="flex-end">
            <HorizontalGroup margin="ml1">
              <Button
                type="submit"
                variant="outlined"
                color="primary"
                disabled={!dirty || isSubmitting}
              >
                Save
              </Button>
              <Button onClick={() => onCancel(dirty)}>Cancel</Button>
            </HorizontalGroup>
          </Box>
          <ProductTypeForm
            productType={values}
            touched={touched}
            errors={errors}
            setFieldValue={setFieldValue}
            onBlur={handleBlur}
            onChange={handleChange}
          />
        </form>
      );
    }}
  </Formik>
);

/**
 * ProductTypeForm
 *
 * Functional component to render the decriptive information of the
 * product type.
 *
 * @param {*} props
 */
const ProductTypeForm = ({
  productType,
  touched,
  errors,
  setFieldValue,
  onBlur,
  onChange,
}) => {
  const [accounts, setAccounts] = useState(null);
  const classes = useStyles();
  const app = useApp();
  const api = useAPI();

  useEffect(() => {
    fetchRevenueAccounts();
  }, []);

  ///////////////////////////////////////////////////////////////////
  //
  //  Utility Methods
  //
  ///////////////////////////////////////////////////////////////////

  const fetchRevenueAccounts = () => {
    //
    //  Status message
    //
    const notifyHandle = app.notify("Loading");

    api
      .fetch(`/api/accounts/type/6`)
      .then(({ payload: accounts }) => {
        setAccounts(accounts);
        app.endNotify(notifyHandle);
      })
      .catch((error) => {
        app.error({ error });
      });
  };

  ///////////////////////////////////////////////////////////////////
  //
  //  Event Handlers
  //
  ///////////////////////////////////////////////////////////////////

  return (
    <Box>
      <Typography variant="h4" paragraph>
        Describe the Product
      </Typography>
      <Box className={classes.inputs}>
        <TextField
          id="name"
          label="Name"
          value={productType.name}
          onBlur={onBlur}
          onChange={onChange}
          fullWidth
          required
          error={errors.name && touched.name}
          helperText={errors.name || " "}
        />
        <TextField
          id="tagLine"
          label="Tag Line"
          value={productType.tagLine}
          onBlur={onBlur}
          onChange={onChange}
          multiline
          rows="2"
          variant="outlined"
          fullWidth
          required
          error={errors.tagLine && touched.tagLine}
          helperText={errors.tagLine || " "}
        />
        <TextField
          id="target"
          label="Target"
          value={productType.target}
          onBlur={onBlur}
          onChange={onChange}
          multiline
          rows="2"
          variant="outlined"
          fullWidth
          required
          error={errors.target && touched.target}
          helperText={errors.target || " "}
        />
        <TextField
          id="shortDescription"
          label="Short Description"
          value={productType.shortDescription}
          onBlur={onBlur}
          onChange={onChange}
          multiline
          rows="2"
          variant="outlined"
          fullWidth
          required
          error={errors.shortDescription && touched.shortDescription}
          helperText={errors.shortDescription || " "}
          multiline
          rows="2"
        />
        <TextField
          id="description"
          className={classes.input}
          label="Description"
          value={productType.description}
          onBlur={onBlur}
          onChange={onChange}
          multiline
          rows="5"
          variant="outlined"
          fullWidth
          required
          error={errors.description && touched.description}
          helperText={errors.description || " "}
          multipline
          rows="5"
        />
        <FormControl fullWidth>
          <InputLabel id="timezone-label" shrink>
            What time zone is it in?
          </InputLabel>
          <Select
            inputProps={{
              name: "timezone",
              id: "timezone",
            }}
            labelId="timezone-label"
            onChange={(e) => {
              setFieldValue("timezone", e.target.value);
            }}
            value={productType.timezone}
          >
            <MenuItem value="UTC">No Timezone</MenuItem>
            <MenuItem value="America/Vancouver">Pacific</MenuItem>
          </Select>
        </FormControl>
        <FormControl fullWidth>
          <InputLabel id="account-label" shrink>
            What account does it use?
          </InputLabel>
          <Select
            inputProps={{
              name: "accountId",
              id: "accountId",
            }}
            labelId="account-label"
            value={productType.accountId ? productType.accountId : "-1"}
            onBlur={onBlur}
            onChange={(e) => {
              setFieldValue("accountId", e.target.value);
            }}
            error={errors.description && touched.description}
            helperText={errors.description || " "}
            required
          >
            <MenuItem value="-1" disabled>
              -- Select --
            </MenuItem>
            {accounts &&
              accounts.map((account) => (
                <MenuItem key={account.id} value={account.id}>
                  {account.name}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      </Box>
    </Box>
  );
};

/*
 **  Styles
 */
const useStyles = makeStyles((theme) => ({
  inputs: { paddingLeft: theme.spacing(2), marginBottom: theme.spacing(2) },
}));

export default IdentityEdit;
