/********************************************************************
 *
 * Partners.jsx
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2020 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

import React, { useState, useEffect } from "react";
import { Card } from "react-bootstrap";
import PartnerSummary from "../../../Sales/Partners/PartnerSummary";
import RateClasses from "./RateClasses";
import { useApp, useAPI } from "../../../../providers/AppProvider";

/**
 * Partners
 *
 * Renders a list of Partners able to sell the Product Type.
 *
 */
const Partners = ({ productType }) => {
  const [partners, setPartners] = useState(null);
  const [loaded, setLoaded] = useState(null);
  const app = useApp();
  const api = useAPI();

  useEffect(() => {
    fetchPartners();
  }, []);

  /////////////////////////////////////////////////////////////////////
  //
  //  Utility methods
  //
  /////////////////////////////////////////////////////////////////////

  /**
   * FetchPartners
   *
   * Calls the api to fetch the partners assigned to the product type
   *
   */
  const fetchPartners = () => {
    //
    //  Status message
    //
    const notifyHandle = app.notify("Loading Partners");

    api
      .fetch(`/api/productTypes/productType/${productType.id}/partners/`)
      .then(({ payload: partners }) => {
        setPartners(partners);
        setLoaded(true);
        app.endNotify(notifyHandle);
      })
      .catch((error) => {
        app.error({ error });
        setPartners(null);
        setLoaded(true);
      });
  };

  ///////////////////////////////////////////////////////////////////
  //
  //  Event Handlers
  //
  ///////////////////////////////////////////////////////////////////

  ///////////////////////////////////////////////////////////////////
  //
  //  Lifecycle methods
  //
  ///////////////////////////////////////////////////////////////////

  if (!loaded)
    return <div className="spinner-border text-secondary" role="status" />;

  return (
    <>
      {!partners && "No Partners Available"}
      {partners && (
        <div className="d-flex flex-row flex-wrap">
          {partners.map((partner) => (
            <React.Fragment key={partner.id}>
              {!partner.deleted && (
                <PartnerCard productType={productType} partner={partner} />
              )}
            </React.Fragment>
          ))}
        </div>
      )}
    </>
  );
};

/**
 * PartnerCard
 *
 * Renders a list of Partner summaries in card format
 *
 * @param {*} props
 */
const PartnerCard = ({ productType, partner }) => {
  return (
    <Card className="mb-4 mr-md-4 lgCard">
      <Card.Body>
        <PartnerSummary partner={partner} />
        <h6>Rate Classes</h6>
        <RateClasses productType={productType} partner={partner} />
      </Card.Body>
      <Card.Footer className="bg-transparent">
        <Card.Link type="button">Remove</Card.Link>
      </Card.Footer>
    </Card>
  );
};
export default Partners;
