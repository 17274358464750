/********************************************************************
 *
 * WeeklyCalendarHeader.jsx
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2019 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

import React from "react";
import PropTypes from "prop-types";
import { Typography, makeStyles } from "@material-ui/core";
import moment from "moment-timezone";

/**
 * WeeklyCalendarHeader
 *
 * @param {*} props
 */
const WeeklyCalendarHeader = ({ start }) => {
  const classes = styles();

  ///////////////////////////////////////////////////////////////////////
  //
  //  Lifecycle methods
  //
  ///////////////////////////////////////////////////////////////////////

  return (
    <div className={classes.header}>
      <div className={classes.headerPrefixContainer}>
        <Typography className={classes.timezone}>{start.zoneAbbr()}</Typography>
      </div>
      <div className={classes.headerBar}>
        <div className={classes.dowRowContainer}>
          <div className={classes.dowRow}>
            {[...Array(7).keys()].map((d, index) => {
              const day = start.clone().add(index, "day");
              const isToday = moment().isSame(day, "day");

              return (
                <div key={index} className={classes.dow}>
                  <h2 className={classes.dowCell}>
                    <Typography
                      className={`${classes.dowName} ${
                        isToday && classes.todayName
                      }`}
                    >
                      {day.format("ddd")}
                    </Typography>
                    <Typography
                      className={`${classes.dowNum} ${
                        isToday && classes.todayNum
                      }`}
                    >
                      {day.format("DD")}
                    </Typography>
                  </h2>
                </div>
              );
            })}
          </div>
        </div>
        <div className={classes.bannerRowContainer}>
          <div className={classes.bannerRow}>{/*Add Daily Info*/}</div>
        </div>
      </div>
    </div>
  );
};

/*
 **  Styles
 */
const styles = makeStyles((theme) => ({
  header: { display: "flex", flex: "none" },
  headerPrefixContainer: {
    display: "flex",
    flex: "none",
    justifyContent: "flex-end",
    minWidth: "49px",
    paddingRight: "1px",
    flexDirection: "column",
    whiteSpace: "nowrap",
  },
  timezone: {
    fontSize: "10px",
    height: "20px",
    lineHeight: "20px",
    marginLeft: "auto",
    maxWidth: "80px",
    minWidth: "40px",
    overflow: "hidden",
    paddingRight: "9px",
    textAlign: "right",
    color: "red",
  },
  headerBar: {
    display: "flex",
    flex: "1 1 auto",
    flexDirection: "column",
    overflow: "hidden",
    position: "relative",
  },
  dowRowContainer: {
    height: "84px",
    display: "flex",
    flex: "none",
    overflow: "hidden",
  },
  dowRow: {
    display: "flex",
    flex: "1 1 auto",
    overflow: "hidden",
    overflowX: "scroll",
  },
  dowPrefix: { width: "9px", minWidth: "9px" },
  dow: {
    overflow: "hidden",
    boxSizing: "border-box",
    display: "flex",
    flex: "1 0 auto",
  },
  dowCell: {
    fontWeight: 400,
    margin: 0,
    textAlign: "center",
    width: "100%",
  },
  dowName: {
    lineHeight: "32px",
    position: "relative",
    zIndex: "2",
    fontSize: "11px",
    fontWeight: 500,
    letterSpacing: "0.8px",
    marginLeft: 0,
    marginRight: 0,
    textIndent: "0.8px",
    textTransform: "uppercase",
  },
  todayName: {
    color: theme.palette.primary.main,
  },
  dowNum: {
    zIndex: "2",
    fontSize: "24px",
    letterSpacing: "0px",
    textIndent: "0px",
    fontWeight: 200,
    fontVariant: "tabular-nums",
    fontFeatureSettings: "'tnum' 1",
    borderRadius: "100%",
    lineHeight: "46px",
    height: "46px",
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: "-8px",
    width: "46px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  todayNum: {
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
  },
  bannerRowContainer: {
    display: "flex",
    position: "relative",
    fontSize: "24px",
    maxHeight: "3em",
    overflow: "hidden",
    overflowX: "scroll",
  },
  bannerRow: {
    borderLeft: "#dadce0 1px solid",
    borderRight: "1px solid transparent",
    position: "relative",
    flex: "1 0 auto",
    alignSelf: "flex-start",
    overflow: "hidden",
    marginLeft: "-1",
  },
}));

/*
 **  PropTypes
 */
WeeklyCalendarHeader.propTypes = {
  start: PropTypes.instanceOf(moment).isRequired,
};

export default WeeklyCalendarHeader;
