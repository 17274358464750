/********************************************************************
 *
 * /components/goods/goods/tour/edit.jsx
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2019 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

import React, { useState, useEffect } from "react";
import moment from "moment";
import {
  Box,
  Grid,
  FormControl,
  FormHelperText,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Button,
} from "@material-ui/core";
import { Formik } from "formik";
import * as yup from "yup";
import { HorizontalGroup } from "../../../lib/Groups";
import ModalDialog, { DialogContent } from "../../../lib/ModalDialog";
import DateTimePicker from "../../../lib/Inputs/DateTimePicker";
import format from "../../../../utils/format";
import { useApp, useAPI } from "../../../../providers/AppProvider";

/**
 * Edit
 *
 * Component for adding a new, or editting the descriptive information
 * an existing Tour.
 *
 */
const Edit = ({ tour, show, onSaved, onCancelled }) => {
  const app = useApp();
  const api = useAPI();

  ///////////////////////////////////////////////////////////////////
  //
  //  Event Handlers
  //
  ///////////////////////////////////////////////////////////////////

  /**
   * OnSave
   *
   * Fired when the user saves the changes.
   *
   * Updates the product type and notifies the parent.
   *
   * @param {*} values
   */
  const onSave = (values) => {
    //
    //  Save
    //
    const notifyHandle = app.notify("Saving");

    api
      .update(`/api/tours/tour/${values.id ? values.id : ""}`, values)
      .then(({ payload: tour }) => {
        onSaved && onSaved({ tour });
        app.endNotify(notifyHandle);
      })
      .catch((error) => {
        app.error({ error });
      });
  };

  /**
   * OnCancel
   *
   * Fired when the user aborts changes.
   *
   */
  const onCancel = (dirty) => {
    if (
      !dirty ||
      window.confirm(
        "You have unsaved changes. Are you sure you want to close this dialog and loose your changes?"
      )
    ) {
      onCancelled && onCancelled();
    }
  };

  ///////////////////////////////////////////////////////////////////
  //
  //  Lifecycle methods
  //
  ///////////////////////////////////////////////////////////////////

  return (
    <ModalDialog title="Manage Tour" subtitle="TBD" show={show}>
      <DialogContent>
        <FormBody tour={tour} onSave={onSave} onCancel={onCancel} />
      </DialogContent>
    </ModalDialog>
  );
};

/**
 * FormBody
 *
 * @param {*} props
 */
const FormBody = ({ tour, onCancel, onSave }) => (
  <Formik
    initialValues={tour}
    enableReinitialize={true}
    onSubmit={(values) => {
      onSave(values);
    }}
    validationSchema={yup.object().shape({
      start: yup.date().required("Start time is required"),
      acquire: yup
        .date()
        .max(yup.ref("start"), "Acquire time must be before start time")
        .required("Acquire time is required"),
      end: yup
        .date()
        .min(yup.ref("start"), ({ min }) => "End time must be after start time")
        .required("End time is required"),
      release: yup
        .date()
        .min(yup.ref("end"), "Release time must be after end time")
        .required("Release time is required"),
      capacity: yup.number().required("Capacity is required"),
    })}
  >
    {({
      values,
      touched,
      errors,
      dirty,
      isSubmitting,
      setFieldValue,
      handleChange,
      handleBlur,
      handleSubmit,
    }) => {
      return (
        <form onSubmit={handleSubmit}>
          <Box display="flex" justifyContent="flex-end">
            <HorizontalGroup margin="ml1">
              <Button
                type="submit"
                variant="outlined"
                color="primary"
                disabled={!dirty || isSubmitting}
              >
                Save
              </Button>
              <Button onClick={() => onCancel(dirty)}>Cancel</Button>
            </HorizontalGroup>
          </Box>
          <TourForm
            tour={values}
            touched={touched}
            errors={errors}
            setFieldValue={setFieldValue}
            onBlur={handleBlur}
            onChange={handleChange}
          />
        </form>
      );
    }}
  </Formik>
);

/**
 * TourForm
 *
 * @param {*} props
 */
const TourForm = ({
  tour,
  touched,
  errors,
  setFieldValue,
  onBlur,
  onChange,
}) => {
  const [assets, setAssets] = useState(null);
  const app = useApp();
  const api = useAPI();

  useEffect(() => {
    fetchAssets();
  }, []);

  ///////////////////////////////////////////////////////////////////
  //
  //  Utility Functions
  //
  ///////////////////////////////////////////////////////////////////

  /**
   * FetchAssetsForTour
   *
   * Initializes the list of available assets for the tour.
   */
  const fetchAssets = () => {
    api
      .fetch(`/api/tours/tour/${tour.id}/assets/`)
      .then(({ payload: assets }) => {
        setAssets(assets);
      })
      .catch((error) => {
        app.error({ error });
      });
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={6}>
        <DateTimePicker
          id="start"
          label="Depart"
          value={tour.start}
          tz={tour.tz}
          onBlur={onBlur}
          onChange={(dateTime) => {
            setFieldValue("start", format.toApiDateTime(dateTime));
            setFieldValue(
              "acquire",
              format.toApiDateTime(
                moment(dateTime).subtract(
                  process.env.REACT_APP_DEFAULT_TOUR_ASSET_PRETOUR_INTERVAL,
                  "minutes"
                )
              )
            );
          }}
          fullWidth
          required
          error={errors.start && touched.start}
          helperText={errors.start || " "}
        />
        <DateTimePicker
          id="acquire"
          label="Acquire"
          value={tour.acquire}
          tz={tour.tz}
          onBlur={onBlur}
          onChange={(dateTime) => {
            dateTime.isBefore(tour.start)
              ? setFieldValue("acquire", format.toApiDateTime(dateTime))
              : setFieldValue("acquire", format.toApiDateTime(tour.start));
          }}
          fullWidth
          required
          error={errors.acquire && touched.acquire}
          helperText={errors.acquire || " "}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <DateTimePicker
          id="end"
          label="Return"
          value={tour.end}
          tz={tour.tz}
          onBlur={onBlur}
          onChange={(dateTime) => {
            setFieldValue("end", format.toApiDateTime(dateTime));
            setFieldValue(
              "release",
              format.toApiDateTime(
                moment(dateTime).add(
                  process.env.REACT_APP_DEFAULT_TOUR_ASSET_POSTTOUR_INTERVAL,
                  "minutes"
                )
              )
            );
          }}
          fullWidth
          required
          error={errors.end && touched.end}
          helperText={errors.end || " "}
        />
        <DateTimePicker
          id="release"
          label="Release"
          value={tour.release}
          tz={tour.tz}
          onBlur={onBlur}
          onChange={(dateTime) => {
            dateTime.isAfter(tour.end)
              ? setFieldValue("release", format.toApiDateTime(dateTime))
              : setFieldValue("release", format.toApiDateTime(tour.end));
          }}
          fullWidth
          required
          error={errors.release && touched.release}
          helperText={errors.release || " "}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          id="capacity"
          type="number"
          label="Capacity"
          variant="outlined"
          value={tour.capacity}
          inputProps={{ min: 0 }}
          onBlur={onBlur}
          onChange={onChange}
          fullWidth
          error={errors.capacity && touched.capacity}
          helperText={errors.capacity || " "}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <FormControl fullWidth>
          <InputLabel id="asset-label" shrink>
            Asset
          </InputLabel>
          <Select
            inputProps={{
              name: "asset",
              id: "asset",
            }}
            labelId="asset-label"
            value={tour.asset ? tour.asset.id : null}
            onChange={(e) => {
              setFieldValue(
                "asset",
                assets.find((asset) => asset.id == e.target.value)
              );
            }}
          >
            <MenuItem value={null}>None</MenuItem>
            {!!assets &&
              assets.map((asset) => (
                <MenuItem key={asset.id} value={asset.id}>
                  {asset.name}
                </MenuItem>
              ))}
          </Select>
          <FormHelperText>Some important helper text</FormHelperText>
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <TextField
          id="notes"
          type="text"
          label="notes"
          value={tour.notes}
          onBlur={onBlur}
          onChange={onChange}
          multiline
          rows="2"
          variant="outlined"
          fullWidth
          error={errors.notes && touched.notes}
          helperText={errors.notes || " "}
        />
      </Grid>
    </Grid>
  );
};

export default Edit;
