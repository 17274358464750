/********************************************************************
 *
 * SideMenu.jsx
 *
 * Copyright 2020 Canadian Coastal Sailing, Inc. All rights reserved.
 *
 ********************************************************************/

import React from "react";
import PropTypes from "prop-types";
import {
  Drawer,
  List,
  ListItem,
  ListItemText,
  Divider,
  makeStyles,
} from "@material-ui/core";

/**
 * SideMenu
 *
 * Renders the side menu for smaller displays
 *
 * @param {*} props
 */
const SideMenu = ({ items, open, onClose, data }) => {
  const classes = useStyles();

  const list = (navItems) => (
    <div
      className={classes.list}
      role="presentation"
      onClick={onClose}
      onKeyDown={onClose}
    >
      <List>
        <Divider />
        {navItems &&
          navItems.map(({ label, hide, path, children }, index) => (
            <React.Fragment key={index}>
              {!hide && (
                <>
                  {children && children.length > 0 ? (
                    <>
                      {children.map(({ label, hide, path }, index) => (
                        <React.Fragment key={index}>
                          {!hide && (
                            <ListItem button component="a" href={path}>
                              <ListItemText primary={label} />
                            </ListItem>
                          )}
                        </React.Fragment>
                      ))}
                      <Divider />
                    </>
                  ) : (
                    <ListItem button component="a" href={path}>
                      <ListItemText primary={label} />
                    </ListItem>
                  )}
                </>
              )}
            </React.Fragment>
          ))}
      </List>
    </div>
  );

  return (
    <Drawer anchor={"left"} open={open} onClose={onClose}>
      {list(items)}
    </Drawer>
  );
};

/*
 **  Styles
 */
const useStyles = makeStyles({
  list: {
    width: 250,
  },
});

/*
 **  PropTypes
 */
SideMenu.propTypes = {
  items: PropTypes.array.isRequired,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  data: PropTypes.object,
};

SideMenu.defaultProps = {
  open: true,
};

export default SideMenu;
