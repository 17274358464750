/********************************************************************
 *
 * /components.sales/leads/notes.jsx
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2020 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

import React, { useState, useEffect } from "react";
import {
  Typography,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Chip,
  makeStyles,
} from "@material-ui/core";
import {
  Timeline,
  TimelineItem,
  TimelineSeparator,
  TimelineDot,
  TimelineConnector,
  TimelineContent,
} from "@material-ui/lab";
import { Comment, SpeakerNotes } from "@material-ui/icons";
import format from "../../../utils/format";
import { useApp, useAPI } from "../../../providers/AppProvider";

/**
 * Notes
 *
 * Lists notes for a lead
 *
 * @param {*} props
 */
const Notes = ({ notes }) => {
  const classes = useStyles();
  const app = useApp();
  const api = useAPI();

  if (!notes) return null;

  return (
    <Timeline>
      {notes.map((note, index) => (
        <TimelineItem
          classes={{
            missingOppositeContent: classes.missingOppositeContent,
          }}
        >
          <TimelineSeparator>
            {index === 0 ? (
              <TimelineDot color="primary">
                <Comment />
              </TimelineDot>
            ) : (
              <TimelineDot color="primary" variant="outlined">
                <Comment color="primary" />
              </TimelineDot>
            )}
            {index < notes.length - 1 && (
              <TimelineConnector classes={{ root: classes.connector }} />
            )}
          </TimelineSeparator>
          <TimelineContent>
            <Note note={note} isCustomer={index === 0} />
          </TimelineContent>
        </TimelineItem>
      ))}
    </Timeline>
  );
};

const Note = ({ note, isCustomer }) => {
  const classes = useStyles();
  return (
    <Card classes={{ root: classes.noteCard }}>
      <CardHeader
        title={
          <Chip
            label={`${note.user.fname} ${note.user.lname}`}
            size="small"
            color="primary"
            variant={isCustomer ? "outlined" : "default"}
          />
        }
      />
      <CardContent>
        <Typography variant="body" paragraph>
          {note.message}
        </Typography>
      </CardContent>
      <CardActions>{format.dateTime(note.timestamp)}</CardActions>
    </Card>
  );
};

/*
 **  Styles
 */
const useStyles = makeStyles((theme) => ({
  controls: { padding: theme.spacing(2), marginBottom: theme.spacing(2) },
  noteCard: {
    "&::before": {
      top: "26px",
      right: "-15px",
      content: "I am here!",
      display: "inline-block",
      position: "absolute",
      borderTop: "15px solid transparent",
      borderLeft: "15px solid #e4e4e4",
      borderRight: "0 solid #e4e4e4",
      borderBottom: "15px solid transparent",
    },
  },
  missingOppositeContent: { "&:before": { display: "none" } },
  connector: { backgroundColor: "rgba(0,0,0,0.1)" },
}));

export default Notes;
