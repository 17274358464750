/********************************************************************
 *
 * /CRM/Reviews/Identity/Identity.jsx
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2020 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

import React, { useState } from "react";
import { Grid, Chip, IconButton, Button, Link } from "@material-ui/core";
import { Edit } from "@material-ui/icons";
import IdentityEdit from "./Edit";
import { VerticalGroup, Property } from "../../../lib";
import ModalDialog, { DialogContent } from "../../../lib/ModalDialog";
import format from "../../../../utils/format";
import WidgetFrame from "../../../lib/WidgetFrame";

/**
 * Identity
 *
 * Component that displays, adds and edits the descriptive information
 * for a review.
 *
 * Note that the presence of the onUpdate callback determines if the
 * component is readonly or can be modified.
 *
 */
const Identity = ({ review, onUpdate }) => {
  const [edit, setEdit] = useState(false);

  ///////////////////////////////////////////////////////////////////
  //
  //  Event Handlers
  //
  ///////////////////////////////////////////////////////////////////

  /**
   * OnSaved
   *
   * Fired once the edit state has saved the descriptive information.
   *
   * Returns to display mode.
   *
   * @param {*} event
   */
  const onSaved = (event) => {
    if (onUpdate) {
      onUpdate(event);
      setEdit(false);
    }
  };

  ///////////////////////////////////////////////////////////////////
  //
  //  Lifecycle methods
  //
  ///////////////////////////////////////////////////////////////////

  return (
    <WidgetFrame
      title={
        <Chip
          label={review.display ? "Live" : "Inactive"}
          color={review.display ? "primary" : "default"}
          size="small"
        />
      }
      action={
        !!onUpdate && (
          <IconButton onClick={() => setEdit(true)}>
            <Edit />
          </IconButton>
        )
      }
    >
      <>
        <Grid container spacing={4}>
          <Grid item xs={12} md={4}>
            <Meta review={review} />
          </Grid>
          <Grid item xs={12} md={8}>
            <Content review={review} />
          </Grid>
        </Grid>
        {!review.deleted && onUpdate && (
          <ModalDialog
            title="Manage Review"
            subtitle="Reviews are the core of customer trust and company reputation"
            show={!!edit}
          >
            <DialogContent>
              <IdentityEdit
                review={review}
                onSaved={onSaved}
                onCancelled={() => setEdit(false)}
              />
            </DialogContent>
          </ModalDialog>
        )}
      </>
    </WidgetFrame>
  );
};

const Content = ({ review }) => (
  <>
    <VerticalGroup>
      <Property title="Lead" data={review.lead} />
      <Property title="Content" data={review.content} />
    </VerticalGroup>
  </>
);

const Meta = ({ review }) => (
  <Grid container spacing={2}>
    <Grid item xs={12}>
      {!!review.customer && (
        <Link
          href={`/app/crm/customer/${review.customer.id}`}
        >{`${review.customer.fname} ${review.customer.lname}`}</Link>
      )}
    </Grid>
    <Grid item xs={12}>
      {!!review.orderId && (
        <Button
          variant="outlined"
          color="primary"
          href={`/app/sales/order/${review.orderId}/reviews`}
        >{`Go to Order ${review.orderId}`}</Button>
      )}
    </Grid>
    <Grid item xs={12}>
      {!!review.url && (
        <a href={review.url} target="_+blank">
          {review.url}
        </a>
      )}
    </Grid>
    <Grid item xs={12}>
      <Property
        title="Published"
        data={format.date(review.date)}
        background="transparent"
      />
    </Grid>
  </Grid>
);
export default Identity;
