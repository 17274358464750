import React, { useEffect, useState } from "react";
import queryString from "query-string";
import SectionRouterHelper from "../SectionRouterHelper";
import _SectionSubRouter from "./_SubSectionRouter";

import ProductTypes from "./Products/ProductTypes";
import ProductType from "./Products/ProductType";

import RateClasses from "./Rates/RateClasses";
import RateClassEdit from "./Rates/RateClassEdit";
import RateClassPartners from "./Rates/RateClassPartners";
import RateClassPartnersEdit from "./Rates/RateClassPartnersEdit";

import ProductReport from "./Reports/ProductReport";

import Product from "./Products/Products/Product";
import { ProductEdit } from "./Products/Products/Identity";
import RateClassRateEdit from "./Rates/RateClassRateEdit";
import OrderProvider from "../../providers/OrderProvider";

export let routes = {
  root: "/app/catalog",
  tree: {
    Products: {
      label: "Products",
      path: "/app/catalog/producttypes/",
      component: ProductTypes,
      children: [
        {
          path: "/app/catalog/producttype/:productTypeId",
          component: ProductType,
        },
        {
          path: "/app/catalog/producttype/",
          component: ProductType,
        },
        {
          path: "/app/catalog/product/edit/:id",
          component: ProductEdit,
        },
        {
          path: "/app/catalog/product/edit/",
          component: ProductEdit,
        },
        {
          path: "/app/catalog/product/:id",
          component: Product,
        },
      ],
    },
    RateClasses: {
      label: "Rate Classes",
      path: "/app/catalog/rateclasses/",
      component: RateClasses,
      children: [
        {
          path: "/app/catalog/rateclass/:id",
          component: RateClassEdit,
        },
        {
          path: "/app/catalog/rateclass/",
          component: RateClassEdit,
        },
        {
          path: "/app/catalog/rateclass/:id/partners",
          component: RateClassPartnersEdit,
        },
        {
          path: "/app/catalog/rateclass/:id/partners/",
          component: RateClassPartners,
        },
        {
          path: "/app/catalog/rate/edit/:id",
          component: RateClassRateEdit,
        },
        {
          path: "/app/catalog/rateclass/:id/partners/edit/",
          component: RateClassPartnersEdit,
        },
      ],
    },
    Reports: {
      label: "Reports",
      path: "/app/catalog/reports/products/",
      component: ProductReport,
      children: [
        {
          path: "/app/catalog/reports/products/",
          component: ProductReport,
        },
      ],
    },
  },
};

const _SectionRouter = ({ location }) => {
  const [orderId, setOrderId] = useState(null);

  useEffect(() => {
    //
    //  If product id is a paramter, we are in edit mode, otherwise
    //  we are creating a new product
    //
    let params = queryString.parse(location.search);
    setOrderId(params["orderId"]);
  }, []);

  //
  //  Create the section router.
  //
  return (
    <OrderProvider orderId={orderId}>
      <SectionRouterHelper
        routes={routes}
        subSectionRouter={_SectionSubRouter}
      />
    </OrderProvider>
  );
};

export { _SectionRouter };
