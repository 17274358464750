/********************************************************************
 *
 * UserExperience.jsx
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2024 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

import React, { useState, useEffect } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { Avatar } from "@material-ui/core";
import { PersonOutlined } from "@material-ui/icons";
import { Main } from "../../lib";
import {
  SectionList,
  SectionItemGroup,
  SectionItem,
} from "../../lib/SideBar/Menu";
import Error404 from "../../Error/404";
import Sessions from "../../lib/Analytics/Sessions/UserSessions";
// import History from "./History/History";
// import Communications from "./Communications/Communications";
// import Communication from "./Communications/Communication";
// import Account from "./Account/Account";

import { useApp, useAPI } from "../../../providers/AppProvider";

/**
 * UserExperience
 *
 * Renders User Experience information
 */
const UserExperience = ({ match }) => {
  const [user, setUser] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const app = useApp();
  const api = useAPI();

  const { userId } = match.params;

  useEffect(() => {
    fetchUser(userId);
  }, [userId]);

  ///////////////////////////////////////////////////////////////////
  //
  //  Utility methods
  //
  ///////////////////////////////////////////////////////////////////

  const fetchUser = (userId) => {
    if (!userId) return;
    //
    //  Status message
    //
    const notifyHandle = app.notify("Loading Customer");

    api
      .fetch(`/api/users/user/${userId}?verbose`)
      .then(({ payload: user }) => {
        setUser(user);
        setLoaded(true);
        app.endNotify(notifyHandle);
      })
      .catch((error) => {
        app.error({ error });
        setUser(null);
        setLoaded(true);
      });
  };

  ///////////////////////////////////////////////////////////////////
  //
  //  Event Handlers
  //
  ///////////////////////////////////////////////////////////////////

  const onUpdate = () => {
    // fetchUser(userId);
  };

  return (
    <Main
      menu={{
        icon: <PersonOutlined />,
        title: "User Experience",
        subtitle: "Customer Journey",
        nav: <Menu user={user} />,
      }}
      header={{
        icon: !!user && (
          <Avatar>
            {user.fname && user.fname[0]}
            {user.lname && user.lname[0]}
          </Avatar>
        ),
        title:
          user && (user.fname || user.lname)
            ? `${user.fname} ${user.lname}`
            : "Anonymous",
      }}
      loaded={loaded}
    >
      <Router user={user} onUpdate={onUpdate} />
    </Main>
  );
};

/**
 * Renders the navigation controls for the functional areas of the
 * product type maagement page.
 *
 * @param {*} props
 */
const Menu = ({ user }) => {
  const { id = 0 } = user || {};

  return (
    <SectionList>
      <SectionItemGroup icon={<PersonOutlined />} name="Sessions" open={true}>
        <SectionItem to={`/app/crm/customer/${id}/details`}>
          Details
        </SectionItem>
        <SectionItem to={`/app/crm/customer/${id}/history`}>
          History
        </SectionItem>
      </SectionItemGroup>
      <SectionItemGroup icon={<PersonOutlined />} name="Sales" open={true}>
        <SectionItem to={`/app/crm/customer/${id}/communication`}>
          Communication
        </SectionItem>
        <SectionItem to={`/app/crm/customer/${id}/account`}>
          Account
        </SectionItem>
      </SectionItemGroup>
    </SectionList>
  );
};

/**
 * Renders the routing logic for the page
 *
 * @param {*} props
 */
const Router = ({ user, onUpdate }) => {
  return (
    <Switch>
      Identity
      <Route
        path="/app/crm/userexperience/:userId/details"
        render={(props) => (
          <Sessions {...props} user={user} onUpdate={onUpdate} />
        )}
        exact
      />
      {/* History */}
      {/* <Route
        path="/app/crm/customer/:customerId/history"
        render={(props) => <History {...props} customer={customer} />}
      /> */}
      {/* Communication */}
      {/* <Route
        path="/app/crm/customer/:customerId/communication/:communicationId"
        render={(props) => <Communication {...props} />}
      />
      <Route
        path="/app/crm/customer/:customerId/communication"
        render={(props) => (
          <Communications {...props} customerId={customer.id} />
        )}
      /> */}
      {/* Account */}
      {/* <Route
        path="/app/crm/customer/:customerId/account"
        render={(props) => <Account {...props} customer={customer} />}
      /> */}
      {/* Redirect */}
      <Route
        path="/app/crm/userexperience/:userId"
        render={() => (
          <Redirect to={`/app/crm/userexperience/${user.id}/details`} />
        )}
      />
      <Route path="/app/crm/" component={Error404} />
    </Switch>
  );
};

export default UserExperience;
