/********************************************************************
 *
 * Analytics/ProductTypeHits.jsx
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2019 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

import React, { useState, useEffect } from "react";
import {
  Card,
  CardHeader,
  CardContent,
  Avatar,
  Typography,
  makeStyles,
} from "@material-ui/core";
import { TouchApp } from "@material-ui/icons";
import Chart from "react-apexcharts";
import moment from "moment-timezone";
import WidgetFrame from "../../lib/WidgetFrame";
import {
  useApp,
  useAnalyticsAPI,
  useAPI,
} from "../../../providers/AppProvider";

const START_DATE = "2021-01-01";
const END_DATE = "2022-01-01";

const ProductTypeHits = () => {
  const [graphData, setGraphData] = useState(null);
  const app = useApp();
  const analyticsAPI = useAnalyticsAPI();
  const api = useAPI();

  const classes = useStyles();

  useEffect(() => {
    fetchProductTypeHits();
  }, []);

  ///////////////////////////////////////////////////////////////////
  //
  //  Utility Functions
  //
  ///////////////////////////////////////////////////////////////////

  /**
   * FetchProductTypeHits
   *
   */
  const fetchProductTypeHits = () => {
    const date = moment();

    return Promise.all([
      api.fetch(`/api/producttypes/`),
      analyticsAPI.fetch(
        `/api/hits/producttype/${date.startOf("year").format()}/${date
          .endOf("year")
          .format()}`
      ),
    ])
      .then(([{ payload: productTypes }, { payload: hits }]) => {
        const dateMap = new Map();
        const lastDate = moment(END_DATE);
        //
        //  Create a hash for every day in the time period
        //
        for (
          let m = new moment(START_DATE);
          m.isBefore(lastDate);
          m.add(1, "d")
        ) {
          dateMap.set(m.format("YYYY-MM-DD"), {});
        }

        for (const key of dateMap.keys()) {
        }

        //
        //  Add Data
        //
        hits.forEach((productTypeHits) => {
          productTypeHits.hits.map((hit) => {
            dateMap.get(hit.date)[productTypeHits.productTypeId] = hit.count;
          });
        });

        //
        //  Transform data
        //
        setGraphData({ xAxis: dateMap.keys });
      })
      .catch((error) => {
        app.error({ error });
      });
  };

  return (
    <WidgetFrame title="Hits By Product Type">
      <div>
        <Chart
          options={{
            stroke: { width: 1, curve: "smooth" },
            fill: { opacity: 0.3 },
            chart: {
              id: "basic-bar",
            },
            xaxis: {
              categories: graphData && graphData.xAxis,
            },
          }}
          series={[
            {
              name: "series-1",
              data: [30, 40, 45, 50, 49, 60, 70, 91],
            },
          ]}
          type="area"
          width="100%"
        />
      </div>
    </WidgetFrame>
  );
};

const useStyles = makeStyles((theme) => ({
  tooltip: {
    backgroundColor: "rgba(255,255,255,0.75)",
    padding: theme.spacing(0),
    zIndex: 9999,
  },
  content: { textAlign: "center" },
}));

export default ProductTypeHits;
