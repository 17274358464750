/********************************************************************
 *
 * Tickets.jsx
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2019 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

import React from "react";
import PropTypes from "prop-types";
import { Grid, Typography, makeStyles } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { ConfirmationNumber, ChildCare } from "@material-ui/icons";
import { LinkCard, CardList, CardAction } from "./index";
import format from "../../utils/format";

/**
 * Tickets
 *
 * Renders a card deck of Tickets.
 */
const Tickets = ({ tickets, tz }) => {
  ///////////////////////////////////////////////////////////////////
  //
  //  Lifecycle methods
  //
  ///////////////////////////////////////////////////////////////////

  if (!tickets) return null;

  return (
    <CardList
      xs={12}
      items={tickets}
      spacing={2}
      headerBG="#01899f"
      onRenderCard={({ item: ticket }) => (
        <LinkCard
          link={`/app/goods/ticket/${ticket.id}`}
          header={<TicketHeader ticket={ticket} />}
          content={<TicketSummary ticket={ticket} tz={tz} />}
          actions={
            <>
              <CardAction to="#" label="Reschedule" />
              <CardAction to="#" label="Delete" />
            </>
          }
        />
      )}
    />
  );
};

const TicketHeader = ({ ticket }) => {
  const classes = styles();
  const { customer, good } = ticket;

  return (
    <Grid container justify="space-between" className={classes.header}>
      <Grid item>
        <ConfirmationNumber style={{ fontSize: "1em", marginRight: "1em" }} />
        {customer ? (
          customer.isMinor ? (
            <>
              <Typography variant="subtitle1" component="span">
                {customer.minorName}
              </Typography>
              <ChildCare style={{ fontSize: "1.5em", marginLeft: "0.75em" }} />
            </>
          ) : (
            <>
              <Typography variant="subtitle1" component="span">
                {`${customer.fname} ${customer.lname}`}
              </Typography>
            </>
          )
        ) : (
          ""
        )}
      </Grid>
      <Grid className={classes.right}>
        <Typography variant="subtitle1">{good.name}</Typography>
      </Grid>
    </Grid>
  );
};

const TicketSummary = ({ ticket, tz }) => {
  const classes = styles();
  const { good, groupName, customer, timestamp } = ticket;

  return (
    <Grid container spacing={2}>
      <Grid item xs={4}>
        <Typography variant="subtitle2">Order</Typography>
        <Typography variant="subtitle1">{groupName}</Typography>
      </Grid>
      <Grid item xs={4}>
        <Typography variant="subtitle2">Issued</Typography>
        <Typography variant="subtitle1">
          {format.dateTime(timestamp, tz)}
        </Typography>
      </Grid>
      <Grid item xs={4}>
        <Typography variant="subtitle2">Supplier</Typography>
        <Typography variant="subtitle1">{good.supplier.name}</Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="subtitle2">Rate Class</Typography>
        <Typography variant="subtitle1">{ticket.rateClass.name}</Typography>
      </Grid>
      {!!customer && !!customer.isMinor && (
        <>
          <Grid item xs={4}>
            Age: {customer.age}
          </Grid>
          <Grid item xs={4}>
            Weight: {customer.weight}
          </Grid>
          <Grid item xs={4}>
            Height: {customer.height}
          </Grid>
        </>
      )}
      {!!customer && (
        <>
          <Grid
            item
            xs={12}
            className={`${classes.section} ${
              !customer.medical ? "d-none" : ""
            }`}
          >
            <Typography>Medical</Typography>
            <Alert severity="info">{customer.medical}</Alert>
          </Grid>
          <Grid
            item
            xs={12}
            className={`${classes.section} ${
              !customer.allergies || customer.allergies.length === 0
                ? "d-none"
                : ""
            }`}
          >
            <Typography className="small">Allergies</Typography>
            <Alert severity="info">{customer.allergies}</Alert>
          </Grid>
        </>
      )}
    </Grid>
  );
};

/*
 **  Styles
 */
const styles = makeStyles((theme) => ({
  right: { textAlign: "right" },
  section: { borderTop: "solid 1px #eeeeee" },
  header: {
    backgroundColor: theme.palette.primary.main,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    color: "white",
  },
}));

/*
 **  Tickets
 */
Tickets.propTypes = {
  tickets: PropTypes.array,
  tz: PropTypes.string,
};

export default Tickets;
