/********************************************************************
 *
 * Sandbox.jsx
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2019 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

import React, { useState, useEffect } from "react";
import moment from "moment-timezone";
import { Container, Card, makeStyles } from "@material-ui/core";
import { DateController, WeeklyCalendar } from "../../lib/Calendars";
//import { useApp } from "../../../providers/AppProvider";

/**
 * Sandbox
 */
const Sandbox = () => {
  //  const app = useApp();
  const [date, setDate] = useState(moment());
  const classes = styles();

  useEffect(() => {
    //    app.debug("I'm here");
    //    app.error("This is a warning");
  }, []);

  ///////////////////////////////////////////////////////////////////////
  //
  //  Event Handlers
  //
  ///////////////////////////////////////////////////////////////////////

  ///////////////////////////////////////////////////////////////////////
  //
  //  Lifecycle methods
  //
  ///////////////////////////////////////////////////////////////////////

  return (
    <Container maxWidth="md">
      <div className={classes.navigation}>
        <DateController
          date={date}
          onChange={(date) => {
            setDate(date);
          }}
        />
      </div>
      <Card className={classes.calendar}>
        <WeeklyCalendar date={date} />
      </Card>
    </Container>
  );
};

/*
 ** Styles
 */
const styles = makeStyles((theme) => ({
  navigation: { padding: "20px 0 20px 0" },
  calendar: { padding: "10px" },
}));

export default Sandbox;
