/********************************************************************
 *
 * Tour.jsx
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2019 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

import React from "react";
import PropTypes from "prop-types";

/**
 * Ticket
 *
 * @param {*} param0
 */
const Ticket = ({ match }) => {
  const { id: tourId } = match.params;

  return tourId;
};

/*
 **  PropTypes
 */

Ticket.propTypes = {
  id: PropTypes.number.isRequired,
};

export default Ticket;
