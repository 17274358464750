/********************************************************************
 *
 * WidgetFrame.jsx
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2021 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Card, CardHeader, CardContent, makeStyles } from "@material-ui/core";

const WidgetFrame = ({ avatar, title, action, background, children }) => {
  const [bgClass, setBgClass] = useState(null);
  const classes = useStyles();

  useEffect(() => {
    switch (background) {
      case "dark":
        setBgClass(classes.darkBG);
        break;
      default:
        setBgClass(null);
    }
  }, [background]);

  return (
    <Card data-test-id="WIDGET_FRAME" className={`${classes.card} ${bgClass}`}>
      <CardHeader
        avatar={avatar}
        title={title}
        action={action}
        classes={{ root: classes.headerRoot, title: classes.headerTitle }}
      />
      <CardContent>{children}</CardContent>
    </Card>
  );
};

/*
 **  Styles
 */
const useStyles = makeStyles((theme) => ({
  card: {
    overflow: "visible",
    padding: theme.spacing(2),
    flex: 1,
    border: "1px solid rgba(122,123,151,.3)",
  },
  darkBG: {
    backgroundColor: theme.palette.primary.dark,
    color: "#ffffff",
  },
  headerRoot: { paddingBottom: 0 },
  headerTitle: {
    color: theme.palette.text.secondary,
    fontSize: "1.1em",
    fontWeight: 500,
    textTransform: "uppercase",
  },
}));

//
//  PropTypes
//
WidgetFrame.propTypes = {
  title: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
};

export default WidgetFrame;
