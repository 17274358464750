/********************************************************************
 *
 * Agenda.jsx
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2019 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import format from "../../utils/format";
import ShiftStatus from "../ShiftStatus";
import { useApp, useAPI } from "../../providers/AppProvider";

/**
 * Agenda
 *
 * Renders the products available for a selected date.
 *
 * @param {array} props
 */
const Agenda = ({ date, products }) => {
  const [loaded, setLoaded] = useState(false);
  const app = useApp();
  const api = useAPI();

  if (!date) return null;

  return (
    <div className="mx-2">
      <Link
        to={`/app/catalog/product/edit/?date=${format.toApiDate(date)}`}
        className="btn btn-outline-cc btn-sm float-right"
      >
        Add Product
      </Link>
      <h4 className="mb-4">{format.date(date)}</h4>
      {products &&
        products.map(
          (product) =>
            !product.deleted && (
              <div key={product.id}>
                <div>
                  <Link
                    to={`/app/catalog/product/${product.id}`}
                    className="text-uppercase"
                  >
                    {product.name}
                  </Link>
                </div>
                <div>
                  {`${format.time(
                    product.startTime.time,
                    product.timezone
                  )} - ${format.time(product.endTime.time, product.timezone)}`}
                  <span className={`badge badge-pill badge-primary ml-2`}>
                    {product.departure ? product.departure.status.name : "open"}
                  </span>
                </div>
                <div className="small text-muted">
                  {product.capacity - product.available}/{product.capacity}
                  &nbsp;booked
                </div>
                <Shifts departure={product.departure} />
              </div>
            )
        )}
    </div>
  );
};

const Shifts = ({ departure }) => {
  const [shifts, setShifts] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const app = useApp();
  const api = useAPI();

  useEffect(() => {
    if (!departure) return;

    api
      .fetch(`/api/tours/tour/${departure.id}/shifts`)
      .then(({ payload: shifts }) => {
        setShifts(shifts);
        setLoaded(true);
      })
      .catch((error) => {
        app.error({ error });
        setLoaded(true);
      });
  }, []);

  if (!shifts) return null;

  return (
    <div className="pb-2">
      {shifts.map((shift) => (
        <div key={shift.id} className="small text-muted">
          {shift.employee.fname} {shift.employee.lname}&nbsp;
          <ShiftStatus status={shift.status} />
        </div>
      ))}
    </div>
  );
};

export default Agenda;
