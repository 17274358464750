import React, { Component } from "react";
import SectionRouterHelper from "../SectionRouterHelper";
import _SectionSubRouter from "./_SubSectionRouter";
import { Order, OrderManagement } from "./Orders";
import Partner from "./Partners/Partner";
import Partners from "./Partners/Partners";
import Lead from "./Leads/Lead";
import DiscountTypes from "./Discounts/DiscountTypes";
import { Campaigns, Campaign } from "./Campaigns";
import Ticket from "./Tickets/Ticket";

//
//  Define the routing for the section. A submenu is created using
//  the first level children.
//
export let routes = {
  root: "/app/sales",
  tree: {
    Orders: {
      label: "Orders",
      path: "/app/sales/orders/",
      component: OrderManagement,
      children: [
        {
          path: "/app/sales/orders/sales",
          component: OrderManagement,
        },
        {
          path: "/app/sales/orders/abandoned",
          component: OrderManagement,
        },
        {
          path: "/app/sales/order/:id",
          component: Order,
        },
        {
          path: "/app/sales/ticket/:id",
          component: Ticket,
        },
      ],
    },
    Partners: {
      label: "Partners",
      path: "/app/sales/partners/",
      component: Partners,
      children: [
        {
          path: "/app/sales/partner/:id",
          component: Partner,
        },
      ],
    },
    Lead: {
      label: "Lead",
      path: "/app/sales/leads/lead/",
      component: Lead,
      children: [
        {
          path: "/app/sales/leads/lead/:id",
          component: Lead,
        },
      ],
    },
    Discounts: {
      label: "Discounts",
      path: "/app/sales/discounts/",
      component: DiscountTypes,
    },
    Campaigns: {
      label: "Campaigns",
      path: "/app/sales/campaigns/",
      component: Campaigns,
      children: [
        {
          path: "/app/sales/campaign/:id",
          component: Campaign,
        },
      ],
    },
  },
};

export class _SectionRouter extends Component {
  render() {
    //
    //  Create the section router.
    //
    return (
      <SectionRouterHelper
        routes={routes}
        subSectionRouter={_SectionSubRouter}
      />
    );
  }
}
