/********************************************************************
 *
 * Analytics/UnearnedTours.jsx
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2021 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

import React, { useState, useEffect } from "react";
import { Avatar } from "@material-ui/core";
import { DirectionsBoatOutlined } from "@material-ui/icons";
import { SuperStat } from "../../lib";
import {
  useApp,
  useAnalyticsAPI,
  useAPI,
} from "../../../providers/AppProvider";

const UnearnedTours = () => {
  const [totalTours, setTotalTours] = useState(null);
  const app = useApp();
  const analyticsApi = useAnalyticsAPI();

  useEffect(() => {
    fetchTotalFutureTourCount();
  }, []);

  const fetchTotalFutureTourCount = () => {
    analyticsApi
      .fetch("/api/tours/unearned/")
      .then(({ payload: count }) => {
        setTotalTours(count);
      })
      .catch((error) => {
        app.error({ error });
      });
  };

  return (
    <SuperStat
      icon={
        <Avatar style={{ backgroundColor: "RebeccaPurple" }}>
          <DirectionsBoatOutlined />
        </Avatar>
      }
      label="Upcoming Tours"
      value={totalTours}
    />
  );
};

export default UnearnedTours;
