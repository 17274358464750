/********************************************************************
 *
 * /components.sales/leads/leads.jsx
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2020 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Grid, Typography } from "@material-ui/core";
import { Highlight } from "@material-ui/icons";
import { Main } from "../../lib";
import CustomerCard from "../../lib/CustomerCard/CustomerCard";
import format from "../../../utils/format";
import LeadManager from "./LeadManager";
import Notes from "./Notes";
import AddNote from "./AddNote";
import { useApp, useAPI } from "../../../providers/AppProvider";

/**
 * Lead
 *
 * Manages a sales lead.
 *
 * @param {*} param0
 */
const Lead = ({ match }) => {
  const [lead, setLead] = useState({ status: { id: 0 } });
  const [loaded, setLoaded] = useState(false);
  const { id } = match.params;
  const history = useHistory();
  const app = useApp();
  const api = useAPI();

  useEffect(() => {
    if (id) fetchLead(id);
    else setLoaded(true);
  }, [id]);

  ///////////////////////////////////////////////////////////////////////
  //
  //  Utility Methods
  //
  ///////////////////////////////////////////////////////////////////////

  /**
   * FetchLead
   *
   * @param {*} leadId
   */
  const fetchLead = (leadId) => {
    const notifyHandle = app.notify("Loading Logs");

    api
      .fetch(`/api/leads/lead/${leadId}`)
      .then(({ payload: lead }) => {
        if (!lead) throw new Error("API failed to return lead.");

        setLead(lead);
        setLoaded(true);
        app.endNotify(notifyHandle);
      })
      .catch((error) => {
        app.error({ error });
        setLoaded(true);
      });
  };

  /**
   * SaveLead
   *
   * Fired when the user saves the changes.
   *
   * Updates the lead and notifies the parent.
   *
   * @param {*} lead
   */
  const saveLead = (lead) => {
    //
    //  Save
    //
    const notifyHandle = app.notify("Saving");

    api
      .update(`/api/leads/lead/${lead.id}`, lead)
      .then(({ payload: lead }) => {
        setLead(lead);
        app.endNotify(notifyHandle);
        if (lead.status.id == -1) window.location.href = "/";
      })
      .catch((error) => {
        app.error({ error });
      });
  };

  ///////////////////////////////////////////////////////////////////////
  //
  //  Event Handlers
  //
  ///////////////////////////////////////////////////////////////////////

  const onAddNote = (note) => {
    api
      .create(`/api/leads/lead/${lead.id}/note`, { note })
      .then(({ payload: lead }) => {
        setLead(lead);
      })
      .catch((error) => {
        app.error({ error });
      });
  };

  const onChangeUser = ({ customer }) => {
    lead.user = customer;
    saveLead(lead);
  };

  ///////////////////////////////////////////////////////////////////////
  //
  //  Lifecycle methods
  //
  ///////////////////////////////////////////////////////////////////////

  return (
    <Main
      icon={<Highlight />}
      header={{
        title: `${!lead.id ? "New " : ""} Lead ${
          lead.id ? `${lead.id} - ${lead.shortDesc}` : ""
        }`,
        subtitle:
          lead &&
          lead.assigned &&
          `${lead.assigned.fname} ${lead.assigned.lname}`,
      }}
      bonus={
        lead.source && (
          <Typography variant="body2" align="right" color="textSecondary">
            Created {format.dateTime(lead.timestamp)}
          </Typography>
        )
      }
      loaded={loaded}
    >
      <Grid container spacing={4} alignItems="stretch">
        <Grid item xs={12} md={6} lg={4}>
          <CustomerCard customer={lead.user} onCustomerSelect={onChangeUser} />
        </Grid>
        <Grid item xs={12} md={6} lg={8}>
          <LeadManager lead={lead} onSave={saveLead} />
        </Grid>
        <Grid item xs={12} lg={6}>
          <AddNote onAddNote={onAddNote} />
        </Grid>
        <Grid item xs={12} lg={6}>
          <Notes notes={lead.notes} />
        </Grid>
      </Grid>
    </Main>
  );
};

export default Lead;
