/********************************************************************
 *
 * TextFieldEditor.jsx
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2020 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Box,
  Button,
  IconButton,
  TextField,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  makeStyles,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";

const CCDialogTitle = (props) => {
  const { children, onClose, ...other } = props;
  const classes = useStyles();

  return (
    <DialogTitle disableTypography className={classes.title} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <Close />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

/**
 * TextFieldEditor
 *
 * Component that displays and edits textfields.
 *
 */
const TextFieldEditor = ({
  title,
  prefix,
  content,
  variant,
  className,
  onSave,
}) => {
  const [value, setValue] = useState(undefined);
  const [open, setOpen] = useState(false);
  const classes = useStyles();

  useEffect(() => {
    setValue(content);
  }, [content]);

  return (
    <div className={className}>
      <Button
        className={classes.button}
        variant={variant}
        onClick={() => {
          setOpen(true);
        }}
      >
        <Typography>{`${prefix ? prefix + " " : ""} ${
          content ? content : "--"
        }`}</Typography>
      </Button>
      <Dialog
        open={open}
        onClose={() => {
          setOpen(false);
        }}
      >
        <CCDialogTitle
          onClose={() => {
            setOpen(false);
          }}
        >
          {title}
        </CCDialogTitle>
        <DialogContent>
          <Box display="flex">
            <Box flexGrow={1}>
              <TextField
                value={value}
                onChange={(e) => setValue(e.target.value)}
              />
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              onSave(value);
              setOpen(false);
            }}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  title: {
    margin: 0,
    padding: theme.spacing(2),
  },
  button: {
    textTransform: "none",
    height: "36px",
    borderRadius: "18px",
    padding: "8px",
    marginLeft: "-8px",
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));

TextFieldEditor.propTypes = {
  message: PropTypes.string,
  onSave: PropTypes.func.isRequired,
};

export default TextFieldEditor;
