/********************************************************************
 *
 * DateTimePicker.jsx
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2019 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

import React from "react";
import PropTypes from "prop-types";
import moment from "moment-timezone";

// pick a date util library
import MomentUtils from "@date-io/moment";

import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { makeStyles } from "@material-ui/core";

/**
 * DateTimePicker
 *
 * Component to manage dates and times within the context of a
 * specified timezone. If a timezone is not provided, the browser's
 * default timezone is used.
 *
 */
const DTPicker = ({ value, tz, label, showUTC, onChange }) => {
  const classes = styles();
  const dateTime = moment.tz(value, tz);

  ///////////////////////////////////////////////////////////////////////
  //
  //  Lifecycle methods
  //
  ///////////////////////////////////////////////////////////////////////

  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <>
        {/* Date */}
        <DateTimePicker
          label={label}
          inputVariant="outlined"
          value={moment.tz(dateTime, tz)}
          onChange={onChange}
          className={classes.picker}
          required
        />
        {showUTC && (
          <div className="small text-muted">
            {moment(dateTime).utc().format()}
          </div>
        )}
      </>
    </MuiPickersUtilsProvider>
  );
};

/*
 **  Styles
 */
const styles = makeStyles((theme) => ({
  picker: { width: "100%" },
}));

/*
 **  PropTypes
 */
DTPicker.propTypes = {
  dateTime: PropTypes.string.isRequired,
  tz: PropTypes.string,
  label: PropTypes.string,
  showUTC: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
};

export default DTPicker;
