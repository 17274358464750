/********************************************************************
 *
 * /CRM/Reviews/Review.jsx
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2019 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

import React, { useState, useEffect } from "react";
import { Box, Chip, Avatar } from "@material-ui/core";
import { Rating } from "@material-ui/lab/";
import { RateReview } from "@material-ui/icons";
import { Main } from "../../lib";
import Identity from "./Identity";
import moment from "moment";
import { useApp, useAPI } from "../../../providers/AppProvider";

/**
 * Review
 *
 * Creates and edits a customer review
 */
const Review = ({ match }) => {
  const [review, setReview] = useState({
    source: {},
    date: new moment().format("YYYY-MM-DD"),
    display: false,
  });
  const [loaded, setLoaded] = useState(false);
  const app = useApp();
  const api = useAPI();

  const { id } = match.params;

  useEffect(() => {
    fetchReview(id);
  }, []);

  /////////////////////////////////////////////////////////////////////
  //
  //  Utility methods
  //
  /////////////////////////////////////////////////////////////////////

  /**
   * FetchReview
   *
   * Calls the api to fetch a review
   */
  const fetchReview = (reviewId) => {
    if (!reviewId) return;

    //
    //  Status message
    //
    const notifyHandle = app.notify("Loading Review");

    //
    //  Request review
    //
    api
      .fetch(`/api/reviews/review/${reviewId}`)
      .then(({ payload: review }) => {
        setReview(review);
        setLoaded(true);
        app.endNotify(notifyHandle);
      })
      .catch((error) => {
        app.error({ error });
        setLoaded(true);
      });
  };

  ///////////////////////////////////////////////////////////////////////
  //
  //  Event Handlers
  //
  ///////////////////////////////////////////////////////////////////////

  /**
   * OnUpdate
   *
   * @param {*} event
   */
  const onUpdate = (event) => {
    fetchReview(id);
  };

  ///////////////////////////////////////////////////////////////////////
  //
  //  Lifecycle methods
  //
  ///////////////////////////////////////////////////////////////////////

  return (
    <Main
      header={{
        icon:
          review && review.image ? (
            <Avatar alt={review.source.name} src={review.image} />
          ) : (
            <RateReview />
          ),
        title: review ? `${review.author}, ${review.location}` : "New Review",
        callout: review &&
          !review.display && { label: "Inactive", color: "error" },
        bonus: (
          <Box display="flex" flexDirection="column">
            <Box>
              {review && review.rating ? (
                <Rating value={review.rating} precision={0.1} readOnly />
              ) : (
                "No rating"
              )}
            </Box>
            <Box>
              {review && review.source && (
                <Chip label={review.source.name} color="primary" size="small" />
              )}
            </Box>
          </Box>
        ),
      }}
      loaded={loaded}
    >
      <Identity review={review} onUpdate={onUpdate} />
    </Main>
  );
};

export default Review;
