/********************************************************************
 *
 * ModalDialog.jsx
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2019 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Box,
  Dialog,
  Typography,
  Fade,
  CircularProgress,
  makeStyles,
} from "@material-ui/core";

/**
 * ModalDialog
 *
 * Modal container with error handling.
 *
 */
const ModalDialog = ({
  size = "lg",
  show,
  title,
  subtitle,
  loading = false,
  onEnter,
  onClose,
  error,
  children,
}) => {
  const [titleSizeClass, setTitleSizeClass] = useState(null);
  const classes = useStyles();

  useEffect(() => {
    switch (size) {
      case "sm":
        setTitleSizeClass(classes.smallTitle);
        break;
      default:
        setTitleSizeClass(classes.largeTitle);
    }
  }, [size]);

  if (!show) return null;

  return (
    <Dialog
      open={show && show != null}
      onEnter={onEnter}
      onClose={onClose}
      scroll={"paper"}
      maxWidth={size}
      fullWidth={true}
    >
      <Fade in={show} style={{ height: "100%" }}>
        <Box className={classes.container}>
          <Box
            className={`${classes.titleContainer} ${classes.heroWrapper} ${titleSizeClass}`}
          >
            {title && (
              <Typography variant="h3" paragraph>
                {title}
              </Typography>
            )}
            {subtitle && (
              <Typography className={classes.subtitle} variant="caption">
                {subtitle}
              </Typography>
            )}
            {error && <div className="text-danger">{error.message}</div>}
          </Box>
          <Box className={classes.contentContainer}>
            {loading ? (
              <Box
                display="flex"
                justifyContent="center"
                className={classes.spinnerContainer}
              >
                <CircularProgress />
              </Box>
            ) : (
              children
            )}
          </Box>
        </Box>
      </Fade>
    </Dialog>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "stretch",
    height: "100%",
    [theme.breakpoints.up("md")]: {
      flexDirection: "row",
    },
  },
  titleContainer: {
    padding: theme.spacing(2),
    width: "100%",
    height: "150px",
    [theme.breakpoints.up("md")]: {
      height: "auto",
      minWidth: "350px",
      maxWidth: "350px",
    },
    backgroundImage:
      "linear-gradient(135deg,rgba(102,00,234, 0.95),rgba(0,0,0, 0.85)), url('/assets/images/SquamishSailing.jpg')",
    filter: "grayscale(80%)",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "50%",
    color: "white",
  },
  smallTitle: {
    width: "100%",
    height: "150px",
    [theme.breakpoints.up("md")]: {
      height: "auto",
      minWidth: "200px",
      maxWidth: "200px",
    },
  },
  largeTitle: {
    width: "100%",
    height: "150px",
    [theme.breakpoints.up("md")]: {
      height: "auto",
      minWidth: "350px",
      maxWidth: "350px",
    },
  },
  subtitle: { color: "#999999" },
  heroWrapper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    paddingTop: theme.spacing(4),
  },
  contentContainer: { flexGrow: 1 },
  spinnerContainer: { paddingTop: theme.spacing(4) },
}));

/*
 **  PropTypes
 */
ModalDialog.propTypes = {
  show: PropTypes.any.isRequired,
  size: PropTypes.oneOf(["sm", "lg"]),
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  subtitle: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  loading: PropTypes.bool,
  onEnter: PropTypes.func,
  onClose: PropTypes.func,
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]),
};

export default ModalDialog;
export { DialogContent, DialogActions } from "@material-ui/core";
