/********************************************************************
 *
 * RateClasses.jsx
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2020 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

import React, { useEffect, useState } from "react";
import { useApp, useAPI } from "../../../../providers/AppProvider";

/**
 * RateClasses
 *
 * Renders a list of Rate Classes for the Partner.
 *
 */
const RateClasses = ({ partner }) => {
  const [rateClasses, setRateClasses] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const app = useApp();
  const api = useAPI();

  useEffect(() => {
    //
    //  Status message
    //
    const notifyHandle = app.notify("Loading");

    fetchRateClasses()
      .then(({ rateClasses }) => {
        setRateClasses(rateClasses);
        setLoaded(true);
        app.endNotify(notifyHandle);
      })
      .catch((error) => {
        app.error({ error });
        setRateClasses(null);
        setLoaded(true);
      });
  }, []);

  /////////////////////////////////////////////////////////////////////
  //
  //  Utility methods
  //
  /////////////////////////////////////////////////////////////////////

  /**
   * FetchRateClasses
   *
   * Calls the api to fetch rate classes for a partner selling the
   * product type
   *
   */
  const fetchRateClasses = () => {
    return new Promise((resolve, reject) => {
      api
        .fetch(`/api/partners/partner/${partner.id}/rateclasses/`)
        .then(({ payload: rateClasses }) => {
          resolve({
            rateClasses,
          });
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  ///////////////////////////////////////////////////////////////////////
  //
  //  Lifecycle methods
  //
  ///////////////////////////////////////////////////////////////////////

  if (!loaded)
    return <div className="spinner-border text-secondary" role="status" />;

  return (
    <table className="table table-striped table-sm small">
      <tbody>
        {rateClasses &&
          Object.keys(rateClasses).map((key) => (
            <tr key={key}>
              <td>{rateClasses[key].name}</td>
              <td>{rateClasses[key].description}</td>
              <td></td>
            </tr>
          ))}
      </tbody>
    </table>
  );
};

export default RateClasses;
