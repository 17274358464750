/********************************************************************
 *
 * ProductSKU.jsx
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2020 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import format from "../../../../utils/format";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons";
import { useApp, useAPI } from "../../../../providers/AppProvider";
import { useOrder } from "../../../../providers/OrderProvider";

/**
 * ProductSKU
 *
 * Renders a product sku widget.
 */
const ProductSKU = ({ history, sku, canBook }) => {
  const [numPAX, setNumPAX] = useState(null);
  const [dirty, setDirty] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const app = useApp();
  const api = useAPI();
  const currOrder = useOrder();

  ///////////////////////////////////////////////////////////////////////
  //
  //  Event Handlers
  //
  ///////////////////////////////////////////////////////////////////////

  /**
   * OnChangeNumPAX
   *
   * Fired when the number of PAX is changed.
   *
   * @param {*} e
   */
  const onChangeNumPAX = (event) => {
    setNumPAX(event.target.value);
    setDirty(true);
  };

  /**
   * OnCreateOrder
   *
   * Fired when the user books a product.
   *
   * Creates an order with the selected SKU and navigates to the order.
   *
   * @param {*} skuId
   * @param {*} pax
   */
  const onCreateOrder = (skuId, pax) => {
    //
    //  Status message
    //
    const notifyHandle = app.notify("Booking");

    //
    //  Create new order
    //
    api
      .create(
        `/api/orders/order/${!!currOrder.order ? currOrder.order.id : ""}`,
        {
          items: [
            {
              skuId: skuId,
              qty: pax,
            },
          ],
        }
      )
      .then(({ payload: order }) => {
        app.endNotify(notifyHandle);
        history.push(`/app/sales/order/${order.id}`);
      })
      .catch((error) => {
        app.error({ error });
      });
  };

  ///////////////////////////////////////////////////////////////////////
  //
  //  Lifecycle methods
  //
  ///////////////////////////////////////////////////////////////////////

  if (!sku)
    return <div className="spinner-border text-secondary" role="status" />;

  return (
    <div className="card m-1 m-md-2">
      <div
        className="btn card-header"
        data-toggle="modal"
        data-target={`#skuModal${sku.id}`}
      >
        {sku.rateClass.name}
        <div className="float-right">
          <FontAwesomeIcon className="text-black-50" icon={faExternalLinkAlt} />
        </div>
      </div>
      <div className="card-body">
        <div className="card-title">
          <div className="small muted">Rate Window</div>
          {format.shortDate(sku.open)} - {format.shortDate(sku.close)}
        </div>
        <div className="card-text">
          <div className="flexRowStretch" style={{ alignItems: "baseline" }}>
            <div className="font-weight-bold mr-2">
              {format.currency(sku.rate)}
            </div>
            <div>
              <select
                className={`custom-select ${!canBook && "d-none"}`}
                onChange={onChangeNumPAX}
                defaultValue="-1"
              >
                <option value={-1} disabled>
                  PAX
                </option>
                {(function () {
                  let items = [];

                  for (let i = 1; i <= sku.available; i++) {
                    items.push(
                      <option key={i} value={i}>
                        {i}
                      </option>
                    );
                  }
                  return items;
                })()}
              </select>
            </div>
          </div>
        </div>

        {canBook && (
          <>
            <Button
              className="btn btn-primary btn-block mt-3"
              disabled={!dirty}
              onClick={() => onCreateOrder(sku.id, numPAX)}
            >
              Book
            </Button>

            <Button
              className="btn btn-primary btn-block mt-3"
              disabled={!dirty || !numPAX}
              data-toggle="modal"
              data-target={`#linkModal${sku.id}`}
            >
              Link
            </Button>
          </>
        )}
      </div>
      <Modal sku={sku} />
      <LinkModal sku={sku} pax={numPAX} />
    </div>
  );
};

/**
 * Policies
 *
 * @param {*} props
 */
const Policies = ({ policies }) => {
  if (!policies) return "Loading...";

  return (
    <div>
      {policies.map((policy) => (
        <div key={policy.id}>
          <p className="font-weight-bold">{policy.name}</p>
          <p>{policy.description}</p>
        </div>
      ))}
    </div>
  );
};

/**
 * Modal
 *
 * @param {*} props
 */
const Modal = ({ sku }) => {
  return (
    <div
      className="modal fade"
      id={`skuModal${sku.id}`}
      tabIndex="-1"
      role="dialog"
    >
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="skuModalLabel">
              {sku.rateClass.name}
            </h5>
            <button type="button" className="close" data-dismiss="modal">
              <span>&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <p className="pb-3">{sku.rateClass.description}</p>
            <Policies policies={sku.rateClass.policies} />
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              data-dismiss="modal"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

/**
 * LinkModal
 *
 * Renders a modal with links to booking on the CC website
 *
 * @param {*} props
 */
const LinkModal = ({ sku, pax }) => {
  let url = `https://canadiancoastal.com/cart/?product=${sku.id}&qty=${pax}`;

  return (
    <div
      className="modal fade"
      id={`linkModal${sku.id}`}
      tabIndex="-1"
      role="dialog"
    >
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="skuModalLabel">
              Customer Website Link
            </h5>
            <button type="button" className="close" data-dismiss="modal">
              <span>&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <h3 className="pb-3">{sku.name}</h3>
            <h4 className="">{format.dayDate(sku.startTime)}</h4>
            <h5 className="pb-3">
              {format.time(sku.startTime, sku.timezone)} -
              {format.time(sku.endTime, sku.timezone)}
            </h5>
            <p className="">{sku.rateClass.description}</p>
            <p>
              {pax} @ {format.currency(sku.rate)}
            </p>
            <div className="mb-3">
              <input className="w-100" type="text" readOnly value={url} />
            </div>
            <small>
              <a rel="noopener noreferrer" target="_blank" href={url}>
                {url}
              </a>
            </small>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              data-dismiss="modal"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(ProductSKU);
