/********************************************************************
 *
 * /lib/StatCard.jsx
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2020 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

import React from "react";
import { Card, Typography, makeStyles } from "@material-ui/core";

const StatCard = ({ label, value }) => {
  const classes = useStyles();

  return (
    <Card classes={{ root: classes.cardRoot }} elevation={1}>
      <Typography variant="h2" noWrap>
        {value}
      </Typography>
      <div className={classes.divider} />
      <Typography variant="overline" color="textPrimary" noWrap gutterBottom>
        {label}
      </Typography>
    </Card>
  );
};

const useStyles = makeStyles((theme) => ({
  cardRoot: {
    overflow: "hidden",
    padding: theme.spacing(2),
    backgroundColor: "#e5f3f5",
    height: "100%",
  },
  divider: {
    display: "block",
    width: "25%",
    borderRadius: "0.65rem!important",
    borderColor: "#f4772e!important",
    backgroundColor: "#263055!important",
    border: "2px solid",
    background: "#eeeff8",
    height: "1px",
    overflow: "hidden",
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  content: { flexGrow: "1" },
  avatarRoot: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.secondary.light,
    marginBottom: theme.spacing(1),
    height: theme.spacing(6),
    width: theme.spacing(6),
  },
}));

export default StatCard;
