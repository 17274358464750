/********************************************************************
 *
 * Reviews.jsx
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2019 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

import React, { useState, useEffect } from "react";
import { Box, Button } from "@material-ui/core";
import { RateReview } from "@material-ui/icons";
import { Main, CardList, LinkCard2, CardAction } from "../../lib/";
import Pagination from "../../lib/Pagination";
import LiveSearch from "../../lib/LiveSearch";
import { ReviewHeader, ReviewSummary } from "./Identity";
import { useApp, useAPI } from "../../../providers/AppProvider";

const PAGE_SIZE = 18;

/**
 * Reviews
 *
 * Renders a list of Reviews.
 */
const Reviews = () => {
  const [reviews, setReviews] = useState(null);
  const [reviewCount, setReviewCount] = useState(null);
  const [currPage, setCurrPage] = useState(1);
  const [pageSize] = useState(PAGE_SIZE);
  const [match, setMatch] = useState("");
  const [loaded, setLoaded] = useState(false);
  const app = useApp();
  const api = useAPI();

  useEffect(() => {
    fetchReviews(currPage, pageSize, match);
  }, [currPage, pageSize, match]);

  /////////////////////////////////////////////////////////////////////
  //
  //  Utility methods
  //
  /////////////////////////////////////////////////////////////////////

  /**
   * FetchReviews
   *
   * Calls the api to fetch reviews
   *
   * @param {*} currPage
   * @param {*} pageSize
   * @param {*} match
   */
  const fetchReviews = (currPage, pageSize, match = "") => {
    //
    //  Status message
    //
    const notifyHandle = app.notify("Loading");

    //
    //  Request reviews
    //
    api
      .fetch(`/api/reviews/?match=${match}&page=${currPage}&limit=${pageSize}`)
      .then(({ payload: reviews, max: count }) => {
        setReviews(reviews);
        setReviewCount(count);
        setLoaded(true);
        app.endNotify(notifyHandle);
      })
      .catch((error) => {
        app.error({ error });
        setReviews(null);
        setReviewCount(null);
        setLoaded(true);
      });
  };

  ///////////////////////////////////////////////////////////////////////
  //
  //  Event Handlers
  //
  ///////////////////////////////////////////////////////////////////////

  /**
   * onDeleteReview
   *
   * Deletes the review
   *
   * @param {*} reviewId
   */
  const onDeleteReview = (reviewId) => {
    if (!window.confirm("Are you sure you want to delete this review?")) return;

    //
    //  Status message
    //
    const notifyHandle = app.notify("Deleting Review");

    //
    //  Request reviews
    //
    api
      .delete(`/api/reviews/review/${reviewId}`)
      .then(() => {
        fetchReviews(currPage, pageSize, match);
        app.endNotify(notifyHandle);
      })
      .catch((error) => {
        app.error({ error });
        fetchReviews(currPage, pageSize, match);
      });
  };

  /**
   * OnSearch
   *
   */
  const onSearch = ({ match }) => {
    setCurrPage(1);
    setMatch(match);
  };

  ///////////////////////////////////////////////////////////////////////
  //
  //  Lifecycle methods
  //
  ///////////////////////////////////////////////////////////////////////

  return (
    <Main
      header={{
        icon: <RateReview />,
        title: `Reviews for ${process.env.REACT_APP_ORG_NAME}`,
        bonus: (
          <Button variant="outlined" color="primary" href="/app/sales/review">
            Create a new review
          </Button>
        ),
      }}
      loaded={loaded}
    >
      <Box
        display="flex"
        alignItems="baseLine"
        justifyContent="space-between"
        flexWrap="wrap"
        mb={2}
      >
        <LiveSearch match={match} onSearch={onSearch} />
        <Pagination
          currPage={currPage}
          totalRecords={reviewCount}
          pageSize={pageSize}
          pageNeighbours={1}
          onPageChanged={(e) => {
            setCurrPage(e.currPage);
          }}
        />
      </Box>
      <CardList
        xs={12}
        md={6}
        lg={4}
        spacing={4}
        items={reviews}
        onRenderCard={({ item: review }) => (
          <LinkCard2
            onClick={(e) => {
              window.location.href = `/app/crm/review/${review.id}`;
            }}
            header={
              <ReviewHeader
                review={review}
                onUpdate={() => fetchReviews(currPage, pageSize, match)}
              />
            }
            content={<ReviewSummary review={review} />}
            actions={
              <>
                <CardAction label="Edit" to={`/app/crm/review/${review.id}`} />
                <CardAction
                  label="Delete"
                  onClick={() => onDeleteReview(review.id)}
                />
                {!!review.orderId && (
                  <CardAction
                    label="Order"
                    to={`/app/sales/order/${review.orderId}/reviews`}
                  />
                )}
              </>
            }
          />
        )}
      />
    </Main>
  );
};

export default Reviews;
