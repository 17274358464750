/********************************************************************
 *
 * /Sales/Orders/OrderManagement.jsx
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2024 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

import React, { useState } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { Receipt } from "@material-ui/icons";
import { Main } from "../../lib";
import {
  SectionList,
  SectionItemGroup,
  SectionItem,
} from "../../lib/SideBar/Menu";
import Error404 from "../../Error/404";
import Sales from "./Sales";
import Abandoned from "./Abandoned";

/**
 * Orders
 *
 * Container component for managing orders
 *
 */
const OrderManagement = ({ match, location }) => {
  const [loaded, setLoaded] = useState(true);
  const [title, setTitle] = useState("Loading...");

  ///////////////////////////////////////////////////////////////////////
  //
  //  Utility Methods
  //
  ///////////////////////////////////////////////////////////////////////

  ///////////////////////////////////////////////////////////////////////
  //
  //  Event Handlers
  //
  ///////////////////////////////////////////////////////////////////////

  ///////////////////////////////////////////////////////////////////////
  //
  //  Lifecycle methods
  //
  ///////////////////////////////////////////////////////////////////////

  return (
    <Main
      menu={{
        title: "Order Management",
        subtitle: "Review All Orders",
        nav: <Menu />,
      }}
      header={{
        icon: <Receipt />,
        title: title,
        subtitle: "",
        bonus: "",
      }}
      loaded={loaded}
    >
      <Router
        onLoaded={(e) => {
          setLoaded(e);
        }}
        setTitle={(title) => {
          setTitle(title);
        }}
      />
    </Main>
  );
};

/**
 * Renders the navigation controls for the functional areas of the
 * order management page.
 *
 * @param {*} props
 */
const Menu = ({ order }) => {
  const { id = 0 } = order || {};

  return (
    <SectionList id="sidebar-nav">
      <SectionItemGroup icon="" name="Order Types" open={true}>
        <SectionItem to={`/app/sales/orders/sales`}>Sales</SectionItem>
        <SectionItem to={`/app/sales/orders/abandoned`}>Abandoned</SectionItem>
      </SectionItemGroup>
    </SectionList>
  );
};

/**
 * Renders the routing logic for the page
 *
 * @param {*} props
 */
const Router = ({ setTitle, onLoaded }) => {
  return (
    <Switch>
      {/* Sales */}
      <Route
        path="/app/sales/orders/sales"
        // render={(props) => "Sales"}
        render={(props) => (
          <Sales {...props} onLoaded={onLoaded} setTitle={setTitle} />
        )}
      />
      {/* Abandoned */}
      <Route
        path="/app/sales/orders/abandoned"
        // render={(props) => "Abandoned"}
        render={(props) => (
          <Abandoned {...props} onLoaded={onLoaded} setTitle={setTitle} />
        )}
      />
      {/* Redirect */}
      <Route
        path="/app/sales/orders"
        render={() => <Redirect to={`/app/sales/orders/sales`} />}
      />
      <Route path="/app/sales/" component={Error404} />
    </Switch>
  );
};

export default OrderManagement;
