/********************************************************************
 *
 * /lib/Property.jsx
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2020 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

import React, { useEffect, useState } from "react";
import { Paper, Typography, makeStyles } from "@material-ui/core";

const Property = ({
  title,
  data,
  href,
  target,
  children,
  background = "default",
}) => {
  const [backgroundClass, setBackgroundClass] = useState(null);
  const classes = useStyles();

  useEffect(() => {
    switch (background) {
      case "default":
        setBackgroundClass(classes.backgroundDefault);
        break;
      case "error":
        setBackgroundClass(classes.backgroundError);
        break;
      default:
        setBackgroundClass(null);
    }
  }, [background]);

  return (
    <Paper
      className={`${classes.container} ${backgroundClass}`}
      elevation={0}
      href={href}
      target={target}
      component={!!href ? "a" : undefined}
    >
      {!!title && <Typography className={classes.header}>{title}</Typography>}
      {!!data && <Typography className={classes.content}>{data}</Typography>}
      {!!children && children}
    </Paper>
  );
};

/*
 **  Styles
 */
const useStyles = makeStyles((theme) => ({
  container: {
    display: "block",
    padding: theme.spacing(2),
    margin: theme.spacing(2),
  },
  header: { fontSize: "0.85em", fontWeight: 600 },
  content: { fontSize: "0.85em", paddingLeft: theme.spacing(2) },
  backgroundDefault: { backgroundColor: "rgba(1, 137, 159, 0.05)" },
  backgroundError: { backgroundColor: "rgba(255,0,0,0.2)" },
}));

export default Property;
