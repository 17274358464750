import React from "react";
import PropTypes from "prop-types";
import { Switch, Route, NavLink } from "react-router-dom";
import { Navbar, Nav } from "react-bootstrap";

/////////////////////////////////////////////////////////////////////
//
//  SubSectionRouterHelper
//
//  Used by the subsection root pages. Builds the submenu for the
//  section and routes to the appropriate subsection root page.
//
//  Routing information is provided in the JSON formatted tree
//  structure.
//
//  Note that error handling for unmatched URI paths is handled in
//  the SectionRouterHelper component.
//
/////////////////////////////////////////////////////////////////////
const SubSectionRouterHelper = ({ routes, hideMenu }) => {
  const keys = Object.keys(routes.tree);

  return (
    <>
      {!hideMenu && (
        <div className="container">
          {keys && keys.length > 1 && (
            <Navbar id="section-navbar">
              <Nav>
                {keys.map((key) => (
                  <Nav.Link key={key} as={NavLink} to={routes.tree[key].path}>
                    {routes.tree[key].label}
                  </Nav.Link>
                ))}
              </Nav>
            </Navbar>
          )}
        </div>
      )}
      <Switch>
        {keys.map((key) => (
          <Route
            key={key}
            path={routes.tree[key].path}
            render={(props) =>
              React.createElement(routes.tree[key].component, {
                ...props,
              })
            }
          />
        ))}
      </Switch>
    </>
  );
};

SubSectionRouterHelper.propTypes = {
  routes: PropTypes.object.isRequired,
  hideMenu: PropTypes.bool,
};

export default SubSectionRouterHelper;
