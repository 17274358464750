/********************************************************************
 *
 * ProductWaterLevel.jsx
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2020 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import format from "../../utils/format";
import { useApp, useAPI } from "../../providers/AppProvider";

/**
 * ProductWaterLevel
 *
 * Displays the Product Water Level
 *
 * @param {*} param0
 */
const ProductWaterLevel = ({ product }) => {
  const [waterLevels, setWaterLevels] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const app = useApp();
  const api = useAPI();

  useEffect(() => {
    fetchWaterLevels();
  }, []);

  const fetchWaterLevels = () => {
    //
    //  Request searches
    //
    api
      .fetch(`/api/products/product/${product.id}/tides/`)
      .then(({ payload: waterLevels }) => {
        setWaterLevels(waterLevels);
        setLoaded(true);
      })
      .catch((error) => {
        app.error({ error });
        setLoaded(true);
      });
  };

  if (!waterLevels) return "...";

  return (
    <div>
      {`${format.waterLevel(waterLevels.start)} / ${format.waterLevel(
        waterLevels.end
      )}`}
      {waterLevels.warning ? (
        <FontAwesomeIcon
          className="ml-1"
          icon={faExclamationTriangle}
          color="orange"
        />
      ) : (
        ""
      )}
    </div>
  );
};

export default ProductWaterLevel;
