/********************************************************************
 *
 * /Sales/Campaigns/CouponGenerator.jsx
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2020 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

import React, { useState, useEffect } from "react";
import { useApp, useAPI } from "../../../providers/AppProvider";

/**
 * CouponGenerator
 *
 * Generates coupons
 */
const CouponGenerator = () => {
  const [code, setCode] = useState(null);
  const [autogenerate, setAutogenerate] = useState(false);
  const [amount, setAmount] = useState(null);
  const [percent, setPercent] = useState(false);
  const [reusable, setReusable] = useState(true);
  const [quantity, setQuantity] = useState(null);
  const [account, setAccount] = useState(null);
  const [accounts, setAccounts] = useState(null);
  const [productTypes, setProductTypes] = useState(null);
  const [productType, setProductType] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const app = useApp();
  const api = useAPI();

  useEffect(() => {
    initialize();
  }, []);

  ///////////////////////////////////////////////////////////////////////
  //
  //  Utility Methods
  //
  ///////////////////////////////////////////////////////////////////////

  const initialize = () => {
    Promise.all([
      api.fetch(`/api/accounts/type/6`),
      api.fetch(`/api/producttypes`),
    ])
      .then(([{ payload: accounts }, { payload: productTypes }]) => {
        setAccounts(accounts);
        setProductTypes(productTypes);
        setLoaded(true);
      })
      .catch((error) => {
        app.error({ error });
        setLoaded(true);
      });
  };

  ///////////////////////////////////////////////////////////////////////
  //
  //  Event Handlers
  //
  ///////////////////////////////////////////////////////////////////////

  const onCreateCoupons = (event) => {
    event.preventDefault();
  };

  ///////////////////////////////////////////////////////////////////////
  //
  //  Lifecycle methods
  //
  ///////////////////////////////////////////////////////////////////////

  return (
    <div className="card text-right mb-3">
      <div className="card-body">
        <div className="card-title h6 text-uppercase">Coupon Generator</div>
        <form className="needs-validation text-left" onSubmit={onCreateCoupons}>
          <div className="form-group">
            <label htmlFor="code">Code</label>
            <input
              id="code"
              className="form-control"
              type="text"
              placeholder="Coupon Code"
              onBlur={(e) => setCode(e.target.value)}
              required
            />
            <small className="form-text text-muted">
              Enter a Unique Code for the Coupon
            </small>
          </div>
          <div className="form-group">
            <div className="custom-control custom-switch">
              <input
                id="autogenerate"
                className="custom-control-input"
                type="checkbox"
                checked={autogenerate}
                onChange={(e) => setAutogenerate(e.target.checked)}
              />
              <label className="custom-control-label" htmlFor="autogenerate">
                Auto-Generate Code Suffix
              </label>
            </div>
            <small className="form-text text-muted">
              Appends an automatically generated string to every coupon code
              (required if generating more than one non-reusable coupon)
            </small>
          </div>
          <hr />
          <div className="form-group">
            <label htmlFor="amount">Amount</label>
            <div className="input-group mb-3">
              {percent ? (
                ""
              ) : (
                <div className="input-group-prepend">
                  <span className="input-group-text">$</span>
                </div>
              )}
              <input
                id="amount"
                className="form-control text-right"
                type="number"
                onBlur={(e) => setAmount(e.target.value)}
                required
              />
              {percent ? (
                <div className="input-group-append">
                  <span className="input-group-text">%</span>
                </div>
              ) : (
                ""
              )}
            </div>
            <small className="form-text text-muted">
              Enter Amount of Discount
            </small>
          </div>
          <div className="form-group">
            <div className="custom-control custom-switch">
              <input
                id="percent"
                className="custom-control-input"
                type="checkbox"
                checked={percent}
                onChange={(e) => setPercent(e.target.checked)}
              />
              <label className="custom-control-label" htmlFor="percent">
                Percentage
              </label>
            </div>
            <small className="form-text text-muted">
              Select if the amount is a percentage discount
            </small>
          </div>
          <hr />
          <div className="form-group">
            <label htmlFor="quantity">Product</label>
            {!productTypes ? (
              "Waiting..."
            ) : (
              <select
                id="producttype"
                className="form-control"
                onChange={(e) => setProductType(e.target.value)}
                defaultValue="0"
              >
                <option value="0" disabled>
                  -- All --
                </option>
                {productTypes.map((productType, index) => (
                  <option key={productType.id} value={index}>
                    {productType.name}
                  </option>
                ))}
              </select>
            )}
            <small className="form-text text-muted">Enter Product Type</small>
          </div>
          <hr />
          <div className="form-group">
            <div className="custom-control custom-switch">
              <input
                id="reusable"
                className="custom-control-input"
                onChange={(e) => setReusable(e.target.checked)}
                type="checkbox"
                checked={reusable}
              />
              <label className="custom-control-label" htmlFor="reusable">
                Reusable
              </label>
            </div>
            <small className="form-text text-muted">
              Select if the same coupon code can be reused by multiple customers
            </small>
          </div>
          <div className="form-group">
            <label htmlFor="quantity">Quantity</label>
            <input
              id="quantity"
              className="form-control text-right"
              type="number"
              disabled={reusable}
              onBlur={(e) => setQuantity(e.target.value)}
            />
            <small className="form-text text-muted">
              Enter Number of Coupons to issue
            </small>
          </div>
          <div className="form-group">
            <label htmlFor="quantity">Account</label>
            {!accounts ? (
              "Waiting..."
            ) : (
              <select
                id="account"
                className="form-control"
                onChange={(e) => setAccount(e.target.value)}
                defaultValue="-1"
              >
                <option value="-1" disabled>
                  -- Select --
                </option>
                {accounts.map((account, index) => (
                  <option key={account.id} value={index}>
                    {account.name}
                  </option>
                ))}
              </select>
            )}
            <small className="form-text text-muted">Choose Account</small>
          </div>
          <div>
            <input
              className={`btn btn-primary`}
              type="submit"
              value="Generate"
            />
          </div>
        </form>
      </div>
    </div>
  );
};

export default CouponGenerator;
