/********************************************************************
 *
 * SectionRouterHelper.jsx
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2019 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

import React from "react";
import PropTypes from "prop-types";
import { Switch, Route, Redirect, withRouter } from "react-router-dom";
import Error404 from "./Error/404";

/////////////////////////////////////////////////////////////////////
//
//  SectionRouterHelper
//
//  Builds a router using a JSON format routing tree.
//
//  A submenu will be built with the Section names and URIs. The
//  paths of the children will have routing initialized.
//
//  { <Section1Name>:
//      { path: <uriPath>,
//        component: <component>,
//        children:
//          {
//            path: <uriPath>,
//            component: <component
//          }
//      },
//    <Section2Name>: ...
//  }
//
/////////////////////////////////////////////////////////////////////
const SectionRouterHelper = ({ routes, subSectionRouter }) => {
  const keys = Object.keys(routes.tree);

  if (!keys) return;

  return (
    <>
      <Switch>
        {
          //
          //  If page is the root of a subsection, route to
          //  subsection router to render the subsection menu before
          //  routing to the component.
          //
          keys.map((key, index) => (
            <Route
              key={index}
              path={routes.tree[key].path}
              render={(props) =>
                React.createElement(subSectionRouter, {
                  ...props,
                })
              }
              exact
            />
          ))
        }
        {
          //
          //  If page is the child of a subsection, route directly
          //  to the component
          //
          keys.map(
            (key) =>
              routes.tree[key].children &&
              routes.tree[key].children.map((child) => (
                <Route
                  key={child.path}
                  path={child.path}
                  render={(props) =>
                    React.createElement(child.component, {
                      ...props,
                    })
                  }
                />
              ))
          )
        }
        <Route
          path={routes.root}
          render={() => <Redirect to={routes.tree[keys[0]].path} />}
        />
        <Route path="/" component={Error404} />
      </Switch>
    </>
  );
};

SectionRouterHelper.propTypes = {
  routes: PropTypes.object.isRequired,
  subSectionRouter: PropTypes.oneOfType([PropTypes.element, PropTypes.func])
    .isRequired,
};

export default withRouter(SectionRouterHelper);
