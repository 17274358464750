/********************************************************************
 *
 * Day.jsx
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2019 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

import React from "react";
import { makeStyles } from "@material-ui/core";
import format from "../../../utils/format";
import moment from "moment-timezone";

/**
 * Day
 *
 * Renders a day on the calendar grid. A day has continaing DIV and
 * inner DIV.
 *
 */
const Day = ({ date, selectedDate, onDateRender, onClickDate }) => {
  const classes = useStyles();

  if (!date) return null;

  return (
    <div className={classes.day}>
      <div
        className={`${classes.inner} ${
          moment(date).isSame(selectedDate, "day") ? classes.selected : ""
        } ${moment(date).isBefore(moment(), "day") ? classes.past : ""}`}
        onClick={() => {
          onClickDate({ date: date.format("YYYY-MM-DD") });
        }}
      >
        {onDateRender
          ? onDateRender({ date: date.format("YYYY-MM-DD") })
          : format.mday(date)}
      </div>
    </div>
  );
};

const useStyles = makeStyles({
  day: {
    width: "14.2857% !important",
    display: "inline-block !important",
    position: "relative !important",
    fontSize: "0.9em",
    borderWidth: "0.5px !important",
    borderStyle: "solid !important",
    borderColor:
      "transparent transparent transparent rgb(235, 235, 235) !important",
    borderImage: "initial !important",
    cursor: "pointer",
  },
  inner: {
    position: "absolute !important",
    backgroundColor: "rgb(255, 255, 255)",
    top: "0px !important",
    bottom: "0px !important",
    left: "0px !important",
    right: "0px !important",
    padding: "0em !important",
    "&:hover": {
      backgroundColor: "#eeeeee",
    },
  },
  selected: {
    border: "1px solid #009900",
  },
  past: {
    backgroundColor: "#f8f8f8",
  },
});
export default Day;
