/********************************************************************
 *
 * /CRM/Reviews/Identity/Edit.jsx
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2019 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

import React, { useState, useEffect } from "react";
import {
  Grid,
  Box,
  FormControl,
  FormControlLabel,
  TextField,
  Select,
  Switch,
  MenuItem,
  InputLabel,
  Button,
} from "@material-ui/core";
import { Rating } from "@material-ui/lab/";
import moment from "moment-timezone";
import { Formik } from "formik";
import * as yup from "yup";
import format from "../../../../utils/format";
import { HorizontalGroup } from "../../../lib";
import { useApp, useAPI } from "../../../../providers/AppProvider";

/**
 * ReviewEdit
 *
 * Addsof edits a review
 */
const ReviewEdit = ({
  review = {
    lead: "",
    content: "",
    rating: 4.5,
    author: "",
    location: "",
    date: moment().format("YYYY-MM-DD"),
    display: false,
    image: null,
    url: null,
    customerId: null,
    sourceId: null,
  },
  order,
  onSaved,
  onCancelled,
}) => {
  const app = useApp();
  const api = useAPI();

  /////////////////////////////////////////////////////////////////////
  //
  //  Utility methods
  //
  /////////////////////////////////////////////////////////////////////

  ///////////////////////////////////////////////////////////////////////
  //
  //  Event Handlers
  //
  ///////////////////////////////////////////////////////////////////////

  /**
   * OnSave
   *
   * Fired when the user commits the change.
   *
   * @param {*} values
   */
  const onSave = (values) => {
    //
    //  Save
    //
    const notifyHandle = app.notify("Saving Review");

    api
      .update(`/api/reviews/review/${review.id ? review.id : ""}`, values)
      .then(({ payload: review }) => {
        onSaved && onSaved(review);
        app.endNotify(notifyHandle);
      })
      .catch((error) => {
        app.error({ error });
      });
  };

  /**
   * OnCancel
   *
   * Fired when the user aborts changes.
   *
   */
  const onCancel = (dirty) => {
    if (
      !dirty ||
      window.confirm(
        "You have unsaved changes. Are you sure you want to close this dialog and loose your changes?"
      )
    ) {
      onCancelled && onCancelled();
    }
  };

  ///////////////////////////////////////////////////////////////////////
  //
  //  Lifecycle methods
  //
  ///////////////////////////////////////////////////////////////////////

  return (
    <Formik
      initialValues={review}
      enableReinitialize={true}
      onSubmit={(values) => {
        if (order) values.orderId = order.id;
        onSave(values);
      }}
      validationSchema={yup.object().shape({
        author: yup
          .string()
          .nullable()
          .required("Author display name is required"),
        location: yup
          .string()
          .nullable()
          .required("Customer location is required"),
        image: yup.string().nullable().url("Image is not a valid URL"),
        date: yup.date("Date format is invalid"),
        url: yup.string().nullable().url("Review link is not a valid URL"),
        lead: yup.string().nullable().required("Review lead is required"),
        content: yup.string().nullable().required("Review lead is required"),
      })}
    >
      {({
        values,
        touched,
        errors,
        dirty,
        isSubmitting,
        setFieldValue,
        handleChange,
        handleBlur,
        handleSubmit,
      }) => {
        return (
          <form onSubmit={handleSubmit}>
            <Box display="flex" justifyContent="flex-end">
              <HorizontalGroup margin="ml1">
                <Button
                  type="submit"
                  variant="outlined"
                  color="primary"
                  disabled={!dirty || isSubmitting}
                >
                  Save
                </Button>
                <Button onClick={() => onCancel(dirty)}>Cancel</Button>
              </HorizontalGroup>
            </Box>
            <ReviewForm
              review={values}
              rate={values}
              touched={touched}
              errors={errors}
              setFieldValue={setFieldValue}
              onBlur={handleBlur}
              onChange={handleChange}
            />
          </form>
        );
      }}
    </Formik>
  );
};

const ReviewForm = ({
  review,
  touched,
  errors,
  setFieldValue,
  onBlur,
  onChange,
}) => {
  const [reviewSources, setReviewSources] = useState(null);
  const [hoverValue, setHoverValue] = useState(-1);
  const app = useApp();
  const api = useAPI();

  useEffect(() => {
    fetchReviewSources();
  }, []);

  /////////////////////////////////////////////////////////////////////
  //
  //  Utility methods
  //
  /////////////////////////////////////////////////////////////////////

  /**
   * FetchReviewSources
   *
   * Calls the api to fetch review sources
   */
  const fetchReviewSources = () => {
    //
    //  Request review sources
    //
    api
      .fetch(`/api/reviewSources/`)
      .then(({ payload: reviewSources }) => {
        setReviewSources(reviewSources);
      })
      .catch((error) => {
        app.error({ error });
      });
  };

  return (
    <Box>
      <Box
        display="flex"
        justifyContent="space-between"
        flexWrap="no-wrap"
        className="pt-4"
      >
        <Box>
          <FormControlLabel
            label="Display"
            control={
              <Switch
                id="display"
                checked={review.display}
                onChange={(e) => {
                  setFieldValue("display", !review.display);
                }}
              />
            }
          />
        </Box>
        <Box>
          <Box display="flex" flexWrap="no-wrap" alignItems="center">
            <Rating
              id="rating"
              value={review.rating}
              precision={0.5}
              onChangeActive={(event, hoverValue) => setHoverValue(hoverValue)}
              onChange={(event, value) => {
                setFieldValue("rating", value);
              }}
            />
            {(!!review.rating || hoverValue) && (
              <Box ml={2}>
                {parseFloat(
                  hoverValue !== -1 ? hoverValue : review.rating
                ).toFixed(1)}
              </Box>
            )}
          </Box>
        </Box>
      </Box>
      <TextField
        id="author"
        type="text"
        label="Author"
        placeholder="Author"
        value={review.author}
        onBlur={onBlur}
        onChange={onChange}
        fullWidth
        required
        error={errors.author && touched.author}
        helperText={errors.author || " "}
      />
      <TextField
        id="location"
        type="text"
        label="Location"
        placeholder="Location"
        value={review.location}
        onBlur={onBlur}
        onChange={onChange}
        fullWidth
        required
        error={errors.location && touched.location}
        helperText={errors.location || " "}
      />
      <TextField
        id="image"
        type="url"
        label="Image URL"
        placeholder="Image URL"
        value={review.image}
        onBlur={onBlur}
        onChange={onChange}
        fullWidth
        error={errors.image && touched.image}
        helperText={errors.image || " "}
      />
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <FormControl fullWidth>
            <InputLabel id="source-label" shrink>
              Review Source
            </InputLabel>
            <Select
              inputProps={{
                name: "source",
                id: "source",
              }}
              labelId="source-label"
              onChange={(e) => {
                setFieldValue(
                  "source",
                  reviewSources.find(
                    (reviewSource) => reviewSource.id == e.target.value
                  )
                );
              }}
              value={review.source ? review.source.id : null}
            >
              <MenuItem value={null}>Other</MenuItem>
              {!!reviewSources &&
                reviewSources.map((reviewSource) => (
                  <MenuItem key={reviewSource.id} value={reviewSource.id}>
                    {reviewSource.name}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            id="date"
            type="date"
            label="Date"
            value={format.toApiDate(review.date)}
            onBlur={onBlur}
            onChange={onChange}
            fullWidth
            required
            error={errors.date && touched.date}
            helperText={errors.date || " "}
          />
        </Grid>
      </Grid>
      <TextField
        id="url"
        type="url"
        label="Url"
        placeholder="URL"
        value={review.url}
        onBlur={onBlur}
        onChange={onChange}
        fullWidth
        error={errors.url && touched.url}
        helperText={errors.url || " "}
      />
      <TextField
        id="lead"
        label="Lead"
        value={review.lead}
        onBlur={onBlur}
        onChange={onChange}
        multiline
        rows="2"
        variant="outlined"
        fullWidth
        required
        error={errors.lead && touched.lead}
        helperText={errors.lead || " "}
      />
      <TextField
        id="content"
        label="Content"
        value={review.content}
        onBlur={onBlur}
        onChange={onChange}
        multiline
        rows="6"
        variant="outlined"
        fullWidth
        required
        error={errors.content && touched.content}
        helperText={errors.content || " "}
      />
    </Box>
  );
};

export default ReviewEdit;
