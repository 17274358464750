/********************************************************************
 *
 * EmployeeSummary.jsx
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2019 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

import React from "react";
import { Box, Avatar, Paper, Typography, makeStyles } from "@material-ui/core";
import { CardContentHeader } from "../../lib";

/**
 *  EmployeeSummaryHeader
 *
 *  Renders a summary of the employee
 *
 * @param {*} param0
 */
const EmployeeSummaryHeader = ({ employee }) => {
  const classes = styles();

  if (!employee) return "";

  return (
    <CardContentHeader
      avatar={
        <Avatar className={classes.avatar}>
          {employee.fname && employee.fname[0]}
          {employee.lname && employee.lname[0]}
        </Avatar>
      }
      content={
        <Box>
          <Typography variant="h4">
            {employee.fname} {employee.lname}
          </Typography>
          <Typography variant="h6">{employee.role}</Typography>
        </Box>
      }
    />
  );
};

/**
 *  EmployeeSummary
 *
 *  Renders a summary of the employee
 *
 * @param {*} param0
 */
const EmployeeSummary = ({ employee }) => {
  const classes = styles();

  if (!employee) return "";

  return (
    <Paper className={classes.contactContainer} elevation={0}>
      <Box display="flex" justifyContent="space-between">
        <Box className={classes.contactTitle}>Email:</Box>
        <Box>
          <Typography className={classes.contactInfo}>
            {employee.email}
          </Typography>
        </Box>
      </Box>
      <Box display="flex" justifyContent="space-between">
        <Box className={classes.contactTitle}>Phone:</Box>
        <Box>
          <Typography className={classes.contactInfo}>
            {employee.phone}
          </Typography>
        </Box>
      </Box>
    </Paper>
  );
};

/*
 **  Styles
 */
const styles = makeStyles((theme) => ({
  avatar: { height: "75px", width: "75px" },
  contactContainer: {
    padding: theme.spacing(2),
    backgroundColor: "rgba(1, 137, 159, 0.05)",
    width: "100%",
  },
  contactTitle: { fontSize: "0.85em", lineHeight: "2em", fontWeight: 500 },
  contactInfo: {
    fontSize: "0.85em",
    lineHeight: "2em",
    color: theme.palette.text.secondary,
    fontWeight: 300,
  },
}));

export { EmployeeSummaryHeader, EmployeeSummary };
