/********************************************************************
 *
 * /components/crm/customers/identity/travelDetails.jsx
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2020 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

import React, { useState } from "react";
import { Grid, IconButton } from "@material-ui/core";
import { Edit } from "@material-ui/icons";
import { WidgetFrame, Property } from "../../../lib";
import TravelDetailsEdit from "./TravelDetailsEdit";

/**
 * TravelDetails
 *
 * Component that displays, adds and edits the descriptive information
 * for a customer.
 *
 */
const TravelDetails = ({ customer, onUpdate }) => {
  const [edit, setEdit] = useState(false);

  ///////////////////////////////////////////////////////////////////
  //
  //  Event Handlers
  //
  ///////////////////////////////////////////////////////////////////

  /**
   * onSaved
   *
   * Fired once the edit state has saved the descriptive information.
   *
   * Returns to display mode.
   *
   * @param {*} event
   */
  const onSaved = (event) => {
    onUpdate && onUpdate(event);
    setEdit(false);
  };

  /**
   * onCancelled
   *
   * Fired once the edit state has aboorted changes to the descriptive
   * information.
   *
   * Returns to display mode.
   *
   */
  const onCancelled = () => {
    setEdit(false);
  };

  ///////////////////////////////////////////////////////////////////
  //
  //  Lifecycle methods
  //
  ///////////////////////////////////////////////////////////////////

  return (
    <WidgetFrame
      title="Travel Details"
      action={
        !!onUpdate && (
          <IconButton onClick={() => setEdit(true)}>
            <Edit />
          </IconButton>
        )
      }
    >
      <>
        <Grid container spacing={2}>
          {!!customer.isMinor && (
            <>
              <Grid item xs={4}>
                <Property
                  title="Age"
                  data={customer.age ? customer.age : "Not specified"}
                />
              </Grid>
              <Grid item xs={4}>
                <Property
                  title="Height"
                  data={customer.height ? customer.height : "Unkonwn"}
                />
              </Grid>
              <Grid item xs={4}>
                <Property
                  title="Weight"
                  data={customer.weight ? customer.weight : "Unknown"}
                />
              </Grid>
            </>
          )}
          <Grid item xs={12}>
            <Property title="Allergies">
              {customer.allergies ? customer.allergies : "No Allergies"}
            </Property>
          </Grid>
          <Grid item xs={12}>
            <Property title="Medical">
              {customer.medical ? customer.medical : "No Known Medical"}
            </Property>
          </Grid>
          <Grid item xs={12}>
            <Property title="Requests">
              {customer.requests ? customer.requests : "None"}
            </Property>
          </Grid>
          <Grid item xs={12}>
            <Property title="Comments">
              {customer.comments ? customer.comments : "None"}
            </Property>
          </Grid>
        </Grid>
        {!customer.deleted && onUpdate && edit && (
          <TravelDetailsEdit
            customer={customer}
            show={edit}
            onSaved={onSaved}
            onCancelled={onCancelled}
          />
        )}
      </>
    </WidgetFrame>
  );
};

export default TravelDetails;
