/********************************************************************
 *
 * /Sales/Campaigns/Identity/Summary.jsx
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2021 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

import React from "react";
import { Typography } from "@material-ui/core";

/**
 * CampaignSummary
 *
 * Displays summary information for a Campaign.
 *
 * @param {*} props
 */
const CampaignSummary = ({ campaign, digest }) => {
  return (
    <Typography variant="body2" paragraph>
      {campaign.description}
    </Typography>
  );
};

export default CampaignSummary;
