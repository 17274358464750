/********************************************************************
 *
 * Products.jsx
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2020 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  Paper,
  Grid,
  Box,
  Card,
  Typography,
  Button,
  makeStyles,
} from "@material-ui/core";
import { AddShoppingCart } from "@material-ui/icons";
import moment from "moment-timezone";
import { WidgetFrame, TipChip } from "../../../lib";
import Calendar from "../../../lib/Calendar/Calendar";
import format from "../../../../utils/format";
import { useApp, useAPI } from "../../../../providers/AppProvider";
import ProductFactory from "./ProductFactory";

/**
 * Products
 *
 * Renders a calendar-based view of the products belonging to
 * a product type.
 *
 */
const Products = ({ productType }) => {
  const classes = styles();
  const [products, setProducts] = useState(null);
  const [selectedDate, setSelectedDate] = useState(
    moment().format("YYYY-MM-DD")
  );
  const [tz] = useState("America/Vancouver");
  const [dateRange, setDateRange] = useState(null);
  const [showAddProduct, setShowAddProduct] = useState(false);
  const app = useApp();
  const api = useAPI();

  ///////////////////////////////////////////////////////////////////////
  //
  //  Utility Methods
  //
  ///////////////////////////////////////////////////////////////////////

  /**
   * FetchProducts
   *
   * Calls the API to fetch the products for the dates visible on the
   * calendar.
   *
   * @param {*} startDate
   * @param {*} endDate
   */
  const fetchProducts = (startDate, endDate) => {
    let _startDate, _endDate;

    //
    //  Status message
    //
    const notifyHandle = app.notify("Loading");

    return new Promise((resolve, reject) => {
      if (!startDate) throw new Error("Start date not defined");
      if (!endDate) throw new Error("End date not defined");

      //
      //  Request data for the timezone-adjusted start of day
      //
      _startDate = format.getMomentLocalized(startDate, tz).startOf("day");
      _endDate = format.getMomentLocalized(endDate, tz).endOf("day");

      if (!_startDate.isValid()) throw new Error("Invalid start date");
      if (!_endDate.isValid()) throw new Error("Invalid end date");

      if (_endDate.isBefore(_startDate)) {
        resolve(null);
        return;
      }

      //
      //  Request searches
      //
      api
        .fetch(
          `/api/producttypes/producttype/${
            productType.id
          }/products/daterange/${format.toApiDateTime(
            _startDate
          )}/${format.toApiDateTime(_endDate)}`
        )
        .then(({ payload: products }) => {
          setProducts(products);
          app.endNotify(notifyHandle);
        })
        .catch((error) => {
          app.error({ error });
          setProducts(null);
        });
    });
  };

  ///////////////////////////////////////////////////////////////////////
  //
  //  Event Handlers
  //
  ///////////////////////////////////////////////////////////////////////

  /**
   * OnCalendarUpdate
   *
   * Fired when the dates on the calendar change.
   *
   * Fetches products for dates visible on the calendar.
   *
   * @param {*} event
   */
  const onCalendarUpdate = (event) => {
    setDateRange(event);

    if (event && event.startDate && event.endDate)
      fetchProducts(event.startDate, event.endDate);
  };

  const onRefresh = () => {
    if (dateRange) fetchProducts(dateRange.startDate, dateRange.endDate);
  };

  /**
   * OnDateRender
   *
   * Fired when a calendar date renders.
   *
   * Determines if there are tours on the specified date and
   * marks up the calendar accordingly.
   *
   * @param {*} event
   */
  const onDateRender = ({ date }) => {
    let dayProducts = [];

    for (let i = 0; products && i < products.length; i++) {
      let product = products[i];
      if (
        moment
          .tz(product.start, product.timezone)
          .isSame(moment.tz(date, product.timezone), "day")
      )
        dayProducts.push(product);
    }

    return (
      <div
        className={`${classes.dayWrapper} ${
          dayProducts.length && classes.workday
        }`}
      >
        <Typography variant="body2">{format.mday(date)}</Typography>
        <div className={classes.dayContent}>
          {dayProducts.map((dayProduct, index) => (
            <Typography
              className={classes.productTime}
              component="div"
              key={index}
            >
              {format.time(dayProduct.start, dayProduct.timezone)}
            </Typography>
          ))}
        </div>
      </div>
    );
  };

  /**
   * onSelectedDateChanged
   *
   * Fired when the user clicks a date cell in the calendar.
   *
   * @param {event} event
   */
  const onSelectedDateChanged = ({ date }) => {
    setSelectedDate(date);
  };

  ///////////////////////////////////////////////////////////////////////
  //
  //  Lifecycle methods
  //
  ///////////////////////////////////////////////////////////////////////

  return (
    <Box>
      <Grid container spacing={4} wrap="nowrap">
        <Grid item xs={6}>
          <WidgetFrame>
            <Calendar
              date={undefined}
              width={500}
              onCalendarUpdating={onCalendarUpdate}
              onDateRender={onDateRender}
              onSelectedDateChanged={onSelectedDateChanged}
            />
          </WidgetFrame>
        </Grid>
        <Grid item xs={6}>
          <WidgetFrame>
            <Agenda products={products} date={selectedDate} tz={tz} />
          </WidgetFrame>
        </Grid>
      </Grid>
      {dateRange && (
        <Box mt={2}>
          <Button
            variant="outlined"
            onClick={() => {
              setShowAddProduct(true);
            }}
          >
            Add Products
          </Button>
          {showAddProduct && (
            <ProductFactory
              productType={productType}
              start={dateRange.startDate}
              end={dateRange.endDate}
              show={showAddProduct}
              onClose={() => {
                setShowAddProduct(false);
                onRefresh();
              }}
            />
          )}
        </Box>
      )}
    </Box>
  );
};

/**
 * Agenda
 *
 * @param {*} props
 */
const Agenda = ({ products, date, tz }) => {
  const classes = agendaStyles();

  return (
    <>
      <Typography variant="h4" paragraph>
        {date ? format.dayDate(date, tz) : "No date selected"}
      </Typography>
      <hr />
      <Grid container spacing={2}>
        {products &&
          products.map(
            (product, index) =>
              moment(product.start).isSameOrBefore(moment(date), "day") &&
              moment(product.end).isSameOrAfter(moment(date), "day") && (
                <Grid item xs={12} key={index}>
                  <AgendaItem key={index} product={product} />
                </Grid>
              )
          )}
      </Grid>
    </>
  );
};

const AgendaItem = ({ product }) => {
  const classes = agendaStyles();
  const _start = moment(product.start);
  const _end = moment(product.end);

  return (
    <Card elevation={0}>
      <Link
        className={classes.agendaItemWrapper}
        to={`/app/catalog/product/${product.id}`}
      >
        <Grid container justify="space-between">
          <Grid item xs={6}>
            <Typography variant="subtitle1">Product {product.id}</Typography>
            {_start.isSame(_end, "day") ? (
              <Typography variant="subtitle2" component="span">
                {format.time(_start, product.timezone)} -{" "}
                {format.time(_end, product.timezone)}
              </Typography>
            ) : (
              <TipChip label={"multiday"} tip="Multi Day Product" />
            )}
          </Grid>
          <Grid item className={classes.right} xs={6}>
            <TipChip
              label={product.available}
              icon={<AddShoppingCart />}
              tip="Available"
            />
          </Grid>
          <Grid item xs={12}>
            {Object.keys(product.features).map((key, index) => (
              <Grid container className={classes.feature} key={index}>
                <Grid item className={classes.featureTime}>
                  <Typography variant="body2">
                    {`${format.time(
                      product.features[key].start
                    )} - ${format.time(product.features[key].end)}`}
                  </Typography>
                </Grid>
                <Grid item className={classes.featureName}>
                  <Typography variant="body2">
                    {product.features[key].name} ({product.features[key].goodId}
                    )
                  </Typography>
                  <Typography variant="body2">
                    {format.duration(
                      moment.duration(_end.diff(_start)).asMinutes()
                    )}
                  </Typography>
                </Grid>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Link>
    </Card>
  );
};

/*
 **  Styles
 */
const styles = makeStyles((theme) => ({
  calendar: { flex: "0 0 500px" },
  dayWrapper: { paddingLeft: "2px", height: "100%", width: "100%" },
  workday: {
    backgroundColor: "#def1ed",
  },
  dayContent: { margin: "0.1em" },
  productTime: { fontSize: "10px", lineHeight: "10px" },
}));

const agendaStyles = makeStyles((theme) => ({
  agenda: { paddingBottom: theme.spacing(2) },
  right: { textAlign: "right" },
  agendaItemWrapper: {
    display: "block",
    padding: theme.spacing(2),
    "&:hover": {
      backgroundColor: theme.palette.background.default,
      color: "#666666",
      textDecoration: "none",
    },
  },
  available: {
    textAlign: "right",
  },
  feature: {
    borderTop: "solid 1px #cccccc",
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  featureName: { flex: "1 1 auto" },
  featureTime: { flex: "0 0 100px" },
}));

export default Products;
