/********************************************************************
 *
 * ConfigSwitchTextField.jsx
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2019 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

import React from "react";
import PropTypes from "prop-types";
import {
  Box,
  Typography,
  Switch,
  TextField,
  makeStyles,
} from "@material-ui/core";

const ConfigSwitchTextField = ({
  title,
  switchId,
  switchValue,
  valueId,
  value,
  helperText,
  onChangeConfig,
}) => {
  const classes = styles();

  ///////////////////////////////////////////////////////////////////////
  //
  //  Lifecycle methods
  //
  ///////////////////////////////////////////////////////////////////////

  return (
    <div className={classes.container}>
      <Typography>{title}</Typography>
      <Box display="flex" alignItems="baseline">
        <Switch
          name={switchId}
          className={classes.switch}
          checked={switchValue}
          value="1"
          onChange={({ target }) => {
            onChangeConfig({
              key: target.name,
              value: target.checked ? 1 : 0,
            });
          }}
        />
        <TextField
          name={valueId}
          className={classes.input}
          value={value}
          onBlur={({ target }) =>
            onChangeConfig({ key: target.name, value: target.value })
          }
          helperText={helperText}
        />
      </Box>
    </div>
  );
};

/*
 **  Styles
 */
const styles = makeStyles((theme) => ({
  container: { paddingBottom: theme.spacing(2) },
  switch: {
    marginRight: theme.spacing(2),
  },
  input: { width: "100%" },
}));

/*
 **  PropTypes
 */
ConfigSwitchTextField.propTypes = {
  title: PropTypes.string,
  switchId: PropTypes.string.isRequired,
  switchValue: PropTypes.any.isRequired,
  valueId: PropTypes.string.isRequired,
  value: PropTypes.any.isRequired,
  helperText: PropTypes.string,
  onChangeConfig: PropTypes.func.isRequired,
};

export default ConfigSwitchTextField;
