/********************************************************************
 *
 * AppProvider.jsx
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2019 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

import React, { useReducer } from "react";
import { IconButton, makeStyles } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { Alert } from "@material-ui/lab";
import { useSnackbar } from "notistack";

import CCAPIs from "../utils/CCAPIs";

import { useAuth } from "./AuthProvider";

const AppContext = React.createContext({
  notify: () => null,
  endNotify: () => null,
  log: () => null,
  debug: () => null,
  warning: () => null,
  error: () => null,
});

/**
 * AppProvider
 *
 * @param {*} params
 */
const AppProvider = ({ children }) => {
  const [alerts] = useReducer((state, action) => {
    return [
      ...state,
      {
        timestamp: Date.now(),
        message: action.payload.message,
        severity: action.type,
      },
    ];
  }, []);

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const classes = useStyles();

  ///////////////////////////////////////////////////////////////////////
  //
  //  API Methods
  //
  ///////////////////////////////////////////////////////////////////////

  const notify = (message) => {
    return enqueueSnackbar(message, {
      variant: "default",
    });
  };

  const endNotify = (key) => {
    closeSnackbar(key);
  };

  const log = (message) => {
    // BUGBUG: Add application logging
    console.log(message);
  };

  const debug = (message) => {
    if (process.env.NODE_ENV === "development")
      return enqueueSnackbar(message, {
        variant: "info",
      });
  };

  const warning = (message) => {
    return enqueueSnackbar(message, {
      variant: "warning",
    });
  };

  const error = ({ error, component }) => {
    if (!error) throw new Error("Error handler needs an error object");

    return enqueueSnackbar(error.message, {
      variant: "error",
      persist: true,
      action: (key) => (
        <IconButton
          size="small"
          color="inherit"
          onClick={() => closeSnackbar(key)}
        >
          <Close fontSize="small" />
        </IconButton>
      ),
    });
  };

  ///////////////////////////////////////////////////////////////////////
  //
  //  Lifecycle methods
  //
  ///////////////////////////////////////////////////////////////////////

  return (
    <AppContext.Provider
      value={{ notify, endNotify, log, debug, warning, error }}
    >
      {/* Message Handling */}
      {alerts.length > 0 &&
        alerts.map((alert) => (
          <Alert
            key={alert.timestamp}
            className={classes.alert}
            variant="standard"
            severity={alert.severity}
          >
            {alert.message}
          </Alert>
        ))}
      {children}
    </AppContext.Provider>
  );
};

/*
 **  Hooks
 */
const useApp = () => {
  return React.useContext(AppContext);
};

const useAPI = () => {
  let auth = useAuth();

  return CCAPIs(
    process.env.REACT_APP_ADMIN_API_URL,
    auth.getResourceAccessToken(process.env.REACT_APP_AUTH_RESOURCE_ADMIN_API)
  );
};

const useAnalyticsAPI = () => {
  const auth = useAuth();

  return CCAPIs(
    process.env.REACT_APP_ANALYTICS_API_URL,
    auth.getResourceAccessToken(
      process.env.REACT_APP_AUTH_RESOURCE_ANALYTICS_API
    )
  );
};

const useAccounts = () => {
  const auth = useAuth();

  return CCAPIs(
    process.env.REACT_APP_OPENIDCONNET_URI,
    auth.getResourceAccessToken(process.env.REACT_APP_AUTH_RESOURCE_OPENID_API)
  );
};

/*
 **  Styles
 */
const useStyles = makeStyles((theme) => ({
  alert: { zIndex: 1, borderRadius: 0 },
}));

export default AppProvider;
export { useApp, useAPI, useAnalyticsAPI, useAccounts };
