/********************************************************************
 *
 * DateController.jsx
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2019 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Typography, Button, IconButton, makeStyles } from "@material-ui/core";
import { ChevronLeft, ChevronRight } from "@material-ui/icons";
import moment from "moment-timezone";

/**
 * DateController
 *
 * Renders a controller that navigates dates week-by-week.
 *
 * The @function onClick function is fired when the user changes the
 * date, which passes a @var moment representing the chosen date to
 * the event consumer.
 *
 * @param {*} props
 */
const DateController = ({ date, onChange }) => {
  const [startDate, setStartDate] = useState(moment());
  const [endDate, setEndDate] = useState(moment());
  const classes = styles();

  useEffect(() => {
    setStartDate(moment(date).startOf("week"));
    setEndDate(moment(date).endOf("week"));
  }, [date]);

  /////////////////////////////////////////////////////////////////////
  //
  //  Utility methods
  //
  /////////////////////////////////////////////////////////////////////

  const renderDateContent = (date) => {
    if (startDate.year() != endDate.year())
      return `${startDate.format("MMM YYYY")} - ${endDate.format("MMM YYYY")}`;
    else if (startDate.month() != endDate.month())
      return `${startDate.format("MMM")} - ${endDate.format("MMM YYYY")}`;
    else return startDate.format("MMMM YYYY");
  };

  ///////////////////////////////////////////////////////////////////
  //
  //  Event Handlers
  //
  ///////////////////////////////////////////////////////////////////

  const onToday = () => {
    onChange(moment().startOf("day"));
  };

  const onBack = () => {
    onChange(date.clone().subtract(1, "week").startOf("day"));
  };

  const onForward = () => {
    onChange(date.clone().add(1, "week").startOf("day"));
  };

  ///////////////////////////////////////////////////////////////////////
  //
  //  Lifecycle methods
  //
  ///////////////////////////////////////////////////////////////////////

  return (
    <div className={classes.container}>
      <div className={classes.inner}>
        <div className={classes.row}>
          <div className={classes.navigatorContainer}>
            <div className={classes.navigator}>
              <Button
                className={classes.today}
                onClick={onToday}
                variant="outlined"
              >
                Today
              </Button>
              <IconButton className={classes.iconButton} onClick={onBack}>
                <ChevronLeft />
              </IconButton>
              <IconButton className={classes.iconButton} onClick={onForward}>
                <ChevronRight />
              </IconButton>
            </div>
          </div>
          <div className={classes.dateContainer}>
            <div className={classes.date}>
              <div className={classes.browser}>
                <Typography className={classes.content}>
                  {renderDateContent(date)}
                </Typography>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

/*
 **  Styles
 */
const styles = makeStyles((theme) => ({
  container: {
    minWidth: "auto!important",
    textAlign: "right",
    lineHeight: 0,
    whiteSpace: "nowrap",
  },
  inner: {
    overflow: "hidden",
    display: "table-cell",
    height: "48px",
    verticalAlign: "middle",
    textAlign: "initial",
  },
  row: {
    alignItems: "center",
    display: "flex",
    lineHeight: "normal",
    marginLeft: "8px",
    width: "100%",
  },
  navigatorContainer: { display: "inline-block" },
  navigator: {
    display: "flex",
    marginRight: "4px",
    marginLeft: "0px",
    whiteSpace: "nowrap",
    alignItems: "center",
  },
  today: {
    border: "1px solid #dadce0;",
    boxSizing: "border-box",
    fontSize: "14px",
    height: "36px",
    marginRight: "12px",
    lineHeight: "20px",
    transition: "background-color 100ms linear",
    outline: "none!important",
  },
  iconButton: {
    height: "36px",
    width: "36px",
    outline: "none!important",
  },
  icon: { fontSize: "20px" },
  dateContainer: {
    display: "flex",
  },
  date: {
    display: "inline-block",
    lineHeight: 0,
    marginLeft: "8px",
    marginRight: "2px",
    overflow: "hidden",
    position: "relative",
  },
  browser: {
    padding: "8px",
    float: "left",
    fontSize: "16px",
    position: "relative",
    transition: "margin .3s cubic-bezier(0.4, 0, 0.2, 1)",
    textTransform: "none",
    textAlign: "left",
  },
  content: {
    color: theme.palette.primary.dark,
    fontSize: "22px",
    fontWeight: 200,
    letterSpacing: 0,
    lineHeight: "28px",
    whiteSpace: "nowrap",
  },
}));

/*
 **  PropTypes
 */
DateController.propTypes = {
  date: PropTypes.instanceOf(moment),
  onChange: PropTypes.func.isRequired,
};

export default DateController;
