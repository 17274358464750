/********************************************************************
 *
 * Page.jsx
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2020 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

import React from "react";
import { Switch, Route } from "react-router-dom";
import PublicThemeProvider from "../../themes/public";
import Header from "./Header";
import Schedule from "./Schedule";

/**
 * Page
 *
 * Page wrapper for publicly visible pages
 *
 * @param {*} param0
 */
export const Page = () => {
  return (
    <PublicThemeProvider>
      <Header />
      <Switch>
        <Route path="/public/schedule/:encodedId" component={Schedule} />
      </Switch>
    </PublicThemeProvider>
  );
};
