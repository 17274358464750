/********************************************************************
 *
 * CRM/Customers/Account/Account.jsx
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2020 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

import React, { useState, useEffect } from "react";
import AccountListing from "../../../lib/Account";

import { useApp, useAPI } from "../../../../providers/AppProvider";

/**
 * Account
 *
 * Renders the customer's account.
 *
 */
const Account = ({ customer }) => {
  const [account, setAccount] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const app = useApp();
  const api = useAPI();

  useEffect(() => {
    fetchAccount(customer.id);
  }, [customer]);

  ///////////////////////////////////////////////////////////////////
  //
  //  Utility methods
  //
  ///////////////////////////////////////////////////////////////////

  /**
   * FetchAccount
   *
   * @param {*} customerId
   */
  const fetchAccount = (customerId) => {
    if (!customerId) throw new Error("Parameter 'customerId' cannot be null");

    //
    //  Status message
    //
    const notifyHandle = app.notify("Loading Account");

    api
      .fetch(`/api/customers/customer/${customerId}/account/1970-01-01T00:00Z`)
      .then(({ payload: account }) => {
        setAccount(account);
        setLoaded(true);
        app.endNotify(notifyHandle);
      })
      .catch((error) => {
        app.error({ error });
        setAccount(null);
        setLoaded(true);
      });
  };

  ///////////////////////////////////////////////////////////////////
  //
  //  Lifecycle methods
  //
  ///////////////////////////////////////////////////////////////////

  if (!loaded)
    return <div className="spinner-border text-secondary" role="status" />;

  return (
    <>
      <h5>Account</h5>
      <AccountListing account={account} />
    </>
  );
};

export default Account;
