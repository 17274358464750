/********************************************************************
 *
 * Employees.jsx
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2019 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

import React, { useState, useEffect } from "react";
import { Button } from "@material-ui/core";
import { PeopleAlt } from "@material-ui/icons";
import { Main, CardList, LinkCard2, CardAction } from "../../lib";
import Pagination from "../../lib/Pagination";
import { EmployeeSummaryHeader, EmployeeSummary } from "./EmployeeSummary";
import { useApp, useAPI } from "../../../providers/AppProvider";

const PAGE_SIZE = 15;

/**
 *  Employees
 *
 *  Renders a card deck of Employees.
 *
 * @param {*} props
 */
const Employees = () => {
  const [employees, setEmployees] = useState(null);
  const [employeeCount, setEmployeeCount] = useState(0);
  const [currPage, setCurrPage] = useState(1);
  const [pageSize, setPageSize] = useState(PAGE_SIZE);
  const [loaded, setLoaded] = useState(false);
  const app = useApp();
  const api = useAPI();

  useEffect(() => {
    fetchEmployees();
  }, []);

  /////////////////////////////////////////////////////////////////////
  //
  //  Utility methods
  //
  /////////////////////////////////////////////////////////////////////

  const fetchEmployees = () => {
    //
    //  Status message
    //
    const notifyHandle = app.notify("Loading");

    api
      .fetch(`/api/employees`)
      .then(({ payload: employees, max: count }) => {
        setEmployees(employees);
        setEmployeeCount(count);
        setLoaded(true);
        app.endNotify(notifyHandle);
      })
      .catch((error) => {
        app.error({ error });
        setLoaded(true);
      });
  };

  ///////////////////////////////////////////////////////////////////////
  //
  //  Event Handlers
  //
  ///////////////////////////////////////////////////////////////////////

  const onTurnPage = (e) => {
    setCurrPage(e.currentPage);
    setPageSize(e.pageSize);
  };

  ///////////////////////////////////////////////////////////////////////
  //
  //  Lifecycle methods
  //
  ///////////////////////////////////////////////////////////////////////

  return (
    <Main
      header={{
        icon: <PeopleAlt />,
        title: "Staff",
        bonus: (
          <Button
            variant="outlined"
            color="primary"
            href="/app/admin/employee/"
          >
            Create a new employee
          </Button>
        ),
      }}
      loaded={loaded}
    >
      {employees && (
        <>
          <CardList
            xs={6}
            md={4}
            spacing={2}
            items={employees}
            onRenderCard={({ item: employee }) => (
              <LinkCard2
                link={`/app/admin/employee/${employee.id}`}
                header={<EmployeeSummaryHeader employee={employee} />}
                content={<EmployeeSummary employee={employee} />}
                actions={
                  <>
                    <CardAction to="#" label="Email" />
                    <CardAction to="#" label="Delete" />
                  </>
                }
              />
            )}
          />
          <br />
          <Pagination
            className={"small"}
            currPage={currPage}
            totalRecords={employeeCount}
            pageSize={pageSize}
            pageNeighbours={1}
            onPageChanged={onTurnPage}
          />
        </>
      )}
    </Main>
  );
};

export default Employees;
