/**
 * ShiftStatus.jsx
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2019 Canadian Coastal Inc. All rights reserved.
 */

import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faShareSquare,
  faUserCheck,
  faUserTimes,
} from "@fortawesome/free-solid-svg-icons";

const ShiftStatus = ({ status }) => {
  return (
    <>
      {status === 0 && (
        <FontAwesomeIcon className="text-secondary" icon={faShareSquare} />
      )}
      {status === 1 && (
        <FontAwesomeIcon className="text-primary" icon={faUserCheck} />
      )}
      {status === 2 && (
        <FontAwesomeIcon className="text-danger" icon={faUserTimes} />
      )}
    </>
  );
};

export default ShiftStatus;
