/********************************************************************
 *
 * Employee.jsx
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2019 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

import React, { useState, useEffect } from "react";
import {
  Box,
  Grid,
  Avatar,
  Card,
  CardHeader,
  CardContent,
  Tooltip,
  IconButton,
  Typography,
} from "@material-ui/core";
import { RotateLeftOutlined } from "@material-ui/icons/";
import { Main } from "../../lib";
import Shifts from "./Shifts";
import { useApp, useAPI, useAccounts } from "../../../providers/AppProvider";

/**
 * Employee
 *
 * Renders information about an employee
 *
 * @param {*} props
 */
const Employee = ({ match }) => {
  const [employee, setEmployee] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const app = useApp();
  const api = useAPI();

  const { id } = match.params;

  useEffect(() => {
    fetchEmployee(id);
  }, [id]);

  /////////////////////////////////////////////////////////////////////
  //
  //  Utility methods
  //
  /////////////////////////////////////////////////////////////////////

  const fetchEmployee = (id) => {
    //
    //  Status message
    //
    const notifyHandle = app.notify("Loading");

    api
      .fetch(`/api/employees/employee/${id}`)
      .then(({ payload: employee }) => {
        setEmployee(employee);
        setLoaded(true);
        app.endNotify(notifyHandle);
      })
      .catch((error) => {
        app.error({ error });
        setLoaded(true);
      });
  };

  ///////////////////////////////////////////////////////////////////////
  //
  //  Lifecycle methods
  //
  ///////////////////////////////////////////////////////////////////////

  return (
    <Main
      header={{
        icon: employee && <Avatar>{employee.fname[0]}</Avatar>,
        title: employee
          ? `${employee.fname} ${employee.lname} - ${employee.role}`
          : "New Employee",
      }}
      loaded={loaded}
    >
      {employee && (
        <Box style={{ display: "flex", gap: 24 }}>
          <Box style={{ flex: "2 1" }}>
            <Shifts employee={employee} start={"2019-06-01T00:00:00Z"} />
          </Box>
          <Box
            style={{
              display: "flex",
              flex: "1 1",
              flexDirection: "column",
              gap: 24,
            }}
          >
            <Contact employee={employee} />
            <Access employee={employee} />
          </Box>
        </Box>
      )}
    </Main>
  );
};

const Access = ({ employee }) => {
  const app = useApp();
  const api = useAccounts();

  if (!employee) return "";

  const reset = (employee) => {
    if (
      !window.confirm(`This will reset ${employee.fname}'s account. Proceed?`)
    )
      return;

    //
    //  Status message
    //
    const notifyHandle = app.notify("Resetting user account");

    api
      .create(`/admin/notify/user/${employee.id}/configure`)
      .then(() => {
        app.endNotify(notifyHandle);
        window.alert("Account reset.");
      })
      .catch((error) => {
        app.error({ error });
      });
  };

  return (
    <Card>
      <CardHeader
        title="Access"
        action={
          <Tooltip title="Reset password">
            <IconButton
              aria-label="settings"
              onClick={() => {
                reset(employee);
              }}
            >
              <RotateLeftOutlined />
            </IconButton>
          </Tooltip>
        }
      />
      <CardContent></CardContent>
    </Card>
  );
};

const Contact = ({ employee }) => (
  <Card>
    <CardHeader title="Contact" />
    <CardContent>
      <Grid container>
        {employee.phone && (
          <>
            <Grid item xs={4}>
              <Typography>Phone</Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography>{employee.phone}</Typography>
            </Grid>
          </>
        )}
        <Grid item xs={4}>
          <Typography>Email</Typography>
        </Grid>
        <Grid item xs={8}>
          <Typography>
            <a href={`mailto:${employee.email}`}>{employee.email}</a>
          </Typography>
        </Grid>
      </Grid>
    </CardContent>
  </Card>
);
export default Employee;
